<template>
  <div class="step">
    <h2>아크릴 카라비너 모양</h2>
    <div class="carabinerBox clearFix">
      <ul class="carabinerBtns">
        <li
          class="carabinerBtn"
          :class="{ active: shapeNum === 0 }"
          @click="checkShape(0, '하트형')"
          @keydown="checkShape(0, '하트형')"
        >
          <div class="clearFix">
            <p>하트형</p>
            <span>(크기 9*7cm)</span>
            <hr />
            <img
              v-if="shapeNum === 0"
              src="@/assets/goods/heart_active.svg"
              alt=""
              class="shape_img"
            />
            <img v-else src="@/assets/goods/heart_nomal.svg" alt="" class="shape_img" />
          </div>
        </li>
        <li
          class="carabinerBtn"
          :class="{ active: shapeNum === 1 }"
          @click="checkShape(1, '별형')"
          @keydown="checkShape(1, '별형')"
        >
          <div class="clearFix">
            <p>별형</p>
            <span>(크기 8*8cm)</span>
            <hr />
            <img
              v-if="shapeNum === 1"
              src="@/assets/goods/star_active.svg"
              alt=""
              class="shape_img"
            />
            <img v-else src="@/assets/goods/star_nomal.svg" alt="" class="shape_img" />
          </div>
        </li>
        <li
          class="carabinerBtn"
          :class="{ active: shapeNum === 2 }"
          @click="checkShape(2, '자유형')"
          @keydown="checkShape(2, '자유형')"
        >
          <div class="clearFix">
            <p>자유형</p>
            <hr />
            <img
              v-if="shapeNum === 2"
              src="@/assets/goods/freedom_active.svg"
              alt=""
              class="shape_img"
            />
            <img v-else src="@/assets/goods/freedom_nomal.svg" alt="" class="shape_img" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    shapeNum: {
      type: Number,
    },
  },
  methods: {
    checkShape(i, shape) {
      this.$emit('checkShape', 'shape', i, shape)
    },
  },
}
</script>
<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .carabinerBox {
    font-weight: 400;

    > .carabinerBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;

          > div {
            > hr {
              margin-top: 28px;
            }
          }
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 144px;
          height: 185px;
          margin: 26px auto 0;
          vertical-align: middle;

          > p {
            font-size: 16px;
            color: #707070;
          }

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 8px;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 110px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
