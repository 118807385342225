<template>
  <div class="announcementPopupWrap">
    <Announcement
      v-for="(announcement, index) in announcementList"
      :key="index"
      class="announcementPopupBox"
      :announcement="announcement"
      :index="index"
      @close-tab="closetab"
    />
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import Announcement from '../../components/Modal/Announcement.vue'

export default {
  components: {
    Announcement,
  },
  data() {
    return {
      announcementList: [],
    }
  },
  async mounted() {
    await this.getAnnouncement()
  },
  methods: {
    async closetab(index) {
      this.announcementList.splice(index, 1)
    },
    // 팝업 목록 조회
    async getAnnouncement() {
      await this.axios
        .get(this.Path.GetAnnouncement)
        .then((res) => {
          const listArr = []
          // eslint-disable-next-line no-restricted-syntax
          for (const data of res.data.data) {
            const value = Cookie.get(data.title)
            if (!value) {
              listArr.push(data)
            }
          }
          this.announcementList = listArr
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.announcementPopupWrap {
}
</style>
