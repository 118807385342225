<template>
  <div class="couponWrap">
    <div class="couponLength">
      <span class="left">내 쿠폰</span>
      <span class="right">{{ coupon_list.length }}개</span>
    </div>
    <coupon-list :coupon-list="coupon_list" @coupon-registration="couponRegistration" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import couponList from '../../components/MyPage/CouponList.vue'

export default {
  components: {
    couponList,
  },
  data() {
    return {
      coupon_list: [],
    }
  },
  mounted() {
    this.getCouponList()
  },
  methods: {
    // 유저 쿠폰 목록 조회
    getCouponList() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios.get(this.Path.MyCouponList, config).then((res) => {
        if (res.data.success === true) {
          this.coupon_list = res.data.data
        }
      })
    },
    // 쿠폰 등록
    couponRegistration(number) {
      if (!number) {
        this.Message('쿠폰 번호를 입력해주세요.', 0)
        return
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .post(
          this.Path.CouponRegistration,
          {
            coupon_number: number,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('쿠폰이 등록되었습니다.', 0)
            this.coupon_list.push(res.data.data)
          }
        })
        .catch((err) => {
          this.Message(this.ErrorMessage.CouponRegistration(err.response.status), 0, 0)
        })
    },
  },
  computed: {
    ...mapGetters({
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.couponWrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  color: #707070;

  > .couponLength {
    width: 100%;
    background-color: #ffe1ec;
    border-radius: 10px;
    height: 179px;
    line-height: 179px;
    margin-top: 50px;
    margin-bottom: 40px;

    > .left {
      float: left;
      font-size: 26px;
      margin-left: 58px;
    }

    > .right {
      float: right;
      font-size: 26px;
      margin-right: 58px;
    }
  }
}
</style>
