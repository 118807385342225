<template>
  <div class="cutting">
    <h1>{{ guideNum }}. 커팅 레이어 작업</h1>
    <!-- type === '아크릴 키링'  -->
    <div v-show="type === '아크릴 키링'" class="typeWrap">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/cham_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대 량을 최소 2mm가 되게 설정해 주세요. <br />(300dpi기준 24픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <img src="@/assets/guide/cham_cutting_5.png" alt="" />
        <figure>2mm 이상 확인</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_6.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>11. 틈이 있는 부분은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_cutting_7.png" alt="" /><img
              src="@/assets/guide/cham_cutting_8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>12. 외곽의 굴곡진 부분을 둥글게 작업합니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_cutting_9.png" alt="" /><img
              src="@/assets/guide/cham_cutting_10.png"
              alt=""
            />
          </div>
          <figure>모서리의 각진 부분 → 둥글게 처리</figure>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 카드텍'  -->
    <div v-show="type === '아크릴 카드텍'" class="typeWrap">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_cutting_1.png" alt="" />
        </div>
        <div class="card_img_1 right">
          <p>1. 이미지를 클릭합니다.</p>
          <p>2. 캔버스 '크기'를 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_cutting_2.png" alt="" />
        </div>
        <div class="card_img_2 right">
          <p>3. '크기'를 클릭하면 '캔버스 크기'창이 뜹니다.</p>
          <p>4. '새로운 크기'의 폭과 높이를 4mm를 더해줍니다.</p>
          <p>( ex. 폭: 340 / 높이: 209 → 폭: 344 / 높이: 213 )</p>
          <p>※이보다 작을 경우, 커팅 시 화이트 인쇄가 손상될 수 있습니다.</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_cutting_3.png" alt="" />
        </div>
        <div class="card_img_3 right">
          <p>5. 새 레이어를 추가합니다.</p>
          <p>6. 페인트 통 툴을 사용하여 검은색으로 채웁니다.</p>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 스탠드'  -->
    <div v-show="type === '아크릴 스탠드'" class="typeWrap">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_cutting_1.png" alt="" />
        </div>
        <div class="stand_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/stand_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_cutting_3.png" alt="" />
        </div>
        <div class="stand_img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_cutting_4.png" alt="" />
        </div>
        <div class="stand_img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대 량을 최소 2mm가 되게 설정해 주세요. <br />(300dpi기준 24픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <img src="@/assets/guide/stand_cutting_5.png" alt="" />
        <figure>2mm 이상 확인</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_cutting_6.png" alt="" />
        </div>
        <div class="stand_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>11. 틈이 있는 부분은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/stand_cutting_7.png" alt="" /><img
              src="@/assets/guide/stand_cutting_8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>12. 외곽의 굴곡진 부분을 둥글게 작업합니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/stand_cutting_9.png" alt="" /><img
              src="@/assets/guide/stand_cutting_10.png"
              alt=""
            />
          </div>
          <figure>모서리의 각진 부분 → 둥글게 처리</figure>
        </div>
      </div>
    </div>
    <!-- type === '자유형 책갈피' -->
    <div v-show="type === '자유형 책갈피'" class="typeWrap">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/cham_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대 량을 최소 2mm가 되게 설정해 주세요. <br />(300dpi기준 24픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <img src="@/assets/guide/cham_cutting_5.png" alt="" />
        <figure>2mm 이상 확인</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_cutting_6.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>11. 틈이 있는 부분은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_cutting_7.png" alt="" /><img
              src="@/assets/guide/cham_cutting_8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>12. 외곽의 굴곡진 부분을 둥글게 작업합니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_cutting_9.png" alt="" /><img
              src="@/assets/guide/cham_cutting_10.png"
              alt=""
            />
          </div>
          <figure>모서리의 각진 부분 → 둥글게 처리</figure>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 바닥 부품' -->
    <div v-show="type === '아크릴 바닥 부품'" class="typeWrap">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_cutting_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/floor_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_cutting_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_cutting_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대 량을 최소 2mm가 되게 설정해 주세요. <br />(300dpi기준 24픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <img src="@/assets/guide/floor_cutting_5.png" alt="" />
        <figure>2mm 이상 확인</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_cutting_6.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>11. 틈이 있는 부분은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/floor_cutting_7.png" alt="" /><img
              src="@/assets/guide/floor_cutting_8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
    </div>
    <!-- type === '아크릴 램프' -->
    <div v-show="type === '아크릴 램프'" class="typeWrap lamp">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_cutting_1.png" alt="" />
        </div>
        <div class="lamp_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/lamp_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_cutting_3.png" alt="" />
        </div>
        <div class="lamp_img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_cutting_4.png" alt="" />
        </div>
        <div class="lamp_img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대 량을 최소 2mm가 되게 설정해 주세요. <br />(300dpi기준 24픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_cutting_5.png" alt="" />
        </div>
        <div class="lamp_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <img src="@/assets/guide/lamp_cutting_6.png" alt="" />
        <figure>2mm 이상 확인</figure>
      </div>
      <!--  -->
      <!--  -->
      <div>
        <div class="text">
          <p>11. 틈이 있는 부분은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/stand_cutting_7.png" alt="" /><img
              src="@/assets/guide/stand_cutting_8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>12. 외곽의 굴곡진 부분을 둥글게 작업합니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/stand_cutting_9.png" alt="" /><img
              src="@/assets/guide/stand_cutting_10.png"
              alt=""
            />
          </div>
          <figure>모서리의 각진 부분 → 둥글게 처리</figure>
        </div>
      </div>
      <!--  -->
    </div>
    <!-- type === '부착형 아크릴' -->
    <div v-show="type === '부착형 아크릴'" class="typeWrap lamp">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_cutting_1.png" alt="" />
        </div>
        <div class="attach_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/attach_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_cutting_3.png" alt="" />
        </div>
        <div class="attach_img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_cutting_4.png" alt="" />
        </div>
        <div class="attach_img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대 량을 최소 2mm가 되게 설정해 주세요. <br />(300dpi기준 24픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_cutting_5.png" alt="" />
        </div>
        <div class="attach_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <img src="@/assets/guide/attach_cutting_6.png" alt="" />
        <figure>2mm 이상 확인</figure>
      </div>
      <!--  -->
      <!--  -->
      <div>
        <div class="text">
          <p>11. 틈이 있는 부분은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/attach_cutting_7.png" alt="" /><img
              src="@/assets/guide/attach_cutting_8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>12. 외곽의 굴곡진 부분을 둥글게 작업합니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/stand_cutting_9.png" alt="" /><img
              src="@/assets/guide/stand_cutting_10.png"
              alt=""
            />
          </div>
          <figure>모서리의 각진 부분 → 둥글게 처리</figure>
        </div>
      </div>
      <!--  -->
    </div>
    <!-- type === '아크릴 블럭' -->
    <div v-show="type === '아크릴 블럭'" class="typeWrap lamp">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_cutting_1.png" alt="" />
        </div>
        <div class="block_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/block_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_cutting_3.png" alt="" />
        </div>
        <div class="block_img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_cutting_4.png" alt="" />
        </div>
        <div class="block_img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대 량을 최소 3mm가 되게 설정해 주세요. <br />(300dpi기준 36픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_cutting_5.png" alt="" />
        </div>
        <div class="block_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <img src="@/assets/guide/block_cutting_6.png" alt="" />
        <figure>3mm 이상 확인</figure>
      </div>
      <!--  -->
      <!--  -->
      <div>
        <div class="text">
          <p>11. 틈이 있는 부분은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/attach_cutting_7.png" alt="" /><img
              src="@/assets/guide/attach_cutting_8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>12. 외곽의 굴곡진 부분을 둥글게 작업합니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/stand_cutting_9.png" alt="" /><img
              src="@/assets/guide/stand_cutting_10.png"
              alt=""
            />
          </div>
          <figure>모서리의 각진 부분 → 둥글게 처리</figure>
        </div>
      </div>
      <!--  -->
    </div>
    <!-- type === '쉐이커 키링' -->
    <div v-show="type === '쉐이커 키링'" class="typeWrap lamp">
      <div>
        <p>커팅레이어는 아크릴이 <span>실제로 제작되는 영역</span>을 나타냅니다.</p>
        <p>※모든 이미지가 커팅 레이어 안으로 들어와야 합니다.</p>
        <p>(커팅 레이어가 캔버스 크기를 벗어나지 않도록 작업해주세요.)</p>
      </div>
      <!-- 1. 쉐이커 앞면/뒷면 커팅 레이어 -->
      <div class="img_half clearFix">
        <h3>1. 쉐이커 앞면/뒷면 커팅 레이어</h3>
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/shaker_keyring_cutting_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_4.png" alt="" />
        </div>
        <div class="shaker_keyring_img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대량을 최소 0.5 ~ 2mm가 되게 설정해 주세요. <br />(300dpi기준 6~26픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_5.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="img">
          <div>
            <div>
              <img src="@/assets/guide/shaker_keyring_cutting_6.png" alt="" />
              <figure>0.5mm 이상 확인</figure>
            </div>
            <div>
              <img src="@/assets/guide/shaker_keyring_cutting_7.png" alt="" />
              <figure>2mm 이상 확인</figure>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <div class="text">
          <p>11. Ctrl+J를 2번 하여 커팅 레이어를 2번 복사합니다.</p>
        </div>
        <img src="@/assets/guide/shaker_keyring_cutting_8.png" alt="" />
      </div>
      <!--  -->
      <div class="img_full">
        <div class="text">
          <p>
            12. 폴더를 생성하여 앞면/뒷면/파츠로 폴더 이름을 생성합니다.<br />각 폴더에 복사한 커팅
            레이어를 넣습니다.
          </p>
        </div>
        <img src="@/assets/guide/shaker_keyring_cutting_9.png" alt="" />
      </div>
      <!--  -->
      <!-- 2. 쉐이커 파츠 커팅레이어 -->
      <div class="img_half clearFix">
        <h3>2. 쉐이커 파츠 커팅레이어</h3>
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_10.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <img src="@/assets/guide/shaker_keyring_cutting_11.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_12.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '확대'를 클릭합니다.<br />(선택→수정→확대)</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_13.png" alt="" />
        </div>
        <div class="shaker_keyring_img_4 right">
          <p>6. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
          <p>7. 확대량을 최소 0.5 ~ 2mm가 되게 설정해 주세요. <br />(300dpi기준 6~26픽셀 권장)</p>
          <p>*300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
          <p>※이보다 작을 경우, 커팅 시 일러스트가 손상될 수 있습니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_cutting_14.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 검은색으로 채웁니다.<br />(페인트 통을 부으면 외곽 부분이
            흐릿해지는 경우도 있으니<br />
            전체적으로 확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="img">
          <div>
            <div>
              <img src="@/assets/guide/shaker_keyring_cutting_6.png" alt="" />
              <figure>0.5mm 이상 확인</figure>
            </div>
            <div>
              <img src="@/assets/guide/shaker_keyring_cutting_7.png" alt="" />
              <figure>2mm 이상 확인</figure>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div>
        <div class="text">
          <p>11. 빈 공간은 채워 넣습니다.</p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/shaker_keyring_cutting_15.png" alt="" /><img
              src="@/assets/guide/shaker_keyring_cutting_16.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="img_full">
        <div class="text">
          <p>12. 커팅 레이어와 화이트 레이어를 작업한 파츠 도안을 쉐이커파츠 폴더에 넣습니다.</p>
        </div>
        <img src="@/assets/guide/shaker_keyring_cutting_17.png" alt="" />
      </div>
      <!--  -->
      <div class="img_full">
        <div class="text">
          <p>
            13. 복사한 뒷면 커팅 레이어(판 커팅 레이어) 안에 파츠 도안들을 작업하여 배치합니다.<br />파츠
            도안들은 뒷면 커팅 레이어(판 커팅 레이어) 안 쪽에 배치되도록 합니다.
          </p>
        </div>
        <img src="@/assets/guide/shaker_keyring_cutting_18.png" alt="" />
      </div>
      <!--  -->
      <div>
        <div class="img">
          <h3 class="red">
            ※ 파츠 도안과 도안 사이의 거리는 1mm 이상으로 배치해주셔야 합니다.<br />뒷면 커팅
            레이어(판 커팅 레이어)와 파츠 도안 사이의 거리는 4mm 이상으로 배치해주셔야 합니다.
            <br />※ 쉐이커 프레임 보다 크게 작업된 파츠는 임의로 크기가 수정될 수 있습니다. <br />※
            쉐이커 중간면의 두께는 5mm이기 때문에 쉐이커 파츠가 5mm보다 작을 경우, 뒤집힐 수
            있습니다.
          </h3>
          <div>
            <div>
              <img src="@/assets/guide/shaker_keyring_cutting_19.png" alt="" />
              <figure>파츠와 파츠 사이의 거리 1mm 이상 확인</figure>
            </div>
            <div>
              <img src="@/assets/guide/shaker_keyring_cutting_20.png" alt="" />
              <figure>커팅 레이어 사이의 거리 4mm 이상 확인</figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    guideNum() {
      let num = 4
      if (this.type === '아크릴 바닥 부품') {
        num = 5
      } else {
        num = 4
      }
      return num
    },
  },
}
</script>

<style scoped lang="scss">
.cutting {
  text-align: center;
  h1 {
    width: 100%;
  }

  h3 {
    text-align: left;
    margin-bottom: 10px;
    color: #707070;
    font-size: 20px;

    &.red {
      text-align: center;
      font-size: 27px;
      color: #ff6699;
      margin-bottom: 20px;
    }
  }

  > .typeWrap {
    > div {
      margin-top: 100px;

      > img {
        margin-top: 20px;
      }

      > .img {
        margin-top: 30px;

        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      figure {
        margin-top: 10px;
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 20px;

        > span {
          color: #ff6699;
        }
      }

      &.img_half {
        > div {
          float: left;
          text-align: left;

          &.right {
            margin-left: 35px;
          }

          &.img_1 {
            margin-top: 200px;
          }
          &.img_3 {
            margin-top: 120px;
          }
          &.img_4 {
            margin-top: 140px;
          }
          &.img_6 {
            margin-top: 60px;
          }

          &.card_img_1 {
            margin-top: 80px;
          }
          &.card_img_2 {
            margin-top: 50px;
          }
          &.card_img_3 {
            margin-top: 80px;
          }

          &.stand_img_1 {
            margin-top: 150px;
          }
          &.stand_img_3 {
            margin-top: 50px;
          }
          &.stand_img_4 {
            margin-top: 50px;
          }
          &.stand_img_6 {
            margin-top: 80px;
          }

          &.lamp_img_1 {
            margin-top: 150px;
          }
          &.lamp_img_3 {
            margin-top: 50px;
          }
          &.lamp_img_4 {
            margin-top: 50px;
          }
          &.lamp_img_6 {
            margin-top: 80px;
          }

          &.attach_img_1 {
            margin-top: 100px;
          }
          &.attach_img_3 {
            margin-top: 50px;
          }
          &.attach_img_4 {
            margin-top: 50px;
          }
          &.attach_img_6 {
            margin-top: 80px;
          }

          &.block_img_1 {
            margin-top: 100px;
          }
          &.block_img_3 {
            margin-top: 50px;
          }
          &.block_img_4 {
            margin-top: 50px;
          }
          &.block_img_6 {
            margin-top: 80px;
          }

          &.shaker_keyring_img_4 {
            margin-top: 220px;
          }
        }
      }
    }
  }
}
</style>
