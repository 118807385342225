<template>
  <div class="step">
    <div class="quantityBox">
      <ul class="clearFix">
        <li class="totalQuantity">
          <h2>쉐이커 프레임 구멍 개수</h2>
          <div class="quantityBtns">
            <div class="minusBtn" @click="boltCheck(1, 'minus')" @keydown="boltCheck(1, 'minus')">
              <img src="@/assets/goods/quantity_minus.svg" alt="" />
            </div>
            <div class="quantity">
              <input id="goods_quantity" v-model="setBolt" type="number" @change="boltChange(1)" />
            </div>
            <div class="plusBtn" @click="boltCheck(1, 'plus')" @keydown="boltCheck(1, 'plus')">
              <img src="@/assets/goods/quantity_plus.svg" alt="" />
            </div>
          </div>
        </li>
      </ul>
      <div class="referenceBox">
        <div>
          <img src="@/assets/icon/questionIcon.svg" alt="" />
          <span>쉐이커 프레임 구멍 수량 기입 방법</span>
        </div>
        <div class="speech_bubble">
          <div class="bubble_text">
            ex)
            <div>
              <img src="@/assets/goods/shaker_frame_hole.png" alt="" /><span
                >쉐이커 프레임 구멍 수량 <span class="red">2개</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bolt: {
      type: Number,
    },
    shakerSizeHole: {
      type: Boolean,
    },
  },
  data() {
    return {
      setBolt: this.bolt,
    }
  },
  watch: {
    setBolt(to, from) {
      let defalutNum = 2
      if (this.shakerSizeHole === true) {
        defalutNum = 3
      }
      if (to < defalutNum || !to) {
        this.setBolt = defalutNum
      }
    },
    bolt() {
      this.setBolt = this.bolt
    },
  },
  methods: {
    boltChange(i) {
      this.$emit('boltChange', 'bolt', i, this.setBolt)
    },
    boltCheck(i, type) {
      let sendBolt = 0
      let defalutNum = 2
      if (this.shakerSizeHole === true) {
        defalutNum = 3
      }
      if (type === 'minus') {
        if (this.setBolt > defalutNum) {
          sendBolt = this.setBolt - 1
        } else {
          sendBolt = defalutNum
        }
      } else {
        sendBolt = this.setBolt + 1
      }
      this.$emit('boltCheck', 'bolt', i, sendBolt)
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 10px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .quantityBox {
    > ul {
      > li {
        float: left;
        width: 277px;
        height: 35px;
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }

        > .quantityBtns {
          border: 1px solid #bcbcbc;
          border-radius: 5px;
          height: 100%;
          box-sizing: border-box;
          cursor: pointer;

          > div {
            display: inline-block;
            line-height: 33px;
            text-align: center;

            &.minusBtn {
              width: 15%;
              float: left;
            }

            &.quantity {
              width: 70%;
              font-size: 13px;
              color: #707070;
              text-align: center;

              > input {
                width: 30px;
                padding-right: 6px;
                height: 21px;
                background-color: #fafafa;
                border: none;
                border-radius: 5px;
                font-size: 13px;
                color: #707070;
                text-align: right;
              }
            }

            &.plusBtn {
              width: 15%;
              float: right;
            }

            > img {
              width: 13px;
            }
          }
        }
      }
    }

    > .referenceBox {
      margin-top: 40px;
      position: relative;

      > div {
        > span {
          font-size: 12px;
          color: #ff6699;
          margin-left: 3px;
        }
      }
      > .speech_bubble {
        > .bubble_text {
          color: #ff6699;

          > div {
            color: #707070;
            text-align: center;

            > img {
              width: 150px;
              margin-right: 20px;
            }
            .red {
              color: #ff6699;
            }
          }
        }
      }
    }
  }
}
</style>
