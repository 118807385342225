<template>
  <div class="letterWrap">
    <div class="popupBg" @click="closeTab()" @keydown="closeTab()" />
    <div class="letterBox">
      <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <div class="letter">
        <h2>바닥 부품 유의사항</h2>
        <hr />
        <div class="textBox">
          <p class="title">[바닥 부품 없음 선택 시 유의사항]</p>
          <p class="content">
            · <span class="red">'바닥 부품 없음'</span>을 선택 시<span class="red">
              바닥 부품이 없이 배송</span
            >됩니다.
            <br />
            · 기본 바닥 부품을 원하는 경우 바닥 부품 크기를 선택하여 주문 부탁드립니다.
            <br />
            · 바닥 부품 인쇄 및 다양한 모양으로 제작을 원할 시<br />&nbsp; 바닥 부품 제작 페이지를
            이용 부탁드립니다.
          </p>
        </div>
        <div class="textBox">
          <p class="title">[바닥 부품이란?]</p>
          <p class="content">
            아크릴 스탠드를 꽂아 <span class="red">세울 수 있는 아크릴 받침대를 의미합니다.</span>
          </p>
        </div>
        <div class="textBox">
          <p class="title">[기본 바닥부품이란?]</p>
          <p class="content">
            동네공작소에서 제공하는 타원형의 <span class="red">기본 받침대</span><br />(기본
            바닥부품 크기: 4 x 3cm / 6 x 4cm / 8 x 6cm 두께 5T(약 0.5cm))
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: String,
    },
  },
  methods: {
    async closeTab() {
      this.$emit('close-tab')
    },
  },
}
</script>

<style scoped lang="scss">
.letterWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: block;

  > .popupBg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  > .letterBox {
    width: 608px;
    height: 434px;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 1001;

    > .closeTab {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    > .letter {
      width: 501px;
      margin: 47px auto 0;

      > h2 {
        font-size: 20px;
        font-weight: bold;
        color: #ff6699;
      }

      > hr {
        width: 100%;
        height: 2px;
        background-color: #ff6699;
        border: none;
        margin-top: 14px;
        margin-bottom: 39px;
      }

      > .textBox {
        margin-bottom: 17px;
        text-align: left;

        > .title {
          font-size: 16px;
          color: #ff6699;
        }

        > .content {
          font-size: 14px;
          color: #414141;

          > .red {
            color: #ff6699;
          }
        }
      }
    }
  }
}
</style>
