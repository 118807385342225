<template>
  <div class="newsBox">
    <h1>{{ news_data.title }}</h1>
    <div class="contentsWrap clearFix">
      <span>{{ moment(news_data.createdAt).format('YYYY-MM-DD') }}</span>
    </div>
    <div class="newsContent ql-editor" v-if="$route.params.type === 'Notice'">
      <div v-html="news_data.content"></div>
    </div>
    <div class="contentImage" v-if="$route.params.type === 'Event'">
      <img :src="news_data.content" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news_data: {
      type: Object,
    },
  },
}
</script>

<style scoped lang="scss">
.newsBox {
  > h1 {
    margin-top: 40px;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 13px;
  }

  > .contentsWrap {
    border-bottom: 1px solid #ff6699;
    margin-bottom: 34px;

    > span {
      float: right;
      font-size: 16px;
    }
  }

  > .newsContent {
    width: 100%;
    margin: 0 auto 50px;
    overflow-wrap: break-word;

    > div {
      white-space: pre-line;
    }
  }

  > .contentImage {
    text-align: center;
  }
}
</style>
