<template>
  <div class="letterWrap">
    <div class="popupBg" @click="closeTab()" @keydown="closeTab()" />
    <div class="letterBox">
      <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <div class="letter">
        <h2>일러스트 업로드 안내</h2>
        <hr />
        <div class="textBox">
          <p class="title">[일러스트 용량]</p>
          <p class="content">
            업로드 가능한 <span class="red">일러스트의 최대 용량은 5M입니다.</span><br />
            <span class="red">5M 이상</span>의 일러스트는 업로드가
            <span class="res">불가능</span>합니다.
          </p>
        </div>
        <div v-show="params === 'Quoin'" class="textBox">
          <p class="title">[인식 불가 일러스트]</p>
          <p class="content">
            · 4px 이하의 얇은 선(ex. 잔머리카락, 악세서리 등)<br />
            · 복잡한 선으로 이루어진 일러스트(ex. 선으로 된 문양 등)
            <br />
            · 두 개이상의 개체로 제작된 일러스트
          </p>
        </div>
        <div v-show="params === 'Quoin'" class="textBox">
          <p class="title">[일러스트 사이즈 자동 인식]</p>
          <p class="content">
            제품의 사이즈는 업로드된 일러스트의 사이즈를 자동 인식하여 제작되며,<br />임의로
            사이즈를 조절 할 수 없습니다.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: String,
    },
  },
  methods: {
    async closeTab() {
      this.$emit('close-tab')
    },
  },
}
</script>

<style scoped lang="scss">
.letterWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: block;

  > .popupBg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  > .letterBox {
    width: 608px;
    height: 434px;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 1001;

    > .closeTab {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    > .letter {
      width: 501px;
      margin: 47px auto 0;

      > h2 {
        font-size: 20px;
        font-weight: bold;
        color: #ff6699;
      }

      > hr {
        width: 100%;
        height: 2px;
        background-color: #ff6699;
        border: none;
        margin-top: 14px;
        margin-bottom: 39px;
      }

      > .textBox {
        margin-bottom: 17px;
        text-align: left;

        > .title {
          font-size: 16px;
          color: #ff6699;
        }

        > .content {
          font-size: 14px;
          color: #414141;

          > .red {
            color: #ff6699;
          }
        }
      }
    }
  }
}
</style>
