<template>
  <div class="step">
    <h2>관람차 바퀴 크기</h2>
    <div class="WheelSizeBox clearFix">
      <ul class="WheelSizeBtns">
        <li
          class="WheelSizeBtn"
          :class="{ active: wheelNum === 0 }"
          @click="checkWheel(0, '5인용')"
          @keydown="checkWheel(0, '5인용')"
        >
          <div class="clearFix">
            <p>5인용</p>
            <span>(지름: 12cm - 키링: 5개)</span>
            <hr />
            <img
              v-if="wheelNum === 0"
              src="@/assets/goods/wheel_size_5_active.svg"
              alt=""
              class="wheel_img"
            />
            <img v-else src="@/assets/goods/wheel_size_5_nomal.svg" alt="" class="wheel_img" />
          </div>
        </li>
        <li
          class="WheelSizeBtn"
          :class="{ active: wheelNum === 1 }"
          @click="checkWheel(1, '6인용')"
          @keydown="checkWheel(1, '6인용')"
        >
          <div class="clearFix">
            <p>6인용</p>
            <span>(지름: 14cm - 키링: 6개)</span>
            <hr />
            <img
              v-if="wheelNum === 1"
              src="@/assets/goods/wheel_size_6_active.svg"
              alt=""
              class="wheel_img"
            />
            <img v-else src="@/assets/goods/wheel_size_6_nomal.svg" alt="" class="wheel_img" />
          </div>
        </li>
        <li
          class="WheelSizeBtn"
          :class="{ active: wheelNum === 2 }"
          @click="checkWheel(2, '7인용')"
          @keydown="checkWheel(2, '7인용')"
        >
          <div class="clearFix">
            <p>7인용</p>
            <span>(지름: 15cm - 키링: 7개)</span>
            <hr />
            <img
              v-if="wheelNum === 2"
              src="@/assets/goods/wheel_size_7_active.svg"
              alt=""
              class="wheel_img"
            />
            <img v-else src="@/assets/goods/wheel_size_7_nomal.svg" alt="" class="wheel_img" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    wheelNum: {
      type: Number,
    },
  },
  methods: {
    checkWheel(i, wheel) {
      this.$emit('checkWheel', 'wheel', i, wheel)
    },
  },
}
</script>
<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .WheelSizeBox {
    font-weight: 400;

    > .WheelSizeBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 144px;
          height: 185px;
          margin: 26px auto 0;
          vertical-align: middle;

          > p {
            font-size: 16px;
            color: #707070;
          }

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 8px;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 110px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
