<template>
  <div class="step">
    <h2>{{ type }} 크기</h2>
    <div class="canvasSize canvasOption">
      <div>
        <p>{{ type }}</p>
        <span v-show="type === '아크릴 블럭'">(100mm*100mm)</span>
        <span v-show="type === '일러스트 캔버스'">(160mm*230mm)</span>
        <hr />
        <img src="@/assets/goods/canvas_1호.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    margin-bottom: 13px;
    margin-top: 27px;
  }

  > .canvasOption {
    width: 380px;
    height: 230px;
    border: 2px solid #ff6699;
    background-color: #fff7fa87;
    border-radius: 10px;

    > div {
      width: 348px;
      margin: 18px auto 0;
      text-align: center;

      > p {
        font-size: 16px;
        color: #707070;
        text-align: left;
      }

      > span {
        display: block;
        font-size: 10px;
        color: #707070;
        margin-bottom: 5px;
        text-align: left;
      }

      > hr {
        width: 100%;
        height: 1px;
        background-color: #e6e6e6;
        border: none;
      }

      > img {
        width: 97px;
        height: 128px;
        margin-top: 14px;
      }
    }
  }
}
</style>
