<template>
  <div class="photoWrap clearFix">
    <div class="bgWrap" @click="closeTab()" @keydown="closeTab()" />
    <review-detail
      :review="review"
      :image="image"
      :img-num="imgNum"
      @close-tab="closeTab"
      @move-photo="movePhoto"
    />
  </div>
</template>

<script>
import ReviewDetail from '../../components/Modal/ReviewDetail.vue'

export default {
  components: {
    ReviewDetail,
  },
  props: {
    review: {
      type: Object,
    },
    imgNum: {
      type: Number,
    },
  },
  data() {
    return {
      image: '',
      imageSize: 'width',
    }
  },
  watch: {
    imgNum() {
      this.imgView(this.imgNum)
    },
  },
  mounted() {
    this.imgView(this.imgNum)
  },
  methods: {
    closeTab() {
      this.$emit('closeTab')
    },
    // 이미지 사이즈 체크
    imgView(i) {
      let image = new Image()
      let vm = this
      this.image = this.review.file_url[i]
      image.onload = () => {
        if (image.width > image.height) {
          vm.imageSize = 'width'
        } else {
          vm.imageSize = 'height'
        }
      }
      image.src = this.image
    },
    // 다른이미지 보기
    movePhoto(i) {
      this.$emit('imgChange', i)
    },
  },
}
</script>

<style scoped lang="scss">
.photoWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 200;
  color: #707070;
  font-family: 'Noto Sans KR';

  > .bgWrap {
    width: 100%;
    height: 100vh;
    z-index: 201;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    -o-backdrop-filter: blur(3px);
    -ms-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}
</style>
