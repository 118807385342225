export const Point = {
  namespaced: true,
  state: () => ({
    available_point: 0,
  }),
  mutations: {
    POINT_SET(state, data) {
      state.available_point = data
    },
  },
  actions: {
    pointSet({ commit }, data) {
      commit('POINT_SET', data)
    },
  },
  getters: {
    available_point(state) {
      return state.available_point
    },
  },
}
