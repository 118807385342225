<template>
  <div class="findWrap">
    <div class="closeTab" @click="closetab()" @keydown="closetab()">
      <img src="@/assets/icon/close_btn.svg" alt="" />
    </div>
    <div class="pwWrap">
      <h2>비밀번호 찾기</h2>
      <div v-if="complete === false" class="findpwBox">
        <div class="citationBox">
          <div class="citation">
            <img src="@/assets/icon/find_phone.svg" alt="" />
            <p>휴대폰 인증</p>
          </div>
        </div>
        <div class="formBox">
          <div class="id">
            <p>아이디</p>
            <input id="find_pw_userId" v-model="userId" type="text" />
          </div>
          <div class="name">
            <p>이름</p>
            <input id="find_pw_userName" v-model="userName" type="text" />
          </div>
          <div class="phone">
            <p>휴대폰번호</p>
            <select id="find_pw_phone_1" v-model="userPhone_1" name="find_pw_phone">
              <option value="010">010</option>
              <option value="011">011</option>
              <option value="018">018</option>
              <option value="016">016</option>
            </select>
            <input
              id="find_pw_phone_2"
              v-model="userPhone_2"
              type="text"
              name="phoneNumber"
              maxlength="4"
              @input="langCheck('phone_2')"
            />
            <input
              id="find_pw_phone_3"
              v-model="userPhone_3"
              type="text"
              name="phoneNumber"
              maxlength="4"
              @input="langCheck('phone_3')"
            />
            <button type="button" @click="pwCertificationBtn()">본인인증</button>
          </div>
        </div>
        <div class="btnBox">
          <div class="idFind" @click="idFind()" @keydown="idFind()">아이디 찾기</div>
          <div class="next" @click="loginView()" @keydown="loginView()">로그인</div>
        </div>
      </div>
      <div v-if="complete === true">
        <div class="userPwBox">
          <h3>새 비밀번호 입력</h3>
          <div class="formBox">
            <div class="id">
              <p>새 비밀번호</p>
              <input
                id="find_pw_newPw"
                v-model="newPw"
                type="password"
                placeholder="영,숫자 포함 8~20글자"
              />
            </div>
            <div class="name">
              <p>비밀번호 확인</p>
              <input id="find_pw_checkPw" v-model="pwCheck" type="password" />
            </div>
          </div>
        </div>
        <div class="btnBox">
          <div class="pwFind" @click="pwChange()" @keydown="pwChange()">저장</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    complete: {
      type: Boolean,
    },
  },
  data() {
    return {
      userId: '',
      userName: '',
      userPhone_1: '010',
      userPhone_2: '',
      userPhone_3: '',
      newPw: '',
      pwCheck: '',
    }
  },
  methods: {
    closetab() {
      this.$emit('close-tab')
    },
    pwCertificationBtn() {
      this.$emit(
        'pwFind',
        this.userId,
        this.userName,
        this.userPhone_1,
        this.userPhone_2,
        this.userPhone_3,
      )
    },
    idFind() {
      this.$emit('findIdView')
    },
    loginView() {
      this.$emit('loginView')
    },
    pwChange() {
      this.$emit(
        'pwChange',
        this.userId,
        this.userName,
        this.userPhone_1,
        this.userPhone_2,
        this.userPhone_3,
        this.newPw,
        this.pwCheck,
      )
    },
    langCheck(type) {
      if (type === 'phone_2') {
        this.userPhone_2 = this.userPhone_2.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        if (this.userPhone_2.length === 4) {
          let phone_3 = document.getElementById('find_pw_phone_3')
          phone_3.focus()
        }
      } else {
        this.userPhone_3 = this.userPhone_3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.findWrap {
  position: fixed;
  width: 556px;
  height: 650px;
  top: 50%;
  left: 50%;
  display: block;
  z-index: 1002;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  font-family: 'Noto Sans KR';
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);

  > .closeTab {
    position: absolute;
    /* border:1px solid #000; */
    top: 3%;
    right: 3%;
    cursor: pointer;
    z-index: 1002;
    width: 24px;
  }

  > div {
    > h2 {
      margin-top: 40px;
      text-align: center;
      font-weight: 500;
      font-size: 31px;
      color: #707070;
    }

    > div {
      > .citationBox {
        text-align: center;
        margin-top: 30px;

        > .citation {
          width: 130px;
          height: 78px;
          border-radius: 10px;
          background-color: #ff6699;
          margin: 0 auto;

          > img {
            width: 16px;
            height: 25px;
            margin-top: 15px;
            margin-bottom: 6px;
          }

          > p {
            color: #fff;
            font-size: 12px;
            font-weight: 300;
          }
        }
      }

      > .formBox {
        width: 370px;
        margin: 45px auto 0;
        > div {
          > p {
            font-size: 15px;
            color: #707070;
          }

          > input {
            width: 100%;
            box-sizing: border-box;
            border: 2px solid #f8bdd8;
            border-radius: 10px;
            height: 36px;
            margin-bottom: 20px;
            font-size: 15px;
            color: #707070;
            text-indent: 34px;

            &:focus {
              border: 2px solid #ff6699;
            }
          }

          &.phone {
            > select {
              width: 80px;
              height: 36px;
              border-radius: 10px;
              border: 2px solid #f8bdd8;
              text-indent: 15px;
              font-size: 15px;
              color: #707070;
              margin-bottom: 7px;
              box-sizing: border-box;
              margin-right: 5px;
            }
            > input {
              width: 80px;
              margin-right: 5px;
            }

            > button {
              width: 100px;
              border-radius: 10px;
              background-color: #ff6699;
              border: none;
              text-align: center;
              font-size: 14px;
              color: #fff;
              height: 36px;
              line-height: 36px;
              margin-left: 10px;
            }
          }
        }
      }

      > .btnBox {
        text-align: center;

        > div {
          display: inline-block;
          width: 130px;
          height: 36px;
          line-height: 36px;
          background-color: #ff6699;
          color: #fff;
          border-radius: 10px;
          margin: 70px 20px 0;
          cursor: pointer;
          font-size: 14px;
        }
      }

      .userPwBox {
        width: 415px;
        margin: 70px auto 50px;
        text-align: center;

        > h3 {
          border-bottom: 2px solid #ff6699;
          font-size: 23px;
          font-weight: 500;
          color: #707070;
          padding-bottom: 15px;
        }

        > .formBox {
          width: 370px;
          margin: 45px auto 0;

          > div {
            > p {
              font-size: 15px;
              color: #707070;
              text-align: left;
            }

            > input {
              width: 100%;
              box-sizing: border-box;
              border: 2px solid #f8bdd8;
              border-radius: 10px;
              height: 36px;
              margin-bottom: 20px;
              font-size: 15px;
              color: #707070;
              text-indent: 34px;

              &:focus {
                border: 2px solid #ff6699;
              }
            }
          }
        }
      }
    }
  }
}
</style>
