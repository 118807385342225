<template>
  <div class="reviewWrap">
    <div class="bgWrap" @click="closetab()" @keydown="closetab()" />
    <review-edit
      :list="list"
      :file-num="fileNum"
      :star-num="starNum"
      :check-star="checkStar"
      :image="image"
      @star-change="starChange"
      @select-file="selectFile"
      @delete-file="deleteFile"
      @save-review="saveReview"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewEdit from '../../components/Modal/ReviewEdit.vue'

export default {
  components: {
    ReviewEdit,
  },
  props: {
    list: {
      type: Object,
    },
  },
  data() {
    return {
      fileNum: 1,
      image: [],
      file: [],
      starNum: 5,
      checkStar: 5,
    }
  },
  methods: {
    // 리뷰에디터 창 숨김
    closetab() {
      this.$emit('closeTab')
    },
    // 별점 변경
    starChange(star) {
      this.checkStar = star
    },
    // 이미지 선택
    async selectFile(index) {
      let reviewFiles = document.getElementById(`reviewFile_${index}`)
      if (reviewFiles.files[0]) {
        this.file.push(reviewFiles.files[0])
        this.image.push(reviewFiles.files[0])

        await this.createImage(this.image[index], index)
      }
    },
    // 이미지 선택시 해당 이미지 미리보기
    async createImage(file, i) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        let image = new Image()
        // image.src = e.target.result;
        vm.image[i] = e.target.result
        image.src = vm.image[i]
        if (this.fileNum < 5) {
          this.fileNum += 1
        }
      }
      reader.readAsDataURL(file)
    },
    // 선택한 이미지 삭제
    deleteFile(i) {
      this.image.splice(i, 1)
      this.file.splice(i, 1)
      if (this.fileNum > 0) {
        this.fileNum -= 1
      }
    },
    // 리뷰 작성
    saveReview(title, content) {
      if (title === '') {
        this.Message('리뷰 제목을 입력해주세요.', 0)
        return
      }
      if (content === '') {
        this.Message('리뷰 내용을 입력해주세요.', 0)
        return
      }
      const formData = new FormData()
      if (this.file.lenth !== 0) {
        for (let i = 0; i < this.file.length; i++) {
          formData.append('files', this.file[i])
        }
      }
      formData.append('scope', Number(this.checkStar))
      formData.append('title', title)
      formData.append('content', content)
      formData.append('order_id', this.list.id)
      formData.append('type', this.list.type)
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios.post(this.Path.WriteReview, formData, config).then((res) => {
        if (res.data.success === true) {
          this.Message('리뷰가 작성되었습니다.', 1, 0)
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.reviewWrap {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  color: #707070;

  > .bgWrap {
    width: 100%;
    height: 100vh;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    -o-backdrop-filter: blur(3px);
    -ms-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}
</style>
