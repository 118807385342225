<template>
  <div class="messageWrap">
    <div class="bg" @click="closeMessage" @keydown="closeMessage" />
    <div class="messageBox">
      <h2>환불 사유</h2>
      <p>※ 환불 금액 입금의 경우, 은행사의 일정에따라 상의한 점 참고 부탁 드립니다.</p>
      <div class="select">
        <select id="refund_reason" v-model="reason" name="refund_reason">
          <option value="개인적인 사정">개인적인 사정</option>
          <option value="불량/오배송">불량/오배송</option>
          <option value="주문실수">주문실수</option>
          <option value="주문 취소 후 재주문">주문 취소 후 재주문</option>
          <option value="직접입력">직접입력</option>
        </select>
      </div>
      <div v-if="reason === '직접입력'" class="etc">
        <input
          v-model="etc_reason"
          aria-label="reason"
          type="text"
          placeholder="환불 사유를 직접 입력해주세요."
        />
      </div>
      <!-- <textarea v-model="reason" maxlength="1000"></textarea>
      <span>({{ reason.length }}/1000)</span> -->
      <div class="closeBtn" @click="closeMessage" @keydown="closeMessage">취소</div>
      <div class="okBtn" @click="refundBtn()" @keydown="refundBtn()">환불 신청</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reason: '개인적인 사정',
      etc_reason: '',
    }
  },
  methods: {
    async closeMessage() {
      this.$emit('closeMessage')
    },
    refundBtn() {
      let order_reason
      if (this.reason === '직접입력') {
        order_reason = this.etc_reason
      } else {
        order_reason = this.reason
      }
      this.$emit('refundBtn', order_reason)
    },
  },
}
</script>

<style scoped lang="scss">
.messageWrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  .bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9001;
  }

  .messageBox {
    width: 500px;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    z-index: 9002;
    > h2 {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 10px auto;
    }

    > p {
      text-align: center;
      font-size: 12px;
    }

    > textarea {
      width: 90%;
      display: block;
      margin: 10px auto;
      text-align: left;
      box-sizing: border-box;
      height: 150px;
      border: 1px solid #ff6699;
      resize: none;
      padding: 10px;
      font-size: 16px;

      &:focus {
        outline: none;
      }
    }

    > .select {
      width: 80%;
      margin: 20px auto 10px;

      > select {
        width: 100%;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ff6699;
        text-indent: 8px;
        font-size: 18px;

        &:focus {
          outline: 1px solid #ff6699;
        }
      }
    }
    > .etc {
      width: 80%;
      margin: 0 auto;
      > input {
        width: 100%;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ffe1ec;
        text-indent: 8px;
      }
    }

    > span {
      float: right;
      margin-right: 25px;
      color: #aaa;
    }

    > .closeBtn {
      position: absolute;
      width: 80px;
      height: 38px;
      line-height: 38px;
      color: #ff6699;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #ff6699;
      text-align: center;
      bottom: 20px;
      left: 20px;
      font-weight: 400;
      cursor: pointer;
    }
    > .okBtn {
      position: absolute;
      width: 80px;
      height: 38px;
      line-height: 38px;
      color: #fff;
      background-color: #ff6699;
      border-radius: 5px;
      text-align: center;
      bottom: 20px;
      right: 20px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>
