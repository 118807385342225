<template>
  <div class="termsWrap">
    <div class="popupBg" @click="closeTab()" @keydown="closeTab()" />
    <div class="termsBox">
      <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <div class="terms">
        <h2>구매 진행 동의에 대한 안내</h2>
        <hr />
        <div class="textBox">
          <p class="title">[상품구매 동의]</p>
          <p class="content">
            주문할 상품의 편집정보, 상품정보, 상품가격, 배송정보를 확인 하였습니다. <br />
            주문취소 및 수정은 결제 후 1시간 이내에만 가능합니다.
          </p>
        </div>
        <div class="textBox">
          <p class="title">[정보수신 동의]</p>
          <p class="content">
            주문자 정보로 SMS와 이메일이 발송됩니다. <br />
            주문관련 정보 발송, 마케팅 특성에 따른 서비스 제공 및 CRM 용도, 이벤트 당첨자 발표 안내,
            <br />
            이벤트 참여기회 제공, 광고성 정보제공을 위해서 휴대폰번호와 정보수신이 저장됩니다.
          </p>
        </div>
        <div class="textBox">
          <p class="title">[보유기간]</p>
          <p class="content">개인정보 수집 및 이용 목적 달성 시까지 보관합니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async closeTab() {
      this.$emit('close-tab')
    },
  },
}
</script>

<style scoped lang="scss">
.termsWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: block;

  > .popupBg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  > .termsBox {
    width: 608px;
    height: 434px;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 1001;

    > .closeTab {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    > .terms {
      width: 501px;
      margin: 47px auto 0;

      > h2 {
        font-size: 20px;
        font-weight: bold;
        color: #ff6699;
      }

      > hr {
        width: 100%;
        height: 2px;
        background-color: #ff6699;
        border: none;
        margin-top: 14px;
        margin-bottom: 39px;
      }

      > .textBox {
        margin-bottom: 17px;
        text-align: left;

        > .title {
          font-size: 14px;
          color: #ff6699;
        }

        > .content {
          font-size: 12px;
          color: #414141;
        }
      }
    }
  }
}
</style>
