<template>
  <div class="newsWrap">
    <news-detail :news_data="news_data" />
    <ul class="listBtn clearFix">
      <li><span @click="goList()" @keydown="goList()">목록</span></li>
    </ul>
  </div>
</template>

<script>
import NewsDetail from '../../components/News/NewsDetail.vue'

export default {
  components: {
    NewsDetail,
  },
  data() {
    return {
      news_data: {
        title: '',
        createdAt: '',
        content: '',
      },
      params: '',
    }
  },
  watch: {
    $route(to, from) {
      this.paramsCheck()
    },
  },
  mounted() {
    this.paramsCheck()
    this.getDetail()
  },
  methods: {
    paramsCheck() {
      this.params = this.$route.params.page
    },
    // sessionStorage에 저장된 공지사항&이벤트 상세정보를 조회
    getDetail() {
      const detail = JSON.parse(sessionStorage.getItem('newsDetail'))
      this.news_data = detail
    },
    goList() {
      this.$router.push({
        name: 'NewsList',
        params: { page: this.params },
        query: {
          page: this.$route.query.page,
          status: this.$route.query.status,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.newsWrap {
  width: 1160px;
  margin: 0 auto;
  color: #707070;
  font-family: 'Noto Sans KR';

  > .listBtn {
    text-align: center;
    margin: 50px auto 100px;

    > li {
      display: inline-block;
      width: 150px;
      height: 30px;
      border: 1px solid #ff6699;
      line-height: 30px;
      text-align: center;
      border-radius: 10px;
      font-size: 20px;
      margin: 0 20px;
      cursor: pointer;
    }
  }
}
</style>
