<template>
  <div class="couponModal">
    <div class="bg" @click="closetab" @keydown="closetab" />
    <div class="couponWrap">
      <h2>내 쿠폰함</h2>
      <div class="closeTab" @click="closetab" @keydown="closetab">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <coupon-list
        :coupon-list="coupon_list"
        :select-index="select_index"
        @select-coupon="selectCoupon"
        @coupon-registration="couponRegistration"
      />
      <div class="btn" @click="useCoupon" @keydown="useCoupon">사용</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import couponList from '../../components/MyPage/CouponList.vue'

export default {
  components: {
    couponList,
  },
  data() {
    return {
      coupon_list: [],
      select_index: null,
      select_coupon: {},
    }
  },
  mounted() {
    this.getCouponList()
  },
  methods: {
    closetab() {
      this.$emit('closeTab')
    },
    // 유저 쿠폰 목록 조회
    getCouponList() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios.get(this.Path.MyCouponList, config).then((res) => {
        if (res.data.success === true) {
          this.coupon_list = res.data.data
        }
      })
    },
    // 쿠폰 등록
    couponRegistration(number) {
      if (!number) {
        this.Message('쿠폰 번호를 입력해주세요.', 0)
        return
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .post(
          this.Path.CouponRegistration,
          {
            coupon_number: number,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('쿠폰이 등록되었습니다.', 0)
            this.coupon_list.push(res.data.data)
          }
        })
        .catch((err) => {
          this.Message(this.ErrorMessage.CouponRegistration(err.response.status), 0, 0)
        })
    },
    // 쿠폰 선택 이벤트
    selectCoupon(coupon, index) {
      this.select_coupon = coupon
      this.select_index = index
    },
    // 쿠폰 사용
    useCoupon() {
      this.$emit('useCoupon', this.select_coupon)
    },
  },
  computed: {
    ...mapGetters({
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.couponModal {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1010;
  display: block;

  > .bg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  > .couponWrap {
    position: fixed;
    width: 600px;
    max-height: 600px;
    top: 50%;
    left: 50%;
    display: block;
    z-index: 1001;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    padding: 20px;
    overflow-y: auto;

    > .closeTab {
      position: absolute;
      /* border:1px solid #000; */
      top: 3%;
      right: 3%;
      cursor: pointer;
      z-index: 1002;
      width: 24px;
    }

    > .btn {
      width: 150px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #ff6699;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      margin: 80px auto 0;
      border-radius: 5px;
    }
  }
}
</style>
