<template>
  <div class="container4 clearFix">
    <div class="reviewTitle">
      <div class="ballon">
        <span
          >리뷰를 작성하고 포인트를 더 적립하세요! 글 리뷰 +500P 사진 리뷰 +1,000P추가 적립</span
        >
      </div>
      <p class="title">
        <router-link :to="{ name: 'Review' }"> 다양한 후기들을 만나보세요! </router-link>
      </p>
      <p class="tag">#동네공작소 #동네굿즈 #동네</p>
    </div>
    <div class="reviewWrap">
      <div
        v-for="(review, index) in reviewList"
        :key="index"
        class="reviewBox"
        @click="reviewDetail(review)"
        @keydown="reviewDetail(review)"
      >
        <div class="reviewImg">
          <div class="center">
            <img :src="review.file_url[0]" alt="" />
          </div>
        </div>
        <img
          v-show="review.file_url.length > 1"
          class="multiImg"
          src="@/assets/icon/multi_img.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reviewList: {
      type: Array,
    },
  },
  methods: {
    reviewDetail(review) {
      this.$emit('reviewDetail', review)
    },
  },
}
</script>

<style scoped lang="scss">
.container4 {
  width: 1160px;
  height: 612px;
  margin: 88px auto 132px;

  > .reviewTitle {
    width: 550px;
    margin: 0 auto;
    text-align: left;
    position: relative;

    > .title {
      font-size: 45px;
      color: #707070;
      margin-bottom: 7px;
    }

    .tag {
      font-size: 20px;
      color: #ff6699;
    }

    > .ballon {
      // display: none;
      position: absolute;
      font-size: 12px;
      width: 430px;
      left: -5px;
      top: -25px;
      background: #ff6699;
      color: white;
      border-radius: 30px;
      padding: 5px 12.8px;

      &:after {
        border-top: 10px solid #ff6699;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 0px solid transparent;
        content: '';
        position: absolute;
        top: 24px;
        left: 10px;
      }
    }
  }

  > .reviewWrap {
    width: 100%;
    margin-top: 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > .reviewBox {
      position: relative;
      width: 220px;
      height: 220px;
      margin-bottom: 19px;
      cursor: pointer;
      overflow: hidden;

      > .reviewImg {
        position: relative;
        padding-top: 100%;
        overflow: hidden;

        > .center {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          > img {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 220px;
            width: 220px;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            object-fit: cover;
          }
        }
      }

      > .multiImg {
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>
