<template>
  <div>
    <ranking-container />
  </div>
</template>

<script>
import RankingContainer from '../../containers/MyPage/RankingContainer.vue'

export default {
  components: {
    RankingContainer,
  },
}
</script>

<style></style>
