<template>
  <div class="canvasWrap clearFix">
    <div v-if="svg === true" class="compareBox clearFix">
      <canvas id="canvas_b" />
      <div id="canvas_1">
        <img id="canvas_img" alt="" />
      </div>
      <div id="canvas_2" />
      <p class="canvasMessage">
        {{ message }}
      </p>
    </div>
    <label v-if="svg === false" id="canvasLabel" for="canvas_svg_file" class="inputLabel">
      <div
        @dragleave.prevent="overEvent"
        @dragenter.prevent="overEvent"
        @dragover.prevent
        @drop.prevent="dropEvent"
      >
        <div class="dropBox" :style="dropBoxStyle" />
        <p>이곳에 이미지를 업로드해 주세요.</p>
        <img src="@/assets/goods/canvas_img.svg" alt="" />
      </div>
    </label>
    <input
      id="canvas_svg_file"
      ref="img_file"
      type="file"
      class="file-input"
      name="files"
      @change="imgSelectFile"
    />
  </div>
</template>

<script>
export default {
  props: {
    svg: {
      type: Boolean,
    },
    message: {
      type: String,
    },
    dropBoxStyle: {
      type: Object,
    },
  },
  methods: {
    overEvent(e) {
      this.$emit('overEvent', e)
    },
    dropEvent(e) {
      this.$emit('dropEvent', e, 'canvas')
    },
    imgSelectFile(e) {
      this.$emit('imgSelect', 'canvas', e.target.files[0])
    },
  },
}
</script>

<style scoped lang="scss">
.canvasWrap {
  width: 675px;
  height: 700px;
  margin: 10px auto 25px;
  position: relative;

  > .compareBox {
    position: relative;
    height: 100%;
    overflow: hidden;

    #c {
      width: 500px;
      height: 500px;
    }

    > .canvas-container {
      width: 500px;
      height: 500px;
      z-index: 3;

      > .upper-canvas {
        z-index: 100;
      }
    }

    > #canvas_1 {
      width: 322px;
      height: 455px;
      border: 0.5px solid #000;
      background-color: #fff;
      position: absolute;
      top: 122.5px;
      left: 176.5px;
      z-index: 2;
      box-sizing: border-box;
      pointer-events: none;

      > img {
        width: 0;
        height: 0;
        display: hidden;
      }
    }

    > #canvas_2 {
      position: absolute;
      width: 303px;
      height: 436px;
      border: 1px dotted #ff6699;
      top: 132px;
      left: 186px;
      z-index: 95;
      box-sizing: border-box;
      pointer-events: none;
    }

    > .canvasMessage {
      position: absolute;
      width: 320px;
      text-align: center;
      margin-left: -160px;
      top: 90px;
      left: 50%;
    }
  }

  > label {
    > div {
      position: absolute;
      top: 50%;
      margin-top: -233px;
      left: 50%;
      margin-left: -166px;

      > div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 5;
      }

      > p {
        position: absolute;
        color: #ff6699;
        font-size: 14px;
        top: 60%;
        left: 20%;
      }

      > .plusIcon {
        position: absolute;
        cursor: pointer;
        top: 50%;
        left: 50%;
        margin-top: -19px;
        margin-left: -19px;
        z-index: 4;
      }
    }
  }

  > .ysizeBar {
    position: absolute;
    border-right: 1px solid #ff6699;
    height: 200px;
    width: 1px;
    top: 50%;
    margin-top: -100px;
    right: 0;

    > span {
      position: absolute;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }
    }

    > .bar {
      width: 5px;
      border-bottom: 1px solid #ff6699;
      left: -1px;
    }

    > .ysize {
      top: 50%;
      left: 10px;
      font-size: 10px;
      color: #ff6699;
      margin-top: -7px;
    }
  }

  > .xsizeBar {
    position: absolute;
    border-bottom: 1px solid #ff6699;
    height: 1px;
    width: 200px;
    bottom: 0;
    margin-left: -100px;
    left: 50%;

    > span {
      position: absolute;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    > .bar {
      height: 5px;
      border-right: 1px solid #ff6699;
      top: -1px;
    }
  }
}
input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
