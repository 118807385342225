<template>
  <div class="imgWrap clearFix">
    <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
      <img src="@/assets/icon/photoClose.svg" alt="" />
    </div>
    <div class="imgBox clearFix">
      <div class="imgBg clearFix">
        <div class="center clearFix">
          <img id="photoImg" :src="image" alt="" :class="{ height: imgView(image) }" />
        </div>
      </div>
      <ul class="arrowBtns clearFix">
        <li
          v-show="imgNum > 0"
          class="left"
          @click="movePhoto(imgNum - 1)"
          @keydown="movePhoto(imgNum - 1)"
        >
          <img src="@/assets/icon/photoPrev.svg" alt="" />
        </li>
        <li
          v-show="imgNum < review.file_url.length - 1"
          class="right"
          @click="movePhoto(imgNum + 1)"
          @keydown="movePhoto(imgNum + 1)"
        >
          <img src="@/assets/icon/photoNext.svg" alt="" />
        </li>
      </ul>
      <div class="smallImg">
        <div
          v-for="(file, i) in review.file_url"
          :key="i"
          :class="{ active: imgNum === i }"
          @click="movePhoto(i)"
          @keydown="movePhoto(i)"
        >
          <img :src="file" alt="" />
        </div>
      </div>
    </div>
    <div class="reviewInfo">
      <p class="reviewTitle">
        {{ review.title }}
      </p>
      <div class="starDateId">
        <span>{{ moment(review.createdAt).format('YYYY-MM-DD') }}</span>
        <span v-show="review?.user_id">{{ review?.user_id?.user_id.substr(0, 3) }}*****</span>
        <div class="starBox">
          <img
            v-for="(star, index) in starNum"
            v-show="review.scope > index"
            :key="index"
            src="@/assets/icon/star_black_fill.svg"
            class="star"
            alt=""
          />
          <img
            v-for="(star, index) in starNum"
            v-show="review.scope <= index"
            :key="index"
            src="@/assets/icon/star_black_empty.svg"
            class="star"
            alt=""
          />
        </div>
      </div>
      <span class="reviewContent">{{ review.content }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      type: Object,
    },
    image: {
      type: String,
    },
    imgNum: {
      type: Number,
    },
  },
  data() {
    return {
      starNum: 5,
    }
  },
  methods: {
    closeTab() {
      this.$emit('closeTab')
    },
    movePhoto(i) {
      this.$emit('movePhoto', i)
    },
    // 이미지 사이즈 체크
    async imgView(img) {
      let image = new Image()
      let imageSize
      image.onload = async () => {
        if (image.width > image.height) {
          imageSize = 'width'
        } else {
          imageSize = 'height'
        }
        return imageSize
      }
      image.src = img
    },
  },
}
</script>

<style scoped lang="scss">
.imgWrap {
  position: fixed;
  width: 816px;
  height: 580px;
  left: 50%;
  top: 50%;
  z-index: 205;
  margin-left: -408px;
  margin-top: -290px;
  border-radius: 10px;
  box-shadow: 0px 3px 9px #0000005e;
  background-color: #fff;
  text-align: center;

  > div {
    > img {
      width: 100%;
      text-align: center;
      vertical-align: center;
      border-radius: 10px;
    }

    > .reviewTitle {
      font-size: 18px;
      font-weight: 500;
      margin-left: 35px;
      margin-top: 40px;
      width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > .starDateId {
      margin-left: 35px;

      > span {
        margin-right: 10px;
        margin-top: 7px;
      }

      > .starBox {
        margin-right: 10px;
        font-size: 0;
        margin-top: 11px;

        > img {
          width: 15px;
          height: 21px;
        }
      }
    }

    > .reviewContent {
      margin-top: 40px;
      margin-left: 35px;
      font-size: 12px;
      width: 300px;
      display: block;
      word-break: break-all;
    }
  }

  > .imgBox {
    width: 440px;
    height: 100%;
    background-color: #fedbe829;
    border-right: 1px solid #ff6699;
    float: left;
    position: relative;

    > .imgBg {
      position: relative;
      padding-top: 100%;
      overflow: hidden;
      background-color: white;
      margin-top: 30px;

      > .center {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        > img {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 400px;
          height: auto;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          &.height {
            width: auto;
            max-height: 400px;
          }
        }
      }
    }

    > .smallImg {
      margin: 30px auto 0;
      width: 330px;
      text-align: center;

      > div {
        float: left;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          border: 1px solid #ff6699;
        }

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > .arrowBtns {
      > li {
        width: 12px;
        height: 19px;
        position: absolute;
        top: 40%;
        cursor: pointer;
        margin-top: -6px;

        > img {
          width: 100%;
        }

        &.left {
          left: 5px;
        }

        &.right {
          right: 5px;
        }
      }
    }
  }

  > .closeTab {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  > .reviewInfo {
    float: left;
    text-align: left;
  }
}
</style>
