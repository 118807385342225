const methods = {
  Commas: (number) => {
    if (number) {
      number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return number
  },
}
export default methods.Commas
