<template>
  <div class="index">
    <main-container />
  </div>
</template>

<script>
import MainContainer from '../containers/Main/MainContainer.vue'

export default {
  name: 'Index',
  components: {
    MainContainer,
  },
}
</script>

<style scoped lang="scss"></style>
