<template>
  <div :class="{ step: path === 'Basic', SpecialStep: path === 'Special' }">
    <h2>와이어링 색상 선택</h2>
    <div class="colorWrap">
      <div class="colorBox">
        <div class="color">
          <div
            v-for="(wire, index) in wiring_color"
            :key="index"
            :style="{ backgroundColor: wire.color }"
            :class="{ active: wireColorNum === index + 1 }"
            @click="checkWireColor(index + 1, wire.name)"
            @keydown="checkWireColor(index + 1, wire.name)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
    wireColorNum: {
      type: Number,
    },
  },
  data() {
    return {
      wiring_color: [
        {
          name: '빨강',
          color: '#F32F2F',
        },
        {
          name: '주황',
          color: '#FF7D44',
        },
        {
          name: '노랑',
          color: '#FFE167',
        },
        {
          name: '연두',
          color: '#76DF68',
        },
        {
          name: '하늘',
          color: '#4BBEF5',
        },
        {
          name: '남색',
          color: '#2D2D9C',
        },
        {
          name: '연보라',
          color: '#C89FDB',
        },
        {
          name: '분홍',
          color: '#F54385',
        },
        {
          name: '흰색',
          color: '#ffffff',
        },
        {
          name: '검정',
          color: '#000000',
        },
      ],
    }
  },
  methods: {
    checkWireColor(i, color) {
      this.$emit('checkWireColor', 'wiringColor', i, color)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .colorWrap {
    width: 100%;
    border: 2px solid #bcbcbc;
    height: 178px;
    border-radius: 10px;

    > .colorBox {
      width: 95%;
      margin: 25px auto;

      > .color {
        width: 90%;
        margin: 0 auto;
        height: 125px;

        > div {
          display: inline-block;
          width: 57px;
          height: 57px;
          border: 1px solid #bcbcbc;
          border-radius: 3px;
          box-sizing: border-box;
          cursor: pointer;
          margin-bottom: 15px;
          margin-right: 50px;

          &:nth-child(5),
          &:last-child {
            margin-right: 0px;
          }

          &.active {
            border: 2px solid #f25ca2;
            background-image: url('../../../assets/goods/dog_tag_color_check.png');
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .colorWrap {
    width: 100%;
    border: 2px solid #bcbcbc;
    height: 118px;
    border-radius: 10px;

    > .colorBox {
      width: 95%;
      margin: 15px auto;

      > .color {
        width: 300px;
        height: 85px;
        margin: 0 auto;

        > div {
          display: inline-block;
          width: 38px;
          height: 38px;
          border: 1px solid #bcbcbc;
          border-radius: 3px;
          box-sizing: border-box;
          cursor: pointer;
          margin-bottom: 10px;
          margin-right: 26px;

          &:nth-child(5),
          &:last-child {
            margin-right: 0px;
          }

          &.active {
            border: 2px solid #f25ca2;
            background-image: url('../../../assets/goods/dog_tag_color_check.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 90%;
          }
        }
      }
    }
  }
}
</style>
