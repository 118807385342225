<template>
  <div class="container3 clearFix">
    <div class="enterpriseBox">
      <div class="imgBox">
        <img src="@/assets/main/main_enterprise.svg" alt="" />
      </div>
      <h2>기업 주문 서비스</h2>
      <hr />
      <div class="enterpriseText">
        <p>동네공작소에서는 기업 주문 서비스를 제공합니다.</p>
        <p>메일로 문의 주시면 기업 고객에 맞춘 가격 정보를 제공해 드리고 있습니다.</p>
        <p>동네공작소 기업 고객으로 문의하시고 다양한 혜택을 제공받으세요!</p>
        <div>
          <h3>CONTACT US</h3>
          <p>상담 가능시간 : 평일 10 : 00 ~ 18 : 00 (점심 시간: 13:00-14:00 제외)</p>
          <div>
            <p>craft.dongne@gmail.com</p>
            <p>070-8848-8489</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.container3 {
  width: 100%;
  height: 735px;
  padding: 93px 0 60px;

  > .enterpriseBox {
    width: 1160px;
    margin: 0 auto;
    text-align: center;

    > .imgBox {
      margin-top: 82px;
    }

    > h2 {
      font-size: 42px;
      font-weight: 700;
      color: #ff6699;
      margin: 18px 0;
    }

    > hr {
      width: 518px;
      height: 8px;
      background-color: #ff6699;
      margin: 0 auto;
      border: none;
    }

    > .enterpriseText {
      margin-top: 47px;

      > p {
        font-size: 20px;
        color: #707070;
        line-height: 40px;
      }

      > div {
        margin-top: 35px;

        > h3 {
          font-weight: 700;
          font-size: 24px;
          color: #ff6699;
        }

        > p {
          font-size: 15px;
          color: #707070;
        }

        > div {
          margin-top: 20px;
          font-size: 20px;
          color: #707070;
        }
      }
    }

    > .enterpriseBtn {
      width: 292px;
      height: 64px;
      line-height: 64px;
      color: #fff;
      margin: 47px auto 0;
      border-radius: 10px;
      background-color: #ff6699;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
</style>
