<template>
  <div>
    <div class="confirm">
      <p>비밀번호를 다시한번 입력해주세요.</p>
      <div class="inputBox">
        <form method="post" @submit.prevent="pwCheck()">
          <label for="myPage_check_password">비밀번호 확인</label>
          <input id="myPage_check_password" v-model="pw_check" type="password" name="password" />
          <button type="submit">확인</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pw_check: '',
    }
  },
  methods: {
    pwCheck() {
      this.$emit('pwCheck', this.pw_check)
    },
  },
}
</script>

<style scoped lang="scss">
.confirm {
  text-align: center;
  margin-top: 220px;

  > p {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 50px;
  }

  > .inputBox {
    border: 1px solid #ff6699;
    width: 590px;
    height: 94px;
    margin: 0px auto 400px;
    line-height: 94px;
    border-radius: 5px;

    label {
      font-size: 12px;
      font-weight: 500;
      margin-right: 10px;
    }

    input {
      width: 209px;
      height: 29px;
      line-height: 29px;
      border: 1px solid #ffe1ec;
      text-indent: 20px;
      box-sizing: border-box;

      :focus {
        outline: 1px solid #ff6699;
      }
    }

    button {
      margin-left: 10px;
      width: 52px;
      height: 29px;
      text-align: center;
      border: none;
      background-color: #ff6699;
      font-size: 12px;
      color: #fff7fa;
      cursor: pointer;
    }
  }
}
</style>
