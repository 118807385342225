<template>
  <div class="ToSWrap">
    <h2>{{ title }}</h2>
    <terms v-show="params === 'terms'" />
    <privacy v-show="params === 'privacy'" />
  </div>
</template>

<script>
import Terms from '../../components/ToS/ToSTerms.vue'
import Privacy from '../../components/ToS/ToSPrivacy.vue'

export default {
  components: {
    Terms,
    Privacy,
  },
  data() {
    return {
      title: '',
      params: '',
    }
  },
  mounted() {
    this.params = this.$route.params.type
    if (this.params === 'terms') {
      this.title = '이용약관'
    } else {
      this.title = '개인정보처리방침'
    }
  },
}
</script>

<style scoped lang="scss">
.ToSWrap {
  width: 1160px;
  margin: 0 auto;
  color: #707070;
  font-family: 'Noto Sans KR';

  > h2 {
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    border-bottom: 2px solid #ff6699;
    padding-bottom: 40px;
    margin-top: 40px;
  }
}
</style>
../../components/ToS/TTerms.vue
