<template>
  <div :class="{ step: path === 'Basic', SpecialStep: path === 'Special' }">
    <h2>고리 옵션 선택</h2>
    <div class="keyringBox clearFix">
      <ul class="keyringBtns">
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 0 }"
          @click="checkKeyring(0, '일반형')"
          @keydown="checkKeyring(0, '일반형')"
        >
          <div class="clearFix">
            <p>일반형</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 0"
              class="keyring_img"
              src="@/assets/goods/keyring_일반형_active.svg"
              alt=""
            />
            <img v-else class="keyring_img" src="@/assets/goods/keyring_일반형_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 1 }"
          @click.stop="checkKeyring(1, '붕어모양')"
          @keydown.stop="checkKeyring(1, '붕어모양')"
        >
          <div class="clearFix">
            <p>붕어 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
              <li
                class="gold"
                :class="{ color: keyringColorNum === 1 }"
                @click="checkColor(1, '골드')"
                @keydown="checkColor(1, '골드')"
              />
              <li
                class="rose"
                :class="{ color: keyringColorNum === 2 }"
                @click="checkColor(2, '로즈골드')"
                @keydown="checkColor(2, '로즈골드')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 1"
              class="keyring_img"
              src="@/assets/goods/keyring_붕어모양_active.svg"
              alt=""
            />
            <img
              v-else
              class="keyring_img"
              src="@/assets/goods/keyring_붕어모양_nomal.svg"
              alt=""
            />
          </div>
        </li>
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 2 }"
          @click="checkKeyring(2, 'D모양')"
          @keydown="checkKeyring(2, 'D모양')"
        >
          <div class="clearFix">
            <p>D 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
              <li
                class="gold"
                :class="{ color: keyringColorNum === 1 }"
                @click="checkColor(1, '골드')"
                @keydown="checkColor(1, '골드')"
              />
              <li
                class="rose"
                :class="{ color: keyringColorNum === 2 }"
                @click="checkColor(2, '로즈골드')"
                @keydown="checkColor(2, '로즈골드')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 2"
              class="keyring_img"
              src="@/assets/goods/keyring_D모양_active.svg"
              alt=""
            />
            <img v-else class="keyring_img" src="@/assets/goods/keyring_D모양_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 3 }"
          @click="checkKeyring(3, '하트모양')"
          @keydown="checkKeyring(3, '하트모양')"
        >
          <div class="clearFix">
            <p>하트 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
              <li
                class="gold"
                :class="{ color: keyringColorNum === 1 }"
                @click="checkColor(1, '골드')"
                @keydown="checkColor(1, '골드')"
              />
              <li
                class="rose"
                :class="{ color: keyringColorNum === 2 }"
                @click="checkColor(2, '로즈골드')"
                @keydown="checkColor(2, '로즈골드')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 3"
              class="keyring_img"
              src="@/assets/goods/keyring_하트모양_active.svg"
              alt=""
            />
            <img
              v-else
              class="keyring_img"
              src="@/assets/goods/keyring_하트모양_nomal.svg"
              alt=""
            />
          </div>
        </li>
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 4 }"
          @click="checkKeyring(4, '별모양')"
          @keydown="checkKeyring(4, '별모양')"
        >
          <div class="clearFix">
            <p>별 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
              <li
                class="gold"
                :class="{ color: keyringColorNum === 1 }"
                @click="checkColor(1, '골드')"
                @keydown="checkColor(1, '골드')"
              />
              <li
                class="rose"
                :class="{ color: keyringColorNum === 2 }"
                @click="checkColor(2, '로즈골드')"
                @keydown="checkColor(2, '로즈골드')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 4"
              class="keyring_img"
              src="@/assets/goods/keyring_별모양_active.svg"
              alt=""
            />
            <img v-else class="keyring_img" src="@/assets/goods/keyring_별모양_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 5 }"
          @click="checkKeyring(5, '달모양')"
          @keydown="checkKeyring(5, '달모양')"
        >
          <div class="clearFix">
            <p>달 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
              <li
                class="gold"
                :class="{ color: keyringColorNum === 1 }"
                @click="checkColor(1, '골드')"
                @keydown="checkColor(1, '골드')"
              />
              <li
                class="rose"
                :class="{ color: keyringColorNum === 2 }"
                @click="checkColor(2, '로즈골드')"
                @keydown="checkColor(2, '로즈골드')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 5"
              class="keyring_img"
              src="@/assets/goods/keyring_달모양_active.svg"
              alt=""
            />
            <img v-else class="keyring_img" src="@/assets/goods/keyring_달모양_nomal.svg" alt="" />
          </div>
        </li>

        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 6 }"
          @click="checkKeyring(6, '고양이모양')"
          @keydown="checkKeyring(6, '고양이모양')"
        >
          <div class="clearFix">
            <p>고양이 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
              <li
                class="gold"
                :class="{ color: keyringColorNum === 1 }"
                @click="checkColor(1, '골드')"
                @keydown="checkColor(1, '골드')"
              />
              <li
                class="rose"
                :class="{ color: keyringColorNum === 2 }"
                @click="checkColor(2, '로즈골드')"
                @keydown="checkColor(2, '로즈골드')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 6"
              class="keyring_img"
              src="@/assets/goods/keyring_고양이모양_active.svg"
              alt=""
            />
            <img
              v-else
              class="keyring_img"
              src="@/assets/goods/keyring_고양이모양_nomal.svg"
              alt=""
            />
          </div>
        </li>
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 7 }"
          @click="checkKeyring(7, '벚꽃모양')"
          @keydown="checkKeyring(7, '벚꽃모양')"
        >
          <div class="clearFix">
            <p>벚꽃 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="rose"
                :class="{ color: keyringColorNum === 2 }"
                @click="checkColor(2, '로즈골드')"
                @keydown="checkColor(2, '로즈골드')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 7"
              class="keyring_img"
              src="@/assets/goods/keyring_벚꽃모양_active.svg"
              alt=""
            />
            <img
              v-else
              class="keyring_img"
              src="@/assets/goods/keyring_벚꽃모양_nomal.svg"
              alt=""
            />
          </div>
        </li>
        <li
          class="keyringBtn"
          :class="{ active: keyringKindNum === 8 }"
          @click="checkKeyring(8, '체인모양')"
          @keydown="checkKeyring(8, '체인모양')"
        >
          <div class="clearFix">
            <p>체인 모양</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                class="metal"
                :class="{ color: keyringColorNum === 0 }"
                @click="checkColor(0, '실버')"
                @keydown="checkColor(0, '실버')"
              />
            </ul>
            <img
              v-if="keyringKindNum === 8"
              class="keyring_img"
              src="@/assets/goods/keyring_체인모양_active.svg"
              alt=""
            />
            <img
              v-else
              class="keyring_img"
              src="@/assets/goods/keyring_체인모양_nomal.svg"
              alt=""
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
    keyringKindNum: {
      type: Number,
    },
    keyringColorNum: {
      type: Number,
    },
  },
  methods: {
    checkKeyring(i, kind) {
      this.$emit('checkKind', 'keyring_kind', i, kind)
    },
    checkColor(i, color) {
      this.$emit('checkColor', 'keyring_color', i, color)
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .keyringBox {
    font-weight: 400;

    > .keyringBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 17px;
        cursor: pointer;
        margin-bottom: 15px;

        &:nth-child(3),
        &:nth-child(6),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;

          > div {
            > .colorOption {
              > .color {
                border: 2px solid #ff6699;
                background-image: url('../../../assets/icon/checkIcon.png');
                background-position: center center;
              }
            }
          }
        }

        > div {
          width: 144px;
          margin: 26px auto 0;

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin-top: 25px;
            width: 100px;
            height: 100px;
            vertical-align: middle;
            float: right;
          }

          .colorOption {
            margin-top: 4px;
            float: left;

            > li {
              width: 30px;
              height: 30px;
              border-radius: 3px;
              margin-right: 3px;
              margin-top: 15px;
              cursor: pointer;
              box-sizing: border-box;

              &.metal {
                background-color: #c7c7c7;
              }

              &.gold {
                background-color: #ece476;
              }

              &.rose {
                background-color: #e8c1bc;
              }
            }
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .keyringBox {
    font-weight: 400;

    > .keyringBtns {
      float: left;

      > li {
        width: 118px;
        height: 160px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 10px;

        &:nth-child(3),
        &:nth-child(6),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;

          > div {
            > .colorOption {
              > .color {
                border: 2px solid #ff6699;
                background-image: url('../../../assets/icon/checkIcon.png');
                background-position: center center;
              }
            }
          }
        }

        > div {
          width: 95px;
          margin: 26px auto 0;

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 70%;
            height: 60px;
            margin-top: 20px;
            vertical-align: middle;
            float: right;
          }

          .colorOption {
            margin-top: 2px;
            float: left;

            > li {
              width: 20px;
              height: 20px;
              border-radius: 3px;
              margin-right: 0px;
              margin-top: 8px;
              cursor: pointer;
              box-sizing: border-box;

              &.metal {
                background-color: #c7c7c7;
              }

              &.gold {
                background-color: #ece476;
              }

              &.rose {
                background-color: #e8c1bc;
              }
            }
          }
        }
      }
    }
  }
}
</style>
