export const IntroSet = {
  namespaced: true,
  state: () => ({
    headerTop: 0,
    topMargin: 0,
  }),
  mutations: {
    HEADER_TOP(state, data) {
      state.headerTop = data.headerTop
      state.topMargin = data.topMargin
    },
  },
  actions: {
    headerTop({ commit }, data) {
      commit('HEADER_TOP', data)
    },
  },
  getters: {
    headerTop(state) {
      return state.headerTop
    },
    topMargin(state) {
      return state.topMargin
    },
  },
}
