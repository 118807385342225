<template>
  <div class="whiteLayer">
    <h1>{{ guideNum }}. 화이트 레이어 작업</h1>
    <!-- type === '아크릴 키링' -->
    <div v-show="type === '아크릴 키링'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/cham_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/cham_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_6.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_7.png" alt="" /><img
              src="@/assets/guide/cham_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_11.png" alt="" /><img
              src="@/assets/guide/cham_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 카드텍' -->
    <div v-show="type === '아크릴 카드텍'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_white_1.png" alt="" />
        </div>
        <div class="card_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/card_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_white_3.png" alt="" />
        </div>
        <div class="card_img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_white_4.png" alt="" />
        </div>
        <div class="card_img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/card_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_white_6.png" alt="" />
        </div>
        <div class="card_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/card_white_7.png" alt="" /><img
              src="@/assets/guide/card_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/card_white_11.png" alt="" /><img
              src="@/assets/guide/card_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 스탠드' -->
    <div v-show="type === '아크릴 스탠드'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_white_1.png" alt="" />
        </div>
        <div class="stand_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/stand_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_white_3.png" alt="" />
        </div>
        <div class="stand_img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_white_4.png" alt="" />
        </div>
        <div class="stand_img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/stand_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_white_6.png" alt="" />
        </div>
        <div class="stand_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/card_white_7.png" alt="" /><img
              src="@/assets/guide/card_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/card_white_11.png" alt="" /><img
              src="@/assets/guide/card_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '자유형 책갈피' -->
    <div v-show="type === '자유형 책갈피'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/cham_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/cham_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_white_6.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_7.png" alt="" /><img
              src="@/assets/guide/cham_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_11.png" alt="" /><img
              src="@/assets/guide/cham_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 바닥 부품' -->
    <div v-show="type === '아크릴 바닥 부품'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_white_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/floor_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_white_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_white_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/floor_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_white_6.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_7.png" alt="" /><img
              src="@/assets/guide/cham_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_11.png" alt="" /><img
              src="@/assets/guide/cham_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 램프' -->
    <div v-show="type === '아크릴 램프'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_white_1.png" alt="" />
        </div>
        <div class="lamp_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/lamp_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_white_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_white_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/lamp_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_white_6.png" alt="" />
        </div>
        <div class="lamp_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_7.png" alt="" /><img
              src="@/assets/guide/cham_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_11.png" alt="" /><img
              src="@/assets/guide/cham_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '부착형 아크릴' -->
    <div v-show="type === '부착형 아크릴'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_white_1.png" alt="" />
        </div>
        <div class="attach_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/attach_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_white_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_white_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/attach_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_white_6.png" alt="" />
        </div>
        <div class="attach_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_7.png" alt="" /><img
              src="@/assets/guide/cham_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_11.png" alt="" /><img
              src="@/assets/guide/cham_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '아크릴 블럭' -->
    <div v-show="type === '아크릴 블럭'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_white_1.png" alt="" />
        </div>
        <div class="block_img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/block_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_white_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_white_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/block_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_white_6.png" alt="" />
        </div>
        <div class="block_img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_7.png" alt="" /><img
              src="@/assets/guide/cham_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_11.png" alt="" /><img
              src="@/assets/guide/cham_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
    <!-- type === '쉐이커 키링' -->
    <div v-show="type === '쉐이커 키링'" class="typeWrap">
      <div>
        <p>
          화이트 레이어를 작업하지 않을 경우,
          <span> 스테인드 글라스처럼 투명</span>하게 인쇄됩니다.
        </p>
        <p>
          이러한 현상이 없이 종이에 인쇄한 것 같은 효과를 얻기 위해 화이트 영역을 지정해야 합니다.
        </p>
        <p>※ 일러스트의 흰색 부분은 화이트 레이어가 없을 시 색상이 인쇄되지 않습니다.</p>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_white_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 일러스트 레이어를 클릭합니다.</p>
          <p>2. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
        </div>
      </div>
      <div class="img_2 img_full">
        <img src="@/assets/guide/shaker_keyring_white_2.png" alt="" />
        <figure>픽셀 선택이 된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_white_3.png" alt="" />
        </div>
        <div class="img_3 right">
          <p>3. 메뉴에 '선택'을 클릭합니다.</p>
          <p>4. '수정'을 클릭합니다.</p>
          <p>5. '축소'를 클릭합니다.</p>
          <p>(선택→수정→축소)</p>
        </div>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_white_4.png" alt="" />
        </div>
        <div class="img_4 right">
          <p>6. '축소'를 클릭하면 '선택 영역 축소'라는 창이 뜹니다.</p>
          <p>7. 축소량을 2픽셀로 설정해주세요.</p>
          <p>(외곽선이 흰 색에 가까운 경우 1픽셀 축소 권장)</p>
        </div>
      </div>
      <div class="img_5 img_full">
        <img src="@/assets/guide/shaker_keyring_white_5.png" alt="" />
        <figure>2픽셀 축소된 모습</figure>
      </div>
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_white_6.png" alt="" />
        </div>
        <div class="img_6 right">
          <p>8. 새 레이어 추가합니다.</p>
          <p>
            9. 페인트 통 툴을 사용하여 노란색(C0 M0 <span>Y100</span> K0)으로 채웁니다.<br />&nbsp;&nbsp;&nbsp;(페인트
            통을 부은 이후, 구멍이 생기는 경우도 있으니 <br />&nbsp;&nbsp;&nbsp;&nbsp;전체적으로
            확인 부탁 드립니다.)
          </p>
          <p>10. ctrl + D를 눌러 선택 영역을 해제합니다.</p>
        </div>
      </div>
      <div>
        <h2 class="red">
          ※ 쉐이커 뒷면, 파츠, 앞면(있을 경우)을 모두 같은 방법으로 화이트 레이어를 제작합니다.
        </h2>
      </div>
      <div>
        <h2>※화이트 레이어 작업 시 주의사항※</h2>
        <div class="text">
          <p>
            ※화이트 레이어에 노란색이 아닌 다른 색 또한 화이트로 인쇄되니 작업에 유의해 주시기
            바랍니다.
          </p>
          <p>※머리카락 모서리 부분은 축소가 안 될 수도 있으니 확인하여 작업 부탁 드립니다.</p>
          <p>
            (일러스트 레이어에 색상 오버레이를 씌우거나, 검은색으로 클리핑 마스크를 만들면
            확인하시기 편합니다.)
          </p>
        </div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_7.png" alt="" /><img
              src="@/assets/guide/cham_white_8.png"
              alt=""
            />
          </div>
          <figure>축소가 되지 않은 곳 → 지우개 툴로 정리</figure>
        </div>
      </div>
      <div>
        <div class="img">
          <p>
            ※흰 색에 가까운 색의 경우, 화이트 레이어가 없을 시 <span>투명</span>하게 나올 수
            있습니다.
          </p>
          <p>(흰 색상은 화이트 레이어와 겹치게 혹은 1픽셀만 축소 부탁드립니다.)</p>
        </div>
      </div>
      <div>
        <div class="img">
          <div>
            <img src="@/assets/guide/cham_white_11.png" alt="" /><img
              src="@/assets/guide/cham_white_12.png"
              alt=""
            />
          </div>
          <figure>테두리가 흰 색인 경우 → 화이트 레이어와 흰 색이 겹치게 작업</figure>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※화이트 레이어의 불투명도는 그대로 흰 색 인쇄에 반영됩니다.</p>
          <p>(양면일 경우, 화이트 불투명도가 제대로 표현되지 않을 수 있습니다.)</p>
          <div>
            <img src="@/assets/guide/cham_white_13.png" alt="" />
          </div>
        </div>
      </div>
      <div class="center">
        <div class="img">
          <p>※투명도가 있는 화이트 레이어는 불투명한 화이트 레이어와 병합해 주세요.</p>
          <div>
            <img src="@/assets/guide/cham_white_14.png" alt="" />
          </div>
          <figure>병합 전 → 병합 후</figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    guideNum() {
      let num = 3
      if (this.type === '아크릴 바닥 부품') {
        num = 4
      } else {
        num = 3
      }
      return num
    },
  },
}
</script>

<style scoped lang="scss">
.whiteLayer {
  text-align: center;
  h1 {
    width: 100%;
    font-size: 34px;
  }

  h2 {
    font-size: 34px;
    margin: 50px auto;

    &.red {
      color: #ff6699;
      font-size: 30px;
    }
  }
  > .typeWrap {
    > div {
      margin-top: 40px;

      &.center {
        text-align: center;

        > .img {
          > div {
            margin-top: 20px;
            display: block;
          }
        }
      }

      > .img {
        margin-top: 80px;

        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      figure {
        margin-top: 10px;
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 20px;

        > span {
          color: #ff6699;
        }
      }

      &.img_half {
        margin-top: 100px;
        > div {
          float: left;
          margin-left: 35px;
          text-align: left;

          &.img_1 {
            margin-top: 200px;
          }
          &.img_3 {
            margin-top: 150px;
          }
          &.img_4 {
            margin-top: 180px;
          }
          &.img_6 {
            margin-top: 50px;
          }

          &.card_img_1 {
            margin-top: 100px;
          }
          &.card_img_3 {
            margin-top: 50px;
          }
          &.card_img_4 {
            margin-top: 20px;
          }
          &.card_img_6 {
            margin-top: 50px;
          }

          &.stand_img_1 {
            margin-top: 80px;
          }
          &.stand_img_3 {
            margin-top: 120px;
          }
          &.stand_img_4 {
            margin-top: 200px;
          }
          &.stand_img_6 {
            margin-top: 100px;
          }

          &.lamp_img_1 {
            margin-top: 100px;
          }
          &.lamp_img_6 {
            margin-top: 100px;
          }

          &.attach_img_1 {
            margin-top: 100px;
          }
          &.attach_img_6 {
            margin-top: 100px;
          }

          &.block_img_1 {
            margin-top: 100px;
          }
          &.block_img_6 {
            margin-top: 100px;
          }
        }
      }
    }
  }
}
</style>
