<template>
  <div class="step step1">
    <h2>디페스타 입장권 패키지</h2>
    <div class="ticketBox">
      <p class="border">디페스타 입장권</p>
      <img src="@/assets/goods/ticket_card.png" alt="" />
    </div>
    <div class="standBox">
      <ul>
        <li :class="{ active: ticket_package > 0 }">
          <p class="border">아크릴 우드 램프</p>
          <img src="@/assets/goods/ticket_lamp.png" alt="" />
        </li>
        <!-- <li @click="checkPackage('Neri', ticket_package)" :class="{ active: ticket_package > 0 }">
          <p class="border">네리 스탠드</p>
          <img src="@/assets/goods/ticket_stand_neri.png" alt="" />
        </li> -->
        <!-- <li
          @click="checkPackage('Nathan', ticket_package.Nathan)"
          :class="{ active: ticket_package.Nathan > 0 }"
        >
          <p class="border">네이선 스탠드</p>
          <img src="@/assets/goods/ticket_stand_nathan.png" alt="" />
        </li>
        <li
          @click="checkPackage('Synthesis', ticket_package.Synthesis)"
          :class="{ active: ticket_package.Synthesis > 0 }"
        >
          <p class="border">네이선+네리 스탠드</p>
          <img src="@/assets/goods/ticket_stand_synthesis.png" alt="" />
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket_package: {
      type: Number,
    },
  },
  methods: {
    checkPackage(type, num) {
      this.$emit('checkPackage', type, num)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    margin-bottom: 27px;
  }

  > .ticketBox {
    width: 100%;
    border: 2px solid #ff6699;
    border-radius: 10px;
    height: auto;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 30px;

    > .border {
      width: 490px;
      text-align: left;
      margin: 30px auto;
      border-bottom: 2px solid #e6e6e6;
      padding-bottom: 10px;
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 700;
    }

    > img {
      width: 230px;
      height: 146px;
    }
  }

  > .standBox {
    margin: 70px auto;
    > ul {
      display: flex;
      justify-content: space-between;
      > li {
        width: 100%;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        height: auto;
        box-sizing: border-box;
        text-align: center;
        padding-bottom: 30px;
        cursor: pointer;

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > .border {
          width: 90%;
          text-align: left;
          margin: 15px auto;
          border-bottom: 2px solid #e6e6e6;
          padding-bottom: 8px;
          font-size: 14px;
          font-weight: 700;
        }

        > img {
          width: 209.6px;
          height: 346px;
        }
      }
    }
  }
}
</style>
