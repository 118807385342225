<template>
  <div class="news">
    <list-container />
  </div>
</template>

<script>
import ListContainer from '../../containers/News/ListContainer.vue'

export default {
  name: 'NewsList',
  components: {
    ListContainer,
  },
}
</script>

<style></style>
