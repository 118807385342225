export const SizeBox = {
  namespaced: true,
  state: () => ({
    sizeBox: {
      boxWidth: 300,
      boxHeight: 300,
      dpi: 300,
      boxMargin: 100,
    },
    imgSize: 0,
    imageStyle: {
      width: '0px',
      height: '0px',
    },
  }),
  mutations: {
    RESET_SIZE(state, data) {
      state.sizeBox = data
    },
    DPI_CHANGE(state, data) {
      state.sizeBox.dpi = data
    },
    IMG_SIZE_CHANGE(state, data) {
      state.imgSize = data
    },
    STYLE_CHANGE(state, data) {
      state.imageStyle = data
    },
  },
  actions: {
    resetSize({ commit }, data) {
      commit('RESET_SIZE', data)
    },
    dpiChange({ commit }, data) {
      commit('DPI_CHANGE', data)
    },
    imgSizeChange({ commit }, data) {
      commit('IMG_SIZE_CHANGE', data)
    },
    styleChange({ commit }, data) {
      commit('STYLE_CHANGE', data)
    },
  },
  getters: {
    sizeBox(state) {
      return state.sizeBox
    },
    imgSize(state) {
      return state.imgSize
    },
    imageStyle(state) {
      return state.imageStyle
    },
  },
}
