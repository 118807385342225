<template>
  <div :class="{ step: path === 'Basic' || path === 'Template', SpecialStep: path === 'Special' }">
    <h2>도안</h2>
    <div v-if="path === 'Template'" class="templateDownload">
      <div class="referenceBox">
        <div
          @mouseover="templateSpeechView()"
          @focus="templateSpeechView()"
          @mouseout="templateSpeechHide()"
          @focusout="templateSpeechHide()"
        >
          <img src="@/assets/icon/questionIcon.svg" alt="" />
          <span>{{ type }} 주문 방법</span>
        </div>
        <div class="speech_bubble" :class="{ active: templateSpeechBubble === true }">
          <div class="bubble_text">
            <p>1. 템플릿 다운로드 합니다.</p>
            <p>
              2. 템플릿에 맞추어 도안 작업하여 업로드 합니다.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;도안 제작이 어려울시, 의뢰 부탁드립니다.
            </p>
          </div>
        </div>
      </div>
      <a
        v-show="type !== '아크릴 카라비너' && type !== '아크릴 관람차'"
        :href="`https://storage.googleapis.com/dn-web-339309.appspot.com/template/${type} 템플릿.pdf`"
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        템플릿 다운로드<img src="@/assets/icon/ic_file_upload_24px.png" alt="" />
      </a>
      <a
        v-show="type === '아크릴 카라비너'"
        :href="`https://storage.googleapis.com/dn-web-339309.appspot.com/template/${type}_${shapeNum} 템플릿.pdf`"
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        템플릿 다운로드<img src="@/assets/icon/ic_file_upload_24px.png" alt="" />
      </a>
      <a
        v-show="type === '아크릴 관람차'"
        :href="`https://storage.googleapis.com/dn-web-339309.appspot.com/template/${type}_${wheelNum} 템플릿.pdf`"
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        템플릿 다운로드<img src="@/assets/icon/ic_file_upload_24px.png" alt="" />
      </a>
    </div>
    <div class="selectBox clearFix">
      <ul>
        <li
          :class="{ active: request === false }"
          @click="checkRequest(false)"
          @keydown="checkRequest(false)"
        >
          도안 파일 업로드
        </li>
        <li
          :class="{ active: request === true }"
          @click="checkRequest(true)"
          @keydown="checkRequest(true)"
        >
          도안 파일 제작 의뢰
        </li>
      </ul>
    </div>
    <div class="uploadBox">
      <div class="center clearFix">
        <div class="left">
          <label for="goods_file">
            <div v-if="file">
              <span>{{ file.name }}</span>
            </div>
            <div v-else>
              <img src="@/assets/goods/file_upload_plus.png" alt="" />
            </div>
          </label>
          <input
            id="goods_file"
            ref="goods_file"
            type="file"
            class="file-input"
            name="files"
            @change="selectFile()"
          />
        </div>
        <div v-show="request === false" class="right">
          <div class="note">
            <p>1. PSD/AI 작업된 파일로 작업 부탁드립니다.</p>
            <p>
              2. 제작가이드를 참고하여 제작한 <br />&nbsp;&nbsp;&nbsp;&nbsp;도안 파일 업로드
              해주시기 바랍니다.
            </p>
            <p>3. 작업 파일을 ZIP 파일로 압축해 업로드 해주시기 바랍니다.</p>
          </div>
          <a
            v-show="path === 'Basic'"
            class="btn"
            target="_blank"
            rel="noopener noreferrer"
            :href="`/guide/${$route.params.type}`"
          >
            제작 가이드
          </a>
        </div>
        <div v-show="request === true" class="right">
          <div class="note">
            <p class="strong">도안 작업이 어렵다면 의뢰해주세요!</p>
            <p>· 배경 투명화된 'png파일'을 압축하여 업로드 부탁드립니다.</p>
            <p>· 해상도는 최소 300dpi로 업로드 부탁드립니다.</p>
            <p>· 배경 투명화가 어렵다면 아래 버튼을 클릭해주세요.</p>
          </div>
          <a class="btn" target="_blank" rel="noopener noreferrer" href="https://www.remove.bg/ko">
            배경 투명화 사이트로 이동<img src="@/assets/icon/ic_launch_24px.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
    type: {
      type: String,
    },
    file: {
      type: String,
    },
    request: {
      type: Boolean,
    },
    shapeNum: {
      type: Number,
    },
    wheelNum: {
      type: Number,
    },
    templateSpeechBubble: {
      type: Boolean,
    },
  },
  methods: {
    selectFile() {
      this.$emit('selectFile')
    },
    checkRequest(boolean) {
      this.$emit('checkRequest', 'request', 0, boolean)
    },
    templateSpeechView() {
      this.$emit('templateSpeechView')
    },
    templateSpeechHide() {
      this.$emit('templateSpeechHide')
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 10px;
  }

  > .templateDownload {
    > .referenceBox {
      position: relative;

      > div {
        display: inline-block;
        cursor: pointer;
        > span {
          font-size: 12px;
          color: #ff6699;
          margin-left: 3px;
        }
      }
      > .speech_bubble {
        width: 310px;
        height: 80px;
        position: absolute;
        left: 145px;
        top: 2px;
        background: url('../../../assets/icon/speech_bubble.png') center center no-repeat;
        transition: 0.5s;
        opacity: 0;
        pointer-events: none;

        &.active {
          opacity: 1;
        }

        > .bubble_text {
          position: absolute;
          top: 13px;
          left: 20px;

          > p {
            font-size: 12px;
            color: #707070;
          }
        }
      }
    }

    > a {
      color: #fff;
      display: block;
      width: 100%;
      height: 59px;
      line-height: 59px;
      background-color: #ff6699;
      border-radius: 10px;
      text-align: center;
      font-size: 22px;
      margin-bottom: 20px;

      > img {
        vertical-align: inherit;
      }
    }
  }

  > .selectBox {
    width: 100%;
    margin-bottom: 20px;

    > ul {
      display: flex;
      justify-content: space-between;

      > li {
        width: 47%;
        height: 35px;
        line-height: 35px;
        border: 2px solid #6d6d6d;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        color: #707070;
        cursor: pointer;

        &.active {
          border: 2px solid #ff6699;
          background-color: #fffbfc;
        }
      }
    }
  }

  > .uploadBox {
    width: 100%;
    border: 2px solid #bcbcbc;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px;

    > .center {
      margin: 0 auto;

      > .left {
        float: left;
        width: 117px;
        height: 117px;
        border: 2px dashed #ff6699;
        border-radius: 10px;

        > label {
          > div {
            position: relative;
            width: 100%;
            height: 100%;
            cursor: pointer;

            > img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
            }

            > span {
              width: 90%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #ff6699;
              text-align: center;
            }
          }
        }
        > input {
          display: none;
        }
      }

      > .right {
        float: right;

        > .note {
          font-size: 13px;

          > .strong {
            font-size: 14px;
            margin-bottom: 3px;
          }
        }
        > .btn {
          display: block;
          width: 100%;
          background: #ff6699;
          color: #fff;
          height: 25px;
          border-radius: 5px;
          line-height: 25px;
          text-align: center;
          margin-top: 15px;
          cursor: pointer;
          font-weight: 300;

          > img {
            vertical-align: super;
            margin-left: 2px;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .uploadBox {
    font-weight: 400;

    > .uploadBtns {
      float: left;

      > li {
        width: 118px;
        height: 160px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 95px;
          margin: 26px auto 0;
          text-align: center;

          > span {
            font-size: 10px;
            color: #707070;
          }

          > p {
            text-align: left;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 70%;
            height: 60px;
            margin-top: 20px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
