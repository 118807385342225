<template>
  <div class="wrap">
    <h1>상세 주문</h1>
    <div class="tableWrap">
      <h2>주문 상품</h2>
      <product-table
        v-if="get_order === true"
        :path="path"
        :products="orderInfo.order"
        :order-info="orderInfo"
        :file-data="fileData"
        @select-file="selectFile"
        @file-save="fileSave"
        @delete-file="deleteFile"
      />
    </div>
    <!-- 가상계좌 정보 -->
    <order-info
      v-if="get_order === true"
      :type="'vBank'"
      :order-info="orderInfo"
      :vbank-info="vbankInfo"
      :cancel-info="cancelInfo"
    />

    <!-- 버튼 -->
    <div class="detailBtns">
      <div class="list" @click="goList()" @keydown="goList()">목 록</div>
      <div
        v-if="orderInfo.payment_status === 'ready'"
        class="orderCancel"
        @click="cancelBtn('cancel')"
        @keydown="cancelBtn('cancel')"
      >
        주 문 취 소
      </div>
      <div
        v-if="
          orderInfo.payment_status === 'paid' &&
          orderInfo.order_status !== '제작중' &&
          orderInfo.order_status !== '배송준비중' &&
          orderInfo.order_status !== '배송완료' &&
          orderInfo.order_status !== '배송중'
        "
        class="orderCancel"
        @click="cancelBtn('request')"
        @keydown="cancelBtn('request')"
      >
        환 불 신 청
      </div>
      <div
        v-if="orderInfo.order_status === '배송완료' && orderInfo.payment_status === 'paid'"
        class="orderCancel"
        @click="confirmation()"
        @keydown="confirmation()"
      >
        구 매 확 정
      </div>
    </div>
    <order-cancel
      v-if="cancel_modal === true"
      @close-message="closeModal"
      @cancel-btn="paymentCancel"
    />
    <order-refund
      v-if="refund_modal === true"
      @close-message="closeModal"
      @refund-btn="refundRequest"
    />
    <div
      v-if="orderInfo.order_status === '파일에러' && orderInfo.file_corrections"
      class="corrections"
      @click="retouchView()"
      @keydown="retouchView()"
    >
      <img src="@/assets/icon/corrections.png" alt="" />
    </div>
    <Order-retouch
      v-if="retouch_view === true"
      :retouch="retouch"
      @close-tab="closeModal"
      @retouch-answer="retouchAnswer"
    />
    <order-confirmation
      v-if="confirmation_view === true"
      :point="confirmation_point"
      @close-message="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductTable from '../../components/Public/ProductTable.vue'
import OrderInfo from '../../components/Order/OrderInfo.vue'
import OrderRefund from '../../components/Modal/OrderRefund.vue'
import OrderCancel from '../../components/Modal/OrderCancel.vue'
import OrderRetouch from '../../components/Modal/ModalRetouch.vue'
import OrderConfirmation from '../../components/Modal/OrderConfirmation.vue'

export default {
  components: {
    ProductTable,
    OrderInfo,
    OrderRefund,
    OrderCancel,
    OrderRetouch,
    OrderConfirmation,
  },
  data() {
    return {
      path: '', // 현재 route name
      products: [], // 선택한 상품 목록
      fileData: {}, // 도안파일을 저장할 객체
      fileName: {}, // 도안파일 이름을 저장할 객체
      orderInfo: {}, // 주문정보
      get_order: false,
      vbankInfo: {},
      cancelInfo: {},
      refund_modal: false,
      retouch_view: false,
      retouch: {
        contents: '',
      }, // 도안 수정사항
      cancel_modal: false,
      confirmation_view: false,
      confirmation_point: 0,
    }
  },
  mounted() {
    this.path = this.$route.name
    this.params = this.$route.params.type
    this.getOrder()
  },
  methods: {
    // 주문내역 상세조회
    async getOrder() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      await this.axios
        .get(`${this.Path.GetOrderDetail}?payment_id=${this.$route.query.payment_id}`, config)
        .then(async (res) => {
          if (res.data.success === true) {
            if (res.data.data.payment_status === 'ready') {
              await res.data.data.account.map((data) => {
                if (data.type === '가상계좌') {
                  this.vbankInfo = data
                }
                return
              })
            }
            if (
              res.data.data.payment_status === 'request' ||
              res.data.data.payment_status === 'refund_complete'
            ) {
              await this.getRefundInfo()
              await res.data.data.account.map((data) => {
                if (data.type === '환불계좌') {
                  this.cancelInfo.account_holder = data.account_holder
                  this.cancelInfo.account_number = data.account_number
                  this.cancelInfo.bank_name = data.bank_name
                }
                return
              })
            }
            this.orderInfo = res.data.data
            this.get_order = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 환불정보 조회
    async getRefundInfo() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      await this.axios
        .get(`${this.Path.GetRefundInfo}?payment_id=${this.$route.query.payment_id}`, config)
        .then((res) => {
          if (res.data.success === true) {
            this.cancelInfo = res.data.data
          }
        })
    },
    // 파일선택 이벤트
    selectFile(e, index) {
      let orderFile = e.target
      if (orderFile.files[0].type.match(/zip.*/)) {
        if (orderFile.files[0].size > 15728640) {
          this.Message('파일 크기는 15M 까지만 가능합니다.', 0, 0)
        } else {
          this.fileData[index] = orderFile.files[0]
        }
      } else {
        this.Message('zip파일 형식이 아닙니다. zip파일 형식으로 업로드 해주세요.', 0, 0)
      }
      if (!orderFile.files[0]) {
        delete this.fileData[index]
      }
    },
    // 상품 도안 파일 저장
    fileSave(product, index) {
      let formData = new FormData()
      let index_num
      if (index < 10) {
        index_num = `0${index}`
      } else {
        index_num = index
      }
      formData.append('file', this.fileData[index], `${index_num}:${this.fileData[index].name}`)
      formData.append('order_id', product.id)
      formData.append('order_status', this.orderInfo.order_status)
      formData.append('payment_id', this.orderInfo.id)
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      this.axios.post(this.Path.OrderFileUpload, formData, config).then((res) => {
        if (res.data.success === true) {
          this.Message('도안파일이 등록되었습니다.', 0)
          this.orderInfo.order[index].design_file = res.data.data
        }
      })
    },
    // 상품 도안파일 삭제
    deleteFile(product, index) {
      let fileName = this.SubStr.deleteFileName(product.design_file)
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .delete(`${this.Path.OrderFileDelete}?order_id=${product.id}&file_name=${fileName}`, config)
        .then((res) => {
          if (res.data.success === true) {
            this.Message('도안파일이 삭제되었습니다.', 0)
            this.orderInfo.order[index].design_file = ''
          }
        })
    },
    // 주문 취소, 환불신청 버튼
    cancelBtn(data) {
      if (data === 'cancel') {
        this.cancel_modal = true
      } else {
        this.refund_modal = true
      }
    },
    // 주문 취소
    paymentCancel() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .patch(
          this.Path.CancelPayment,
          {
            id: this.orderInfo.id,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('주문 취소가 완료되었습니다.', 0)
            this.cancel_modal = false
            this.orderInfo.payment_status = 'cancel'
          }
        })
    },
    // 환불 신청
    refundRequest(reason) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .post(
          this.Path.RefundRequest,
          {
            reason,
            request_amount: this.orderInfo.total_payment_amount,
            payment_id: this.orderInfo.id,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('환불 신청이 완료되었습니다.', 0)
            this.orderInfo.payment_status = 'request'
            this.refund_modal = false
          }
        })
    },
    // 구매확정
    confirmation() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .patch(
          this.Path.ConfirmationOrder,
          {
            payment_id: this.orderInfo.id,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.confirmation_view = true
            this.confirmation_point = res.data.data.point
            this.orderInfo.payment_status = 'confirmation'
            this.$store.dispatch('UserInfo/confirmation', res.data.data)
          }
        })
    },
    // 도안 수정사항 내용 보기
    retouchView() {
      this.retouch = this.orderInfo.file_corrections
      this.retouch_view = true
    },
    // 도안 수정요청 답변시 order_status값을 변경
    retouchAnswer(status) {
      this.orderInfo.order_status = status
    },
    closeModal() {
      this.refund_modal = false
      this.cancel_modal = false
      this.retouch_view = false
      this.confirmation_view = false
    },
    goList() {
      this.$router.push({
        name: 'OrderList',
        params: { type: this.params },
        query: {
          page: this.$route.query.page,
        },
      })
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.wrap {
  font-family: 'Noto Sans KR';
  width: 1160px;
  color: #707070;
  margin: 0 auto;
  position: relative;

  > h1 {
    color: #707070;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
  }

  > .tableWrap {
    width: 100%;
    margin: 26px auto 65px;

    > h2 {
      font-size: 16px;
      color: #707070;
      text-align: left;
      font-weight: 400;
    }

    > table {
      width: 100%;
      border-collapse: collapse;
    }
  }

  > .detailBtns {
    text-align: center;
    margin: 112px auto;

    > div {
      display: inline-block;
      width: 276px;
      height: 60px;
      line-height: 60px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;

      &.list {
        border: 2px solid #ff6699;
        background-color: #ff6699;
        color: #fff;
        margin-right: 60px;
      }

      &.orderCancel {
        border: 2px solid #ff6699;
        color: #707070;
      }
    }
  }

  > .corrections {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 5%;
    bottom: 10%;
    cursor: pointer;
    z-index: 50;

    > img {
      width: 100%;
    }
  }
}
</style>
