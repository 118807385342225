<template>
  <div class="goods">
    <special-container />
  </div>
</template>

<script>
import SpecialContainer from '../../containers/Goods/SpecialContainer.vue'

export default {
  name: 'Special',
  components: {
    SpecialContainer,
  },
}
</script>

<style></style>
