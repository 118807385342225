<template>
  <div>
    <cart-container />
  </div>
</template>

<script>
import CartContainer from '../../containers/Cart/CartContainer.vue'

export default {
  components: {
    CartContainer,
  },
}
</script>

<style></style>
