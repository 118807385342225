<template>
  <div>
    <review-container />
  </div>
</template>

<script>
import ReviewContainer from '../../containers/MyPage/ReviewContainer.vue'

export default {
  components: {
    ReviewContainer,
  },
}
</script>

<style></style>
