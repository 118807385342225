<template>
  <div class="rightWrap clearFix sticky">
    <div class="detailWrap">
      <div class="imageBox">
        <img :src="require(`@/assets/goods/right_acrylic_${$route.params.type}.png`)" alt="" />
      </div>
      <div class="detailBox">
        <h2>{{ type }}</h2>
        <div class="product_period">
          <p class="none-border">
            ※ 제작기간 : 도안이 접수된 날로부터 영업일 기준
            <span>최대 {{ production_period }}일 소요</span>
          </p>
          <p v-show="goods.period !== '빠른제작' && type !== '쉐이커 키링'">
            ※ <span>홀로그램과 투명 쉘</span>로 주문하실 경우
            <span>최대 {{ shell_period }}일</span> 소요될 수 있습니다.
          </p>
        </div>

        <div v-if="type !== '쉐이커 키링'" class="clearFix productInfo">
          <span v-show="type !== '자유형 책갈피'" class="left">아크릴 두께</span>
          <span v-show="type === '자유형 책갈피'" class="left">책갈피 두께</span>
          <span class="right">{{ goods.thick }}</span>
        </div>
        <div v-if="type !== '쉐이커 키링'" class="clearFix productInfo">
          <span class="left">아크릴 종류</span>
          <span class="right"
            >{{ goods.kind }}<span v-if="goods.hologram_kind"> [{{ goods.hologram_kind }}]</span>
          </span>
        </div>
        <div v-if="type === '아크릴 스탠드' || type === '아크릴 램프'" class="clearFix productInfo">
          <span class="left">아크릴 바닥부품</span>
          <span class="right">{{ goods.stand }}</span>
        </div>
        <div class="clearFix productInfo">
          <span v-show="type !== '자유형 책갈피'" class="left">아크릴 크기</span>
          <span v-show="type === '자유형 책갈피'" class="left">책갈피 크기</span>
          <span class="right">{{ goods.x }}cm*{{ goods.y }}cm</span>
        </div>
        <div class="clearFix productInfo">
          <span v-show="type !== '자유형 책갈피' && type !== '쉐이커 키링'" class="left"
            >아크릴 인쇄면</span
          >
          <span v-show="type === '자유형 책갈피'" class="left">책갈피 인쇄면</span>
          <span v-show="type === '쉐이커 키링'" class="left">쉐이커 앞면 인쇄면</span>
          <span class="right">{{ goods.print }}</span>
        </div>
        <div v-if="type === '쉐이커 키링'" class="clearFix productInfo">
          <span class="left">쉐이커 뒷면 인쇄면</span>
          <span class="right">{{ goods.second_print }}</span>
        </div>
        <div v-if="type === '쉐이커 키링'" class="clearFix productInfo">
          <span class="left">쉐이커 파츠 인쇄면</span>
          <span class="right">{{ goods.parts_print }}</span>
        </div>
        <div v-if="type !== '아크릴 램프'" class="clearFix productInfo">
          <span class="left">부자재 선택</span>
          <span class="right"
            >{{ goods.etc
            }}<span v-if="goods.etc === '고리'"
              >({{ goods.keyring_option }} - 색상 : {{ goods.keyring_color }})</span
            >
            <!--  -->
            <span v-if="goods.etc === '군번줄'">({{ goods.tag_color }})</span>
            <span v-if="goods.etc === '와이어링'">({{ goods.wiring_color }})</span>
            <!--  -->
            <span v-if="goods.etc === '뱃지'">({{ goods.badge_kind }})</span>
            <!--  -->
            <span v-if="goods.etc === '스마트톡'">({{ goods.tok_color }})</span>
            <!--  -->
            <span v-if="goods.item_opp === true">(OPP봉투 추가)</span></span
          >
        </div>
        <div class="clearFix productInfo">
          <span class="left">조립/포장 선택</span>
          <span class="right">{{ goods.packing }}</span>
        </div>
        <div class="clearFix productInfo">
          <span class="left">도안파일</span>
          <span v-if="goods.file" class="right"
            >{{ goods.file.name
            }}<span v-show="goods.design_request === true">(제작 의뢰)</span></span
          >
          <span v-else class="right"
            >없음<span v-show="goods.design_request === true">(제작 의뢰)</span></span
          >
        </div>
        <div v-if="type === '쉐이커 키링'" class="clearFix productInfo">
          <span class="left">쉐이커 프레임 구멍 개수</span>
          <span class="right">{{ goods.bolt_set }}개</span>
        </div>
        <div v-if="type !== '쉐이커 키링'" class="clearFix productInfo">
          <span class="left">제작할 도안 수량</span>
          <span class="right">{{ goods.design_quantity }}개</span>
        </div>
        <div class="clearFix productInfo">
          <span class="left">총 주문 수량</span>
          <span class="right">{{ goods.order_quantity }}개</span>
        </div>
      </div>
      <div class="priceBox">
        <div class="clearFix">
          <span class="left">총 금액</span>
          <span class="right clearFix">
            <span class="strong">￦{{ Commas(goods.fullPrice) }}원</span>
            <ul>
              <li class="bubble" :class="{ show: goods.order_quantity <= 1 }">
                <img src="@/assets/goods/speech_bubble.png" alt="" />
                <span>-{{ goods.percentage }}%</span>
              </li>
              <li class="discount" :class="{ show: goods.order_quantity <= 1 }">
                (<strong>개당</strong>: {{ Commas(goods.price4one) }}원)
              </li>
              <li class="price">(<strong>개당</strong>: {{ Commas(goods.price) }}원)</li>
            </ul></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      lamp: 'Lamp/lamp',
      type: 'Goods/type',
      quick_order: 'Goods/quick_order',
      production_period: 'Goods/production_period',
      shell_period: 'Goods/shell_period',
    }),
  },
}
</script>

<style scoped lang="scss">
.rightWrap {
  float: right;
  width: 410px;
  height: 100vh;
  background-color: #fff7fa;
  -ms-overflow-style: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  > .detailWrap {
    > .imageBox {
      border-bottom: 1px solid #ff6699;
      text-align: center;
      padding: 5px 0;
    }

    > .detailBox {
      width: 345px;
      margin: 15px auto 0;

      > h2 {
        display: inline-block;
        color: #707070;
      }

      > .quantity {
        display: inline-block;

        > p {
          font-size: 16px;
          font-weight: 500;
          margin-left: 5px;
        }
      }

      > .product_period {
        border-bottom: 1px solid #ff6699;
        padding-bottom: 10px;
        > p {
          font-size: 11px;

          > span {
            color: #ff6699;
          }
        }
      }

      > .productInfo {
        height: 35px;
        line-height: 35px;
        border-bottom: 1px solid #ff6699;

        > .left {
          float: left;
          color: #979797;
          font-size: 12px;
          text-indent: 2px;
        }

        > .right {
          float: right;
          color: #656565;
          font-size: 12px;
        }
      }
    }

    > .priceBox {
      width: 345px;
      margin: 55px auto 0;

      > div {
        > .left {
          float: left;
          color: #656565;
          font-size: 12px;
          margin-top: 15px;
        }

        > .right {
          float: right;
          color: #707070;
          font-size: 12px;
          position: relative;

          > .bubble {
            // position: absolute;
            // top: -20px;
            // left: 5px;
            display: inline-block;
          }
          > .strong {
            font-size: 26px;
            font-weight: 700;
            float: right;
          }

          > ul {
            // display: inline-block;
            float: right;
            margin-right: 5px;

            > .show {
              opacity: 0;
            }

            > .bubble {
              position: relative;
              left: -75%;
              top: -8px;

              > * {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                color: #fff;
                font-size: 14px;
              }
            }
            > .discount {
              text-decoration: line-through;
            }

            > .price {
              color: #ff6699;
            }
          }
        }
      }
    }
  }
}
</style>
