<template>
  <div>
    <login-container />
  </div>
</template>

<script>
import LoginContainer from '../../containers/Login/LoginContainer.vue'

export default {
  components: {
    LoginContainer,
  },
}
</script>

<style></style>
