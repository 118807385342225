<template>
  <div class="step">
    <h2>아크릴 바닥부품</h2>
    <div class="standBox clearFix">
      <div>
        <input
          id="standnone"
          type="checkbox"
          :checked="standNum === 0"
          :disabled="standNum === 0"
          @change="checkstand(0, '없음')"
        /><label for="standnone">바닥 부품 없음</label>
      </div>
      <ul class="standBtns">
        <li
          class="standBtn"
          :class="{ active: standNum === 1 }"
          @click="checkstand(1, '4cm')"
          @keydown="checkstand(1, '4cm')"
        >
          <div>
            <p>4cm</p>
            <span>(바닥부품 두께 : 5T)</span>
            <hr />
            <img
              v-if="standNum === 1"
              class="stand_img"
              src="@/assets/goods/stand_4cm_active.svg"
              alt=""
            />
            <img v-else class="stand_img" src="@/assets/goods/stand_4cm_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="standBtn"
          :class="{ active: standNum === 2 }"
          @click="checkstand(2, '6cm')"
          @keydown="checkstand(2, '6cm')"
        >
          <div>
            <p>6cm</p>
            <span>(바닥부품 두께 : 5T)</span>
            <hr />
            <img
              v-if="standNum === 2"
              class="stand_img"
              src="@/assets/goods/stand_6cm_active.svg"
              alt=""
            />
            <img v-else class="stand_img" src="@/assets/goods/stand_6cm_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="standBtn"
          :class="{ active: standNum === 3 }"
          @click="checkstand(3, '8cm')"
          @keydown="checkstand(3, '8cm')"
        >
          <div>
            <p>8cm</p>
            <span>(바닥부품 두께 : 5T)</span>
            <hr />
            <img
              v-if="standNum === 3"
              class="stand_img"
              src="@/assets/goods/stand_8cm_active.svg"
              alt=""
            />
            <img v-else class="stand_img" src="@/assets/goods/stand_8cm_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    standNum: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  methods: {
    checkstand(i, stand) {
      this.$emit('checkStand', 'stand', i, stand)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .standBox {
    font-weight: 400;

    > .standBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          > div {
            > img {
              margin-top: 45px;
            }
          }
        }

        &:nth-child(2) {
          > div {
            > img {
              margin-top: 35px;
            }
          }
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        &.disabled {
          background: #ddd;
          pointer-events: none;
        }

        > div {
          width: 144px;
          height: 185px;
          margin: 26px auto 0;
          text-align: center;
          vertical-align: middle;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin-top: 25px;
          }
        }
      }
    }
  }
}
input[type='checkbox'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      content: ' ';
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 15px;
      margin: -2px 8px 0 0;
      text-align: center;
      vertical-align: middle;
      background: #fafafa;
      border: 1px solid #979797;
      border-radius: 5px;
    }
  }

  &:checked {
    + label {
      &::before {
        content: '\2713';
        /* font-weight: 700; */
        color: #ff6699;
        text-shadow: 0.5px 0.5px #ff6699;
        background: #fedbe8;
        border-color: #979797;
      }
    }
  }
}
label {
  color: #707070;
  font-size: 14px;
  margin-bottom: 5px;
}
</style>
