<template>
  <div class="error_wrap">
    <div class="center">
      <img src="@/assets/main/error_page.png" alt="" />
      <strong>요청하신 페이지를 찾을 수 없습니다.</strong>
      <p>주소가 잘못되었거나 페이지가 삭제되었습니다.</p>
      <span>주소를 다시 한 번 확인부탁드립니다.</span>
      <ul>
        <li class="prev" @click="$router.go(-1)" @keydown="$router.go(-1)">이전 페이지로 이동</li>
        <li
          class="main"
          @click="$router.push({ name: 'index' })"
          @keydown="$router.push({ name: 'index' })"
        >
          메인으로 이동
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.error_wrap {
  min-width: 1080px;
  width: 1160px;
  margin: 0 auto;

  > .center {
    width: 500px;
    margin: 0 auto;
    text-align: center;

    > img {
      display: block;
      margin: 100px auto 50px;
    }

    > strong {
      display: block;
      font-size: 25px;
      margin-bottom: 30px;
    }

    > p {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    > span {
      display: block;
      font-size: 16px;
      color: #ff6699;
      font-weight: 500;
    }

    > ul {
      display: flex;
      justify-content: space-around;
      margin: 70px 0;

      > li {
        width: 150px;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ff6699;
        color: #ff6699;
        cursor: pointer;
        border-radius: 5px;

        &.main {
          color: #fff;
          background: #ff6699;
        }
      }
    }
  }
}
</style>
