<template>
  <div>
    <error-404 />
  </div>
</template>

<script>
import error404 from '../../components/Error/error_404.vue'

export default {
  components: {
    error404,
  },
}
</script>

<style></style>
