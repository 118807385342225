<template>
  <div class="leftWrap">
    <div v-if="params === 'Items'" class="stepWrap">
      <!-- 키링 옵션 -->
      <keyring :keyring-type="keyringType" @check-color="checkColor" />
      <!-- 군번줄 색상 선택 -->
      <dog-tag :dog-tag="dogTag" @check-tag-color="checkTagColor" />

      <!-- 와이어링 색상 선택 -->
      <Wiring :wiring="wiring" @check-wiring-color="checkWiringColor" />
      <!-- 스마트톡 색상 선택 -->
      <smart-tok :smart-tok="smartTok" @check-smart-tok="checkSmartTok" />
      <!-- 뱃지 종류 선택 -->
      <Badge :badge="badge" @check-badge="checkBadge" />
      <!-- o링, 휴대폰줄 -->
      <accessories :Oring="Oring" :strap="strap" @check-item="checkItem" />
    </div>
    <div v-if="params === 'Ticket_Package'" class="stepWrap">
      <ticket-package :ticket_package="ticket_package" @check-package="checkPackage" />
    </div>
    <div v-if="params === 'Ticket'" class="stepWrap">
      <ticket />
    </div>
    <!-- 장바구니 버튼 -->
    <div
      class="cartBtn"
      :class="[
        { disabled: params === 'Ticket_Package' && ticket_package_quantity === 0 },
        { disabled: params === 'Ticket' && ticket_quantity === 0 },
      ]"
      @click="cartAdd()"
      @keydown="cartAdd()"
    >
      장바구니에 담기
    </div>
  </div>
</template>

<script>
import Accessories from '../List/EtcItem/ItemAccessories.vue'
import Keyring from '../List/EtcItem/ItemKeyring.vue'
import DogTag from '../List/EtcItem/DogTag.vue'
import Wiring from '../List/EtcItem/ItemWiring.vue'
import SmartTok from '../List/EtcItem/SmartTok.vue'
import Badge from '../List/EtcItem/ItemBadge.vue'
import TicketPackage from '../List/EtcItem/TicketPackage.vue'
import Ticket from '../List/EtcItem/ItemTicket.vue'
import Ticket_Select from '../List/EtcItem/Ticket_Select.vue'

export default {
  components: {
    Accessories,
    Keyring,
    TicketPackage,
    DogTag,
    Wiring,
    SmartTok,
    Badge,
    Ticket,
  },
  props: {
    params: {
      type: String,
    },
    Oring: {
      type: Number,
    },
    wiring: {
      type: Array,
    },
    smartTok: {
      type: Object,
    },
    badge: {
      type: Object,
    },
    dogTag: {
      type: Array,
    },
    strap: {
      type: Number,
    },
    keyringType: {
      type: Array,
    },
    ticket: {
      type: Number,
    },
    ticket_package: {
      type: Number,
    },
    ticket_package_quantity: {
      type: Number,
    },
    ticket_quantity: {
      type: Number,
    },
  },
  methods: {
    checkItem(type, num) {
      this.$emit('checkItem', type, num)
    },
    checkPackage(type, num) {
      this.$emit('checkPackage', type, num)
    },
    checkColor(type, num, index) {
      this.$emit('checkColor', type, num, index)
    },
    checkTagColor(num, index) {
      this.$emit('checkTagColor', num, index)
    },
    checkWiringColor(num, index) {
      this.$emit('checkWiringColor', num, index)
    },
    checkSmartTok(color, num) {
      this.$emit('checkSmartTok', color, num)
    },
    checkBadge(color, num) {
      this.$emit('checkBadge', color, num)
    },
    cartAdd() {
      this.$emit('cartAdd')
    },
  },
}
</script>

<style scoped lang="scss">
.leftWrap {
  float: left;
  width: 750px;

  > .stepWrap {
    margin-top: 46px;
  }

  > .cartBtn {
    width: 574px;
    height: 45px;
    line-height: 45px;
    border-radius: 10px;
    background-color: #ff6699;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0 auto 50px;
    cursor: pointer;
  }

  .disabled {
    background-color: #777;
    pointer-events: none;
  }
}
</style>
