<template>
  <div class="goods">
    <custom-order-container />
  </div>
</template>

<script>
import CustomOrderContainer from '../../containers/Goods/CustomOrderContainer.vue'

export default {
  name: 'CustomOrder',
  components: {
    CustomOrderContainer,
  },
}
</script>

<style></style>
