<template>
  <div class="deliveryWrap clearFix">
    <table class="clearFix">
      <tr class="clearFix">
        <td class="deliveryTitle">배송지 확인</td>
        <td>
          <input
            id="basic"
            type="radio"
            name="delivery"
            :value="delivery"
            :disabled="select_coupon.type === '행사'"
            checked
            @change="deliveryChange('기본배송지')"
          />
          <label for="basic">기본배송지</label>
          <input
            id="directly"
            type="radio"
            name="delivery"
            :value="delivery"
            :disabled="select_coupon.type === '행사'"
            @change="deliveryChange('직접입력')"
          />
          <label for="directly">직접입력</label>
          <input
            id="visit"
            type="radio"
            name="delivery"
            :value="delivery"
            :disabled="select_coupon.type === '행사'"
            @change="deliveryChange('방문수령')"
          />
          <label for="visit">방문수령</label>
          <input
            v-show="sitePick === true"
            id="site"
            type="radio"
            name="delivery"
            :value="delivery"
            @change="deliveryChange('현장수령')"
          />
          <label v-show="sitePick === true" for="site">현장수령</label>
        </td>
      </tr>
      <tr class="receive">
        <td class="deliveryTitle">수령인 성함 <span class="star">*</span></td>
        <td>
          <input
            v-if="delivery === '기본배송지'"
            type="text"
            :value="deliveryInfo.recipient"
            disabled
            size="6"
            aria-label="recipient"
          />
          <input
            v-else
            id="pay_recipient"
            v-model="recipient"
            type="text"
            size="6"
            placeholder="이름을 입력하세요."
            aria-label="pay_recipient"
            @change="inputChange('recipient')"
          />
        </td>
        <!-- <td><button @click="recipientChange()" class="recipient">변경</button></td> -->
      </tr>
      <tr v-if="delivery === '기본배송지'" class="addressTr">
        <td class="deliveryTitle">주소 <span class="star">*</span></td>
        <td>
          <input
            type="text"
            :value="deliveryInfo.zipCode"
            placeholder="우편 번호"
            size="5"
            disabled
            aria-label="zipCode"
          />
          <p class="address">
            <input
              type="text"
              :value="deliveryInfo.address"
              size="25"
              placeholder="도로명주소"
              disabled
              aria-label="address"
            />
          </p>
          <p>
            <input
              type="text"
              :value="deliveryInfo.detailed_address"
              size="30"
              placeholder="상세 주소를 입력하세요."
              disabled
              aria-label="detail_address"
            />
          </p>
        </td>
      </tr>
      <tr v-if="delivery === '직접입력'" class="addressTr">
        <td class="deliveryTitle">주소 <span class="star">*</span></td>
        <td>
          <input
            id="pay_zipCode"
            type="text"
            :value="deliveryInfo.zip_code"
            name="payZipCode"
            placeholder="우편 번호"
            disabled
          />
          <button class="addressBtn" type="button" @click="addressBtn()">주소 찾기</button>
          <p class="address">
            <input
              id="pay_address"
              type="text"
              :value="deliveryInfo.address"
              name="address"
              placeholder="도로명주소"
              disabled
            />
          </p>
          <p>
            <input
              id="pay_detailed_address"
              v-model="detailed_address"
              type="text"
              name="detailedAddress"
              placeholder="상세 주소를 입력하세요."
              @change="inputChange('detailed_address')"
            />
          </p>
        </td>
      </tr>
      <tr v-if="delivery === '현장수령'" class="addressTr">
        <td class="deliveryTitle">현장수령</td>
        <td>
          <p class="reference">
            {{ deliveryInfo.address }}
          </p>
        </td>
      </tr>
      <tr class="callBox">
        <td class="deliveryTitle">휴대전화 <span class="star">*</span></td>
        <td>
          <select
            id="payment_ready_phone_1"
            v-model="phone_1"
            name="payment_ready_phone"
            @change="langCheck('phone_1')"
          >
            <option value="010">010</option>
            <option value="011">011</option>
            <option value="018">018</option>
            <option value="016">016</option>
          </select>
          <input
            id="payment_ready_phone_2"
            v-model="phone_2"
            type="text"
            name="phoneNumber"
            maxlength="4"
            @input="langCheck('phone_2')"
          />
          <input
            id="payment_ready_phone_3"
            v-model="phone_3"
            type="text"
            name="phoneNumber"
            maxlength="4"
            @input="langCheck('phone_3')"
          />
        </td>
      </tr>
      <tr class="requestBox">
        <td class="deliveryTitle">배송메모</td>
        <td>
          <p>
            <select id="require" v-model="requirements" name="require" @change="requirementsSel">
              <option value="" selected hidden>배송시 요청사항을 선택해주세요.</option>
              <option value="배송 전에 미리 연락 바랍니다.">배송 전에 미리 연락 바랍니다.</option>
              <option value="부재시 경비실에 맡겨 주세요.">부재시 경비실에 맡겨 주세요.</option>
              <option value="부재시 전화 주시거나 문자 남겨주세요.">
                부재시 전화 주시거나 문자 남겨주세요.
              </option>
              <option value="경비실에 맡겨 주세요.">경비실에 맡겨 주세요.</option>
              <option value="문 앞에 놔주세요.">문 앞에 놔주세요.</option>
              <option value="택배함에 넣어주세요.">택배함에 넣어주세요.</option>
              <option value="직접입력">직접입력</option>
            </select>
          </p>
          <p v-if="requirements === '직접입력'">
            <input
              v-model="etc_requirements"
              type="text"
              size="30"
              placeholder="기타내용을 직접 입력해주세요."
              maxlength="20"
              aria-label="etc_requirements"
              @change="etcRequirements"
            />
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    delivery: {
      type: String,
    },
    deliveryInfo: {
      type: Object,
    },
    sitePick: {
      type: Boolean,
    },
    select_coupon: {
      type: Object,
    },
  },
  data() {
    return {
      phone_1: '',
      phone_2: '',
      phone_3: '',
      recipient: '',
      detailed_address: '',
      requirements: '',
      phone: '',
      etc_requirements: '',
    }
  },
  watch: {
    deliveryInfo() {
      this.phone_1 = this.deliveryInfo.phone.substr(0, 3)
      this.phone_2 = this.deliveryInfo.phone.substr(3, 4)
      this.phone_3 = this.deliveryInfo.phone.substr(7, 4)
      this.recipient = this.deliveryInfo.recipient
      this.detailed_address = this.deliveryInfo.detailed_address
    },
  },
  methods: {
    deliveryChange(delivery) {
      this.$emit('deliveryChange', delivery)
    },
    requirementsSel() {
      this.$emit('requirementsSel', this.requirements)
    },
    etcRequirements() {
      this.$emit('etcRequirements', this.etc_requirements)
    },
    inputChange(type) {
      this.$emit('inputChange', type, this[type])
    },
    addressBtn() {
      this.$emit('addressBtn')
    },
    langCheck(type) {
      this[type] = this[type].replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      this.phone = `${this.phone_1}${this.phone_2}${this.phone_3}`
      this.inputChange('phone', this.phone)
    },
  },
}
</script>

<style scoped lang="scss">
.deliveryWrap {
  > table {
    width: 100%;
    border-collapse: collapse;

    > tr {
      border-top: 2px solid #ff6699;
      border-bottom: 2px solid #ffe1ec;
      height: 44px;
      line-height: 44px;

      &.addressTr {
        > td {
          input {
            margin-bottom: 10px;
          }

          > input {
            width: 117px;
            margin-top: 10px;
          }

          > p {
            > input {
              width: 450px;
            }
          }

          > .reference {
            line-height: 20px;
          }

          .addressBtn {
            width: 80px;
            height: 25px;
            line-height: 25px;
            border: 1px solid #ff6699;
            box-sizing: content-box;
            border-radius: 5px;
            background-color: #ffe1ec;
            margin-left: 10px;
            font-size: 12px;
          }
        }
      }

      &.callBox {
        > td {
          > select {
            width: 80px;
            height: 25px;
            border: 1px solid #ffe1ec;
            text-indent: 8px;
            font-size: 12px;
            color: #656565;
            margin-right: 5px;
          }
          > input {
            width: 80px;
            margin-right: 5px;
          }
        }
      }

      &.requestBox {
        line-height: 25px;

        > td {
          > p {
            margin: 10px 0;

            > select {
              width: 355px;
              height: 25px;
              line-height: 25px;
              border: 1px solid #ffe1ec;
              text-indent: 8px;

              > option {
                padding: 5px;
                font-size: 14px;
              }
            }

            > input {
              width: 355px;
            }
          }
        }
      }

      &.receive {
        > td {
          > input {
            width: 180px;
          }
        }
      }

      > td {
        font-size: 13px;
        display: inline-block;

        &.deliveryTitle {
          text-align: center;
          width: 10%;
        }

        &.star {
          color: #ff6699;
        }

        > label {
          margin-right: 60px;
        }
        input {
          &[type='text'],
          &[type='number'] {
            height: 25px;
            border: 1px solid #ffe1ec;
            text-indent: 8px;
            font-size: 12px;
            color: #656565;
          }
          &[type='radio'] {
            vertical-align: inherit;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
