<template>
  <div class="main_banner clearFix">
    <div
      class="left_wrap clearFix"
      @mouseover="clearEvent"
      @focus="clearEvent"
      @mouseout="startEvent"
      @focusout="startEvent"
      @click="moveGoods()"
      @keydown="moveGoods()"
    >
      <div id="img_wrap" class="img_wrap clearFix">
        <img
          v-for="(banner, index) in banner_array"
          :key="index"
          :src="banner.image"
          alt=""
          class="left_img"
          :class="{ on: bNum === index }"
        />
      </div>
      <ul class="btn_wrap">
        <li
          v-for="(banner, index) in banner_array"
          :key="index"
          :class="{ on: bNum === index }"
          @click.stop="bNumChange(index)"
          @keydown.stop="bNumChange(index)"
        />
      </ul>
    </div>
    <div
      id="main_right_best_item"
      class="right_wrap clearFix"
      @scroll="scrollCheck"
      @mouseover="arrowHide()"
      @focus="arrowHide()"
      @mouseout="arrowView()"
      @focusout="arrowView()"
    >
      <div class="best_item">
        <h3>BEST ITEM</h3>
        <ul>
          <li class="item_box">
            <router-link :to="{ name: 'Acrylic', params: { type: 'Cham' } }">
              <img src="@/assets/main/subMenu_img_10.jpg" alt="" />
              <strong>아크릴 키링</strong>
              <hr />
              <p>2,200원 ~</p>
            </router-link>
          </li>
          <li class="item_box">
            <router-link :to="{ name: 'Acrylic', params: { type: 'Stand' } }">
              <img src="@/assets/main/subMenu_img_11.jpg" alt="" />
              <strong>아크릴 스탠드</strong>
              <hr />
              <p>2,700원 ~</p>
            </router-link>
          </li>
          <li class="item_box">
            <router-link :to="{ name: 'Acrylic', params: { type: 'Block' } }">
              <img src="@/assets/main/subMenu_img_14.png" alt="" />
              <strong>아크릴 블럭</strong>
              <hr />
              <p>6,500원 ~</p>
            </router-link>
          </li>
          <li class="item_box">
            <router-link :to="{ name: 'Acrylic', params: { type: 'Attach' } }">
              <img src="@/assets/main/subMenu_img_13.png" alt="" />
              <strong>부착형 아크릴</strong>
              <hr />
              <p>2,200원 ~</p>
            </router-link>
          </li>
          <li class="item_box">
            <router-link :to="{ name: 'Acrylic', params: { type: 'Card' } }">
              <img src="@/assets/main/subMenu_img_12.jpg" alt="" />
              <strong>아크릴 카드텍</strong>
              <hr />
              <p>2,200원 ~</p>
            </router-link>
          </li>
          <li class="item_box">
            <router-link :to="{ name: 'Acrylic', params: { type: 'Bookmark' } }">
              <img src="@/assets/main/subMenu_img_17.png" alt="" />
              <strong>자유형 책갈피</strong>
              <hr />
              <p>2,200원 ~</p>
            </router-link>
          </li>
          <li class="item_box">
            <router-link :to="{ name: 'Special', params: { type: 'Canvas' } }">
              <img src="@/assets/main/subMenu_img_15.jpg" alt="" />
              <strong>일러스트 캔버스</strong>
              <hr />
              <p>20,000원</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="arrow_img_top" :class="{ hide: arrow_top_view === false }">
      <img src="@/assets/main/right_menu_arrow_top.png" alt="" />
    </div>
    <div class="arrow_img_bottom" :class="{ hide: arrow_bottom_view === false }">
      <img src="@/assets/main/right_menu_arrow_bottom.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bNum: {
      type: Number,
    },
    arrow_bottom_view: {
      type: Boolean,
    },
    arrow_top_view: {
      type: Boolean,
    },
    banner_array: {
      type: Array,
    },
  },
  methods: {
    moveGoods(url) {
      this.$emit('moveGoods', url)
    },
    bNumChange(num) {
      this.$emit('bNumChange', num)
    },
    clearEvent() {
      this.$emit('clearEvent')
    },
    startEvent() {
      this.$emit('startEvent')
    },
    arrowHide() {
      this.$emit('arrowHide')
    },
    arrowView() {
      this.$emit('arrowView')
    },
    scrollCheck() {
      this.$emit('scrollCheck')
    },
  },
}
</script>

<style scoped lang="scss">
.main_banner {
  width: 100%;
  overflow: hidden;
  position: relative;

  > .left_wrap {
    width: 75%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 42%;
    float: left;

    > .img_wrap {
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;

      > img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: 2s;
        opacity: 0;

        &.on {
          opacity: 1;
        }

        &:first-child {
          z-index: 10;
        }
      }
    }

    > .btn_wrap {
      position: absolute;
      bottom: 20px;
      left: 50%;
      z-index: 50;
      transform: translate(-50%, 0%);
      cursor: pointer;

      > li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #ff6699;
        background-color: #fff;
        float: left;
        margin: 0px 5px;

        &.on {
          background-color: #ff6699;
        }
      }
    }
  }

  > .right_wrap {
    width: 25%;
    height: 100%;
    float: right;
    padding-bottom: 42%;
    position: relative;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    > .best_item {
      width: 80%;
      margin: 0px 10%;
      position: absolute;
      top: 0;
      left: 0;

      > h3 {
        margin: 10px 0;
        font-size: 28px;
        font-weight: 700;
        color: #000;
      }

      > ul {
        > li {
          width: 100%;
          height: 100%;
          margin: 40px 0px;

          img {
            width: 100%;
          }

          strong {
            display: block;
            font-size: 16px;
            margin: 5px 0;
            color: #ff6699;
          }

          hr {
            height: 3px;
            background: #f2e3ea;
            border: none;
          }

          p {
            font-size: 14px;
            margin: 5px 0;
            color: #000;
          }
        }
      }
    }
  }
  > .arrow_img_top {
    width: 25%;
    pointer-events: none;
    opacity: 1;
    position: absolute;
    right: 0;
    top: -2px;
    z-index: 50;
    transition: 0.5s;

    > img {
      width: 100%;
    }

    &.hide {
      opacity: 0;
    }
  }
  > .arrow_img_bottom {
    width: 25%;
    pointer-events: none;
    opacity: 1;
    position: absolute;
    right: 0;
    bottom: -2px;
    z-index: 50;
    transition: 0.5s;

    &.hide {
      opacity: 0;
    }

    > img {
      width: 100%;
    }
  }
}
</style>
