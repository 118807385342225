<template>
  <div class="footerBar">
    <MainFooter />
  </div>
</template>

<script>
import MainFooter from '../components/Footer/MainFooter.vue'

export default {
  components: {
    MainFooter,
  },
}
</script>

<style scoped lang="scss">
.footerBar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
