<template>
  <div>
    <service-view />
  </div>
</template>

<script>
import ServiceView from '../../components/Service/ServiceIndex.vue'

export default {
  components: {
    ServiceView,
  },
}
</script>

<style></style>
