<template>
  <div>
    <complet :order-info="orderInfo" @go-page="goPage" @order-detail="orderDetail" />
  </div>
</template>

<script>
import complet from '../../components/Payment/PaymentComplet.vue'

export default {
  components: {
    complet,
  },
  data() {
    return {
      orderInfo: '',
      mobile: false, // 모바일 접속여부
    }
  },
  async mounted() {
    const filter = 'win16|win32|win64|mac|macintel'
    if (navigator.platform) {
      if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
        this.mobile = true
        await this.indexedDB.initializeData()
      }
    }
    await this.getOrderInfo()
  },
  methods: {
    //   주문정보를 불러옴
    async getOrderInfo() {
      if (this.mobile === true) {
        if (this.$route.query.completeOrder) {
          this.orderInfo = JSON.parse(this.$route.query.completeOrder)
        }
        if (this.$route.query.products) {
          const products = JSON.parse(this.$route.query.products)
          await this.indexedDB.selectOrderClear()
          await products.forEach(async (product) => {
            await this.indexedDB.deleteOrderFromDB(product.id)
          })
        }
      } else {
        this.orderInfo = JSON.parse(sessionStorage.getItem('completeOrder'))
      }
    },
    goPage(name) {
      this.$router.push({
        name,
      })
    },
    orderDetail() {
      this.$router.push({
        name: 'OrderDetail',
        params: { type: 'All' },
        query: {
          page: 1,
          payment_id: this.orderInfo.payment_id,
        },
      })
    },
  },
}
</script>

<style></style>
