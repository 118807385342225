<template>
  <div>
    <ready-container />
  </div>
</template>

<script>
import ReadyContainer from '../../containers/Payment/ReadyContainer.vue'

export default {
  components: {
    ReadyContainer,
  },
}
</script>

<style></style>
