<template>
  <div>
    <complet-container />
  </div>
</template>

<script>
import CompletContainer from '../../containers/Payment/CompletContainer.vue'

export default {
  components: {
    CompletContainer,
  },
}
</script>

<style></style>
