<template>
  <div>
    <info-container />
  </div>
</template>

<script>
import InfoContainer from '../../containers/MyPage/InfoContainer.vue'

export default {
  components: {
    InfoContainer,
  },
}
</script>

<style></style>
