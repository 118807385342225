<template>
  <div class="userWrap clearFix">
    <table>
      <tr>
        <td class="userInfo">주문자명</td>
        <td>{{ userInfo.name }}</td>
      </tr>
      <tr>
        <td class="userInfo">이메일</td>
        <td>{{ userInfo.email }}</td>
      </tr>
      <tr>
        <td class="userInfo">휴대전화</td>
        <td>{{ userInfo.phone }}</td>
      </tr>
      <tr class="request">
        <td class="userInfo">주문 요청사항</td>
        <td>
          <textarea
            v-model="request"
            placeholder="주문시 요청사항을 입력해주세요."
            maxlength="1000"
            aria-label="request"
            @input="requestChange"
          />
          <p>({{ request.length }}/1000)</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
    },
  },
  data() {
    return {
      request: '',
    }
  },
  methods: {
    requestChange() {
      this.$emit('requestChange', this.request)
    },
  },
}
</script>

<style scoped lang="scss">
.userWrap {
  > table {
    width: 100%;
    border-collapse: collapse;

    > tr {
      border-top: 2px solid #ff6699;
      border-bottom: 2px solid #ffe1ec;
      height: 44px;
      line-height: 44px;

      &.request {
        height: auto;
        line-height: normal;
      }

      > td {
        font-size: 12px;
        color: #707070;

        &.userInfo {
          width: 10%;
          text-align: center;
        }

        > textarea {
          width: 500px;
          height: 100px;
          padding: 10px;
          margin: 10px 0;
          resize: none;
          border: 1px solid #ffe1ec;
        }
        > p {
          font-size: 12px;
          color: #777;
          margin-left: 470px;
          margin-top: -10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
