<template>
  <div>
    <coupon-container />
  </div>
</template>

<script>
import CouponContainer from '../../containers/MyPage/CouponContainer.vue'

export default {
  components: {
    CouponContainer,
  },
}
</script>

<style></style>
