<template>
  <div class="allReview">
    <div v-for="(review, index) in reviewArray" :key="index" class="reviewBox clearFix">
      <div
        v-if="review.file_url.length > 0"
        class="imageBox"
        @click="allView(review, 0)"
        @keydown="allView(review, 0)"
      >
        <div class="imageBg">
          <div class="center">
            <img
              class="cursorPoint"
              :src="review.file_url[0]"
              :class="{ height: imgView(review.file_url[0]) }"
              alt=""
            />
          </div>
        </div>
        <img
          v-show="review.file_url.length > 1"
          class="multiImg"
          src="@/assets/icon/multi_img.svg"
          alt=""
        />
      </div>
      <div class="noneBox" />
      <div class="textBox clearFix">
        <p class="title">
          {{ review.title }}
        </p>
        <div class="starDateId">
          <div class="starBox">
            <img
              v-for="(star, index) in starNum"
              v-show="review.scope > index"
              :key="index"
              src="@/assets/icon/star_black_fill.svg"
              class="star"
              alt=""
            />
            <img
              v-for="(star, index) in starNum"
              v-show="review.scope <= index"
              :key="index"
              src="@/assets/icon/star_black_empty.svg"
              class="star"
              alt=""
            />
          </div>
          <span>{{ moment(review.createdAt).format('YYYY-MM-DD') }}</span>
          <span>{{ review?.user_id?.user_id.substr(0, 3) }}*****</span>
        </div>
        <p class="content">
          {{ review.content }}
        </p>
      </div>
    </div>
    <div v-show="reviewArray.length === 0" class="none_review">
      <div>작성된 리뷰가 없습니다.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reviewArray: {
      type: Array,
    },
  },
  data() {
    return {
      starNum: 5,
    }
  },
  methods: {
    allView(review, index) {
      this.$emit('reviewDetail', review, index)
    },
    // 이미지 사이즈 체크
    async imgView(img) {
      let image = new Image()
      let imageSize
      image.onload = async () => {
        if (image.width > image.height) {
          imageSize = 'width'
        } else {
          imageSize = 'height'
        }
        return imageSize
      }
      image.src = img
    },
  },
}
</script>

<style scoped lang="scss">
.allReview {
  > .reviewBox {
    position: relative;
    border-bottom: 2px solid #fedbe8;
    margin-top: 25px;
    padding-bottom: 25px;

    > .imageBox {
      width: 237px;
      height: 237px;
      float: left;
      margin: 0px 32px 0px 5px;
      position: relative;
      cursor: pointer;

      > .imageBg {
        position: relative;
        padding-top: 100%;
        overflow: hidden;

        > .center {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          > img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 237px;
            height: auto;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            &.height {
              width: auto;
              max-height: 237px;
            }
          }
        }
      }

      > .multiImg {
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 32px;
        height: 32px;
      }
    }

    > .noneBox {
      float: left;
      margin: 0px 32px 0px 5px;
      position: relative;
    }

    > .textBox {
      margin-left: 25px;
      float: left;
      color: #707070;

      > .title {
        font-size: 25px;
        font-weight: 500;
        color: #707070;
      }

      > .starDateId {
        font-size: 14px;
        margin-bottom: 33px;

        > .starBox {
          display: inline-block;
          font-size: 0;
          margin-right: 13px;

          > .star {
            vertical-align: -3px;
          }
        }
        > span {
          margin-right: 10px;
        }
      }

      > .content {
        white-space: pre-line;
      }
    }
  }

  .none_review {
    text-align: center;
    width: 100%;
    height: 150px;

    > div {
      margin-top: 150px;
      font-size: 20px;
    }
  }
}
</style>
