<template>
  <div class="cutting">
    <h1>5. 커팅 레이어 하단 작업</h1>
    <div class="typeWrap lamp">
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_cutting_lower_1.png" alt="" />
        </div>
        <div class="block_img_1 right">
          <p>1. 사각형 도구를 선택 합니다.</p>
          <p>
            2. 커팅 레이어의 아랫부분과 양 끝 부분에<br />
            맞추어 사각형을 그려줍니다.
          </p>
          <p>3. 커팅 레이어와 사각형을 Ctrl+E를 눌러 병합합니다.</p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_cutting_lower_2.png" alt="" />
        </div>
        <div class="block_img_2 right">
          <p>
            ※ 사각형이 커팅 레이어보다 튀어나왔을 경우,<br />
            지우개 툴을 이용하여 지워줍니다.
          </p>
        </div>
      </div>
      <!--  -->
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_cutting_lower_3.png" alt="" />
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.cutting {
  text-align: center;
  h1 {
    width: 100%;
  }

  > .typeWrap {
    > div {
      margin-top: 100px;

      > img {
        margin-top: 20px;
      }

      > .img {
        margin-top: 30px;

        > div {
          display: flex;
          justify-content: space-between;
        }
      }
      figure {
        margin-top: 10px;
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 20px;

        > span {
          color: #ff6699;
        }
      }

      &.img_half {
        > div {
          float: left;
          margin-left: 35px;
          text-align: left;
          &.block_img_1 {
            margin-top: 50px;
          }
          &.block_img_2 {
            margin-top: 200px;
          }
        }
      }
    }
  }
}
</style>
