<template>
  <div class="messageWrap">
    <div class="bg" />
    <div class="messageBox">
      <span
        >회원 탈퇴 하시겠습니까?<span class="small"
          >탈퇴 처리기간은 한달이상 소요되며,<br />
          해당 기간 동안 재가입이 불가하니 유의해주시기 바랍니다.</span
        ></span
      >
      <div class="closeBtn" @click="closeMessage" @keydown="closeMessage">취소</div>
      <div class="okBtn" @click="deleteUser()" @keydown="deleteUser()">확인</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeMessage() {
      this.$emit('closeMessage')
    },
    deleteUser() {
      this.$emit('deleteUser')
    },
  },
}
</script>

<style scoped lang="scss">
.messageWrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  .bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9001;
  }

  .messageBox {
    width: 500px;
    height: 250px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    z-index: 9002;
    > span {
      width: 90%;
      text-align: center;
      display: inline-block;
      position: absolute;
      top: 45%;
      left: 50%;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);

      > .small {
        display: block;
        font-size: 14px;
        margin-top: 10px;
        color: #707070;
      }
    }

    > .closeBtn {
      position: absolute;
      width: 80px;
      height: 38px;
      line-height: 38px;
      color: #ff6699;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #ff6699;
      text-align: center;
      bottom: 20px;
      left: 20px;
      font-weight: 400;
      cursor: pointer;
    }
    > .okBtn {
      position: absolute;
      width: 80px;
      height: 38px;
      line-height: 38px;
      color: #fff;
      background-color: #ff6699;
      border-radius: 5px;
      text-align: center;
      bottom: 20px;
      right: 20px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>
