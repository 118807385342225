<template>
  <div class="rightWrap clearFix sticky">
    <div class="detailWrap">
      <div class="imageBox">
        <img :src="require(`@/assets/goods/right_acrylic_${$route.params.type}.png`)" alt="" />
      </div>
      <div class="detailBox">
        <h2>{{ type }}</h2>
        <p>
          ※ 제작기간 : 도안이 접수된 날로부터 영업일 기준
          <span>최대 {{ production_period }}일 소요</span>
        </p>

        <div class="clearFix productInfo">
          <span class="left">{{ type }} 두께</span>
          <span class="right">{{ goods.thick }}</span>
        </div>
        <!-- 오뚝이 아크릴일때 바닥부품 -->
        <div v-show="type === '오뚝이 아크릴'" class="clearFix productInfo">
          <span class="left">아크릴 바닥부품</span>
          <span class="right">{{ goods.stand }}</span>
        </div>
        <!-- 카라비너일때 카라비너 모양 -->
        <div v-show="type === '아크릴 카라비너'" class="clearFix productInfo">
          <span class="left">{{ type }} 모양</span>
          <span class="right">{{ goods.shape }}</span>
        </div>
        <!-- 관람차일때 관람차 바퀴 크기 -->
        <div v-show="type === '아크릴 관람차'" class="clearFix productInfo">
          <span class="left">아크릴 관람차 바퀴 크기</span>
          <span class="right">{{ goods.wheel }}</span>
        </div>
        <!-- 크기 -->
        <div class="clearFix productInfo">
          <span class="left">{{ type }} 크기</span>
          <span class="right">{{ goods.x }}cm*{{ goods.y }}cm</span>
        </div>
        <!-- 인쇄면 -->
        <div v-show="type === '아크릴 관람차'" class="clearFix productInfo">
          <span class="left">관람차 기둥/바퀴 인쇄면</span>
          <span class="right">{{ goods.second_print }}</span>
        </div>
        <div class="clearFix productInfo">
          <span v-show="type === '오뚝이 아크릴'" class="left"> 스탠드 인쇄면</span>
          <span v-show="type === '투명 부채'" class="left">투명 부채 인쇄면</span>
          <span v-show="type === '아크릴 카라비너'" class="left">아크릴 인쇄면</span>
          <span v-show="type === '아크릴 관람차'" class="left">관람차 키링 인쇄면</span>
          <span class="right">{{ goods.print }}</span>
        </div>
        <!-- 포장옵션 -->
        <div class="clearFix productInfo">
          <span class="left">조립/포장 선택</span>
          <span class="right">{{ goods.packing }}</span>
        </div>
        <!-- 도안파일 -->
        <div class="clearFix productInfo">
          <span class="left">도안파일</span>
          <span v-if="goods.file" class="right"
            >{{ goods.file.name
            }}<span v-show="goods.design_request === true">(제작 의뢰)</span></span
          >
          <span v-else class="right"
            >없음<span v-show="goods.design_request === true">(제작 의뢰)</span></span
          >
        </div>
        <!-- 수량 -->
        <div class="clearFix productInfo">
          <span class="left">제작할 도안 수량</span>
          <span class="right">{{ goods.design_quantity }}개</span>
        </div>
        <div class="clearFix productInfo">
          <span class="left">총 주문 수량</span>
          <span class="right">{{ goods.order_quantity }}개</span>
        </div>
      </div>
      <div class="priceBox">
        <div class="clearFix">
          <span class="left">총 금액</span>
          <span class="right clearFix">
            <span class="strong">￦{{ Commas(goods.fullPrice) }}원</span>
            <ul v-if="type !== '아크릴 관람차'">
              <li class="bubble" :class="{ show: goods.order_quantity <= 1 }">
                <img src="@/assets/goods/speech_bubble.png" alt="" />
                <span>-{{ goods.percentage }}%</span>
              </li>
              <li class="discount" :class="{ show: goods.order_quantity <= 1 }">
                (<strong>개당</strong>: {{ Commas(goods.price4one) }}원)
              </li>
              <li class="price">(<strong>개당</strong>: {{ Commas(goods.price) }}원)</li>
            </ul></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      type: 'Goods/type',
      quick_order: 'Goods/quick_order',
      production_period: 'Goods/production_period',
      shell_period: 'Goods/shell_period',
    }),
  },
}
</script>

<style scoped lang="scss">
.rightWrap {
  float: right;
  width: 410px;
  height: 100vh;
  background-color: #fff7fa;
  -ms-overflow-style: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  > .detailWrap {
    > .imageBox {
      border-bottom: 1px solid #ff6699;
      text-align: center;
      padding: 5px 0;
    }

    > .detailBox {
      width: 345px;
      margin: 15px auto 0;

      > h2 {
        display: inline-block;
        color: #707070;
      }

      > .quantity {
        display: inline-block;

        > p {
          font-size: 16px;
          font-weight: 500;
          margin-left: 5px;
        }
      }

      > p {
        font-size: 11px;
        padding-bottom: 19px;
        border-bottom: 1px solid #ff6699;

        &.none-border {
          font-size: 12px;
          border-bottom: none;
          padding-bottom: 0px;
        }

        > span {
          color: #ff6699;
        }
      }

      > .productInfo {
        height: 35px;
        line-height: 35px;
        border-bottom: 1px solid #ff6699;

        > .left {
          float: left;
          color: #979797;
          font-size: 12px;
          text-indent: 2px;
        }

        > .right {
          float: right;
          color: #656565;
          font-size: 12px;
        }
      }
    }

    > .priceBox {
      width: 345px;
      margin: 60px auto 0;

      > div {
        > .left {
          float: left;
          color: #656565;
          font-size: 12px;
          margin-top: 15px;
        }

        > .right {
          float: right;
          color: #707070;
          font-size: 12px;
          position: relative;

          > .bubble {
            // position: absolute;
            // top: -20px;
            // left: 5px;
            display: inline-block;
          }
          > .strong {
            font-size: 26px;
            font-weight: 700;
            float: right;
          }

          > ul {
            // display: inline-block;
            float: right;
            margin-right: 5px;

            > .show {
              opacity: 0;
            }

            > .bubble {
              position: relative;
              left: -75%;
              top: -8px;

              > * {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                color: #fff;
                font-size: 14px;
              }
            }
            > .discount {
              text-decoration: line-through;
            }

            > .price {
              color: #ff6699;
            }
          }
        }
      }
    }
  }
}
</style>
