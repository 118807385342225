<template>
  <div>
    <div id="nav">
      <nav-container />
    </div>
    <router-view />
    <div id="footer">
      <footer-container />
    </div>
    <Message v-if="message_view === true" @close-message="closeMessage" />

    <div v-if="mobile === true" class="mobileBg">
      <div>
        <img class="glass" src="@/assets/icon/hourglass.png" alt="" />
        <h2>모바일 페이지 <span>준비중</span>입니다.</h2>
        <p>보다 나은 서비스 제공을 위하여,</p>
        <p>모바일 페이지를 준비 중에 있습니다.</p>
        <p>빠른 시일 내에 준비하여 찾아뵙겠습니다.</p>
        <img class="logo" src="@/assets/main/dongne_logo.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import './assets/css/reset.css'
import './assets/css/quill.snow.css'
import './assets/css/swiper.min.css'
import { mapGetters } from 'vuex'
import NavContainer from './containers/NavContainer.vue'
import FooterContainer from './containers/FooterContainer.vue'
import Message from './components/Modal/ModalMessage.vue'
import ChannelService from './plugins/ChannelService'
import { pluginKey } from './config/Channel_config'

export default {
  components: {
    NavContainer,
    FooterContainer,
    Message,
  },
  data() {
    return {
      mobile: false,
    }
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0)
    },
  },
  beforeCreate() {
    this.$store.dispatch('UserInfo/getUserInfo')
  },
  computed: {
    ...mapGetters({
      message_view: 'Message/view',
      userInfo: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
  async mounted() {
    await this.quickCheck()
    if (this.userInfo.user_id) {
      let { userInfo } = this
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      let memberHash = ''
      await this.axios
        .get(`${this.Path.UserIdHash}?user_id=${userInfo.user_id}`, config)
        .then((res) => {
          memberHash = res.data.data
        })

      await ChannelService.boot({
        pluginKey,
        memberId: userInfo.user_id,
        memberHash,
        profile: {
          name: `${userInfo.name}(${userInfo.user_id})`,
          mobileNumber: userInfo.phone,
        },
      })
    } else {
      await ChannelService.boot({
        pluginKey,
      })
    }
    // let filter = 'win16|win32|win64|mac|macintel'
    // if (navigator.platform) {
    //   if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
    //     this.mobile = true
    //   }
    // }
  },
  methods: {
    closeMessage() {
      this.$store.commit('Message/closeMessage')
    },
    async quickCheck() {
      await this.axios.get(this.Path.GetQuickOrder).then((res) => {
        if (res.data.success === true) {
          this.$store.dispatch('Goods/quickOrderCheck', res.data.data.state)
        }
      })
    },
  },
}
</script>
<style lang="scss">
#app {
  min-width: 1080px;
  padding-bottom: 390px;
}

.mobileBg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  > div {
    width: 500px;
    height: 300px;
    padding: 10px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    border-radius: 5px;
    text-align: center;

    > .glass {
      margin-top: 20px;
      width: 60px;
    }

    > h2 {
      margin-top: 30px;
      font-size: 25px;
      margin-bottom: 10px;

      > span {
        color: #ff6699;
      }
    }

    > p {
      font-size: 14px;
    }

    > .logo {
      width: 120px;
      margin-top: 20px;
    }
  }
}
</style>
