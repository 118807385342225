<template>
  <div class="loginBox">
    <div class="closeTab" @click="closetab" @keydown="closeTab">
      <img src="@/assets/icon/close_btn.svg" alt="" />
    </div>
    <div class="logoBox">
      <img src="@/assets/icon/loginLogo.svg" alt="" />
    </div>
    <div class="formBox clearFix">
      <form method="post" @submit.prevent="login()">
        <div class="idBox">
          <p>ID</p>
          <input
            id="id"
            v-model="formUsername"
            type="text"
            name="username"
            @focus="messageReset()"
          />
        </div>
        <div class="pwBox">
          <p>PW</p>
          <input
            id="password"
            v-model="formPassword"
            type="password"
            name="password"
            @focus="messageReset()"
          />
        </div>
        <div class="error">
          {{ formError }}
        </div>
        <button type="submit" class="loginBtn">로그인</button>
      </form>
      <div class="moveBtn clearFix">
        <ul>
          <li @click="registerBtn()" @keydown="registerBtn()">회원가입</li>
          <li @click="findBtn()" @keydown="findBtn()">아이디/비밀번호찾기</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formError: {
      type: String,
    },
  },
  data() {
    return {
      formUsername: '',
      formPassword: '',
    }
  },
  methods: {
    closetab() {
      this.$emit('close-tab')
    },
    login() {
      this.$emit('login', this.formUsername, this.formPassword)
    },
    messageReset() {
      this.$emit('messageReset')
    },
    registerBtn() {
      this.$emit('registerBtn')
    },
    findBtn() {
      this.$emit('findId')
    },
  },
}
</script>

<style scoped lang="scss">
.loginBox {
  position: fixed;
  width: 556px;
  height: 650px;
  top: 50%;
  left: 50%;
  display: block;
  z-index: 1001;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);

  > .closeTab {
    position: absolute;
    /* border:1px solid #000; */
    top: 3%;
    right: 3%;
    cursor: pointer;
    z-index: 1002;
    width: 24px;
  }

  > .logoBox {
    text-align: center;
    height: 190px;

    > img {
      margin-top: 63px;
    }
  }

  > .formBox {
    width: 368px;
    margin: 0 auto;

    > form {
      border-bottom: 1px solid #ff6699;

      > div {
        > p {
          text-indent: 5px;
        }

        > input {
          width: 100%;
          height: 48px;
          border: 1px solid #f25ca24d;
          border-radius: 10px;
          text-indent: 34px;
          font-size: 15px;
          color: #707070;
          box-sizing: border-box;
          background-color: #f2e3ea;
          text-align: left;

          &:focus {
            background-color: #fff;
            border: 2px solid #ff6699;
          }
        }

        &.pwBox {
          margin-top: 11px;
        }
      }

      > .loginBtn {
        width: 100%;
        border: none;
        height: 73px;
        background-color: #ff6699;
        color: white;
        font-size: 25px;
        text-align: center;
        line-height: 73px;
        border-radius: 10px;
        margin-top: 90px;
        margin-bottom: 38px;
        cursor: pointer;
      }
    }

    > .moveBtn {
      > ul {
        > li {
          float: left;
          width: 50%;
          text-align: center;
          color: #707070;
          margin-top: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
