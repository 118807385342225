import router from '../router'
import TypeValue from './TypeValue'

const DB_NAME = 'dongne_front'
const DB_VERSION = 2

let order_db = null

const indexedDB = {
  async initializeData() {
    order_db = await this.getOrderDB()
  },
  getOrderDB() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(DB_NAME, DB_VERSION)
      //  새로운 데이터 베이스 만들 때 발생하는 이벤트

      const transaction = order_db?.transaction(['order_list', 'select_list'])
      if (!transaction) {
        request.onupgradeneeded = (e) => {
          const db = e.target.result
          db.createObjectStore('order_list', {
            autoIncrement: true,
            keyPath: 'id',
          })
          db.createObjectStore('select_list', {
            keyPath: 'id',
          })
        }
      }
      request.onerror = (e) => reject(e)
      request.onsuccess = (e) => resolve(e.target.result)
    })
  },
  async addOrder(goods, type, svg) {
    if (goods.etc === '고리') {
      goods.etc = `${goods.keyring_option}(${goods.keyring_color})`
    } else if (goods.etc === '군번줄') {
      goods.etc = `군번줄(${goods.tag_color})`
    } else if (goods.etc === '와이어링') {
      goods.etc = `와이어링(${goods.wiring_color})`
    } else if (goods.etc === '뱃지') {
      goods.etc = `뱃지(${goods.badge_kind})`
    } else if (goods.etc === '스마트톡') {
      goods.etc = `스마트톡(${goods.tok_color})`
    }
    if (goods.kind === '홀로그램') {
      goods.kind = `${goods.kind}[${goods.hologram_kind}]`
    }

    let fileName = ''
    let fileType = ''
    if (goods.file) {
      fileName = goods.file.name
      fileType = goods.file.type
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = reject
        })
      const change = await toBase64(goods.file)
      goods.file = change
    }

    let order = {
      x: goods.x,
      y: goods.y,
      design_quantity: goods.design_quantity,
      item_opp: goods.item_opp,
      packing: goods.packing,
      print: goods.print,
      second_print: goods.second_print,
      parts_print: goods.parts_print,
      type,
      stand: goods.stand,
      thick: goods.thick,
      file: goods.file,
      fileName,
      fileType,
      price: goods.fullPrice,
      etc: goods.etc,
      kind: goods.kind,
      shape: goods.shape,
      wheel: goods.wheel,
      bolt_set: goods.bolt_set,
      period: goods.period,
      order_quantity: goods.order_quantity,
      svgData: svg,
      design_request: goods.design_request,
    }
    await this.addOrderToDB(order)
  },
  addOrderToDB(order) {
    return new Promise((resolve, reject) => {
      const transaction = order_db.transaction(['order_list'], 'readwrite')
      const objStore = transaction.objectStore('order_list')
      objStore.add(order)
      transaction.oncomplete = async (e) =>
        resolve(
          await router.push({
            name: 'Cart',
          }),
        )
    })
  },
  updateOrderToDB(order, key) {
    return new Promise((resolve, reject) => {
      const transaction = order_db.transaction(['order_list'], 'readwrite')
      const objStore = transaction.objectStore('order_list')
      objStore.put(order)
      transaction.oncomplete = async (e) =>
        resolve(
          await router.push({
            name: 'Cart',
          }),
        )
    })
  },
  async addItems(goods, type) {
    let cartList = await this.getOrderFromDB()
    await goods.map(async (data) => {
      let check = false
      data.type = type
      await cartList.forEach(async (cart) => {
        if (type !== '커스텀 주문') {
          if (cart.type === data.type) {
            if (cart.etc === data.etc) {
              data.order_quantity += cart.order_quantity
              data.price += cart.price
              data.id = cart.id
              check = true
              return check
            }
          }
        }
        return check
      })
      if (check === false) {
        await this.addOrderToDB(data)
      } else {
        await this.updateOrderToDB(data)
      }
    })
  },
  selectOrderClear() {
    return new Promise((resolve, reject) => {
      const transaction = order_db.transaction(['select_list'], 'readwrite')
      const objStore = transaction.objectStore('select_list')
      objStore.clear()
      transaction.oncomplete = async (e) => resolve()
    })
  },
  async selectOrderToDB(goods) {
    const order = {
      x: goods.x,
      y: goods.y,
      design_quantity: goods.design_quantity,
      etc: goods.etc,
      kind: goods.kind,
      shape: goods.shape,
      wheel: goods.wheel,
      bolt_set: goods.bolt_set,
      period: goods.period,
      file: goods.file,
      fileName: goods.fileName,
      fileType: goods.fileType,
      id: goods.id,
      item_opp: goods.item_opp,
      order_quantity: goods.order_quantity,
      packing: goods.packing,
      price: goods.price,
      print: goods.print,
      second_print: goods.second_print,
      parts_print: goods.parts_print,
      stand: goods.stand,
      svgData: goods.svgData,
      thick: goods.thick,
      type: goods.type,
      design_request: goods.design_request,
    }
    return new Promise((resolve, reject) => {
      const transaction = order_db.transaction(['select_list'], 'readwrite')
      const objStore = transaction.objectStore('select_list')
      objStore.add(order)
      transaction.oncomplete = async (e) => resolve()
    })
  },
  getOrderFromDB() {
    // db의 트랙잭션이 people를 가리키도록 하고 -> cursor를 사용해 db의 데이터를 모두 가져옴
    return new Promise((resolve, reject) => {
      const order_list = []
      const transaction = order_db.transaction(['order_list'], 'readonly')
      const objStore = transaction.objectStore('order_list')
      const cursorRequest = objStore.openCursor()

      cursorRequest.onsuccess = (e) => {
        const cursor = e.target.result
        if (cursor) {
          order_list.push(cursor.value)
          cursor.continue()
        }
      }
      transaction.oncomplete = (e) => resolve(order_list)
    })
  },
  getSelectOrderFromDB() {
    // db의 트랙잭션이 people를 가리키도록 하고 -> cursor를 사용해 db의 데이터를 모두 가져옴
    return new Promise((resolve, reject) => {
      const order_list = []
      const transaction = order_db.transaction(['select_list'], 'readonly')
      const objStore = transaction.objectStore('select_list')
      const cursorRequest = objStore.openCursor()

      cursorRequest.onsuccess = (e) => {
        const cursor = e.target.result
        if (cursor) {
          order_list.push(cursor.value)
          cursor.continue()
        }
      }
      transaction.oncomplete = (e) => resolve(order_list)
    })
  },
  async deleteOrderFromDB(id) {
    return new Promise((resolve, reject) => {
      const transaction = order_db.transaction(['order_list'], 'readwrite')
      const objStore = transaction.objectStore('order_list')
      objStore.delete(id)
      transaction.oncomplete = (e) => resolve()
    })
  },
  async deleteSelectOrder(id) {
    return new Promise((resolve, reject) => {
      const transaction = order_db.transaction(['select_list'], 'readwrite')
      const objStore = transaction.objectStore('select_list')
      objStore.delete(id)
      transaction.oncomplete = (e) => resolve()
    })
  },
}

export default indexedDB
