<template>
  <div :class="{ step: path === 'Basic', SpecialStep: path === 'Special' }">
    <h2 v-show="type !== '자유형 책갈피'">아크릴 두께</h2>
    <h2 v-show="type === '자유형 책갈피'">책갈피 두께</h2>
    <div class="thickBox clearFix">
      <ul
        v-if="type !== '자유형 책갈피' && type !== '아크릴 블럭' && type !== '입체 아크릴 블럭'"
        class="thickBtns"
      >
        <li
          v-if="
            type !== '아크릴 스탠드' &&
            type !== '아크릴 램프' &&
            type !== '아크릴 바닥 부품' &&
            type !== '부착형 아크릴'
          "
          class="thickBtn"
          :class="{ active: thickNum === 1 }"
          @click="checkthick(1, '1t')"
          @keydown="checkthick(1, '1t')"
        >
          <div>
            <p>1T</p>
            <p class="small">(약 1mm)</p>
            <hr />
            <img src="@/assets/goods/thick_1t.png" alt="" />
          </div>
        </li>
        <li
          v-if="type !== '아크릴 램프'"
          class="thickBtn"
          :class="{ active: thickNum === 2 }"
          @click="checkthick(2, '3t')"
          @keydown="checkthick(2, '3t')"
        >
          <div>
            <p>3T</p>
            <p class="small">(약 3mm)</p>
            <hr />
            <img src="@/assets/goods/thick_3t.png" alt="" />
          </div>
        </li>
        <li
          v-if="type !== '아크릴 스탠드'"
          class="thickBtn"
          :class="{ active: thickNum === 3 }"
          @click="checkthick(3, '5t')"
          @keydown="checkthick(3, '5t')"
        >
          <div>
            <p>5T</p>
            <p class="small">(약 5mm)</p>
            <hr />
            <img src="@/assets/goods/thick_5t.png" alt="" />
          </div>
        </li>
      </ul>
      <ul v-if="type === '자유형 책갈피'" class="thickBtns">
        <li
          v-if="type !== '아크릴 스탠드'"
          class="thickBtn"
          :class="{ active: thickNum === 0 }"
          @click="checkthick(0, '0.5t')"
          @keydown="checkthick(0, '0.5t')"
        >
          <div>
            <p>0.5T</p>
            <p class="small">(약 0.5mm)</p>
            <hr />
            <img src="@/assets/goods/thick_0.5t.svg" alt="" />
          </div>
        </li>
      </ul>
      <ul v-if="type === '아크릴 블럭'" class="thickBtns">
        <li
          class="thickBtn"
          :class="{ active: thickNum === 2 }"
          @click="checkthick(2, '10t')"
          @keydown="checkthick(2, '10t')"
        >
          <div>
            <p>10T</p>
            <p class="small">(약 10mm)</p>
            <hr />
            <img src="@/assets/goods/thick_10t.svg" alt="" />
          </div>
        </li>
        <li
          class="thickBtn"
          :class="{ active: thickNum === 3 }"
          @click="checkthick(3, '15t')"
          @keydown="checkthick(3, '15t')"
        >
          <div>
            <p>15T</p>
            <p class="small">(약 15mm)</p>
            <hr />
            <img src="@/assets/goods/thick_15t.svg" alt="" />
          </div>
        </li>
      </ul>
      <ul v-if="type === '입체 아크릴 블럭'" class="thickBtns">
        <li
          class="thickBtn"
          :class="{ active: thickNum === 2 }"
          @click="checkthick(2, '10t')"
          @keydown="checkthick(2, '10t')"
        >
          <div>
            <p>10T</p>
            <p class="small">(약 10mm)</p>
            <hr />
            <img src="@/assets/goods/thick_10t.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    thickNum: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  methods: {
    checkthick(i, thick) {
      this.$emit('checkThick', 'thick', i, thick)
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .thickBox {
    font-weight: 400;

    > .thickBtns {
      float: left;

      > li {
        width: 180px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 120px;
          height: 185px;
          margin: 20px auto 40px;
          text-align: center;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
            text-align: left;
          }

          > .small {
            font-size: 10px;
            color: #707070;
            text-align: left;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 10px;
          }

          > img {
            width: 100%;
            height: 150px;
            margin: 10px auto 0;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-top: 27px;
    margin-bottom: 13px;
  }

  > .thickBox {
    font-weight: 400;

    > .thickBtns {
      float: left;

      > li {
        width: 118px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 80px;
          margin: 20px auto 20px;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 1px;
          }

          > .small {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 100%;
            margin-top: 11px;
          }
        }
      }
    }
  }
}
</style>
