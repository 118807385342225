<template>
  <div class="frame_hole">
    <h1>5. 쉐이커 프레임 구멍 작업</h1>
    <!--  -->
    <div>
      <p>쉐이커를 고정할 수 있는 볼트와 너트를 넣을 구멍을 작업합니다.</p>
      <p>
        ※커팅 레이어 안에 구멍 레이어가 있을 경우, 패스 과정 중 구멍의 모양과 크기가 변형될 수
        있습니다.
      </p>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/shaker_keyring_frame_hole_1.png" alt="" />
      </div>
      <div class="img_1 right">
        <p>1. 타원 도구를 선택 합니다.</p>
        <p>2. 타원 도구가 선택 되어 있는 상태에서 캔버스를 클릭합니다.</p>
        <p>3. 캔버스를 클릭하면 '타원 만들기'라는 창이 뜹니다.</p>
        <p>4. 폭과 높이를 3.2mm로 설정해 주세요.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/shaker_keyring_frame_hole_2.png" alt="" />
      </div>
      <div class="img_2 right">
        <p>5. 칠을 빨간색으로 설정합니다.</p>
        <p>6. 획은 없음으로 설정합니다.</p>
        <p>7. 같은 방법으로 2개 이상의 타원을 만듭니다.</p>
        <p class="red">
          전체 사이즈가 40*50mm 이상일 경우,<br />프레임 구멍은 3개 이상으로 고정됩니다.
        </p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/shaker_keyring_frame_hole_3.png" alt="" />
      </div>
      <div class="img_3 right">
        <p>8. 레이어에서 ctrl을 눌러 타원 2개를 선택합니다.</p>
        <p>9. 원하시는 위치에 타원 레이어를 옮깁니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_full clearFix">
      <div class="text">
        <p>타원 레이어와 커팅 레이어 간격은 <span class="red">1mm 이상을 권장</span>합니다.</p>
        <p>(그 이하일 경우, 제품에 <span class="red">손상</span>이 갈 수 있습니다.)</p>
      </div>
      <div class="center">
        <img src="@/assets/guide/shaker_keyring_frame_hole_4.png" alt="" />
      </div>
      <p>1mm 이상 확인</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.frame_hole {
  text-align: center;
  h1 {
    width: 100%;
  }

  > div {
    margin-top: 40px;

    > img {
      margin-top: 20px;
    }

    > .img {
      margin-top: 30px;

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
    figure {
      margin-top: 10px;
    }
    p {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-top: 20px;

      span {
        color: #ff6699;
      }
    }

    &.img_half {
      > div {
        float: left;
        margin-left: 35px;
        text-align: left;

        &.img_1 {
          margin-top: 50px;
        }
        &.img_2 {
          margin-top: 140px;

          > .red {
            margin-left: 25px;
            color: #ff6699;
            font-weight: bold;
          }
        }
        &.img_3 {
          margin-top: 100px;
        }
        &.img_4 {
          margin-top: 120px;
        }
        &.img_5 {
          margin-top: 120px;
        }
        &.img_6 {
          margin-top: 120px;
        }
      }
    }

    &.img_full {
      margin: 80px 0;
      > div {
        text-align: center;

        &.text {
          margin-bottom: 20px;
        }
      }

      > p {
        text-align: center;
        font-size: 14px;

        &.fs20 {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
