<template>
  <div class="messageWrap">
    <div class="bg" />
    <div class="messageBox">
      <div class="closeTab" @click="closeMessage()" @keydown="closeMessage()">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <h2 class="title">구매 확정 완료</h2>
      <div class="message">
        <p class="point">+{{ Commas(point) }}P</p>
        <p>적립되었습니다.</p>
        <div class="promotion">
          <p>리뷰 작성하고 포인트를 더 적립하세요!</p>
          <p>글 리뷰 +500P, 사진 리뷰 + 1,000P</p>
        </div>
      </div>
      <div class="btnBox">
        <router-link
          class="review"
          :to="{ name: 'MyPageReview', query: { page: 1, status: 'write' } }"
        >
          리뷰쓰기
        </router-link>
        <router-link class="point" :to="{ name: 'MyPagePoint' }"> 내 포인트 </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    point: {
      type: Number,
    },
  },
  methods: {
    closeMessage() {
      this.$emit('closeMessage')
    },
  },
}
</script>

<style scoped lang="scss">
.messageWrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  .bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9001;
  }

  .messageBox {
    width: 608px;
    height: 434px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    z-index: 9002;

    > .closeTab {
      position: absolute;
      /* border:1px solid #000; */
      top: 3%;
      right: 3%;
      cursor: pointer;
      z-index: 1002;
      width: 24px;
    }

    > h2 {
      color: #ff6699;
      width: 85%;
      border-bottom: 3px solid #ff6699;
      margin: 50px auto 0;
      text-align: center;
      padding-bottom: 10px;
    }

    > .message {
      text-align: center;
      margin-top: 70px;

      > p {
        font-size: 24px;
        color: #414141;
        font-weight: 500;

        &.point {
          color: #ff6699;
        }
      }
      > .promotion {
        margin-top: 30px;
        color: #ff6699;
      }
    }

    > .btnBox {
      margin-top: 50px;
      display: flex;
      justify-content: space-around;

      > a {
        width: 200px;
        height: 42px;
        border-radius: 10px;
        text-align: center;
        line-height: 42px;
        font-weight: 500;

        &.review {
          background-color: #ff6699;
          color: #fff;
        }
        &.point {
          border: 2px solid #ff6699;
          color: #414141;
        }
      }
    }
  }
}
</style>
