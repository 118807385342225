<template>
  <div class="saleBox">
    <h2>할인 수단</h2>
    <ul>
      <li
        :class="{ active: saleType === '선택안함' }"
        @click="sale_type('선택안함')"
        @keydown="sale_type('선택안함')"
      >
        선택안함
      </li>
      <li
        :class="[
          { active: saleType === '등급할인' },
          { disabled: paymentMethod === 'cultureland' },
        ]"
        @click="sale_type('등급할인')"
        @keydown="sale_type('등급할인')"
      >
        등급할인
      </li>
      <li
        :class="[
          { active: saleType === '쿠폰할인' },
          { disabled: paymentMethod === 'cultureland' },
        ]"
        @click="sale_type('쿠폰할인')"
        @keydown="sale_type('쿠폰할인')"
      >
        쿠폰할인
      </li>
      <li
        :class="[
          { active: saleType === '포인트사용' },
          { disabled: paymentMethod === 'cultureland' },
        ]"
        @click="sale_type('포인트사용')"
        @keydown="sale_type('포인트사용')"
      >
        포인트사용
      </li>
    </ul>
    <div class="saleEx">
      <div v-show="saleType === '선택안함'" class="exBox">
        <h3>선택안함</h3>
        <p>-할인이 선택되지 않았습니다</p>
        <p>-문화상품권은 할인이 적용되지 않습니다.</p>
      </div>
      <div v-show="saleType === '등급할인'" class="exBox">
        <h3>등급할인</h3>
        <p>-고객님의 회원 등급에 따라 %로 할인이 적용됩니다.</p>
        <p>-할인된 금액은 최종결제금액에서 확인 할 수 있습니다.</p>
      </div>
      <div v-show="saleType === '쿠폰할인'" class="exBox">
        <h3>쿠폰할인</h3>
        <p>-결제완료후 결제취소로 환불 처리가 되어도 쿠폰은 재사용 할 수 없습니다.</p>
        <p>-할인된 금액은 최종결제금액에서 확인 할 수 있습니다.</p>
      </div>
      <div v-show="saleType === '포인트사용'" class="exBox">
        <h3>포인트 사용</h3>
        <p>-결제완료후 결제취소로 환불 처리가 되어도 적용된 포인트는 돌아오지 않습니다.</p>
        <p>-할인된 금액은 최종결제금액에서 확인 할 수 있습니다.</p>
        <p>-지급후 3개월 내에 사용하지 않은 포인트는 소멸됩니다.</p>
      </div>
    </div>
    <div class="type">
      <div v-if="saleType === '등급할인'" class="memberType">
        <span
          >현재 회원님의 등급은
          <span class="red">{{ TypeValue.User_Ranking(userInfo.ranking) }}</span
          >입니다.</span
        >
        <span v-if="userInfo.ranking === 1" class="memberBtn">0% 할인적용</span>
        <span v-if="userInfo.ranking === 2" class="memberBtn">10% 할인적용</span>
        <span v-if="userInfo.ranking === 0" class="memberBtn">30% 할인적용</span>
      </div>
      <div v-if="saleType === '쿠폰할인'" class="couponType">
        <div v-if="selectCoupon.type" class="coupon clearFix">
          <div class="left">
            <h2 class="couponTitle">
              {{ selectCoupon.name }}
            </h2>
            <p class="couponType">사용 가능 상품: {{ selectCoupon.type }}</p>
            <p class="couponDate">
              {{ moment(selectCoupon.end_day).format('YYYY-MM-DD') }} 까지 사용 가능
            </p>
          </div>
          <div class="right">
            <p class="couponPrice">
              {{ Commas(selectCoupon.price)
              }}{{ TypeValue.Coupon_SaleType(selectCoupon.sale_type) }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="saleType === '포인트사용'" class="pointType">
        <div class="nowPoint clearFix">
          <span class="left">포인트 잔액</span>
          <span class="right">{{ available_point }} 원</span>
        </div>
        <div class="usePoint clearFix">
          <span class="left">사용 포인트</span>
          <label for="paymentUsePoint" class="right"
            ><input id="paymentUsePoint" v-model="point" type="number" @change="changePoint" />
            원</label
          >

          <span class="allPoint" @click="changePoint('all')" @keydown="changePoint('all')"
            >전액사용</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    saleType: {
      type: String,
    },
    userInfo: {
      type: Object,
    },
    selectCoupon: {
      type: Object,
    },
    available_point: {
      type: Number,
    },
    fullPrice: {
      type: Number,
    },
    paymentMethod: {
      type: String,
    },
    products: {
      type: Object,
    },
    shippingFee: {
      type: Number,
    },
  },
  data() {
    return {
      point: 0,
    }
  },
  methods: {
    sale_type(type) {
      this.point = 0
      this.$emit('saleTypeSel', type)
    },
    changePoint(type) {
      let sumValue = 0
      this.products.forEach((data) => {
        if (
          data.type !== '디페스타 입장권 패키지' &&
          data.type !== '디페스타 입장권' &&
          data.type !== '커스텀 주문'
        ) {
          sumValue += data.price
        }
      })
      if (type === 'all') {
        this.point = this.available_point
      }
      if (this.shippingFee === 0) {
        if (this.point > this.available_point) {
          this.point = this.available_point
        }
        if (this.fullPrice === sumValue) {
          if (this.point > this.fullPrice) {
            this.point = this.fullPrice - 1000
          }
        } else {
          if (this.point > sumValue) {
            this.point = sumValue
          }
        }
      } else {
        if (this.point > this.available_point) {
          this.point = this.available_point
        }
        if (this.fullPrice === sumValue) {
          if (this.point > this.fullPrice) {
            this.point = this.fullPrice
          }
        } else {
          if (this.point > sumValue) {
            this.point = sumValue
          }
        }
      }
      this.$emit('changePoint', this.point)
    },
  },
}
</script>

<style scoped lang="scss">
.saleBox {
  width: 718px;
  float: left;

  h2 {
    font-size: 16px;
    color: #707070;
    text-align: left;
    font-weight: 400;
  }

  > ul {
    width: 100%;
    border-top: 2px solid #ff6699;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > li {
      width: 150px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      cursor: pointer;
      border: 2px solid #f8bdd8;
      border-radius: 5px;
      font-size: 12px;
      color: #656565;
      text-align: center;
      margin: 16px 20px 16px 0;

      &.active {
        background-color: #ffebf2;
      }
      &.disabled {
        pointer-events: none;
        background-color: #e7e7e7;
        border: 2px solid #aaa;
      }
    }
  }

  > .saleEx {
    width: 100%;
    height: 91px;
    padding-top: 12px;
    border-radius: 10px;
    background-color: #ffebf2;

    > .exBox {
      margin-left: 34px;

      > h3 {
        font-size: 16px;
        color: #656565;
        margin-bottom: 6px;
      }

      > p {
        font-size: 12px;
        color: #656565;
      }
    }
  }

  > .type {
    width: 100%;

    > .memberType {
      width: 100%;
      margin: 40px auto;
      text-align: center;

      > span {
        font-size: 16px;

        > .red {
          color: #ff6699;
        }
      }

      > .memberBtn {
        display: inline-block;
        width: 109px;
        height: 25px;
        line-height: 25px;
        border-radius: 3px;
        background-color: #f8bdd8;
        text-align: center;
        font-size: 12px;
        color: #656565;
        margin-left: 22px;
      }
    }

    > .couponType {
      width: 100%;
      margin: 10px auto;

      > .coupon {
        padding: 10px 15px;
        border: 2px solid #ff6699;
        border-radius: 10px;
        background-color: #fff;
        margin-bottom: 10px;

        > .left {
          float: left;

          > .couponTitle {
            color: #707070;
            font-size: 18px;
            font-weight: bold;
          }

          > .couponType {
            margin: 5px 0;
          }
        }

        > .right {
          float: right;

          > p {
            font-size: 25px;
            margin-top: 20px;
            color: #ff6699;
            font-weight: bold;
          }
        }
      }
    }

    > .pointType {
      width: 360px;
      margin: 27px auto;

      > div {
        margin-bottom: 17px;

        > .left {
          font-size: 12px;
          font-weight: 500;
          margin-right: 55px;
          color: #656565;
        }

        > .right {
          width: 105px;
          border-bottom: 1px solid #f8bdd8;
          display: inline-block;
          text-align: right;

          > input {
            border: none;
            display: inline-block;
            width: 80px;
            text-align: right;
            background-color: transparent;
            height: 17px;
          }
        }
      }

      > .usePoint {
        > .allPoint {
          display: inline-block;
          width: 109px;
          height: 25px;
          line-height: 25px;
          border-radius: 3px;
          background-color: #f8bdd8;
          text-align: center;
          font-size: 12px;
          color: #656565;
          margin-left: 22px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
