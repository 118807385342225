<template>
  <div :class="{ step: path === 'Basic', SpecialStep: path === 'Special' }">
    <h2>스마트톡 색상 선택</h2>
    <div class="smartTokBox clearFix">
      <ul class="smartTokBtns">
        <li
          class="smartTokBtn"
          :class="{ active: tokColorNum === 0 }"
          @click="checkTokColor(0, '투명색')"
          @keydown="checkTokColor(0, '투명색')"
        >
          <div class="clearFix">
            <p>투명색</p>
            <hr />
            <img
              v-if="tokColorNum === 0"
              class="keyring_img"
              src="@/assets/goods/smart_tok_empty_active.svg"
              alt=""
            />
            <img v-else class="keyring_img" src="@/assets/goods/smart_tok_empty_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="smartTokBtn"
          :class="{ active: tokColorNum === 1 }"
          @click.stop="checkTokColor(1, '흰색')"
          @keydown.stop="checkTokColor(1, '흰색')"
        >
          <div class="clearFix">
            <p>흰색</p>
            <hr />
            <img
              v-if="tokColorNum === 1"
              class="keyring_img"
              src="@/assets/goods/smart_tok_white_active.svg"
              alt=""
            />
            <img v-else class="keyring_img" src="@/assets/goods/smart_tok_white_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="smartTokBtn"
          :class="{ active: tokColorNum === 2 }"
          @click="checkTokColor(2, '검정색')"
          @keydown="checkTokColor(2, '검정색')"
        >
          <div class="clearFix">
            <p>검정색</p>
            <hr />
            <img
              v-if="tokColorNum === 2"
              class="keyring_img"
              src="@/assets/goods/smart_tok_black_active.svg"
              alt=""
            />
            <img v-else class="keyring_img" src="@/assets/goods/smart_tok_black_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
    tokColorNum: {
      type: Number,
    },
  },
  methods: {
    checkTokColor(i, color) {
      this.$emit('checkTokColor', 'tokColor', i, color)
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .smartTokBox {
    font-weight: 400;

    > .smartTokBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 17px;
        cursor: pointer;
        margin-bottom: 15px;

        &:nth-child(3),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 144px;
          margin: 26px auto 0;
          text-align: center;

          > p {
            text-align: left;
          }

          > span {
            font-size: 10px;
            color: #707070;
            display: block;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin-top: 25px;
            width: 100px;
            height: 100px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .smartTokBox {
    font-weight: 400;

    > .smartTokBtns {
      float: left;

      > li {
        width: 118px;
        height: 160px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 10px;

        &:nth-child(3),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;

          > div {
            > .colorOption {
              > .color {
                border: 2px solid #ff6699;
                background-image: url('../../../assets/icon/checkIcon.png');
                background-position: center center;
              }
            }
          }
        }

        > div {
          width: 95px;
          margin: 26px auto 0;

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 70%;
            height: 60px;
            margin-top: 20px;
            vertical-align: middle;
            float: right;
          }

          .colorOption {
            margin-top: 2px;
            float: left;

            > li {
              width: 20px;
              height: 20px;
              border-radius: 3px;
              margin-right: 0px;
              margin-top: 8px;
              cursor: pointer;
              box-sizing: border-box;

              &.metal {
                background-color: #c7c7c7;
              }

              &.gold {
                background-color: #ece476;
              }

              &.rose {
                background-color: #e8c1bc;
              }
            }
          }
        }
      }
    }
  }
}
</style>
