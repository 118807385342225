export const Ticket = {
  namespaced: true,
  state: () => ({
    Ticket: null,
    // Ticket_package: {
    //   Nathan: null,
    //   Neri: null,
    //   Synthesis: null,
    // },
    Ticket_package: 0,
    price: 0,
  }),
  mutations: {
    RESET(state) {
      state.Ticket = null
      // state.Ticket_package.Nathan = null
      // state.Ticket_package.Neri = null
      // state.Ticket_package.Synthesis = null
      state.price = 0
    },
    TICKET_SEL(state, data) {
      state.Ticket = data.value
      this.dispatch('Ticket/itemsFm')
    },
    PACKAGE_SEL(state, data) {
      // state.Ticket_package[data.type] = data.value
      state.Ticket_package = data.value
      this.dispatch('Ticket/itemsFm')
    },
    PRICE_SET(state, data) {
      state.price = data
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET')
    },
    ticket({ commit }, data) {
      commit('TICKET_SEL', data)
    },
    package({ commit }, data) {
      commit('PACKAGE_SEL', data)
    },
    itemsFm({ state, commit }) {
      let fullPrice = 0
      const ticket = state.Ticket
      // const { Nathan, Neri, Synthesis } = state.Ticket_package
      const ticketPackage = state.Ticket_package

      fullPrice = ticket * 7000 + ticketPackage * 29900

      commit('PRICE_SET', fullPrice)
    },
  },
  getters: {
    Ticket(state) {
      return state.Ticket
    },
    Ticket_package(state) {
      return state.Ticket_package
    },
    price(state) {
      return state.price
    },
  },
}
