export const Message = {
  namespaced: true,
  state: () => ({ message: null, code: null, type: null, data: null, view: false }),
  mutations: {
    Message(state, data) {
      state.message = data.message
      state.code = data.code
      state.type = data.type
      state.data = data.data
      state.view = true
    },
    closeMessage(state) {
      state.view = false
    },
  },
  actions: {
    message({ commit }, data) {
      commit('Message', data)
    },
  },
  getters: {
    message(state) {
      return state.message
    },
    code(state) {
      return state.code
    },
    type(state) {
      return state.type
    },
    data(state) {
      return state.data
    },
    view(state) {
      return state.view
    },
  },
}
