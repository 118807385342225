<template>
  <div class="hole">
    <h1>{{ guideNum }}. 구멍 레이어 작업</h1>
    <!--  -->
    <div>
      <p>부자재가 들어 갈 수 있는 구멍을 제작합니다.</p>
      <p>
        ※커팅 레이어 안에 구멍 레이어가 있을 경우, 패스 과정 중 구멍의 모양과 크기가 변형될 수
        있습니다.
      </p>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/cham_hole_1.png" alt="" />
      </div>
      <div class="img_1 right">
        <p>1. 타원 도구를 선택 합니다.</p>
        <p>2. 타원 도구가 선택 되어 있는 상태에서 캔버스를 클릭합니다.</p>
        <p>3. 캔버스를 클릭하면 '타원 만들기'라는 창이 뜹니다.</p>
        <p>4. 폭과 높이를 7mm로 설정해 주세요.</p>
        <p class="red">7mm: 고리, 군번줄, 휴대폰줄<br />7.5mm: 와이어 링</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/cham_hole_2.png" alt="" />
      </div>
      <div class="img_2 right">
        <p>5. 칠을 회색으로 설정합니다.</p>
        <p>6. 획은 없음으로 설정합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/cham_hole_3.png" alt="" />
      </div>
      <div class="img_3 right">
        <p>7. 타원 도구를 선택 합니다.</p>
        <p>8. 타원 도구가 선택 되어 있는 상태에서 캔버스를 클릭합니다.</p>
        <p>9. 캔버스를 클릭하면 '타원 만들기'라는 창이 뜹니다.</p>
        <p>10. 폭과 높이를 2.5mm로 설정해 주세요.</p>
        <p class="red">2.5mm: 고리, 군번줄, 휴대폰줄<br />3.5mm: 와이어 링</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/cham_hole_4.png" alt="" />
      </div>
      <div class="img_4 right">
        <p>11. 칠을 빨간색으로 설정합니다.</p>
        <p>12. 획은 없음으로 설정합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/cham_hole_5.png" alt="" />
      </div>
      <div class="img_5 right">
        <p>13. 레이어에서 ctrl을 눌러 타원 2개를 선택합니다.</p>
        <p>14. 이동 도구(V)를 선택합니다.</p>
        <p>15. 빨간색의 사각형으로 표기된 정렬 도구를 선택합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/cham_hole_6.png" alt="" />
      </div>
      <div class="img_6 right">
        <p>16. 7mm(회색)의 타원 레이어는 커팅 레이어 밑으로 옮깁니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/cham_hole_7.png" alt="" />
      </div>
      <div class="img_7 right">
        <p>17. 레이어에서 ctrl을 눌러 타원 2개를 선택합니다.</p>
        <p>18. 원하시는 위치에 타원 레이어를 옮깁니다.</p>
      </div>
    </div>
    <!--  -->
    <div>
      <p>* 2.5mm(빨간) 타원 레이어와 일러스트 레이어 간격은 <span>2mm를 권장</span>합니다.</p>
      <p>(그 이하일 경우, 일러스트에 <span>손상</span>이 갈 수 있습니다)</p>
      <img src="@/assets/guide/cham_hole_8.png" alt="" />
      <figure>2mm 이상 확인</figure>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    guideNum() {
      let num = 5
      if (this.type === '쉐이커 키링') {
        num = 6
      } else {
        num = 5
      }
      return num
    },
  },
}
</script>

<style scoped lang="scss">
.hole {
  text-align: center;
  h1 {
    width: 100%;
  }

  > div {
    margin-top: 100px;

    > img {
      margin-top: 20px;
    }

    > .img {
      margin-top: 30px;

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
    figure {
      margin-top: 10px;
    }
    p {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-top: 20px;

      > span {
        color: #ff6699;
      }
    }

    &.img_half {
      > div {
        float: left;
        margin-left: 35px;
        text-align: left;

        &.img_1 {
          margin-top: 50px;
        }
        &.img_2 {
          margin-top: 180px;
        }
        &.img_3 {
          margin-top: 120px;
        }
        &.img_4 {
          margin-top: 150px;
        }
        &.img_5 {
          margin-top: 50px;
        }
        &.img_6 {
          margin-top: 130px;
        }
        &.img_7 {
          margin-top: 100px;
        }
      }
    }
  }
  .red {
    margin-left: 25px;
    color: #f69;
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
