<template>
  <div :class="{ step: path === 'Basic' || path === 'Template', SpecialStep: path === 'Special' }">
    <div class="quantityBox">
      <ul class="clearFix">
        <li
          v-if="
            (path === 'Basic' || path === 'Template') &&
            type !== '아크릴 관람차' &&
            type !== '쉐이커 키링'
          "
          class="designQuantity"
        >
          <h2>제작 할 도안 수량</h2>
          <div class="quantityBtns">
            <div
              class="minusBtn"
              @click="quantityCheck(0, 'minus')"
              @keydown="quantityCheck(0, 'minus')"
            >
              <img src="@/assets/goods/quantity_minus.svg" alt="" />
            </div>
            <div class="quantity">
              <!-- {{design}} -->
              <input
                id="goods_design"
                v-model="setDesign"
                type="number"
                @change="quantityChange(0)"
              />
            </div>
            <div
              class="plusBtn"
              @click="quantityCheck(0, 'plus')"
              @keydown="quantityCheck(0, 'plus')"
            >
              <img src="@/assets/goods/quantity_plus.svg" alt="" />
            </div>
          </div>
        </li>
        <li class="totalQuantity">
          <h2>총 주문 수량</h2>
          <div class="quantityBtns">
            <div
              class="minusBtn"
              @click="quantityCheck(1, 'minus')"
              @keydown="quantityCheck(1, 'minus')"
            >
              <img src="@/assets/goods/quantity_minus.svg" alt="" />
            </div>
            <div class="quantity">
              <!-- {{quantity}} -->
              <input
                id="goods_quantity"
                v-model="setQuantity"
                type="number"
                @change="quantityChange(1)"
              />
            </div>
            <div
              class="plusBtn"
              @click="quantityCheck(1, 'plus')"
              @keydown="quantityCheck(1, 'plus')"
            >
              <img src="@/assets/goods/quantity_plus.svg" alt="" />
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="
          (path === 'Basic' || path === 'Template') &&
          type !== '아크릴 관람차' &&
          type !== '쉐이커 키링'
        "
        class="referenceBox"
      >
        <div
          @mouseover="speechView()"
          @focus="speechView()"
          @mouseout="speechHide()"
          @focusout="speechHide()"
        >
          <img src="@/assets/icon/questionIcon.svg" alt="" />
          <span>도안별 주문 수량이 다를 경우 주문서 작성 방법</span>
        </div>
        <div class="speech_bubble" :class="{ active: speechBubble === true }">
          <div class="bubble_text">
            <p>1. 도안 파일에 해당 도안의 주문 수량을 적어 주세요.</p>
            <p>
              2. 각 도안 파일의 주문 수량을 합해서 주문서의<br />
              &nbsp;&nbsp;&nbsp;&nbsp;총 주문 수량에 적어주세요.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    type: {
      type: String,
    },
    design: {
      type: Number,
    },
    quantity: {
      type: Number,
    },
    speechBubble: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  watch: {
    setDesign(to, from) {
      if (to < 1 || !to) {
        this.setDesign = 1
      }
    },
    setQuantity(to, from) {
      if (to < 1 || !to) {
        this.setQuantity = 1
      }
    },
    quantity() {
      this.setQuantity = this.quantity
    },
    design() {
      this.setDesign = this.design
    },
  },
  data() {
    return {
      setQuantity: this.quantity,
      setDesign: this.design,
    }
  },
  methods: {
    quantityChange(i) {
      let path
      if (this.type === '일러스트 캔버스') {
        path = 'canvas'
      } else if (this.type === '아크릴 관람차') {
        path = 'wheel'
      } else {
        path = 'normal'
      }
      this.$emit('quantityChange', i, path, this.setQuantity, this.setDesign)
    },
    quantityCheck(i, type) {
      let path
      if (this.type === '일러스트 캔버스') {
        path = 'canvas'
      } else if (this.type === '아크릴 관람차') {
        path = 'wheel'
      } else {
        path = 'normal'
      }
      this.$emit('quantityCheck', i, type, path, this.setQuantity, this.setDesign)
    },
    speechView() {
      this.$emit('speechView')
    },
    speechHide() {
      this.$emit('speechHide')
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .quantityBox {
    > ul {
      > li {
        float: left;
        width: 277px;
        height: 35px;
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }

        > .quantityBtns {
          border: 1px solid #bcbcbc;
          border-radius: 5px;
          height: 100%;
          box-sizing: border-box;
          cursor: pointer;

          > div {
            display: inline-block;
            line-height: 33px;
            text-align: center;

            &.minusBtn {
              width: 15%;
              float: left;
            }

            &.quantity {
              width: 70%;
              font-size: 13px;
              color: #707070;
              text-align: center;

              > input {
                width: 30px;
                padding-right: 6px;
                height: 21px;
                background-color: #fafafa;
                border: none;
                border-radius: 5px;
                font-size: 13px;
                color: #707070;
                text-align: right;
              }
            }

            &.plusBtn {
              width: 15%;
              float: right;
            }

            > img {
              width: 13px;
            }
          }
        }
      }
    }

    > .referenceBox {
      margin-top: 40px;
      position: relative;

      > div {
        display: inline-block;
        cursor: pointer;
        > span {
          font-size: 12px;
          color: #ff6699;
          margin-left: 3px;
        }
      }
      > .speech_bubble {
        width: 310px;
        height: 80px;
        position: absolute;
        left: 254px;
        top: 2px;
        background: url('../../../assets/icon/speech_bubble.png') center center no-repeat;
        transition: 0.5s;
        opacity: 0;
        pointer-events: none;

        &.active {
          opacity: 1;
        }

        > .bubble_text {
          position: absolute;
          top: 13px;
          left: 20px;

          > p {
            font-size: 12px;
            color: #707070;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto;

  h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .quantityBox {
    > ul {
      > li {
        height: 35px;
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }

        > .quantityBtns {
          border: 1px solid #bcbcbc;
          border-radius: 5px;
          height: 100%;
          box-sizing: border-box;
          cursor: pointer;

          > div {
            display: inline-block;
            line-height: 33px;
            text-align: center;

            &.minusBtn {
              width: 15%;
              float: left;
            }

            &.quantity {
              width: 70%;
              font-size: 13px;
              color: #707070;
              text-align: center;

              > input {
                width: 30px;
                padding-right: 6px;
                height: 21px;
                background-color: #fafafa;
                border: none;
                border-radius: 5px;
                font-size: 13px;
                color: #707070;
                text-align: right;
              }
            }

            &.plusBtn {
              width: 15%;
              float: right;
            }

            > img {
              width: 13px;
            }
          }
        }
      }
    }
  }
}
</style>
