<template>
  <div class="container">
    <div class="containerImg">
      <img src="@/assets/main/main_container_goods.png" alt="" />
    </div>
    <div class="containerText">
      <div class="contentTitle">여러분의 최애로<br />굿즈를 만들어 보세요.</div>
      <div class="contentText">
        <p>소중한 나의 최애로 키링이나 스탠드를 만들어 보세요.</p>
        <p>
          PANTONE®의 인증을 받은 컬러감으로<br />보다 정확하고 선명한 아크릴 굿즈를 제작할 수
          있습니다.
        </p>
        <p>영롱한 아크릴 굿즈로 일상을 장식해 보세요!</p>
      </div>
      <div class="contentButton">
        <router-link
          :to="{
            name: 'Acrylic',
            params: { type: 'Cham' },
          }"
          >키링 만들기</router-link
        >
        <router-link
          :to="{
            name: 'Acrylic',
            params: { type: 'Stand' },
          }"
          >스탠드 만들기</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.container {
  text-align: left;
  width: 1160px;
  background-color: #fafafa;
  margin: 0 auto;
  height: 1190px;

  .containerImg {
    float: left;
    margin-top: 162px;
  }

  .containerText {
    float: left;
    margin-top: 302px;
    margin-left: 103px;

    > .contentTitle {
      font-size: 42px;
      color: #ff6699;
      font-weight: bold;
    }

    > .contentText {
      > p {
        color: #333;
        margin-top: 45px;
        font-size: 19px;
        letter-spacing: -0.02cm;
        line-height: 30px;
      }
    }

    > .contentButton {
      margin-top: 51px;
      > a {
        display: inline-block;
        color: #fff;
        width: 155px;
        height: 40px;
        text-align: center;
        border-radius: 8px;
        background-color: #ff6699;
        line-height: 40px;
        font-size: 19px;
        cursor: pointer;
        margin-right: 40px;
      }
    }
  }
}
</style>
