<template>
  <div class="index">
    <Banner
      :b-num="bNum"
      :arrow_top_view="arrow_top_view"
      :arrow_bottom_view="arrow_bottom_view"
      :banner_array="banner_array"
      @move-goods="moveGoods"
      @b-num-change="bNumChange"
      @start-event="startBanner"
      @clear-event="clearBanner"
      @arrow-hide="arrowHide"
      @arrow-view="arrowView"
      @scroll-check="scrollCheck"
    />
    <Custom @route-move="routeMove" />
    <MainCanvas />
    <Advantages />
    <Corporate-order />
    <Review :review-list="reviewList" @review-detail="reviewDetail" />
    <div class="getImg">
      <img src="@/assets/icon/find_phone.svg" alt="" />
      <img src="@/assets/icon/loginLogo.svg" alt="" />
    </div>
    <review-detail
      v-if="detail_view === true"
      :review="detail_data"
      :img-num="imgNum"
      @close-tab="closeTab"
      @img-change="imgChange"
    />
    <announcement :announcement-list="announcementList" />
  </div>
</template>

<script>
import Banner from '../../components/Main/MainBanner.vue'
import Custom from '../../components/Main/MainCustom.vue'
import MainCanvas from '../../components/Main/MainCanvas.vue'
import Advantages from '../../components/Main/MainAdvantages.vue'
import CorporateOrder from '../../components/Main/CorporateOrder.vue'
import Review from '../../components/Main/MainReview.vue'
import ReviewDetail from '../Modal/ReviewDetail.vue'
import Announcement from '../Modal/ModalAnnouncement.vue'
import Cookie from 'js-cookie'

export default {
  components: {
    Banner,
    Custom,
    MainCanvas,
    Advantages,
    CorporateOrder,
    Review,
    ReviewDetail,
    Announcement,
  },
  data() {
    return {
      bNum: 0,
      reviewImage: 10,
      interval: '',
      arrow_top_view: false,
      arrow_bottom_view: true,
      right_scroll_top: 0,
      right_scroll_height: 0,
      right_offset_height: 0,
      announcement_view: false,
      banner_array: [
        // {
        //   image: require('@/assets/main/main_banner_img_d-festa.png'),
        //   router_name: 'EtcItem',
        //   router_type: 'Ticket',
        // },
        // {
        //   image: require('@/assets/main/main_banner_img_naver.png'),
        //   router_name: 'Naver',
        // },
        {
          image: require('../../assets/main/main_banner_img_solid_block.png'),
          router_name: 'Acrylic',
          router_type: 'SolidBlock',
        },
        {
          image: require('../../assets/main/main_banner_img_shaker.png'),
          router_name: 'Acrylic',
          router_type: 'ShakerKeyring',
        },
        {
          image: require('../../assets/main/main_banner_img_carabiner.png'),
          router_name: 'Template',
          router_type: 'Carabiner',
        },
        {
          image: require('../../assets/main/main_banner_img_wheel.png'),
          router_name: 'Template',
          router_type: 'Wheel',
        },
        {
          image: require('../../assets/main/main_banner_img_block.png'),
          router_name: 'Acrylic',
          router_type: 'Block',
        },
        {
          image: require('../../assets/main/main_banner_img_attach.png'),
          router_name: 'Acrylic',
          router_type: 'Attach',
        },
        {
          image: require('../../assets/main/main_banner_img_lamp.png'),
          router_name: 'Acrylic',
          router_type: 'LampStand',
        },
        {
          image: require('../../assets/main/main_banner_img_1.png'),
          router_name: 'Acrylic',
          router_type: 'Cham',
          router_query: {
            kind: 'hologram',
          },
        },
        // {
        //   image: require('../../assets/main/main_banner_img_0.png'),
        //   router_name: 'Acrylic',
        //   router_type: 'Bookmark',
        // },
        {
          image: require('../../assets/main/main_banner_img_2.png'),
          router_name: 'Acrylic',
          router_type: 'Stand',
        },
        {
          image: require('../../assets/main/main_banner_img_3.png'),
          router_name: 'Acrylic',
          router_type: 'Cham',
        },
        {
          image: require('../../assets/main/main_banner_img_4.png'),
          router_name: 'Acrylic',
          router_type: 'Card',
        },
      ],
      reviewList: [],
      imgNum: 0,
      detail_view: false,
      detail_data: {},
    }
  },
  async mounted() {
    this.startBanner()
    await this.getReview()
  },
  unmounted() {
    this.clearBanner()
  },
  methods: {
    closeTab() {
      this.detail_view = false
    },
    // 템플릿 주문 페이지로 이동
    routeMove(name, params) {
      this.$router.push({
        name,
        params,
      })
    },
    //   배너 이미지 클릭시 해당 굿즈 페이지로 이동
    moveGoods() {
      if (this.banner_array[this.bNum].router_name === 'Naver') {
        window.open(
          'https://brand.naver.com/webtoonfriends/category/00594dea81104ff1b92c046950467529?st=RECENT&dt=IMAGE',
          '_blank',
        )
      } else {
        if (this.banner_array[this.bNum].router_query) {
          this.$router.push({
            name: this.banner_array[this.bNum].router_name,
            params: { type: this.banner_array[this.bNum].router_type },
            query: this.banner_array[this.bNum].router_query,
          })
        } else {
          this.$router.push({
            name: this.banner_array[this.bNum].router_name,
            params: { type: this.banner_array[this.bNum].router_type },
          })
        }
      }
    },
    scrollCheck() {
      const header = document.getElementById(`main_right_best_item`)
      const { offsetHeight, scrollTop, scrollHeight } = header
      this.right_offset_height = offsetHeight
      this.right_scroll_top = scrollTop
      this.right_scroll_height = scrollHeight
    },
    startBanner() {
      this.interval = setInterval(() => {
        this.bNum++
        if (this.bNum >= this.banner_array.length) {
          this.bNum = 0
        }
      }, 3000)
    },
    // 배너 이벤트 멈춤
    clearBanner() {
      clearInterval(this.interval)
    },

    bNumChange(num) {
      this.bNum = num
    },
    arrowHide() {
      this.arrow_bottom_view = false
      this.arrow_top_view = false
    },
    arrowView() {
      const scroll = this.right_scroll_top + this.right_offset_height
      if (scroll >= this.right_scroll_height) {
        this.arrow_bottom_view = false
      } else {
        this.arrow_bottom_view = true
      }
      if (this.right_scroll_top > 10) {
        this.arrow_top_view = true
      } else {
        this.arrow_top_view = false
      }
    },
    //   리뷰목록 불러오기
    async getReview() {
      await this.axios
        .get(`${this.Path.getReview}?=type=${this.TypeValue.Goods_Type(this.$route.params.type)}`)
        .then(async (res) => {
          if (res.data.success === true) {
            let photoList = []
            let photoImg = 0
            await res.data.data.forEach(async (data, index) => {
              if (data.file_url.length > 0) {
                if (photoImg < this.reviewImage) {
                  photoList.push(data)
                  photoImg++
                } else {
                  return
                }
              }
            })
            this.reviewList = photoList
          }
        })
    },
    // 리뷰 이미지 클릭시 상세리뷰 보이기
    reviewDetail(review) {
      this.detail_data = review
      this.imgNum = 0
      this.detail_view = true
    },
    // 다른 이미지 보기
    imgChange(imgNum) {
      this.imgNum = imgNum
    },
  },
}
</script>

<style scoped lang="scss">
.index {
  // width: 100%;
  min-width: 1080px;

  .getImg {
    position: absolute;
    left: -9999px;
  }
}
// @media screen and (max-width: 768px) {
//   .index {
//     display: none;
//   }
// }
</style>
../Modal/ModalAnnouncement.vue
