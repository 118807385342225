<template>
  <div class="container2 clearFix">
    <div class="content">
      <div class="contentTitle">
        동네공작소
        <hr />
      </div>
      <div>
        <p class="title">빠르고 간편한 제작</p>
        <p class="text">누구나 할 수 있는<br />제품 만들기</p>
      </div>
      <div>
        <p class="title">동네공작소 품질 보증</p>
        <p class="text">PANTONE Digital Color 인증을 <br />받은 기계 사용</p>
      </div>
      <div>
        <p class="title">합리적인 가격</p>
        <p class="text">가성비 좋은 제품을<br />공급합니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.container2 {
  text-align: left;
  width: 100%;
  height: 270px;
  background-color: #fffbfb;

  > .content {
    width: 1160px;
    margin: 0 auto;

    > div {
      float: left;
      margin-top: 83px;
      margin-right: 125px;

      &:last-child {
        margin-right: 0;
      }

      > .title {
        font-size: 22px;
        color: #3f3f3f;
      }

      > .text {
        font-size: 14px;
        color: #707070;
      }
    }

    > .contentTitle {
      font-size: 30px;
      color: #000;
      font-weight: bold;

      hr {
        border: 2px solid #ff6699;
        background-color: #ff6699;
        border-radius: 5px;
      }
    }
  }
}
</style>
