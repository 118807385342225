<template>
  <div class="goods">
    <basic-container />
  </div>
</template>

<script>
import BasicContainer from '../../containers/Goods/BasicContainer.vue'

export default {
  name: 'Acrylic',
  components: {
    BasicContainer,
  },
}
</script>

<style></style>
