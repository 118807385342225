<template>
  <div class="Wrap">
    <div class="goodsWrap clearFix">
      <etc-item-left
        :params="params"
        :Oring="Oring"
        :dog-tag="dogTag"
        :wiring="wiring"
        :smart-tok="smartTok"
        :badge="badge"
        :strap="strap"
        :keyring-type="keyringType"
        :ticket="ticket"
        :ticket_package="ticket_package"
        :ticket_package_quantity="ticket_package_quantity"
        :ticket_quantity="ticket_quantity"
        @check-item="checkItem"
        @check-package="checkPackage"
        @check-color="checkColor"
        @check-tag-color="checkTagColor"
        @check-wiring-color="checkWiringColor"
        @check-smart-tok="checkSmartTok"
        @check-badge="checkBadge"
        @cart-add="cartAdd"
      />
      <etc-item-right
        :type="type"
        :params="params"
        :Oring="Oring"
        :dog-tag="dogTag"
        :wiring="wiring"
        :smart-tok="smartTok"
        :badge="badge"
        :strap="strap"
        :keyring-type="keyringType"
        :item-price="itemsPrice"
        :ticket="ticket"
        :ticket_package="ticket_package"
        :ticket-price="ticketPrice"
        :ticket_package_quantity="ticket_package_quantity"
        :ticket_quantity="ticket_quantity"
        @plmin-btn="plminBtn"
        @num-change="numChange"
        @Ticket_Package_plmin-btn="Ticket_Package_plminBtn"
        @Ticket_Package_num-change="Ticket_Package_numChange"
        @Ticket_plmin-btn="Ticket_plminBtn"
        @Ticket_num-change="Ticket_numChange"
      />
    </div>
    <div class="margin" />
    <etc-item-intro :params="params" :move-bar="intro_movebar" @scroll-event="scrollEvent" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EtcItemLeft from '../../components/Goods/Menu/EtcItemLeft.vue'
import EtcItemRight from '../../components/Goods/Menu/EtcItemRight.vue'
import EtcItemIntro from '../../components/Goods/Intro/EtcItem.vue'

export default {
  components: {
    EtcItemLeft,
    EtcItemRight,
    EtcItemIntro,
  },
  data() {
    return {
      type: '',
      params: '',
      intro_movebar: {
        position: 'absolute',
        width: '100px',
        height: '4px',
        backgroundColor: '#ff6699',
        borderRadius: '10px',
        top: '68px',
        left: '318px',
        transition: '0.5s',
      },
      headerTop: null, // intro 기준 높이
      introHeight: 0, // params에 따른 margin값
      scrolled: false,
      // 디페스타 입장권, 패키지 남은수량
      ticket_package_quantity: 0,
      ticket_quantity: 0,
    }
  },
  computed: {
    ...mapGetters({
      Oring: 'Accessories/Oring',
      dogTag: 'Accessories/dogTag',
      wiring: 'Accessories/wiring',
      smartTok: 'Accessories/smartTok',
      badge: 'Accessories/badge',
      strap: 'Accessories/strap',
      keyringType: 'Accessories/keyringType',
      itemsPrice: 'Accessories/price',
      ticket: 'Ticket/Ticket',
      ticket_package: 'Ticket/Ticket_package',
      ticketPrice: 'Ticket/price',
    }),
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('Goods/periodReset')
      this.accessoriesReset()
      this.ticketReset()
      this.paramsCheck()
    },
  },
  async mounted() {
    await this.$store.dispatch('Goods/periodReset')
    await this.indexedDB.initializeData()
    this.accessoriesReset()
    this.ticketReset()
    this.paramsCheck()
    window.addEventListener('scroll', this.detectWindowScrollY)
  },
  beforeDestory() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  unmounted() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  methods: {
    accessoriesReset() {
      this.$store.dispatch('Accessories/reset')
    },
    ticketReset() {
      this.$store.dispatch('Ticket/reset')
    },
    sticky() {
      let elements = document.querySelectorAll('.sticky')
      Stickyfill.add(elements)
    },
    paramsCheck() {
      const { params } = this.$route
      this.params = params.type
      if (params.type === 'Items') {
        this.type = '부자재 별도 구매'
      } else if (params.type === 'Ticket_Package') {
        this.type = '디페스타 입장권 패키지'
        this.getTicketQuantity(this.type)
        this.$store.dispatch(`Ticket/package`, {
          value: 1,
        })
      } else if (params.type === 'Ticket') {
        this.type = '디페스타 입장권'
        this.getTicketQuantity(this.type)
        this.$store.dispatch(`Ticket/ticket`, {
          value: 1,
        })
        this.$store.dispatch(`Ticket/package`, {
          value: 0,
        })
      }
    },
    // 디페스타 입장권 티켓 조회
    getTicketQuantity(type) {
      this.axios.get(`${this.Path.GetTicketQuantity}?type=${type}`).then((res) => {
        if (res.data.success === true) {
          if (type === '디페스타 입장권 패키지') {
            this.ticket_package_quantity = res.data.data
          } else if (type === '디페스타 입장권') {
            this.ticket_quantity = res.data.data
          }
        }
      })
    },
    // 기타 부자재 선택
    checkItem(type, num) {
      let typeNum
      if (num == null) {
        typeNum = 1
      } else {
        typeNum = null
      }
      this.$store.dispatch(`Accessories/${type}`, {
        type: typeNum,
      })
    },
    // 키링 선택
    checkColor(type, num, index) {
      let typeNum
      if (num == null) {
        typeNum = 1
      } else {
        typeNum = null
      }
      this.$store.dispatch(`Accessories/${type}`, {
        type: typeNum,
        index,
      })
    },
    // 키링 선택
    checkTagColor(num, index) {
      let typeNum
      if (num == null) {
        typeNum = 1
      } else {
        typeNum = null
      }
      this.$store.dispatch(`Accessories/tagSel`, {
        type: typeNum,
        index,
      })
    },
    // 키링 선택
    checkWiringColor(num, index) {
      let typeNum
      if (num == null) {
        typeNum = 1
      } else {
        typeNum = null
      }
      this.$store.dispatch(`Accessories/wiringSel`, {
        type: typeNum,
        index,
      })
    },
    // 스마트톡 선택
    checkSmartTok(color, num) {
      let typeNum
      if (num == null) {
        typeNum = 1
      } else {
        typeNum = null
      }
      this.$store.dispatch(`Accessories/smartTokSel`, {
        type: typeNum,
        color,
      })
    },
    // 뱃지 선택
    checkBadge(color, num) {
      let typeNum
      if (num == null) {
        typeNum = 1
      } else {
        typeNum = null
      }
      this.$store.dispatch(`Accessories/badgeSel`, {
        type: typeNum,
        color,
      })
    },
    // 입장권 패키지 선택
    // checkPackage(type, num) {
    //   let typeNum
    //   if (num == null) {
    //     typeNum = 1
    //   } else {
    //     typeNum = null
    //   }
    //   this.$store.dispatch(`Ticket/package`, {
    //     value: typeNum,
    //     type,
    //   })
    // },
    // 선택된 아이템 수량 플러스 또는 마이너스
    plminBtn(fm, type, num, index, color) {
      let typeNum = num
      if (fm === 'plus') {
        typeNum += 1
      } else {
        if (num > 1) {
          typeNum -= 1
        } else {
          typeNum = null
        }
      }
      this.$store.dispatch(`Accessories/${type}`, {
        type: typeNum,
        index,
        color,
      })
    },
    // 선택된 아이템 수량 변경
    numChange(type, num, index, color) {
      this.$store.dispatch(`Accessories/${type}`, {
        type: num,
        index,
        color,
      })
    },
    // 선택된 입장권 패키지 플러스 또는 마이너스
    Ticket_Package_plminBtn(fm, type, num) {
      let typeNum = num
      if (fm === 'plus') {
        typeNum += 1
      } else {
        if (num > 1) {
          typeNum -= 1
        } else {
          typeNum = 1
        }
      }
      this.$store.dispatch(`Ticket/package`, {
        value: typeNum,
        type,
      })
    },
    // 선택된 입장권 패키지 수량 변경
    Ticket_Package_numChange(type, num) {
      this.$store.dispatch(`Ticket/package`, {
        value: num,
        type,
      })
    },
    // 선택된 입장권 플러스 또는 마이너스
    Ticket_plminBtn(fm, type, num) {
      let typeNum = num
      if (fm === 'plus') {
        typeNum += 1
      } else {
        if (num > 1) {
          typeNum -= 1
        } else {
          typeNum = 1
        }
      }
      this.$store.dispatch(`Ticket/ticket`, {
        value: typeNum,
      })
    },
    // 선택된 입장권 수량 변경
    Ticket_numChange(type, num) {
      let typeNum = num
      if (num < 1) {
        typeNum = 1
      }
      this.$store.dispatch(`Ticket/ticket`, {
        value: typeNum,
      })
    },
    cartAdd() {
      if (this.params === 'Items') {
        this.item_cart()
      } else if (this.params === 'Ticket_Package') {
        this.ticket_package_cart('ticket_package')
      } else if (this.params === 'Ticket') {
        this.ticket_package_cart('ticket')
      }
    },
    // 부자재 장바구니 담기
    item_cart() {
      let jumpRing = 80
      let ballchain = 200
      let colorBallchain = 200
      let colorWiring = 300
      let keyrings = 700
      let toks = 800
      let badges = 250
      let goods = []
      if (this.Oring) {
        goods.push(this.pushData('O링', this.Oring, jumpRing))
      }
      if (this.strap) {
        goods.push(this.pushData('휴대폰줄', this.strap, ballchain))
      }
      if (this.smartTok.empty) {
        goods.push(this.pushData('스마트톡(투명색)', this.smartTok.empty, toks))
      }
      if (this.smartTok.white) {
        goods.push(this.pushData('스마트톡(흰색)', this.smartTok.empty, toks))
      }
      if (this.smartTok.black) {
        goods.push(this.pushData('스마트톡(검정색)', this.smartTok.empty, toks))
      }
      if (this.badge.pin) {
        goods.push(this.pushData('뱃지(핀뱃지)', this.badge.pin, badges))
      }
      if (this.badge.tongs) {
        goods.push(this.pushData('뱃지(집게핀뱃지)', this.badge.tongs, badges))
      }
      this.dogTag.forEach((data) => {
        if (data.name === '일반') {
          if (data.amount) {
            goods.push(this.pushData(`군번줄(${data.name})`, data.amount, ballchain))
          }
        } else {
          if (data.amount) {
            goods.push(this.pushData(`군번줄(${data.name})`, data.amount, colorBallchain))
          }
        }
      })
      this.wiring.forEach((data) => {
        if (data.amount) {
          goods.push(this.pushData(`와이어링(${data.name})`, data.amount, colorWiring))
        }
      })
      this.keyringType.forEach((data) => {
        if (data.metal) {
          goods.push(this.pushData(`${data.type}(실버)`, data.metal, keyrings))
        }
        if (data.gold) {
          goods.push(this.pushData(`${data.type}(골드)`, data.gold, keyrings))
        }
        if (data.rose) {
          goods.push(this.pushData(`${data.type}(로즈골드)`, data.rose, keyrings))
        }
      })
      if (goods.length === 0) {
        this.Message('상품을 선택해주세요.', 0, 0)
        return
      }
      // this.Cart.Items(goods, this.type)
      this.indexedDB.addItems(goods, this.type)
    },
    // 입장권패키지 장바구니 담기
    async ticket_package_cart(type) {
      let ticket = 7000
      let single = 29900
      // let double = 31900
      let goods = []
      if (this.ticket) {
        goods.push(this.pushData('디페스타 입장권', this.ticket, ticket))
      }
      if (this.ticket_package) {
        goods.push(this.pushData('디페스타 입장권 패키지', this.ticket_package, single))
      }
      if (goods.length === 0) {
        this.Message('상품을 선택해주세요.', 0, 0)
        return
      }
      let quantity = 0
      await goods.forEach((data) => {
        quantity += data.order_quantity * 1
      })
      if (type === 'ticket_package') {
        if (quantity > this.ticket_package_quantity) {
          this.Message('구매 수량이 남은 수량보다 많습니다.', 0)
          return
        }
      } else if (type === 'ticket') {
        if (quantity > this.ticket_quantity) {
          this.Message('구매 수량이 남은 수량보다 많습니다.', 0)
          return
        }
      }

      // this.Cart.Items(goods, this.type)
      await this.indexedDB.addItems(goods, this.type)
    },
    pushData(etc, order_quantity, price) {
      let data = {
        etc,
        order_quantity,
        price: price * order_quantity,
      }
      return data
    },
    // 스크롤 위치에따라 movebar위치 이동
    detectWindowScrollY() {
      const header = document.getElementById('EtcItem_pageHeader')
      let fixed_Header = document.getElementById('EtcItem_fixed_Header')
      this.headerTop = header.offsetTop
      let margin = this.headerTop + this.introHeight
      // 감지 이벤트 메서드
      // this.scrolled = window.scrollY > 0
      this.scrolled = window.scrollY > this.headerTop
      if (this.scrolled === true) {
        fixed_Header.classList.add('scrolled')
        // topBtn.style.display = 'block'
      } else {
        fixed_Header.classList.remove('scrolled')
        // topBtn.style.display = 'none'
      }

      if (window.scrollY > 100 && window.scrollY < this.headerTop) {
        this.intro_movebar.left = '318px'
      }
      if (window.scrollY > this.headerTop) {
        this.intro_movebar.left = '744px'
      }
    },
    // intro 메뉴 클릭시 클릭한메뉴 위치로 스크롤이동
    scrollEvent(i) {
      let margin = this.headerTop + this.introHeight
      if (i === 0) {
        window.scroll({
          behavior: 'auto',
          top: 0,
        })
        this.intro_movebar.left = '318px'
      } else if (i === 1) {
        window.scroll({
          behavior: 'auto',
          top: this.headerTop + 100,
        })
        this.intro_movebar.left = '744px'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.goodsWrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  font-weight: 400;
  position: relative;
}
.margin {
  width: 100%;
  height: 100px;
}
</style>
