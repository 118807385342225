<template>
  <div class="Wrap">
    <div class="goodsWrap clearFix">
      <template-left
        :type="type"
        :name="name"
        :print-num="printNum"
        :sec-print-num="secPrintNum"
        :packing-num="packingNum"
        :shape-num="shapeNum"
        :wheel-num="wheelNum"
        :x-length="xLength"
        :y-length="yLength"
        :preview-xsize="previewXsize"
        :preview-ysize="previewYsize"
        :speech-bubble="speechBubble"
        :template-speech-bubble="templateSpeechBubble"
        @cart-add="cartAdd"
        @sel-option="selOption"
        @size-change="sizeChange"
        @location="location"
        @location-over="locationOver"
        @quantity-change="quantityChange"
        @quantity-check="quantityCheck"
        @select-file="selectFile"
        @speech-view="speechView"
        @speech-hide="speechHide"
        @templateSpeech-view="templateSpeechView"
        @templateSpeech-hide="templateSpeechHide"
      />
      <template-right />
    </div>
    <div class="margin" />
    <template-intro :params="params" :move-bar="intro_movebar" @scroll-event="scrollEvent" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TemplateLeft from '../../components/Goods/Menu/TemplateLeft.vue'
import TemplateRight from '../../components/Goods/Menu/TemplateRight.vue'
import TemplateIntro from '../../components/Goods/Intro/IntroTemplate.vue'

export default {
  components: {
    TemplateLeft,
    TemplateRight,
    TemplateIntro,
  },
  data() {
    return {
      params: '', // 현재 페이지의 params
      intro_movebar: {
        position: 'absolute',
        width: '100px',
        height: '4px',
        backgroundColor: '#ff6699',
        borderRadius: '10px',
        top: '68px',
        left: '205px',
        transition: '0.5s',
      },
      headerTop: null, // intro 기준 높이
      introHeight: 0, // params에 따른 margin값
      scrolled: false,
      name: 'Template',
      printNum: 1, // 선택한 인쇄면 번호
      packingNum: 0, // 선택한 조립/포장 번호
      shapeNum: 0, // 카라비너 모양 번호
      wheelNum: 0, // 관람차 바퀴 크기 번호
      secPrintNum: 0, // 선택한 두번째 인쇄면 번호
      xLength: 59, // xsize 최대값
      yLength: 29, // ysize 최대값
      previewXsize: 6, // 마우스 오버시 보이는 xsize
      previewYsize: 6, // 마우스 오버시 보이는 ysize
      speechBubble: false, // 도안수량에대한 말풍선 보임,숨김여부
      templateSpeechBubble: false, // 커스텀 주문 방법 말풍선 보임, 숨김 여부
    }
  },
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      type: 'Goods/type',
    }),
  },
  watch: {
    $route(to, from) {
      if (to.name === 'Template') {
        this.$store.dispatch('Goods/periodReset')
        this.numReset()
        this.paramsCheck()
        this.goodsReset()
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('Goods/periodReset')
    await this.indexedDB.initializeData()
    this.sticky()
    this.numReset()
    this.paramsCheck()
    this.goodsReset()
    window.addEventListener('scroll', this.detectWindowScrollY)
  },
  beforeDestory() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  unmounted() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  methods: {
    sticky() {
      let elements = document.querySelectorAll('.sticky')
      Stickyfill.add(elements)
    },
    goodsReset() {
      if (this.type === '아크릴 관람차') {
        this.$store.dispatch('Goods/wheelReset')
      } else {
        this.$store.dispatch('Goods/reset')
      }
    },
    async paramsCheck() {
      let type = ''
      this.params = this.$route.params.type
      if (this.params === 'Dwarf') {
        type = '오뚝이 아크릴'
        await this.$store.dispatch('Goods/typeSet', type)
        this.selOption('stand', 0, '오뚝이 아크릴')
        this.selOption('item', 0, 'OPP')
        this.selOption('packing', 0, '묶음포장')
      } else if (this.params === 'Fan') {
        type = '투명 부채'
        await this.$store.dispatch('Goods/typeSet', type)
        this.selOption('thick', 0, '0.5t')
        this.sizeChange(19, 19)
        this.selOption('item', 0, 'OPP')
        this.selOption('packing', 0, '묶음포장')
      } else if (this.params === 'Carabiner') {
        type = '아크릴 카라비너'
        await this.$store.dispatch('Goods/typeSet', type)
        this.selOption('shape', 0, '하트형')
        this.selOption('thick', 0, '5t')
        this.sizeChange(9, 7)
        this.selOption('item', 0, 'OPP')
        this.selOption('packing', 0, '묶음포장')
      } else if (this.params === 'Wheel') {
        type = '아크릴 관람차'
        await this.$store.dispatch('Goods/typeSet', type)
        this.selOption('thick', 0, '5t')
        this.selOption('wheel', 0, '5인용')
        this.selOption('second_print', 0, '단면')
        this.sizeChange(12, 12)
      }
    },
    // 옵션 선택
    selOption(type, i, value) {
      if (type === 'print') {
        this.printNum = i
      } else if (type === 'item') {
        this.itemNum = i
      } else if (type === 'packing') {
        this.packingNum = i
      } else if (type === 'shape') {
        this.shapeNum = i
        if (i === 0) {
          this.sizeChange(9, 7)
        }
        if (i === 1) {
          this.sizeChange(8, 8)
        } else if (i === 2) {
          this.Message(
            '상단 사이즈(5*6cm)로 인하여 아크릴 최소 사이즈는 6*6cm인 점 이용에 참고 부탁드립니다.',
            0,
          )
          this.sizeChange(this.goods.x, this.goods.y)
          this.location(this.goods.x, this.goods.y)
        }
      } else if (type === 'wheel') {
        this.wheelNum = i
        if (i === 0) {
          this.sizeChange(12, 12)
        } else if (i === 1) {
          this.sizeChange(14, 14)
        } else if (i === 2) {
          this.sizeChange(15, 15)
        }
      } else if (type === 'second_print') {
        this.secPrintNum = i
      }

      this.$store.dispatch(`Goods/${type}`, value)
    },
    numReset() {
      this.itemNum = 0
      this.printNum = 1
      this.packingNum = 0
      this.shapeNum = 0
      this.wheelNum = 0
    },
    // size
    // 아크릴 크기를 input박스로 바꿀때
    sizeChange(xsize, ysize) {
      if (this.params === 'Dwarf') {
        if (xsize >= 10) {
          xsize = 9
        } else if (xsize <= 3) {
          xsize = 4
        }
        if (ysize >= 7) {
          ysize = 6
        } else if (ysize <= 3) {
          ysize = 4
        }
      } else if (this.params === 'Carabiner') {
        if (this.shapeNum === 2) {
          if (xsize >= 60) {
            xsize = 59
          } else if (xsize <= 6) {
            xsize = 6
          }

          if (ysize >= 30) {
            ysize = 29
          } else if (ysize <= 6) {
            ysize = 6
          }
        }
      }

      this.$store.dispatch('Goods/pSize', {
        x: xsize,
        y: ysize,
      })
    },
    // quantity
    // 도안 또는 주문수량 input으로 입력시 이벤트
    quantityChange(i, path, goods_quantity, goods_design) {
      if (i === 0) {
        if (goods_design > goods_quantity) {
          goods_quantity = goods_design
        }
      } else {
        if (goods_design > goods_quantity) {
          goods_quantity = goods_design
        }
      }
      if (goods_design < 1 || !goods_design) {
        goods_design = 1
      }
      if (goods_quantity < 1 || !goods_quantity) {
        goods_quantity = 1
      }

      this.$store.dispatch('Goods/quantity', {
        design_quantity: goods_design,
        order_quantity: goods_quantity,
        path,
      })
    },
    // 도안 또는 주문수량 버튼 클릭시 이벤트
    quantityCheck(i, type, path, goods_quantity, goods_design) {
      if (type === 'minus') {
        if (i === 0) {
          if (goods_design > 1) {
            goods_design -= 1
          }
        } else {
          if (goods_quantity > 1) {
            goods_quantity -= 1
          }
        }
        if (goods_design > goods_quantity) {
          goods_design = goods_quantity
        }
      } else {
        if (i === 0) {
          goods_design += 1
        } else {
          goods_quantity += 1
        }
        if (goods_design > goods_quantity) {
          goods_quantity = goods_design
        }
      }
      this.$store.dispatch('Goods/quantity', {
        design_quantity: goods_design,
        order_quantity: goods_quantity,
        path,
      })
    },
    // 도안파일 선택시 이벤트
    selectFile() {
      let file = document.getElementById('goods_file')
      if (file.files[0].type.match(/zip.*/)) {
        if (file.files[0].size > 15728640) {
          this.Message('파일 크기는 15M 까지만 가능합니다.', 0, 0)
        } else {
          this.$store.dispatch('Goods/file', file.files[0])
        }
      } else {
        this.Message('zip파일 형식이 아닙니다. zip파일 형식으로 업로드 해주세요.', 0, 0)
      }
    },
    // 아크릴 크기 table에서 선택했을때
    location(xsize, ysize, etc) {
      let yBox = document.getElementsByClassName('yBox')
      if (this.type === '아크릴 카라비너') {
        if (xsize <= 6) {
          xsize = 6
        }
        if (ysize <= 6) {
          ysize = 6
        }
      }

      for (let i = 0; i <= this.yLength - 1; i++) {
        if (i < ysize) {
          for (let j = this.xLength - 1; j >= 0; j--) {
            if (j >= xsize) {
              yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FEDBE8'
            } else if (j < xsize) {
              yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FFBDD8'
            }
          }
        } else {
          for (let j = 0; j < this.xLength; j++) {
            yBox[i].getElementsByClassName('xBox')[j].style.backgroundColor = '#FEDBE8'
          }
        }
      }
      this.$store.dispatch('Goods/pSize', {
        x: xsize,
        y: ysize,
      })
    },
    // 아크릴 크리 table에 마우스 오버했을때 이벤트
    locationOver(x, y) {
      let t1 = document.getElementsByClassName('t1')
      t1[0].style.opacity = '100%'
      if (x <= 3) {
        x = 4
      }
      if (y <= 3) {
        y = 4
      }
      let yBox1 = document.getElementsByClassName('yBox1')
      for (let i = 0; i <= this.yLength - 1; i++) {
        if (i <= y) {
          for (let j = this.xLength - 1; j >= 0; j--) {
            if (j > x) {
              yBox1[i].getElementsByClassName('xBox1')[j].style.opacity = '10%'
            } else if (j <= x) {
              yBox1[i].getElementsByClassName('xBox1')[j].style.opacity = '30%'
            }
          }
        } else {
          for (let j = 0; j < this.xLength; j++) {
            yBox1[i].getElementsByClassName('xBox1')[j].style.opacity = '10%'
          }
        }
      }
      this.previewXsize = x
      this.previewYsize = y
    },
    // 말풍선 보임
    speechView() {
      this.speechBubble = true
    },
    // 말풍선 숨김
    speechHide() {
      this.speechBubble = false
    },
    // 말풍선 보임
    templateSpeechView() {
      this.templateSpeechBubble = true
    },
    // 말풍선 숨김
    templateSpeechHide() {
      this.templateSpeechBubble = false
    },
    async cartAdd() {
      let { goods } = this
      await this.indexedDB.addOrder(goods, this.type)
    },
    // 스크롤 위치에따라 movebar위치 이동
    detectWindowScrollY() {
      const header = document.getElementById('Template_pageHeader')
      let fixed_Header = document.getElementById('template_fixed_Header')
      if (this.type === '오뚝이 아크릴') {
        this.introHeight = 9600
      } else if (this.type === '투명 부채') {
        this.introHeight = 9300
      } else if (this.type === '아크릴 카라비너') {
        this.introHeight = 10650
      } else if (this.type === '아크릴 관람차') {
        this.introHeight = 10300
      }
      this.headerTop = header.offsetTop
      let margin = this.headerTop + this.introHeight
      // 감지 이벤트 메서드
      // this.scrolled = window.scrollY > 0
      this.scrolled = window.scrollY > this.headerTop
      if (this.scrolled === true) {
        fixed_Header.classList.add('scrolled')
        // topBtn.style.display = 'block'
      } else {
        fixed_Header.classList.remove('scrolled')
        // topBtn.style.display = 'none'
      }

      if (window.scrollY > 100 && window.scrollY < this.headerTop) {
        this.intro_movebar.left = '207px'
      }
      if (window.scrollY > this.headerTop && window.scrollY < margin + 400) {
        this.intro_movebar.left = '528px'
      }
      if (window.scrollY > margin + 500) {
        this.intro_movebar.left = '850px'
      }
    },
    // intro 메뉴 클릭시 클릭한메뉴 위치로 스크롤이동
    scrollEvent(i) {
      let margin = this.headerTop + this.introHeight
      if (i === 0) {
        window.scroll({
          behavior: 'auto',
          top: 0,
        })
        this.intro_movebar.left = '207px'
      } else if (i === 1) {
        window.scroll({
          behavior: 'auto',
          top: this.headerTop + 100,
        })
        this.intro_movebar.left = '528px'
      } else if (i === 3) {
        window.scroll({
          behavior: 'auto',
          top: margin + 600,
        })
        this.intro_movebar.left = '850px'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.goodsWrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  font-weight: 400;
  position: relative;
}
.margin {
  width: 100%;
  height: 100px;
}
</style>
