<template>
  <div class="introduceWrap clearFix">
    <div id="Special_pageHeader" class="introduceMenu">
      <div>
        <ul>
          <li @click="scrollEvent(0)" @keydown="scrollEvent(0)">상품 옵션</li>
          <li @click="scrollEvent(1)" @keydown="scrollEvent(1)">상품 소개</li>
          <li @click="scrollEvent(3)" @keydown="scrollEvent(3)">고객 리뷰</li>
        </ul>
        <div :style="moveBar" />
      </div>
    </div>
    <div id="special_fixed_Header" class="introduceMenu fixed">
      <div>
        <ul>
          <li @click="scrollEvent(0)" @keydown="scrollEvent(0)">상품 옵션</li>
          <li @click="scrollEvent(1)" @keydown="scrollEvent(1)">상품 소개</li>
          <li @click="scrollEvent(3)" @keydown="scrollEvent(3)">고객 리뷰</li>
        </ul>
        <div :style="moveBar" />
      </div>
    </div>
    <div class="introduceBox">
      <div>
        <div v-show="params === 'Quoin'">
          <img src="@/assets/intro/intro_cham_1.png" alt="" />
        </div>
        <div v-show="params === 'Quoin'">
          <img src="@/assets/intro/intro_3.png" alt="" />
        </div>
        <div v-show="params === 'Quoin'">
          <img src="@/assets/intro/intro_4.png" alt="" />
        </div>
        <div v-show="params === 'Quoin'">
          <img src="@/assets/intro/intro_5.png" alt="" />
        </div>
        <div v-show="params === 'Quoin'" class="last">
          <img src="@/assets/intro/intro_6.png" alt="" />
        </div>
        <div v-show="params === 'Canvas'">
          <img src="@/assets/intro/intro_canvas_1.png" alt="" />
        </div>
        <div v-show="params === 'Canvas'" class="last">
          <img src="@/assets/intro/intro_canvas_2.png" alt="" />
        </div>
        <!-- <div v-show="params === 'Block'">
          <img src="@/assets/intro/intro_block_1.png" alt="" />
        </div> -->
        <!-- <div class="last" v-show="params === 'Block'">
          <img src="@/assets/intro/intro_block_2.png" alt="" />
        </div> -->
        <div>
          <img src="@/assets/intro/intro_delivery_1.png" alt="" />
        </div>
        <div><img src="@/assets/intro/intro_delivery_2.png" alt="" /></div>
        <div>
          <img src="@/assets/intro/intro_delivery_3.png" alt="" />
        </div>
      </div>
    </div>
    <review-container />
  </div>
</template>

<script>
import ReviewContainer from '../../../containers/Review/ReviewContainer.vue'

export default {
  components: {
    ReviewContainer,
  },
  props: {
    params: {
      type: String,
    },
    moveBar: {
      type: Object,
    },
  },
  methods: {
    scrollEvent(i) {
      this.$emit('scrollEvent', i)
    },
  },
}
</script>

<style scoped lang="scss">
.introduceWrap {
  width: 100%;
  min-width: 1160px;
  margin: 0 auto;

  > .introduceMenu {
    width: 100%;
    min-width: 1160px;
    text-align: center;
    background-color: #fff2f7;
    height: 72px;
    line-height: 72px;

    &.fixed {
      display: none;
    }

    > div {
      width: 1160px;
      text-align: center;
      margin: 0 auto;
      position: relative;

      > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        > li {
          display: inline-block;
          margin: 0 30px;
          font-size: 15px;
          color: #707070;
          cursor: pointer;
        }
      }
    }
  }

  > .introduceBox {
    width: 100%;
    min-width: 1160px;
    margin: 0 auto;
    padding-top: 100px;

    > div {
      > div {
        text-align: center;
        padding-top: 85px;

        &.last {
          padding-bottom: 100px;
        }
      }
    }
  }

  > .scrolled {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 98;
  }
}
</style>
