export const Accessories = {
  namespaced: true,
  state: () => ({
    Oring: null,
    dogTag: [
      {
        name: '일반',
        color: '#c7c7c7',
        amount: null,
      },
      {
        name: '빨강',
        color: '#f32f2f',
        amount: null,
      },
      {
        name: '진분홍',
        color: '#f54385',
        amount: null,
      },
      {
        name: '연분홍',
        color: '#fcc9d6',
        amount: null,
      },
      {
        name: '피치',
        color: '#f9a993',
        amount: null,
      },
      {
        name: '주황',
        color: '#ff7d44',
        amount: null,
      },
      {
        name: '노랑',
        color: '#ffe167',
        amount: null,
      },
      {
        name: '연두',
        color: '#76df68',
        amount: null,
      },
      {
        name: '민트',
        color: '#96dfc9',
        amount: null,
      },
      {
        name: '하늘',
        color: '#4bbef5',
        amount: null,
      },
      {
        name: '남색',
        color: '#2d2d9c',
        amount: null,
      },
      {
        name: '연보라',
        color: '#c89fdb',
        amount: null,
      },
      {
        name: '흰색',
        color: '#ffffff',
        amount: null,
      },
      {
        name: '회색',
        color: '#979797',
        amount: null,
      },
      {
        name: '검정',
        color: '#000000',
        amount: null,
      },
    ],
    wiring: [
      {
        name: '빨강',
        color: '#F32F2F',
        amount: null,
      },
      {
        name: '주황',
        color: '#FF7D44',
        amount: null,
      },
      {
        name: '노랑',
        color: '#FFE167',
        amount: null,
      },
      {
        name: '연두',
        color: '#76DF68',
        amount: null,
      },
      {
        name: '하늘',
        color: '#4BBEF5',
        amount: null,
      },
      {
        name: '남색',
        color: '#2D2D9C',
        amount: null,
      },
      {
        name: '연보라',
        color: '#C89FDB',
        amount: null,
      },
      {
        name: '분홍',
        color: '#F54385',
        amount: null,
      },
      {
        name: '흰색',
        color: '#ffffff',
        amount: null,
      },
      {
        name: '검정',
        color: '#000000',
        amount: null,
      },
    ],
    smartTok: {
      empty: null,
      white: null,
      black: null,
    },
    badge: {
      pin: null,
      tongs: null,
    },
    strap: null,
    keyringType: [
      {
        type: '일반형',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: '붕어모양',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: 'D모양',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: '하트모양',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: '별모양',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: '달모양',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: '고양이모양',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: '벚꽃모양',
        metal: null,
        gold: null,
        rose: null,
      },
      {
        type: '체인모양',
        metal: null,
        gold: null,
        rose: null,
      },
    ],
    price: 0,
  }),
  mutations: {
    RESET(state) {
      state.Oring = null
      state.strap = null
      state.keyringType.map((data) => {
        data.metal = null
        data.gold = null
        data.rose = null
        return state
      })
      state.dogTag.map((data) => {
        data.amount = null
        return state
      })
      state.wiring.map((data) => {
        data.amount = null
        return state
      })
      state.smartTok.empty = null
      state.smartTok.white = null
      state.smartTok.black = null
      state.badge.pin = null
      state.badge.tongs = null
      state.price = 0
    },
    ORING_SEL(state, data) {
      state.Oring = data.type
      this.dispatch('Accessories/itemsFm')
    },
    STRAP_SEL(state, data) {
      state.strap = data.type
      this.dispatch('Accessories/itemsFm')
    },
    METAL_SEL(state, data) {
      state.keyringType[data.index].metal = data.type
      this.dispatch('Accessories/itemsFm')
    },
    GOLD_SEL(state, data) {
      state.keyringType[data.index].gold = data.type
      this.dispatch('Accessories/itemsFm')
    },
    ROSE_SEL(state, data) {
      state.keyringType[data.index].rose = data.type
      this.dispatch('Accessories/itemsFm')
    },
    TAG_SEL(state, data) {
      state.dogTag[data.index].amount = data.type
      this.dispatch('Accessories/itemsFm')
    },
    WIRING_SEL(state, data) {
      state.wiring[data.index].amount = data.type
      this.dispatch('Accessories/itemsFm')
    },
    SMART_TOK_SEL(state, data) {
      state.smartTok[data.color] = data.type
      this.dispatch('Accessories/itemsFm')
    },
    BADGE_SEL(state, data) {
      state.badge[data.color] = data.type
      this.dispatch('Accessories/itemsFm')
    },
    PRICE_SET(state, data) {
      state.price = data
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET')
    },
    Oring({ commit }, data) {
      commit('ORING_SEL', data)
    },
    strap({ commit }, data) {
      commit('STRAP_SEL', data)
    },
    metal({ commit }, data) {
      commit('METAL_SEL', data)
    },
    gold({ commit }, data) {
      commit('GOLD_SEL', data)
    },
    rose({ commit }, data) {
      commit('ROSE_SEL', data)
    },
    tagSel({ commit }, data) {
      commit('TAG_SEL', data)
    },
    wiringSel({ commit }, data) {
      commit('WIRING_SEL', data)
    },
    smartTokSel({ commit }, data) {
      commit('SMART_TOK_SEL', data)
    },
    badgeSel({ commit }, data) {
      commit('BADGE_SEL', data)
    },
    itemsFm({ state, commit }) {
      let fullPrice = 0
      let jumpRing = 100
      let ballchain = 200
      let colorBallchain = 200
      let colorWiring = 300
      let keyrings = 700
      let toks = 800
      let badges = 250
      let { Oring, dogTag, wiring, strap, keyringType, smartTok, badge } = state
      let keyringAmount = 0
      let tagDefaultAmount = 0
      let tagColorAmount = 0
      let wiringColorAmount = 0
      let smartTokColorAmount = 0
      let badgeKindAmount = 0
      dogTag.forEach((tag) => {
        if (tag.name === '일반') {
          tagDefaultAmount += tag.amount
        } else {
          tagColorAmount += tag.amount
        }
      })
      wiring.forEach((wire) => {
        wiringColorAmount += wire.amount
      })
      keyringType.forEach((keyring) => {
        keyringAmount += keyring.metal + keyring.gold + keyring.rose
      })
      smartTokColorAmount += smartTok.empty + smartTok.white + smartTok.black
      badgeKindAmount += badge.pin + badge.tongs
      fullPrice =
        Oring * jumpRing +
        (tagDefaultAmount + strap) * ballchain +
        tagColorAmount * colorBallchain +
        wiringColorAmount * colorWiring +
        keyringAmount * keyrings +
        smartTokColorAmount * toks +
        badgeKindAmount * badges

      commit('PRICE_SET', fullPrice)
    },
  },
  getters: {
    Oring(state) {
      return state.Oring
    },
    dogTag(state) {
      return state.dogTag
    },
    wiring(state) {
      return state.wiring
    },
    smartTok(state) {
      return state.smartTok
    },
    badge(state) {
      return state.badge
    },
    strap(state) {
      return state.strap
    },
    keyringType(state) {
      return state.keyringType
    },
    price(state) {
      return state.price
    },
  },
}
