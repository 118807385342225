<template>
  <div class="goods">
    <etc-item-container />
  </div>
</template>

<script>
import EtcItemContainer from '../../containers/Goods/EtcItemContainer.vue'

export default {
  name: 'EtcItem',
  components: {
    EtcItemContainer,
  },
}
</script>

<style></style>
