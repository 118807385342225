import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import index from '../views/index.vue'
import Acrylic from '../views/Goods/Acrylic.vue'
import Special from '../views/Goods/Special.vue'
import EtcItem from '../views/Goods/EtcItem.vue'
import CustomOrder from '../views/Goods/CustomOrder.vue'
import Template from '../views/Goods/Template.vue'
import NewsList from '../views/News/List.vue'
import NewsDetail from '../views/News/Detail.vue'
import Cart from '../views/Cart/index.vue'
import PaymentReady from '../views/Payment/Ready.vue'
import PaymentComplet from '../views/Payment/Complet.vue'
import OrderList from '../views/Order/List.vue'
import OrderDetail from '../views/Order/Detail.vue'
import MyPageInfo from '../views/MyPage/Info.vue'
import MyPageReview from '../views/MyPage/Review.vue'
import MyPageCoupon from '../views/MyPage/Coupon.vue'
import MyPagePoint from '../views/MyPage/Point.vue'
import MyPageRanking from '../views/MyPage/Ranking.vue'
import Review from '../views/Review/index.vue'
import Service from '../views/Service/index.vue'
import ToS from '../views/ToS/index.vue'
import Guide from '../views/Guide/index.vue'
import Err from '../views/Err/index.vue'
import Login from '../views/Login/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      middleware: ['login'],
    },
  },
  {
    path: '/acrylic/:type',
    name: 'Acrylic',
    component: Acrylic,
  },
  {
    path: '/special/:type',
    name: 'Special',
    component: Special,
  },
  {
    path: '/etc_item/:type',
    name: 'EtcItem',
    component: EtcItem,
  },
  {
    path: '/custom_order/:id',
    name: 'CustomOrder',
    component: CustomOrder,
  },
  {
    path: '/template/:type',
    name: 'Template',
    component: Template,
  },
  {
    path: '/news/:type',
    name: 'NewsList',
    component: NewsList,
  },
  {
    path: '/news/:type/detail',
    name: 'NewsDetail',
    component: NewsDetail,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/payment/ready',
    name: 'PaymentReady',
    component: PaymentReady,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/payment/complet',
    name: 'PaymentComplet',
    component: PaymentComplet,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/order/:type',
    name: 'OrderList',
    component: OrderList,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/order/:type/detail',
    name: 'OrderDetail',
    component: OrderDetail,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/mypage/info/:check',
    name: 'MyPageInfo',
    component: MyPageInfo,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/mypage/Review',
    name: 'MyPageReview',
    component: MyPageReview,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/mypage/Coupon',
    name: 'MyPageCoupon',
    component: MyPageCoupon,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/mypage/Point',
    name: 'MyPagePoint',
    component: MyPagePoint,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/mypage/Ranking',
    name: 'MyPageRanking',
    component: MyPageRanking,
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/Review',
    name: 'Review',
    component: Review,
  },
  {
    path: '/Service',
    name: 'Service',
    component: Service,
  },
  {
    path: '/ToS/:type',
    name: 'ToS',
    component: ToS,
  },
  {
    path: '/guide/:type',
    name: 'Guide',
    component: Guide,
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    component: Err,
    meta: {
      requiresAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const { middleware } = to.meta
  if (middleware) {
    if (middleware.indexOf('auth') > -1) {
      const token = store.state.UserInfo.userToken
      if (token) {
        next()
      } else {
        next({
          path: '/login',
          query: {
            path: to.path,
            page: to.query?.page,
          },
        })
      }
    }
    if (middleware.indexOf('login') > -1) {
      const token = store.state.UserInfo.userToken
      if (token) {
        next({
          path: to.query.path,
          query: {
            page: to.query.page,
          },
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
