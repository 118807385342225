<template>
  <div class="wrap">
    <h1>나의 리뷰</h1>
    <div class="tableWrap clearFix">
      <ul class="reviewPick clearFix">
        <li
          class="writeReview"
          :class="{ active: status === 'list' }"
          @click="statusChange('list')"
          @keydown="statusChange('list')"
        >
          내가 작성한 리뷰
        </li>
        <li
          class="writtenReview"
          :class="{ active: status === 'write' }"
          @click="statusChange('write')"
          @keydown="statusChange('write')"
        >
          작성가능한 리뷰
        </li>
      </ul>
      <review-list
        v-if="status === 'list' && table_view === true"
        :list-array="tableData"
        @review-delete="reviewDelete"
        @review-detail="reviewDetail"
      />
      <review-write
        v-if="status === 'write' && table_view === true"
        :list-array="tableData"
        @review-add="reviewEdit"
      />
    </div>
    <page-list :paging-data="pagingData" @page-btn="pageBtn" />
    <review-edit v-if="review_view === true" :list="edit_data" @close-tab="closeTab" />
    <review-detail
      v-if="detail_view === true"
      :review="detail_data"
      :img-num="imgNum"
      @close-tab="closeTab"
      @img-change="imgChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewList from '../../components/MyPage/ReviewList.vue'
import ReviewWrite from '../../components/MyPage/ReviewWrite.vue'
import ReviewEdit from '../Modal/ReviewEdit.vue'
import ReviewDetail from '../Modal/ReviewDetail.vue'
import PageList from '../../components/Public/PageList.vue'

export default {
  components: {
    ReviewList,
    ReviewWrite,
    ReviewEdit,
    ReviewDetail,
    PageList,
  },
  data() {
    return {
      table_view: false,
      reviewArray: [], // 작성가능한 리뷰 또는 작성한 리뷰,
      tableData: [], // 받아온 상품목록을 페이징처리후 담을 데이터
      list_data: {}, // 페이지 이동시 기존에 있던 주문내역을 저장할 객체
      status: '',
      // 페이징 더미데이터 api에서 페이징 처리후 받아온 값을 넣을 예정
      pagingData: {
        endPage: 5, // 끝 페이지
        startPage: 1, // 시작 페이지
        totalPage: 5, // 총 페이지 수
        totalItem: 0, // 받아온 주문내역의 수
        page_list_size: 5, // 한번에 몇개의 페이지가 보일건지
        page_size: 3, // 한 페이지에 몇개까지 보여줄건지
        curPage: 1, // 현재 페이지
        curSet: 0, // 몇번째 페이지리스트인가
        set_item: 0, // 페이지 리스트에 따른 아이템수
        start_range: 0, // 목록에서 데이터 불러올 시작값
        end_range: 0, // 목록에서 데터터 불러올 끝값
      },
      review_view: false, // 리뷰 작성 창 호출여부
      edit_data: {},
      detail_view: false, // 리뷰 상세창 호출여부
      detail_data: {}, // 리뷰 상세 데이터
      imgNum: 0, // 리뷰상세에서 몇번째 이미지를 보여줄것인지 번호
      status_data: {}, // status값이 변경될때 기존 데이터를 저장할 객체
    }
  },
  watch: {
    async $route(to, from) {
      if (to.name === 'MyPageReview') {
        this.table_view = false
        this.status = to.query.status
        this.status_data[from.query.status] = this.reviewArray
        if (to.query.status !== from.query.status) {
          if (this.status_data[to.query.status]) {
            this.reviewArray = this.status_data[to.query.status]
          } else {
            await this.getList(this.$route.query.page * 1)
          }
        }
        await this.setPagingData()
      }
    },
  },
  async mounted() {
    this.status = this.$route.query.status
    await this.getList(this.$route.query.page * 1)
    await this.setPagingData()
  },
  methods: {
    closeTab() {
      this.review_view = false
      this.detail_view = false
    },
    // 주문목록 또는 리뷰 목록 가져오기
    async getList(curPage) {
      // api에 넘길 skip값
      let skip = Math.ceil(curPage / this.pagingData.page_list_size)
      let url
      if (this.status === 'list') {
        url = 'GetWrittenReview'
      } else {
        url = 'GetPossibleReview'
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      await this.axios.get(`${this.Path[url]}?skip=${skip}`, config).then((res) => {
        if (res.data.success === true) {
          this.reviewArray = res.data.data
        }
      })
      // this.listArray = orderList
    },
    // 페이지 이동 이벤트 (type: prev, next, move, page: 이동할 페이지 번호 또는 +-1)
    async pageBtn(type, page) {
      let move_page
      let next_set = false
      // type이 prev일 경우 이전 페이지로 이동
      if (type === 'prev') {
        move_page = this.$route.query.page * 1 - page
        // 이동해야될 페이지 번호가 pagingData.page_list_size의 배수일 경우 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 0) {
          next_set = true
        }
      } else if (type === 'next') {
        move_page = this.$route.query.page * 1 + page
        // 이동해야될 페이지 번호가 pagingData.page_list_size로 나누었을때 값이 1이라면 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 1) {
          next_set = true
        }
      } else if (type === 'move') {
        move_page = page
      }
      // list_data의 속성값
      let cur_set = Math.ceil(move_page / this.pagingData.page_list_size)
      if (next_set === true) {
        // list_data에 cur_set의 값이 있을경우 list_data의 cur_set속성값을 reviewArray으로 설정
        if (this.list_data[cur_set]) {
          this.reviewArray = this.list_data[cur_set]
        } else {
          // 없을경우 api에서 주문내역값 조회
          await this.getList(move_page)
          if (this.reviewArray.length === 0) {
            // 주문내역 조회하였을떄 값이 없다면 메세지 호출
            this.Message('리뷰내역이 없습니다.', 0, 0)
            return
          }
        }
      }
      this.list_data[cur_set] = this.reviewArray
      await this.$router.push({
        name: this.$route.name,
        params: { type: this.params },
        query: {
          page: move_page,
          status: this.status,
        },
      })
    },
    // 페이지 로딩 또는 라우트가 바뀔때바다 pagingData값을 새로 세팅
    async setPagingData() {
      this.pagingData = await this.PagingData(
        this.pagingData,
        this.reviewArray,
        this.$route.query.page,
      )
      let data = []
      for (let i = this.pagingData.start_range; i < this.pagingData.end_range; i++) {
        data.push(this.reviewArray[i])
      }
      this.tableData = data
      this.table_view = true
    },
    statusChange(status) {
      this.$router.push({
        name: 'MyPageReview',
        query: {
          page: 1,
          status,
        },
      })
    },
    reviewEdit(list) {
      this.edit_data = list
      this.review_view = true
    },
    // 리뷰 상세 보기
    reviewDetail(review, index) {
      this.detail_data = review
      this.imgNum = index
      this.detail_view = true
    },
    imgChange(imgNum) {
      this.imgNum = imgNum
    },
  },
  computed: {
    ...mapGetters({
      user: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.wrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  font-weight: 400;

  > h1 {
    font-size: 26px;
    font-weight: 700;
    color: #707070;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 70px;
  }

  > .tableWrap {
    width: 100%;
    margin: 0px auto 0;

    > .reviewPick {
      margin-bottom: 40px;

      > li {
        float: left;
        width: 50%;
        text-align: center;
        height: 54px;
        line-height: 54px;
        font-size: 20px;
        box-sizing: border-box;
        cursor: pointer;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid #ff6699;
        color: #c7c7c7;

        &.active {
          border-top: 1px solid #ff6699;
          border-right: 1px solid #ff6699;
          border-left: 1px solid #ff6699;
          border-bottom: none;
          color: #ff6699;
        }
      }
    }
  }
}
</style>
