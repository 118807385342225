const PagingData = {
  Paging: async (data, items, page) => {
    // curpage
    data.curPage = page * 1

    // curset
    data.curSet = Math.ceil(data.curPage / data.page_list_size)

    // totalitem
    data.totalItem = items.length

    // startpage
    data.startPage = (data.curSet - 1) * data.page_list_size + 1

    // endpage
    data.endPage = data.startPage + data.page_list_size - 1

    // totalpage
    data.totalPage = data.startPage + (Math.ceil(data.totalItem / data.page_size) - 1)

    // setitem
    data.set_item = (data.curSet - 1) * (data.page_list_size * data.page_size)

    // start_range
    data.start_range = (data.curPage - 1) * data.page_size - data.set_item

    // end_range
    data.end_range = data.curPage * data.page_size - data.set_item

    if (data.totalItem < data.end_range) {
      data.end_range = data.totalItem
    }
    return data
  },
}

export default PagingData.Paging
