<template>
  <div class="letterWrap">
    <div class="popupBg" @click="closeTab()" @keydown="closeTab()" />
    <div class="letterBox">
      <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <div class="letter">
        <h2>아크릴 제작 기간 유의사항</h2>
        <hr />
        <p>
          ※ 일반제작과 빠른제작을 함께 주문해 주실 경우, 해당 주문건은
          <span class="red">일반 제작 기간을 기준</span>으로 제작 됩니다.
        </p>
        <div class="textBox">
          <p class="title">[일반 제작 기간]</p>
          <p class="content">
            <span
              >- 도안이 접수된 날로부터
              <span class="red">영업일 기준 최대 {{ default_period }}일</span> 소요됩니다.
              <br /><span class="red" :style="{ 'margin-left': '6px' }"
                >(홀로그램, 투명 쉘은 영업일 기준 {{ shell_default_period }}일)</span
              ><br />- <span class="red">파본 검수 진행 후 출고되는</span> 제작 기간입니다.
              <span :style="{ color: '#8B8B8B' }">(배송 기간 제외) </span>
            </span>
          </p>
        </div>
        <div class="textBox">
          <p class="title">[빠른 제작 기간]</p>
          <p class="content">
            <span
              >- 도안이 접수된 날로부터
              <span class="red">영업일 기준 {{ quick_period }}일</span> 소요 됩니다.
              <span :style="{ color: '#8B8B8B' }">(배송 기간 제외) </span><br />- 도안 검수 없이
              접수된 도안으로 제작 진행됩니다.<br /><span :style="{ 'margin-left': '6px' }"
                >(도안 사이즈가 크거나, 필수 레이어가 부족한 경우 제외)</span
              ><br />- <span class="red">홀로그램과 투명 쉘은 현재 빠른 제작이 불가능합니다.</span
              ><br />- <span class="red">파본 검수를 진행하지 않고 출고되는</span> 제작
              기간입니다.<br />- 파본 기준에 대한 내용은 '상품 소개' 란을 참고 부탁드립니다.<br />-
              빠른 제작 선택 후 파본으로 인한 재제작 및 환불은 불가능합니다.<br /><span
                :style="{ 'margin-left': '6px' }"
                >(배송과정중 아크릴 파손 및 바닥부품의 구멍 크기가 작아 스탠드가 삽입되지 않는 경우
                제외)</span
              ></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      default_period: 'Goods/default_period',
      shell_default_period: 'Goods/shell_default_period',
      quick_period: 'Goods/quick_period',
    }),
  },
  methods: {
    async closeTab() {
      this.$emit('close-tab')
    },
  },
}
</script>

<style scoped lang="scss">
.letterWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: block;

  > .popupBg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  > .letterBox {
    width: 620px;
    height: 460px;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 1001;

    > .closeTab {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    > .letter {
      width: 540px;
      margin: 47px auto 0;

      > p {
        font-size: 12px;
        margin-bottom: 15px;

        .red {
          color: #ff6699;
        }
      }

      > h2 {
        font-size: 20px;
        font-weight: bold;
        color: #ff6699;
      }

      > hr {
        width: 100%;
        height: 2px;
        background-color: #ff6699;
        border: none;
        margin-top: 14px;
        margin-bottom: 5px;
      }

      > .textBox {
        margin-bottom: 17px;
        text-align: left;

        > .title {
          font-size: 16px;
          color: #ff6699;
          font-weight: 700;
        }

        > .content {
          font-size: 14px;
          color: #707070;

          .red {
            color: #ff6699;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
