<template>
  <div>
    <div class="completeWrap clearFix">
      <h1>주문 완료</h1>
      <div class="completeBox clearFix">
        <div class="complete">
          <p>감사합니다!</p>
          <p>고객님의 주문이 정상적으로 처리되었습니다.</p>
          <p class="orderNum">
            주문번호 : <span class="red">{{ SubStr.OrderNum(orderInfo.merchant_uid) }}</span>
          </p>
          <div class="orderLookup" @click="orderDetail()" @keydown="orderDetail()">주문조회</div>
          <p>주문/배송 관련 문의사항은 고객센터 > 1:1 문의를 이용해 주시기 바랍니다.</p>
        </div>
      </div>
      <div class="paymentType clearFix">
        <h2>결제수단</h2>
        <div class="typeName">
          {{ TypeValue.Payment_Method(orderInfo.payment_method) }}
        </div>
        <div v-show="orderInfo.payment_method === 'vbank'" class="typeContent">
          <p>입금 은행 : {{ orderInfo.vbank_name }}</p>
          <p>예금주 : {{ orderInfo.vbank_holder }}</p>
          <p>입금 계좌 : {{ orderInfo.vbank_num }}</p>
          <p>입금 시간 : {{ orderInfo.vbank_date }} 까지 입금</p>
        </div>
      </div>
      <div class="paymentPrice clearFix">
        <h2>금액</h2>
        <ul class="clearFix">
          <li class="sumValue clearFix">
            <span class="priceLeft">총 상품 금액</span>
            <span class="priceRight">{{ Commas(orderInfo.total_order_amount) }} 원</span>
          </li>
          <li class="shippingFee clearFix">
            <span class="priceLeft">배송비</span>
            <span class="priceRight">{{ Commas(orderInfo.delivery_amount) }} 원</span>
          </li>
          <li class="salePrice clearFix">
            <span class="priceLeft">할인금액</span>
            <span class="priceRight"
              ><span class="red">- {{ Commas(orderInfo.total_discount_amount) }} 원</span></span
            >
          </li>
          <li class="fullPrice clearFix">
            <span class="priceLeft">총 결제 금액</span>
            <span class="priceRight"
              ><span class="red">{{ Commas(orderInfo.total_payment_amount) }}</span> 원</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="btnBox clearFix">
      <div class="cartBtn" @click="goPage('Cart')" @keydown="goPage('Cart')">장바구니</div>
      <div class="shoppingBtn" @click="goPage('index')" @keydown="goPage('index')">
        홈페이지가기
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderInfo: {
      type: Object,
    },
  },
  methods: {
    goPage(name) {
      this.$emit('goPage', name)
    },
    orderDetail() {
      this.$emit('orderDetail')
    },
  },
}
</script>

<style scoped lang="scss">
.completeWrap {
  width: 1160px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Noto Sans KR';
  font-weight: 400;

  > h1 {
    margin: 40px auto;
    font-size: 26px;
    color: #707070;
    font-weight: 700;
  }

  > div {
    > h2 {
      font-size: 16px;
      color: #707070;
      margin-bottom: 4px;
      font-weight: 400;
    }
  }

  > .completeBox {
    width: 100%;
    height: 332px;
    background-color: #ffebf2;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 43px;

    > .complete {
      width: 100%;
      margin: 0 auto;
      padding-top: 32px;

      > p {
        font-size: 24px;
        color: #707070;
        line-height: 35px;

        &:last-child {
          font-size: 18px;
          line-height: 26px;
        }
      }

      > .orderNum {
        font-size: 20px;
        margin-top: 19px;
        margin-bottom: 26px;
        line-height: normal;
      }

      > .orderLookup {
        width: 156px;
        height: 48px;
        line-height: 48px;
        margin: 0 auto;
        color: #fff;
        border-radius: 5px;
        background-color: #ff6699;
        font-size: 20px;
        cursor: pointer;
        margin-bottom: 21px;
      }
    }
  }

  > .paymentType {
    width: 560px;
    float: left;
    text-align: left;
    margin-right: 50px;

    > .typeName {
      height: 60px;
      line-height: 60px;
      border-bottom: 2px solid #fedbe8;
      text-indent: 13px;
      font-size: 16px;
      color: #656565;
      font-weight: 500;
      border-top: 2px solid #ff6699;
    }

    > .typeContent {
      height: 105px;
      border-bottom: 2px solid #fedbe8;
      padding-top: 30px;

      > p {
        font-size: 12px;
        color: #656565;
        text-indent: 13px;
        line-height: 25px;
      }
    }
  }

  > .paymentPrice {
    width: 545px;
    float: left;
    text-align: left;

    > ul {
      width: 100%;
      border-top: 2px solid #ff6699;

      > li {
        margin-bottom: 8px;

        > .priceLeft {
          float: left;
          text-indent: 10px;
          font-size: 14px;
          color: #707070;
        }

        > .priceRight {
          float: right;
          margin-right: 10px;
          font-size: 14px;
          color: #707070;
        }
      }

      > .sumValue {
        height: 47px;
        line-height: 47px;
        font-size: 14px;
        color: #656565;
        border-bottom: 2px solid #ffe8f1;
        margin-bottom: 13px;
      }

      > .fullPrice {
        height: 49px;
        line-height: 49px;
        border-radius: 5px;
        background-color: #ffebf2;
        margin-top: 40px;
      }
    }
  }

  .red {
    color: #ff0c0c;
  }
}

.btnBox {
  width: 1160px;
  margin: 60px auto;
  text-align: center;

  > div {
    display: inline-block;
    width: 137px;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    color: #656565;
    margin: 0px 12px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    border: 2px solid #ffe1ec;
  }
  > .shoppingBtn {
    background-color: #ffe1ec;
  }
}
</style>
