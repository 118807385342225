<template>
  <div class="headerBox clearFix">
    <div class="leftHeader">
      <h2>{{ type }}</h2>
      <p v-if="type === '아크릴 키링'">가로{{ goods.x * 10 }}mm * 세로{{ goods.y * 10 }}mm</p>
      <p v-if="type === '아크릴 블럭'">가로 100mm * 세로 100mm</p>
      <p v-if="type === '일러스트 캔버스'">가로 160mm * 세로 230mm</p>
      <p class="remark">
        ※제작기간 : 도안이 접수된 날로부터 영업일 기준
        <span class="color">최대 {{ production_period }}일 소요</span>
      </p>
      <p class="remark">
        ※ <span>홀로그램과 투명 쉘</span>로 주문하실 경우
        <span>최대 {{ shell_period }}일</span> 소요될 수 있습니다.
      </p>
    </div>
    <div class="rightHeader">
      <p>총 금액</p>
      <div v-if="type === '아크릴 키링'">
        <span class="priceSpan">￦{{ Commas(goods.fullPrice) }}원</span>
        <ul>
          <li class="bubble" :class="{ show: goods.order_quantity <= 1 }">
            <img src="@/assets/goods/speech_bubble.png" alt="" />
            <span>-{{ goods.percentage }}%</span>
          </li>
          <li class="discount" :class="{ show: goods.order_quantity <= 1 }">
            (<strong>개당</strong>: {{ Commas(goods.price4one) }}원)
          </li>
          <li class="price">(<strong>개당</strong>: {{ Commas(goods.price) }}원)</li>
        </ul>
      </div>
      <div v-else>
        <span class="priceSpan">￦{{ Commas(goods.fullPrice) }}원</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      type: 'Goods/type',
      quick_order: 'Goods/quick_order',
      production_period: 'Goods/production_period',
      shell_period: 'Goods/shell_period',
    }),
  },
}
</script>

<style scoped lang="scss">
h2 {
  font-weight: 700;
  font-size: 26px;
  color: #848484;
}
.headerBox {
  width: 100%;
  height: 105px;
  background-color: #f8bdd8;

  > div {
    > p {
      font-size: 14px;
      color: #707070;

      &.remark {
        font-size: 12px;
        color: #707070;

        > span {
          color: #ff6699;
        }
      }
    }

    &.leftHeader {
      float: left;
      margin-left: 29px;
      margin-top: 5px;
    }

    &.rightHeader {
      float: right;
      margin-right: 11px;
      margin-top: 14px;
      text-align: right;

      > div {
        > ul {
          // display: inline-block;
          float: right;
          margin-right: 5px;
          font-size: 13px;
          margin-top: 20px;

          > .show {
            opacity: 0;
          }

          > .bubble {
            position: relative;
            left: -75%;
            top: 0px;

            > * {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              color: #fff;
              font-size: 14px;
            }
          }
          > .discount {
            text-decoration: line-through;
            color: #ff6699;
          }
        }
        > .priceSpan {
          font-size: 26px;
          color: #fff;
          font-weight: 700;
          float: right;
          margin-top: 23px;

          &.fullPrice {
            font-size: 12px;
            color: #707070;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
