<template>
  <div class="leftWrap">
    <div class="stepWrap">
      <!-- 관람차 바퀴 크기 -->
      <wheelSize v-if="type === '아크릴 관람차'" :wheel-num="wheelNum" @check-wheel="selOption" />
      <!-- 카라비너 모양 -->
      <shape v-if="type === '아크릴 카라비너'" :shape-num="shapeNum" @check-shape="selOption" />
      <!-- 사이즈 설정 -->
      <size
        v-show="(type !== '아크릴 카라비너' && type !== '아크릴 관람차') || shapeNum === 2"
        :path="name"
        :xsize="goods.x"
        :ysize="goods.y"
        :x-length="xLength"
        :y-length="yLength"
        :preview-xsize="previewXsize"
        :preview-ysize="previewYsize"
        @size-change="sizeChange"
        @location="location"
        @location-over="locationOver"
      />
      <!-- 두번째 인쇄면 설정 -->
      <secondPrint
        v-if="type === '아크릴 관람차'"
        :sec-print-num="secPrintNum"
        @check-print="selOption"
      />
      <!-- 인쇄면 설정 -->
      <print :type="type" :path="name" :print-num="printNum" @check-print="selOption" />
      <!-- 조립/포장 설장 -->
      <packing
        v-if="type !== '아크릴 관람차'"
        :path="name"
        :type="type"
        :packing-num="packingNum"
        @check-packing="selOption"
      />
      <!-- 파일 업로드 -->
      <file-upload
        :path="name"
        :type="type"
        :file="goods.file"
        :request="goods.design_request"
        :shape-num="shapeNum"
        :wheel-num="wheelNum"
        :template-speech-bubble="templateSpeechBubble"
        @select-file="selectFile"
        @check-request="selOption"
        @templateSpeech-view="templateSpeechView"
        @templateSpeech-hide="templateSpeechHide"
      />
      <!-- 도안수량, 주문수량 -->
      <quantity
        :path="name"
        :type="type"
        :design="goods.design_quantity"
        :quantity="goods.order_quantity"
        :speech-bubble="speechBubble"
        @quantity-change="quantityChange"
        @quantity-check="quantityCheck"
        @speech-view="speechView"
        @speech-hide="speechHide"
      />
    </div>
    <!-- 장바구니 버튼 -->
    <div class="cartBtn" @click="cartAdd()" @keydown="cartAdd()">장바구니에 담기</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import size from '../List/GoodsSize.vue'
import print from '../List/GoodsPrint.vue'
import packing from '../List/GoodsPacking.vue'
import FileUpload from '../List/FileUpload.vue'
import quantity from '../List/GoodsQuantity.vue'
import shape from '../List/Template/TemplateShape.vue'
import wheelSize from '../List/Template/WheelSize.vue'
import secondPrint from '../List/SecondPrint.vue'

export default {
  components: {
    size,
    print,
    packing,
    FileUpload,
    quantity,
    shape,
    wheelSize,
    secondPrint,
  },
  props: {
    type: {
      type: String,
    },
    name: {
      type: String,
    },
    printNum: {
      type: Number,
    },
    secPrintNum: {
      type: Number,
    },
    packingNum: {
      type: Number,
    },
    shapeNum: {
      type: Number,
    },
    wheelNum: {
      type: Number,
    },
    xLength: {
      type: Number,
    },
    yLength: {
      type: Number,
    },
    previewXsize: {
      type: Number,
    },
    previewYsize: {
      type: Number,
    },
    speechBubble: {
      type: Boolean,
    },
    templateSpeechBubble: {
      type: Boolean,
    },
    lamp_quantity: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
    }),
  },
  methods: {
    selOption(type, i, value) {
      this.$emit('selOption', type, i, value)
    },
    sizeChange(xsize, ysize) {
      this.$emit('sizeChange', xsize, ysize)
    },
    location(xsize, ysize) {
      this.$emit('location', xsize, ysize, this.goods.etc)
    },
    locationOver(x, y) {
      this.$emit('locationOver', x, y)
    },
    quantityChange(i, path, setQuantity, setDesign) {
      this.$emit('quantityChange', i, path, setQuantity, setDesign)
    },
    quantityCheck(i, type, path, setQuantity, setDesign) {
      this.$emit('quantityCheck', i, type, path, setQuantity, setDesign)
    },
    selectFile() {
      this.$emit('selectFile')
    },
    cartAdd() {
      this.$emit('cartAdd')
    },
    speechView() {
      this.$emit('speechView')
    },
    speechHide() {
      this.$emit('speechHide')
    },
    templateSpeechView() {
      this.$emit('templateSpeechView')
    },
    templateSpeechHide() {
      this.$emit('templateSpeechHide')
    },
  },
}
</script>

<style scoped lang="scss">
.leftWrap {
  float: left;
  width: 750px;
  > .stepWrap {
    margin-top: 46px;
  }

  > .cartBtn {
    width: 574px;
    height: 45px;
    line-height: 45px;
    border-radius: 10px;
    background-color: #ff6699;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0 auto 50px;
    cursor: pointer;
  }
  .disabled {
    background-color: #777;
    pointer-events: none;
  }
}
</style>
