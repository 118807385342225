<template>
  <div class="answerWrap">
    <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
      <img src="@/assets/icon/photoClose.svg" alt="" />
    </div>
    <div class="answerBox">
      <h2>도안 수정요청 답변</h2>
      <textarea v-model="answer" aria-label="answer" />
      <p>({{ answer.length }}/1000)</p>
      <div class="save" @click="retouchAnswer()" @keydown="retouchAnswer()">제출</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    retouch: {
      type: Object,
    },
  },
  data() {
    return {
      answer: '',
    }
  },
  methods: {
    closeTab() {
      this.$emit('closeTab')
    },
    retouchAnswer() {
      if (!this.answer) {
        this.Message('답변 내용을 입력해주세요.', 0)
        return
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios
        .patch(
          this.Path.OrderRetouchAnswer,
          {
            id: this.retouch.id,
            payment_id: this.$route.query.payment_id,
            answer: this.answer,
          },
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('답변이 등록되었습니다.', 0)
            this.$emit('retouchAnswer', '도안수정')
            this.closeTab()
          }
        })
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.answerWrap {
  width: 996px;
  height: 750px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 9px #0000005e;

  > .closeTab {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > .answerBox {
    width: 889px;
    margin: 47px auto 0;

    > h2 {
      font-size: 20px;
      font-weight: 700;
      color: #ff6699;
      border-bottom: 2px solid #ff6699;
      padding-bottom: 13px;
      margin-bottom: 20px;
    }

    > textarea {
      display: block;
      width: 100%;
      height: 500px;
      padding: 20px;
      font-size: 18px;
      margin: 30px auto;
      border: 1px solid #000;
      resize: none;
      box-sizing: border-box;
      border-radius: 10px;
    }

    > p {
      text-align: right;
      margin-top: -28px;
    }

    > .save {
      width: 100%;
      height: 50px;
      line-height: 50px;
      color: #fff;
      background-color: #ff6699;
      font-size: 20px;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
