import TypeValue from '../../plugins/TypeValue';
<template>
  <div class="rankingWrap">
    <div class="myRanking">
      <div class="left">
        <span
          >{{ userInfo.name }}님의 등급은
          <span
            :class="{
              vip: userInfo.ranking === 0,
              nomal: userInfo.ranking === 2,
              new: userInfo.ranking === 1,
            }"
            >{{ TypeValue.User_Ranking(userInfo.ranking) }}</span
          >
          입니다.</span
        >
        <p v-if="userInfo.ranking === 0">
          <span
            >(VIP 유지 기간 :
            {{ moment(userInfo.vip_end_day).format('YY년 MM월 DD일') }} 까지)</span
          >
        </p>
      </div>
      <div class="right">
        <span
          >최근 3개월 이내 누적금액
          <span class="price">{{ Commas(accumulate_price) }} 원</span></span
        >
        <p>(누적금액은 구매확정된 주문건을 기준으로 계산됩니다.)</p>
      </div>
    </div>
    <div class="rankingDetailBox">
      <h2>회원등급 별 혜택 안내</h2>
      <div class="detail">
        <ul>
          <li>
            <div class="left">
              <div>
                <img src="@/assets/icon/ranking_0.svg" alt="" /> <span class="vip">VIP</span>
              </div>
            </div>
            <div class="right">
              <div class="explanation">
                <h3>등급 조건</h3>
                <p>누적 금액 2,000,000원 이상 구매 고객 <br />(최근 3개월 이내 누적금액)</p>
              </div>
              <div class="explanation">
                <h3>등급 혜택</h3>
                <p>전품목 30% 할인 혜택</p>
              </div>
              <div class="explanation">
                <h3>등급 유지기간</h3>
                <p>VIP 등급 전환 후 3개월</p>
              </div>
            </div>
          </li>
          <li>
            <div class="left">
              <div>
                <img src="@/assets/icon/ranking_2.svg" alt="" /> <span class="nomal">일반회원</span>
              </div>
            </div>
            <div class="right">
              <div class="explanation">
                <h3>등급 조건</h3>
                <p>누적 금액 100,000원 이상 구매 고객</p>
              </div>
              <div class="explanation">
                <h3>등급 혜택</h3>
                <p>전품목 10% 할인 혜택</p>
              </div>
            </div>
          </li>
          <li>
            <div class="left">
              <div>
                <img src="@/assets/icon/ranking_1.svg" alt="" /> <span class="new">신규회원</span>
              </div>
            </div>
            <div class="right">
              <div class="explanation">
                <h3>등급 조건</h3>
                <p>새로 가입해주신 고객</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      accumulate_price: 0,
    }
  },
  mounted() {
    this.getAccumulatePrice()
  },
  methods: {
    getAccumulatePrice() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios.get(this.Path.MyAccumulatePrice, config).then((res) => {
        if (res.data.success === true) {
          this.accumulate_price = res.data.data
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.rankingWrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  color: #707070;

  > .myRanking {
    width: 100%;
    background-color: #ffe1ec;
    border-radius: 10px;
    height: 179px;
    margin-top: 50px;
    margin-bottom: 40px;
    position: relative;

    > .left {
      position: absolute;
      left: 58px;
      top: 50%;
      transform: translate(0%, -50%);
      -webkit-transform: translate(0%, -50%);

      > span {
        font-size: 26px;
        > .vip {
          color: #ff6699;
          font-weight: bold;
        }
        > .nomal {
          color: #ffcc66;
          font-weight: bold;
        }
        > .new {
          color: #64ef8e;
          font-weight: bold;
        }
      }

      > p {
        font-size: 16px;
        color: #ff6699;
      }
    }

    > .right {
      position: absolute;
      right: 58px;
      top: 50%;
      transform: translate(0%, -50%);
      -webkit-transform: translate(0%, -50%);

      > span {
        color: #ff6699;
        font-size: 15px;

        > .price {
          font-size: 26px;
          font-weight: bold;
          margin-left: 20px;
        }
      }

      > p {
        font-size: 12px;
        text-align: right;
      }
    }
  }

  > .rankingDetailBox {
    margin-top: 10px;

    > h2 {
      font-size: 26px;
      border-bottom: 3px solid #ff6699;
    }

    > .detail {
      width: 100%;
      margin-top: 45px;

      li {
        width: 100%;
        height: 179px;
        border: 3px solid #ff6699;
        border-radius: 10px;
        margin-bottom: 45px;
        position: relative;

        > .left {
          position: absolute;
          top: 50%;
          width: 25%;
          height: 120px;
          border-right: 3px solid #ff3366;
          transform: translate(0%, -50%);
          -webkit-transform: translate(0%, -50%);

          > div {
            position: absolute;
            left: 50px;
            top: 50%;
            transform: translate(0%, -50%);
            -webkit-transform: translate(0%, -50%);

            > img {
              width: 37px;
              height: 37px;
              margin-right: 5px;
            }

            > span {
              font-size: 30px;
              font-weight: bold;
              vertical-align: middle;

              &.vip {
                color: #ff6699;
              }
              &.nomal {
                color: #ffcc66;
              }
              &.new {
                color: #64ef8e;
              }
            }
          }
        }

        > .right {
          position: absolute;
          top: 50%;
          right: 50px;
          width: 70%;
          height: 120px;
          transform: translate(0%, -50%);
          -webkit-transform: translate(0%, -50%);
          display: flex;
          justify-content: flex-start;

          > .explanation {
            margin-top: 25px;
            margin-left: 50px;
            > h3 {
              color: #ff6699;
              font-size: 20px;
            }
            > p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
