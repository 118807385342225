<template>
  <div class="pointWrap">
    <div class="availablePoint">
      <span class="left">내 포인트</span>
      <span class="right">
        <span>{{ Commas(available_point) }}</span>
        <div>
          <span>P</span>
        </div>
      </span>
    </div>
    <point-history :point-history-list="point_history_list" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pointHistory from '../../components/MyPage/PointHistory.vue'

export default {
  components: {
    pointHistory,
  },
  data() {
    return {
      point_history_list: [],
    }
  },
  mounted() {
    this.getPointHistory()
  },
  methods: {
    getPointHistory() {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      this.axios.get(this.Path.MyPointHistory, config).then((res) => {
        if (res.data.success === true) {
          this.point_history_list = res.data.data
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      userToken: 'UserInfo/userToken',
      available_point: 'Point/available_point',
    }),
  },
}
</script>

<style scoped lang="scss">
.pointWrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  color: #707070;

  > .availablePoint {
    width: 100%;
    background-color: #ffe1ec;
    border-radius: 10px;
    height: 179px;
    line-height: 179px;
    margin-top: 50px;
    margin-bottom: 40px;

    > .left {
      float: left;
      font-size: 26px;
      margin-left: 58px;
    }

    > .right {
      float: right;
      font-size: 26px;
      margin-right: 58px;

      > div {
        position: relative;
        width: 60px;
        height: 60px;
        border: 2px solid #ff6699;
        border-radius: 50%;
        float: right;
        margin-top: 60px;
        margin-right: 25px;

        > span {
          font-size: 26px;
          font-weight: bold;
          color: #fff;
          display: inline-block;
          width: 46px;
          height: 46px;
          background-color: #ff6699;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          line-height: 46px;
          text-align: center;
        }
      }

      > span {
        margin-right: 30px;
      }
    }
  }
}
</style>
