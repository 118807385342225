<template>
  <div class="letterWrap">
    <div class="popupBg" />
    <div class="letterBox">
      <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <div class="letter">
        <h2>투명 쉘 양면 인쇄시 유의사항</h2>
        <hr />
        <div class="textBox">
          <p class="content">
            <span
              >접착제 성분으로 인하여 앞, 뒷면 색상이 다르게 제작 될 수 있는 점 참고하여 제작 진행
              부탁드립니다.<br />
              해당 문제로 인하여 <span class="red">재제작 및 환불은 불가능한 점</span> 양해
              부탁드립니다.</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async closeTab() {
      this.$emit('close-tab')
    },
  },
}
</script>

<style scoped lang="scss">
.letterWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: block;

  > .popupBg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  > .letterBox {
    width: 608px;
    height: 260px;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 1001;

    > .closeTab {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    > .letter {
      width: 501px;
      margin: 47px auto 0;

      > h2 {
        font-size: 20px;
        font-weight: bold;
        color: #ff6699;
      }

      > hr {
        width: 100%;
        height: 2px;
        background-color: #ff6699;
        border: none;
        margin-top: 14px;
        margin-bottom: 20px;
      }

      > .textBox {
        margin-bottom: 17px;
        text-align: left;

        > .title {
          font-size: 18px;
          color: #ff6699;
        }

        > .content {
          font-size: 16px;
          color: #414141;

          .red {
            color: #ff6699;
          }
        }
      }
    }
  }
}
</style>
