<template>
  <div class="totalPriceBox">
    <h2>금액</h2>
    <ul class="clearFix">
      <li class="sumValue clearFix">
        <span class="priceLeft">총 상품 금액</span>
        <span class="priceRight">{{ Commas(sumValue) }} 원</span>
      </li>
      <li class="salePrice clearFix">
        <span class="priceLeft">할인금액</span>
        <span class="priceRight"
          ><span class="red"> - {{ Commas(totalSale) }} 원</span></span
        >
      </li>
      <li class="shippingFee clearFix">
        <span class="priceLeft">배송비</span>
        <span class="priceRight">{{ Commas(shippingFee) }} 원</span>
      </li>
      <li class="fullPrice clearFix">
        <span class="priceLeft">총 결제 금액</span>
        <span class="priceRight"
          ><span class="red">{{ Commas(fullPrice) }}</span> 원</span
        >
      </li>
      <li class="payCheck">
        <p>
          주문할 상품의 상품명, 상품가격, 배송정보를 확인하였으며,<br />
          구매에 동의 하시겠습니까?<span class="terms" @click="termsView()" @keydown="termsView()"
            >약관보기</span
          >
        </p>
        <input id="payCheck" v-model="payCheck" type="checkbox" @change="checkChange()" /><label
          for="payCheck"
          >동의합니다.</label
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    sumValue: {
      type: Number,
    },
    totalSale: {
      type: Number,
    },
    shippingFee: {
      type: Number,
    },
    fullPrice: {
      type: Number,
    },
  },
  data() {
    return {
      payCheck: false,
    }
  },
  methods: {
    termsView() {
      this.$emit('termsView')
    },
    checkChange() {
      this.$emit('checkChange', this.payCheck)
    },
  },
}
</script>

<style scoped lang="scss">
.totalPriceBox {
  width: 366px;
  float: right;

  h2 {
    font-size: 16px;
    color: #707070;
    text-align: left;
    font-weight: 400;
  }

  .red {
    color: #ff0c0c;
  }

  .terms {
    color: #000;
    margin-left: 5px;
    cursor: pointer;
  }

  > ul {
    width: 100%;
    border-top: 2px solid #ff6699;

    > li {
      margin-bottom: 20px;

      &.sumValue {
        height: 47px;
        line-height: 47px;
        font-size: 14px;
        color: #656565;
        border-bottom: 2px solid #ffe8f1;
        margin-bottom: 13px;
      }

      &.fullPrice {
        height: 49px;
        line-height: 49px;
        border-radius: 5px;
        background-color: #ffebf2;
      }

      > .priceLeft {
        float: left;
        text-indent: 10px;
        font-size: 14px;
        color: #707070;
      }

      > .priceRight {
        float: right;
        margin-right: 10px;
        font-size: 14px;
        color: #707070;
      }

      &.payCheck {
        input[type='checkbox'] {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;

          &:checked + label {
            &::before {
              content: '\2713';
              color: #ff6699;
              text-shadow: 0.5px 0.5px #ff6699;
              background: #fedbe8;
              border-color: #979797;
            }
          }
        }

        input[type='checkbox'] + label {
          display: inline-block;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          &::before {
            content: ' ';
            display: inline-block;
            width: 16px;
            height: 16px;
            line-height: 15px;
            margin: -2px 8px 0 0;
            text-align: center;
            vertical-align: middle;
            background: #fafafa;
            border: 1px solid #979797;
            border-radius: 5px;
          }
        }

        > p {
          font-size: 10px;
          color: #707070;
          margin-left: 10px;
          margin-bottom: 17px;
        }

        > label {
          margin-left: 10px;
          font-size: 10px;
          color: #707070;
        }
      }
    }
  }
}
</style>
