<template>
  <div class="step">
    <h2>스마트톡 선택</h2>
    <div class="itemBox clearFix">
      <ul class="itemBtns">
        <li
          class="itemBtn"
          :class="{ active: smartTok.empty > 0 }"
          @click="checkSmartTok('empty', smartTok.empty)"
          @keydown="checkSmartTok('empty', smartTok.empty)"
        >
          <div>
            <p>투명색</p>
            <hr />
            <img
              v-if="smartTok.empty > 0"
              class="item_img"
              src="@/assets/goods/smart_tok_empty_active.svg"
              alt=""
            />
            <img v-else class="item_img" src="@/assets/goods/smart_tok_empty_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: smartTok.white > 0 }"
          @click="checkSmartTok('white', smartTok.white)"
          @keydown="checkSmartTok('white', smartTok.white)"
        >
          <div>
            <p>흰색</p>
            <hr />
            <img
              v-if="smartTok.white > 0"
              class="item_img"
              src="@/assets/goods/smart_tok_white_active.svg"
              alt=""
            />
            <img v-else class="item_img" src="@/assets/goods/smart_tok_white_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: smartTok.black > 0 }"
          @click="checkSmartTok('black', smartTok.black)"
          @keydown="checkSmartTok('black', smartTok.black)"
        >
          <div>
            <p>검정색</p>
            <hr />
            <img
              v-if="smartTok.black > 0"
              class="item_img"
              src="@/assets/goods/smart_tok_black_active.svg"
              alt=""
            />
            <img v-else class="item_img" src="@/assets/goods/smart_tok_black_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    smartTok: {
      type: Object,
    },
  },
  methods: {
    checkSmartTok(color, num) {
      this.$emit('checkSmartTok', color, num)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .itemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 121px;
          margin: 26px auto 0;
          text-align: center;
          vertical-align: middle;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
            text-align: left;
          }

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin: 25px 10px;
            width: 100px;
            height: 100px;
            vertical-align: middle;
            float: right;
          }
        }
      }
    }
  }
}
</style>
