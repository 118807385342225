<template>
  <div class="stand">
    <h1>5. 접는 선 레이어 및 바 작업</h1>
    <!--  -->
    <!--  -->
    <div>
      <p>제품이 자립을 할 수 있게 접히는 영역을 나타냅니다.</p>
      <p>바 사이즈 : 가로 커팅 레이어 가로 X 세로 30mm~50mm 둥근사각형</p>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/bookmark_folding_1.png" alt="" />
      </div>
      <div class="img_1 right">
        <p>1. 커팅 영역 좌우, 하단 끝 쪽에 스탠드 홈 위치를 설정합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/bookmark_folding_2.png" alt="" />
      </div>
      <div class="img_2 right">
        <p>
          2. '사각형 도구'를 사용하여 임의의 크기 30X30(mm)의<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;둥글기
          20픽셀의 둥근사각형을 만듭니다.
        </p>
      </div>
    </div>
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/bookmark_folding_3.png" alt="" />
      </div>
      <div class="img_3 right">
        <p>
          3. 사각형의 가로 크기를 커팅 영역 가로에 맞추어 조절합니다.<br />&nbsp;&nbsp;&nbsp;&nbsp;(세로
          조절X)
        </p>
      </div>
    </div>
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/bookmark_folding_4.png" alt="" />
      </div>
      <div class="img_4 right">
        <p>4. 사각형과 커팅 영역의 가로가 연결되도록 채워 넣습니다.</p>
      </div>
    </div>
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/bookmark_folding_5.png" alt="" />
      </div>
      <div class="img_5 right">
        <p>5. 접는 선 레이어를 생성합니다.</p>
        <p>
          6. 생성한 레이어에 커팅 영역의 하단 끝에 맞추어<br />&nbsp;&nbsp;&nbsp;&nbsp;붉은색 접는
          선을 1~2픽셀로 표시합니다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.stand {
  text-align: center;
  h1 {
    width: 100%;
  }

  > div {
    margin-top: 40px;

    > img {
      margin-top: 20px;
    }

    > .img {
      margin-top: 30px;

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
    figure {
      margin-top: 10px;
    }
    p {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-top: 20px;

      span {
        color: #ff6699;
      }
    }

    &.img_half {
      > div {
        float: left;
        margin-left: 35px;
        text-align: left;

        &.img_1 {
          margin-top: 100px;
        }
        &.img_2 {
          margin-top: 200px;
        }
        &.img_3 {
          margin-top: 150px;
        }
        &.img_4 {
          margin-top: 200px;
        }
        &.img_5 {
          margin-top: 250px;
        }
      }
    }

    &.ex {
      > div {
        text-align: center;
      }

      > p {
        text-align: left;
        margin-left: 200px;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
