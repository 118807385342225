let env
if (window.location.host === 'web-test-1-dot-dn-web-339309.du.r.appspot.com') {
  env = 'development'
} else if (
  window.location.host === 'dongne-production-dot-dn-web-339309.du.r.appspot.com' ||
  window.location.host === 'www.dongne.cc' ||
  window.location.host === 'dongne.cc'
) {
  env = 'production'
} else {
  env = 'local'
}
const SubStr = {
  OrderNum: (value) => {
    let str
    if (value) {
      str = value.substr(0, 10)
    }

    return str
  },
  fileName: (file) => {
    let str
    if (file) {
      if (env === 'development' || env === 'local') {
        str = file.substr(76, file.length - 76)
      } else {
        str = file.substr(83, file.length - 83)
      }
    }
    return str
  },
  deleteFileName: (file) => {
    let str
    if (file) {
      if (env === 'development' || env === 'local') {
        str = file.substr(59, file.length - 59)
      } else {
        str = file.substr(66, file.length - 66)
      }
    }
    return str
  },
}
export default SubStr
