<template>
  <ul class="nav" @mouseleave="pageNav()" @focusout="pageNav()">
    <li class="logo" @mouseover="navBar(0)" @focus="navBar(0)">
      <router-link :to="{ name: 'index' }">
        <img src="@/assets/main/dongne_logo.svg" alt="" />
      </router-link>
    </li>
    <li
      v-for="(m, i) in menu"
      :key="i"
      :class="`navmenu_${i + 1}`"
      @mouseover="navBar(i + 1)"
      @focus="navBar(i + 1)"
      @mouseleave="subNavHide(i)"
      @focusout="subNavHide(i)"
    >
      <img v-show="i === 2" src="@/assets/main/nav_newIcon.svg" alt="" />
      <img v-show="i === 1" src="@/assets/main/nav_hotIcon.svg" alt="" />
      <span @click="routeMove(i, m.route_name)" @keydown="routeMove(i, m.route_name)">{{
        m.title
      }}</span>
      <div v-show="m.sub_menu.length > 0" class="subWrap">
        <ul class="subNav" :class="{ on: category_view === true }">
          <li
            v-for="(sub, j) in m.sub_menu"
            :key="j"
            class="subMenu"
            :class="{ active: j === sNum }"
            @mouseover.stop="subNavBar(j)"
            @focus="subNavBar(j)"
          >
            <a
              v-if="sub.category"
              class="category"
              @mouseover="categoryView()"
              @focus="categoryView()"
              @mouseleave="categoryHide()"
              @focusout="categoryHide()"
            >
              {{ sub.sub_title }}
              <img v-show="j === sNum" src="@/assets/main/main_subMenu_arrow.svg" alt="" />
              <div class="categoryWrap" :class="{ on: j === sNum }">
                <ul>
                  <li
                    v-for="(category, k) in sub.category"
                    :key="k"
                    :class="{ beta: j === 0 && k === 0 }"
                    @click="routeMove(i, category.route_name, category.route_params)"
                    @keydown="routeMove(i, category.route_name, category.route_params)"
                  >
                    {{ category.name }}
                  </li>
                </ul>
              </div>
            </a>
            <a
              v-else
              @click="routeMove(i, sub.route_name, sub.route_params, sub.route_query)"
              @keydown="routeMove(i, sub.route_name, sub.route_params, sub.route_query)"
            >
              {{ sub.sub_title }}
            </a>
          </li>
        </ul>
        <div v-for="(sub, j) in m.sub_menu" :key="j" class="imgBox" :class="{ on: j === sNum }">
          <img :src="sub.sub_img" alt="" />
        </div>
        <div class="imgBox">
          <img src="@/assets/main/subMenu_img_50.jpg" alt="" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    sNum: {
      type: Number,
    },
    menu: {
      type: Array,
    },
    category_view: {
      type: Boolean,
    },
  },
  methods: {
    pageNav() {
      this.$emit('pageNav')
    },
    navBar(i) {
      this.$emit('navBar', i)
    },
    subNavHide(i) {
      this.$emit('subHide', i)
    },
    subNavBar(i) {
      this.$emit('subNav', i)
    },
    categoryView() {
      this.$emit('categoryView')
    },
    categoryHide() {
      this.$emit('categoryHide')
    },
    routeMove(i, route_name, route_params, route_query) {
      this.$emit('routeMove', i, route_name, route_params, route_query)
    },
  },
}
</script>

<style scoped lang="scss">
.nav {
  float: left;

  > li {
    float: left;
    font-size: 20px;
    height: 88px;
    line-height: 88px;
    margin: 0 30px;
    position: relative;
    cursor: context-menu;

    &:first-child {
      margin-left: 0;
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }

    &.navmenu_2 {
      .subWrap {
        left: -107px;
      }
    }
    &.navmenu_3 {
      .subWrap {
        left: -240px;
      }
    }
    &.navmenu_4 {
      .subWrap {
        left: -391px;
      }
    }
    &.navmenu_5 {
      .subWrap {
        left: -525px;
      }
    }

    > img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    > span {
      color: #000;
    }

    > .subWrap {
      width: 630px;
      height: 0px;
      background-color: #fff;
      position: absolute;
      top: 88px;
      left: -10px;
      z-index: 101;
      /* opacity: 0; */
      border-radius: 15px;
      box-shadow: 0px 5px 10px #00000029;
      transition: 0.5s;
      overflow: hidden;

      > p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
      }

      > .subNav {
        float: left;
        width: 150px;
        height: 100%;
        position: relative;
        background: rgba(255, 255, 255, 0.8);
        z-index: 3;
        overflow: hidden;
        transition: 0.5s;

        &.on {
          width: 300px;
          border-radius: 0 15px 15px 0;
          &::after {
            opacity: 1;
          }
        }
        &::after {
          content: '';
          position: absolute;
          left: 150px;
          top: 0;
          width: 2px;
          height: 100%;
          background-color: #f2e3ea;
          z-index: -1;
          box-sizing: border-box;
          opacity: 0;
          transition: 0.5s;
        }

        > li {
          line-height: 24px;
          margin-bottom: 5px;
          font-size: 16px;
          margin-left: 16px;
          color: #707070;

          > a {
            display: block;
            width: 118px;
            // position: relative;
            padding: 5px;
            cursor: pointer;
          }

          > .category {
            > img {
              width: 13px;
              margin-top: -5px;
              // position: absolute;
              // left: 90px;
              // top: 50%;
              // transform: translate(0%, -50%);
            }
            > .categoryWrap {
              position: absolute;
              left: 137px;
              top: 15px;
              width: 170px;
              height: 95%;
              z-index: 2;
              box-sizing: border-box;
              padding: 20px;
              display: none;

              > ul {
                > li {
                  padding: 5px;

                  &.beta {
                    background: url('../../assets/main/beta.png') 110% -350% no-repeat;
                  }

                  &:hover {
                    border-left: 3px solid #ffcce3;
                  }
                }
              }
              &.on {
                display: block;
              }
            }
          }

          &.active {
            border-left: 3px solid #ff6699;
            color: #000;
          }

          &:first-child {
            margin-top: 34px;
          }
        }
      }

      > .imgBox {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;

        &.on {
          z-index: 1;
        }
      }
    }
  }
}
</style>
