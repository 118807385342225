<template>
  <div class="tableWrap clearFix">
    <ul v-if="params === 'Event'" class="eventPick clearFix">
      <li
        class="nowEvent"
        :class="{ active: $route.query.status === 'now' }"
        @click="eventStatus('now')"
        @keydown="eventStatus('now')"
      >
        진행 중인 이벤트
      </li>
      <li
        class="endEvent"
        :class="{ active: $route.query.status === 'end' }"
        @click="eventStatus('end')"
        @keydown="eventStatus('end')"
      >
        종료된 이벤트
      </li>
    </ul>
    <ul>
      <li
        v-for="(list, index) in listArray"
        :key="index"
        class="noticeBox"
        @click="detail(list)"
        @keydown="detail(list)"
      >
        <div class="notice_image">
          <p v-if="list.thumbnail" class="center">
            <img id="image" :src="list.thumbnail" alt="" />
          </p>
          <p v-else class="center">
            <img
              v-show="params === 'Notice'"
              id="image"
              src="@/assets/news/notice_basic.jpg"
              alt=""
            />
            <img
              v-show="params === 'Event'"
              id="image"
              src="@/assets/news/event_basic.jpg"
              alt=""
            />
          </p>
        </div>
        <p class="notice_title">
          {{ list.title }}
        </p>
        <!-- <p class="notice_text">{{list.text}}</p> -->
        <p v-if="params === 'Notice'" class="notice_date">
          <span>작성일 : {{ moment(list.createdAt).format('YYYY-MM-DD') }}</span>
        </p>
        <p v-else class="notice_date">
          <span>종료일 : ~ {{ moment(list.end_day).format('YYYY-MM-DD') }}</span>
        </p>
        <!-- </div> -->
      </li>
      <li v-if="listArray.length === 2 || listArray.length === 5" class="emptyBox" />
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    listArray: {
      type: Array,
    },
    params: {
      type: String,
    },
  },
  methods: {
    detail(list) {
      this.$emit('detailMove', list)
    },
    eventStatus(status) {
      this.$emit('eventStatus', status)
    },
  },
}
</script>

<style scoped lang="scss">
.tableWrap {
  width: 100%;
  border-top: none;
  margin: 40px auto 0px;

  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .noticeBox {
      width: 325px;
      height: 395px;
      margin-bottom: 32px;
      display: inline-block;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;

      > .notice_image {
        width: 325px;
        height: 325px;
        text-align: center;
        overflow: hidden;
        margin-bottom: 5px;
        position: relative;
        background-color: #fff;

        > .center {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          > img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 325px;
            height: auto;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            &.height {
              width: auto;
              max-height: 325px;
            }
          }
        }
      }

      > .notice_title {
        font-size: 16px;
        color: #707070;
        margin-top: 8px;
      }

      > .notice_date {
        font-size: 10px;
        color: #979797;
        margin-top: 6px;
      }
    }

    > .emptyBox {
      width: 325px;
      height: 380px;
      display: inline-block;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  .eventPick {
    margin-bottom: 115px;

    > li {
      float: left;
      width: 50%;
      text-align: center;
      height: 54px;
      line-height: 54px;
      font-size: 20px;
      box-sizing: border-box;
      cursor: pointer;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid #ff6699;
      color: #c7c7c7;

      &.active {
        border-top: 1px solid #ff6699;
        border-right: 1px solid #ff6699;
        border-left: 1px solid #ff6699;
        border-bottom: none;
        color: #ff6699;
      }
    }
  }
}
</style>
