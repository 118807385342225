<template>
  <div class="reviewPage">
    <h2>리뷰</h2>
    <div class="reviewWrap">
      <ul class="clearFix">
        <li
          :class="{ active: status === 'all' }"
          @click="review_type('all')"
          @keydown="review_type('all')"
        >
          전체 리뷰({{ reviewList.length }}개)
        </li>
        <li
          :class="{ active: status === 'photo' }"
          @click="review_type('photo')"
          @keydown="review_type('photo')"
        >
          포토 리뷰({{ photoList.length }}개)
        </li>
        <li v-if="userInfo.user_id" class="myReview">
          <router-link :to="{ name: 'MyPageReview', query: { page: 1, status: 'list' } }">
            내 리뷰보기
          </router-link>
        </li>
      </ul>
      <all-review
        v-if="status === 'all' && get_review === true"
        :review-array="tableData"
        @review-detail="reviewDetail"
      />
      <all-review
        v-if="status === 'photo' && get_review === true"
        :review-array="tableData"
        @review-detail="reviewDetail"
      />
      <page-list :paging-data="pagingData" :params="params" @page-btn="pageBtn" />
    </div>
    <review-detail
      v-if="detail_view === true"
      :review="detail_data"
      :img-num="imgNum"
      @close-tab="closeTab"
      @img-change="imgChange"
    />
  </div>
</template>

<script>
import AllReview from '../../components/Review/AllReview.vue'
import PhotoReview from '../../components/Review/PhotoReview.vue'
import ReviewDetail from '../Modal/ReviewDetail.vue'
import PageList from '../../components/Public/PageList.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    AllReview,
    // PhotoReview,
    ReviewDetail,
    PageList,
  },
  data() {
    return {
      params: '',
      status: 'all',
      get_review: false,
      reviewList: [],
      photoList: [],
      photoLength: 0,
      detail_view: false,
      detail_data: {},
      pagingData: {
        endPage: 5, // 끝 페이지
        startPage: 1, // 시작 페이지
        totalPage: 5, // 총 페이지 수
        totalItem: 0, // 받아온 주문내역의 수
        page_list_size: 5, // 한번에 몇개의 페이지가 보일건지
        page_size: 3, // 한 페이지에 몇개까지 보여줄건지
        curPage: 1, // 현재 페이지
        curSet: 0, // 몇번째 페이지리스트인가
        set_item: 0, // 페이지 리스트에 따른 아이템수
        start_range: 0, // 목록에서 데이터 불러올 시작값
        end_range: 0, // 목록에서 데터터 불러올 끝값
      },
      imgArray: [],
      imgNum: 0,
      tableData: [],
      list_data: {},
    }
  },
  watch: {
    async $route(to, from) {
      this.params = this.$route.params.type
      this.getReview(this.pagingData.curPage)
    },
    async status(to, from) {
      this.pagingData.curPage = 1
      if (!this.list_data[from]) {
        this.list_data[from] = {}
      }
      if (from === 'all') {
        this.list_data[from] = this.reviewList
        this.setPagingData(this.reviewList, this.pagingData.curPage)
      } else {
        this.list_data[from] = this.photoList
        this.setPagingData(this.photoList, this.pagingData.curPage)
      }
    },
  },
  mounted() {
    this.params = this.$route.params.type
    this.getReview(this.pagingData.curPage)
  },
  methods: {
    //   리뷰목록 불러오기
    async getReview(curPage) {
      // let skip = Math.ceil(curPage / this.pagingData.page_list_size)
      await this.axios
        .get(`${this.Path.getReview}?type=${this.TypeValue.Goods_Type(this.$route.params.type)}`)
        .then(async (res) => {
          if (res.data.success === true) {
            let photoList = []
            await res.data.data.forEach(async (data) => {
              if (data.file_url.length > 0) {
                photoList.push(data)
              }
            })
            this.reviewList = res.data.data
            this.photoList = photoList
            if (this.status === 'all') {
              await this.setPagingData(this.reviewList, curPage)
            } else {
              await this.setPagingData(this.photoList, curPage)
            }
          }
        })
    },
    // 리뷰 상세 보기
    reviewDetail(review, index) {
      this.detail_data = review
      this.imgNum = index
      this.detail_view = true
    },
    // 리뷰 상세 닫기
    closeTab() {
      this.detail_view = false
    },
    // 리뷰 타입 변경
    review_type(type) {
      this.status = type
    },
    // 다른 이미지 보기
    imgChange(imgNum) {
      this.imgNum = imgNum
    },
    // 페이지 이동 이벤트 (type: prev, next, move, page: 이동할 페이지 번호 또는 +-1)
    async pageBtn(type, page) {
      let move_page
      let next_set = false
      // type이 prev일 경우 이전 페이지로 이동
      if (type === 'prev') {
        move_page = this.pagingData.curPage * 1 - page
        // 이동해야될 페이지 번호가 pagingData.page_list_size의 배수일 경우 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 0) {
          next_set = true
        }
      } else if (type === 'next') {
        move_page = this.pagingData.curPage * 1 + page
        // 이동해야될 페이지 번호가 pagingData.page_list_size로 나누었을때 값이 1이라면 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 1) {
          next_set = true
        }
      } else if (type === 'move') {
        move_page = page
      }
      // list_data의 속성값
      if (next_set === true) {
        // list_data에 cur_set의 값이 있을경우 list_data의 cur_set속성값을 reviewList으로 설정
        if (this.list_data[this.status]) {
          if (this.status === 'all') {
            this.reviewList = this.list_data[this.status]
            this.setPagingData(this.reviewList, move_page)
          } else {
            this.photoList = this.list_data[this.status]
            this.setPagingData(this.photoList, move_page)
          }
        } else {
          // 없을경우 api에서 주문내역값 조회
          if (this.reviewList.length === 0) {
            // 주문내역 조회하였을떄 값이 없다면 메세지 호출
            this.Message('리뷰내역이 없습니다.', 0, 0)
            return
          }
        }
      }
      if (!this.list_data[this.status]) {
        this.list_data[this.status] = {}
      }
      if (this.status === 'all') {
        this.list_data[this.status] = this.reviewList
        this.setPagingData(this.reviewList, move_page)
      } else {
        this.list_data[this.status] = this.photoList
        this.setPagingData(this.photoList, move_page)
      }
    },
    // 페이지 로딩 또는 라우트가 바뀔때바다 pagingData값을 새로 세팅
    async setPagingData(list, page) {
      this.pagingData = await this.paging(this.pagingData, list, page)
      let data = []
      for (let i = this.pagingData.start_range; i < this.pagingData.end_range; i++) {
        data.push(list[i])
      }
      this.tableData = data

      this.get_review = true
    },
    async paging(data, items, page) {
      // curpage
      data.curPage = page * 1

      // curset
      data.curSet = Math.ceil(data.curPage / data.page_list_size)

      // totalitem
      data.totalItem = items.length

      // startpage
      data.startPage = (data.curSet - 1) * data.page_list_size + 1

      // endpage
      data.endPage = data.startPage + data.page_list_size - 1

      // totalpage
      data.totalPage = Math.ceil(data.totalItem / data.page_size)

      // setitem
      data.set_item = (data.curSet - 1) * (data.page_list_size * data.page_size)

      // start_range
      data.start_range = (data.curPage - 1) * data.page_size

      // end_range
      data.end_range = data.curPage * data.page_size

      if (data.totalItem < data.end_range) {
        data.end_range = data.totalItem
      }
      return data
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
    }),
  },
}
</script>

<style scoped lang="scss">
.reviewPage {
  width: 1160px;
  margin: 0 auto;
  position: relative;

  > h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #707070;
    margin-bottom: 40px;
    margin-top: 100px;
  }

  > .reviewWrap {
    > ul {
      border-bottom: 2px solid #ff6699;
      height: 35px;

      > li {
        float: left;
        border-right: 1px solid #707070;
        margin-left: 10px;
        padding-right: 10px;
        font-size: 20px;
        color: #c7c7c7;
        cursor: pointer;

        &.active {
          color: #707070;
        }

        &:nth-child(2) {
          border-right: none;
        }

        &.myReview {
          border-right: none;
          float: right;

          > a {
            color: #707070;
          }
        }
      }
    }
  }
}
</style>
