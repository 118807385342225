<template>
  <div class="pointHistoryBox">
    <div class="pointHistoryList" v-if="pointHistoryList.length > 0">
      <div
        class="pointHistory clearFix"
        :class="{ used: pointHistory.point_id }"
        v-for="(pointHistory, index) in pointHistoryList"
        :key="index"
      >
        <div class="left">
          <p class="pointHistoryDate">
            {{ moment(pointHistory.createdAt).format('YYYY-MM-DD HH:mm') }}
          </p>
          <h2 class="pointHistoryTitle">{{ pointHistory.history }}</h2>
        </div>
        <div class="right">
          <p class="pointHistoryPrice" :class="{ used: pointHistory.point_id }">
            <span v-show="pointHistory.point_id">-</span>
            <span v-show="!pointHistory.point_id">+</span>{{ Commas(pointHistory.point) }} P
          </p>
        </div>
      </div>
    </div>
    <div class="notpointHistory" v-else>
      <p>포인트 내역이 없습니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pointHistoryList: {
      type: Array,
    },
  },
}
</script>

<style scoped lang="scss">
.pointHistoryBox {
  width: 100%;
  margin: 50px auto;

  > .pointHistoryList {
    width: 100%;
    margin: 20px auto;
    height: 500px;
    overflow-y: auto;

    > .pointHistory {
      padding: 20px;
      border: 2px solid #60bc8c;
      border-radius: 10px;
      background-color: #fff;
      margin-bottom: 20px;

      &.used {
        border-color: #ff6699;
      }

      > .left {
        float: left;

        > .pointHistoryTitle {
          color: #707070;
          font-size: 23px;
        }

        > .pointHistoryType {
          margin: 10px 0;
        }
      }

      > .right {
        float: right;

        > p {
          font-size: 25px;
          margin-top: 8px;
          color: #60bc8c;
          font-weight: bold;

          &.used {
            color: #ff6699;
          }
        }
      }
    }
  }

  > .notpointHistory {
    height: 200px;

    > p {
      margin-top: 100px;
      font-size: 20px;
      text-align: center;
    }
  }
}
</style>
