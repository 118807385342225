<template>
  <div class="customWrap">
    <div class="carouselBox">
      <h1><span>템플릿 주문하기</span></h1>
      <carousel
        :items-to-show="3"
        :wrap-around="true"
        :transition="500"
        :navigation-next-label="nextLabel"
        :navigation-prev-label="prevLabel"
      >
        <slide v-for="(item, index) in itemList" :key="index">
          <div
            class="slideBox"
            @click="routeMove(item.route_name, item.route_params)"
            @keydown="routeMove(item.route_name, item.route_params)"
          >
            <img :src="item.img" alt="" />
          </div>
        </slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import '../../assets/css/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  data() {
    return {
      itemList: [
        {
          img: require('../../assets/main/main_custom_carabiner.png'),
          name: '카라비너',
          route_name: 'Template',
          route_params: {
            type: 'Carabiner',
          },
        },
        {
          img: require('../../assets/main/main_custom_wheel.png'),
          name: '관람차',
          route_name: 'Template',
          route_params: {
            type: 'Wheel',
          },
        },
        {
          img: require('../../assets/main/main_custom_fan.png'),
          name: '투명부채',
          route_name: 'Template',
          route_params: {
            type: 'Fan',
          },
        },
        {
          img: require('../../assets/main/main_custom_dwarf.png'),
          name: '오뚝이 아크릴',
          route_name: 'Template',
          route_params: {
            type: 'Dwarf',
          },
        },
      ],
    }
  },
  methods: {
    routeMove(name, params) {
      this.$emit('routeMove', name, params)
    },
  },
}
</script>

<style scoped lang="scss">
.customWrap {
  width: 100%;
  margin: 50px auto;
  padding: 10px 0;

  h1 {
    text-align: center;
    color: #000;
    margin-bottom: 20px;

    > span {
      padding: 0 3px;
      border-bottom: 3px solid #ff6699;
    }
  }

  > .carouselBox {
    width: 1160px;
    margin: 50px auto;

    .slideBox {
      cursor: pointer;
      width: 318px;
      height: 220px;

      > img {
        width: 318px;
        height: 220px;
        border-radius: 15px;
      }
    }
  }
}
</style>
