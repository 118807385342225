<template>
  <div class="reviewBox">
    <div>
      <h2>리뷰작성</h2>
      <div class="imgBox clearFix">
        <div class="img">
          <img :src="require(`@/assets/cart/cart_product_img_${list.type}.png`)" alt="" />
        </div>
        <div class="dn">
          <span>{{ moment(list.createdAt).format('YYYY-MM-DD') }}</span>
          <p>{{ list.type }}</p>
        </div>
      </div>
      <div class="starBox clearFix">
        <div>
          <p>별점을 선택해주세요.</p>
          <img
            v-for="(star, index) in starNum"
            v-show="checkStar > index"
            :key="index"
            src="@/assets/icon/star_color_fill.svg"
            class="star"
            alt=""
            @click="starChange(star)"
            @keydown="starChange(star)"
          />
          <img
            v-for="(star, index) in starNum"
            v-show="checkStar <= index"
            :key="index"
            src="@/assets/icon/star_color_empty.svg"
            class="star"
            alt=""
            @click="starChange(star)"
            @keydown="starChange(star)"
          />
        </div>

        <div v-for="(file, index) in fileNum" :key="index" class="fileUpload">
          <div v-if="!image[index]" class="area">
            <label :for="`reviewFile_${index}`" class="labelImg"
              ><img src="@/assets/icon/file_uploadIcon.svg" alt=""
            /></label>
            <label :for="`reviewFile_${index}`" class="inputLabel" id="reviewLabel"
              >이미지 업로드</label
            >
            <input
              :id="`reviewFile_${index}`"
              ref="reviewFile"
              type="file"
              class="file-input"
              name="files"
              @change="selectFile(index)"
            />
          </div>
          <div v-else class="imgArea">
            <img class="img" :src="image[index]" alt="" />
            <img
              class="closeImg"
              src="@/assets/icon/closeIcon.png"
              alt=""
              @click="deleteImage(index)"
              @keydown="deleteImage(index)"
            />
          </div>
        </div>
      </div>
      <div class="reviewWrite">
        <h3>상품 리뷰를 작성해 주세요.</h3>
        <div>
          <div>
            <input
              id="reviewAddTitle"
              v-model="title"
              type="text"
              class="title"
              placeholder="제목"
            />
            <textarea id="reviewAddContent" v-model="content" name="content" placeholder="내용" />
          </div>
        </div>
      </div>
      <div class="completeBtn" @click="save()" @keydown="save()">작성완료</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Object,
    },
    fileNum: {
      type: Number,
    },
    starNum: {
      type: Number,
    },
    checkStar: {
      type: Number,
    },
    image: {
      type: Array,
    },
  },
  data() {
    return {
      title: '',
      content: '',
    }
  },
  methods: {
    starChange(star) {
      this.$emit('starChange', star)
    },
    selectFile(index) {
      this.$emit('selectFile', index)
    },
    deleteImage(index) {
      this.$emit('deleteFile', index)
    },
    save() {
      this.$emit('saveReview', this.title, this.content)
    },
  },
}
</script>

<style scoped lang="scss">
.reviewBox {
  position: fixed;
  width: 996px;
  height: 809px;
  left: 50%;
  top: 50%;
  z-index: 205;
  margin-left: -498px;
  margin-top: -405px;
  border-radius: 10px;
  box-shadow: 0px 3px 9px #0000005e;
  background-color: #fff;

  > div {
    width: 889px;
    margin: 47px auto 0;

    > h2 {
      font-size: 20px;
      font-weight: 700;
      color: #ff6699;
      border-bottom: 2px solid #ff6699;
      padding-bottom: 13px;
    }

    > .imgBox {
      width: 100%;
      height: 110px;
      border-bottom: 2px solid #fedbe8;

      > .img {
        float: left;
        height: 100px;
        margin-top: 3px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > .dn {
        float: left;
        margin-top: 27px;
        margin-left: 10px;
        font-size: 14px;
        color: #979797;

        > .bar {
          display: inline-block;
          padding-right: 8px;
          border-right: 1px solid #979797;
          margin-right: 4px;
        }

        > span {
          > .orderNum {
            font-weight: 500;
          }
        }

        > p {
          font-size: 18px;
          color: #656565;
          margin-top: 3px;
        }
      }
    }

    > .starBox {
      height: 115px;
      font-size: 2px;
      border-bottom: 2px solid #fedbe8;

      > div {
        float: left;
        margin-top: 30px;
        margin-right: 20px;

        > p {
          margin-bottom: 6px;
          font-weight: 500;
          font-size: 14px;
          color: #ff6699;
          margin-left: 10px;
        }

        > img {
          cursor: pointer;

          &:first-of-type {
            margin-left: 10px;
          }
        }
      }

      > .fileUpload {
        width: 120px;
        height: 90px;
        border: 1.5px solid #ff6699;
        border-radius: 10px;
        margin-top: 10px;
        margin-left: 20px;
        position: relative;
        float: left;

        > .area {
          width: 80%;
          margin: 0 auto;
          margin-top: 25px;
          text-align: center;

          > input {
            display: none;
          }

          > label {
            > img {
              width: 14px;
              height: 17px;
              cursor: pointer;
            }
          }

          > .inputLabel {
            display: block;
            font-size: 14px;
            color: #ff6699;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            margin-top: 2px;
          }
        }

        > .imgArea {
          width: 90px;
          height: 90px;
          margin-left: 5px;
          border-radius: 5px;
          overflow: hidden;

          > .img {
            width: 100%;
            height: 100%;
          }

          > .closeImg {
            position: absolute;
            top: 2px;
            right: 2px;
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }

        &:nth-child(6) {
          display: none;
        }
      }
    }

    > .reviewWrite {
      margin-top: 30px;

      > h3 {
        font-size: 14px;
        font-weight: 500;
        color: #ff6699;
        margin-left: 10px;
      }

      > div {
        border: 1px solid #fedbe8;
        width: 865px;
        height: 328px;
        margin: 19px auto 0;

        > div {
          width: 800px;
          margin: 0 auto;

          > input {
            display: block;
            border: none;
            width: 100%;

            &:focus {
              outline: none;
            }
          }

          > textarea {
            resize: none;
            width: 100%;
            border: none;
            margin-top: 18px;
            font-size: 16px;
            height: 220px;

            &:focus {
              outline: none;
            }
          }

          > .title {
            border-bottom: 1px solid #fedbe8;
            font-size: 19px;
            line-height: 37px;
            margin-top: 16px;
            height: 37px;
          }
        }
      }
    }

    > .completeBtn {
      width: 100%;
      height: 48px;
      color: #fff;
      background-color: #ff6699;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}
</style>
