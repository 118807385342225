<template>
  <ul class="service">
    <li v-if="!userInfo.user_id">
      <span id="loginBtn" @click="loginBtn()" @keydown="loginBtn()">로그인/가입</span>
    </li>
    <li
      v-if="userInfo.user_id"
      class="userMenus"
      @mouseover="userMenu()"
      @focus="userMenu()"
      @mouseleave="userMenuHide()"
      @focusout="userMenuHide()"
    >
      <span>{{ userInfo.user_id }}님</span>
      <div class="userMenu">
        <ul>
          <li class="point clearFix" @mouseover="userMenuBar(1)" @focus="userMenuBar(1)">
            <router-link :class="{ redActive: uNum === 1 }" :to="{ name: 'MyPagePoint' }">
              <div class="pointBox">
                <p>{{ Commas(available_point) }}</p>
                <img src="@/assets/icon/pointIcon.png" alt="" />
              </div>
            </router-link>
          </li>
          <li class="ranking clearFix" @mouseover="userMenuBar(0)" @focus="userMenuBar(0)">
            <router-link :to="{ name: 'MyPageRanking' }">
              <div class="rankingBox">
                <img :src="require(`@/assets/icon/ranking_${userInfo.ranking}.svg`)" alt="" />
                <p
                  :class="{
                    vip: userInfo.ranking === 0,
                    nomal: userInfo.ranking === 2,
                    new: userInfo.ranking === 1,
                  }"
                >
                  {{ TypeValue.User_Ranking(userInfo.ranking) }}
                </p>
              </div>
            </router-link>
          </li>
          <li @mouseover="userMenuBar(2)" @focus="userMenuBar(2)">
            <router-link :class="{ redActive: uNum === 2 }" :to="{ name: 'MyPageCoupon' }">
              내 쿠폰
            </router-link>
          </li>
          <li @mouseover="userMenuBar(3)" @focus="userMenuBar(3)">
            <router-link
              :class="{ redActive: uNum === 3 }"
              :to="{ name: 'MyPageInfo', params: { check: 'check' } }"
            >
              회원정보수정
            </router-link>
          </li>
          <li @mouseover="userMenuBar(4)" @focus="userMenuBar(4)">
            <router-link
              :class="{ redActive: uNum === 4 }"
              :to="{ name: 'MyPageReview', query: { page: 1, status: 'list' } }"
            >
              나의 리뷰
            </router-link>
          </li>
          <li
            :class="{ redActive: uNum === 5 }"
            @mouseover="userMenuBar(5)"
            @focus="userMenuBar(5)"
            @click="logout()"
            @keydown="logout()"
          >
            로그아웃
          </li>
        </ul>
      </div>
    </li>
    <li>
      <a @click="orderListBtn()" @keydown="orderListBtn()">주문내역</a>
    </li>
    <li>
      <router-link :to="{ name: 'Review' }"> 리뷰 </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Service' }"> 고객센터 </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Cart' }"> 장바구니 </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    uNum: {
      type: Number,
    },
    available_point: [Number, String],
  },
  computed: {
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
    }),
  },
  methods: {
    userMenu() {
      this.$emit('userMenu')
    },
    userMenuHide() {
      this.$emit('userMenuHide')
    },
    loginBtn() {
      this.$emit('loginBtn')
    },
    userMenuBar(i) {
      this.$emit('userMenuBar', i)
    },
    logout() {
      this.$emit('logout')
    },
    orderListBtn() {
      this.$emit('orderListBtn')
    },
  },
}
</script>

<style scoped lang="scss">
.service {
  float: right;
  font-size: 12px;

  > .userMenus {
    position: relative;

    > .userMenu {
      position: absolute;
      top: 60px;
      left: 5px;
      width: 130px;
      height: 0px;
      background-color: #fff;
      border-radius: 10px;
      transition: 0.5s;
      box-shadow: 0px 3px 6px #00000029;
      overflow: hidden;

      li {
        line-height: 23px;
        margin-left: 16px;
        font-size: 12px;
        color: #707070;
        margin-bottom: 2px;
        cursor: pointer;

        &.redActive {
          color: #ff6699;
        }

        &:first-child {
          margin-top: 10px;
        }

        > .redActive {
          color: #ff6699;
        }

        &.point {
          margin-left: 0;
          margin-bottom: 5px;
          .pointBox {
            width: 80%;
            height: 30px;
            border: 1px solid #ff6699;
            margin: 0 auto;
            border-radius: 5px;
            position: relative;
            > img {
              position: absolute;
              top: 50%;
              right: 5px;
              transform: translate(0%, -50%);
              -webkit-transform: translate(0%, -50%);
              width: 25px;
              height: 25px;
              margin-left: 5px;
            }
            > p {
              position: absolute;
              left: 5px;
              top: 50%;
              font-size: 14px;
              transform: translate(0%, -50%);
              -webkit-transform: translate(0%, -50%);
            }
          }
        }

        &.ranking {
          .rankingBox {
            > img {
              width: 20px;
              height: 20px;
            }
            > p {
              display: inline-block;
              vertical-align: middle;
              font-size: 14px;
              margin-left: 2px;
              font-weight: bold;

              &.vip {
                color: #ff6699;
              }
              &.nomal {
                color: #ffcc66;
              }
              &.new {
                color: #64ef8e;
              }
            }
          }
        }
      }
    }
  }

  > li {
    float: left;
    line-height: 88px;

    &:last-child {
      a {
        border-right: none;
      }
    }

    > span {
      border-right: 1px solid #707070;
      padding: 0 14px;
      color: #707070;
      cursor: pointer;
    }

    > a {
      border-right: 1px solid #707070;
      padding: 0 14px;
      color: #707070;
      cursor: pointer;
    }
  }
}
</style>
