<template>
  <div :class="{ step: path === 'Basic' || path === 'Template', SpecialStep: path === 'Special' }">
    <h2>조립/포장 선택</h2>
    <div
      v-if="
        type === '아크릴 카드텍' ||
        type === '자유형 책갈피' ||
        type === '아크릴 램프' ||
        type === '아크릴 바닥 부품' ||
        path === 'Template' ||
        type === '부착형 아크릴' ||
        type === '쉐이커 키링'
      "
      class="cardPackingBox clearFix"
    >
      <ul class="packingBtns">
        <li
          class="packingBtn"
          :class="{ active: packingNum === 0 }"
          @click="checkpacking(0, '묶음포장')"
          @keydown="checkpacking(0, '묶음포장')"
        >
          <div>
            <p>묶음 포장</p>
            <span>(하나의 OPP에 한 번에 포장하여 발송)</span>
            <hr />
            <img
              v-if="packingNum === 0"
              class="item_img"
              src="@/assets/goods/card_none_active.svg"
              alt=""
            />
            <img v-else class="packing_img" src="@/assets/goods/card_none_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="packingBtn"
          :class="{ active: packingNum === 1 }"
          @click="checkpacking(1, '개별포장')"
          @keydown="checkpacking(1, '개별포장')"
        >
          <div v-if="path !== 'Template'">
            <p>개별 포장</p>
            <span v-show="type !== '아크릴 램프'">(부자재옵션에서 선택한 부자재를 조립 후</span>
            <span v-show="type !== '아크릴 램프'">각각 포장하여 발송)</span>
            <span v-show="type === '아크릴 램프'">(램프 전용 박스에 각 포장하여 발송)</span>
            <hr :class="{ lamp_hr: type === '아크릴 램프' }" />
            <img
              v-if="packingNum === 1"
              class="item_img"
              src="@/assets/goods/card_opp_active.svg"
              alt=""
            />
            <img v-else class="packing_img" src="@/assets/goods/card_opp_nomal.svg" alt="" />
          </div>
          <div v-if="path === 'Template'">
            <p>개별 포장</p>
            <span>(각각 OPP에 포장하여 발송)</span>
            <hr class="template_each_hr" />
            <img
              v-if="packingNum === 1"
              class="item_img"
              src="@/assets/goods/card_opp_active.svg"
              alt=""
            />
            <img v-else class="packing_img" src="@/assets/goods/card_opp_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="
        type !== '아크릴 카드텍' &&
        type !== '자유형 책갈피' &&
        type !== '아크릴 램프' &&
        type !== '아크릴 바닥 부품' &&
        path !== 'Template' &&
        type !== '부착형 아크릴' &&
        type !== '아크릴 블럭' &&
        type !== '입체 아크릴 블럭' &&
        type !== '쉐이커 키링'
      "
      class="packingBox clearFix"
    >
      <div>
        <input
          id="packingNone"
          type="checkbox"
          :checked="packingNum === 0"
          @change="checkpacking(0, '조립없음')"
        /><label for="packingNone">조립/포장 없음</label>
      </div>
      <ul class="packingBtns">
        <li
          class="packingBtn"
          :class="{ active: packingNum === 1 }"
          @click="checkpacking(1, '부자재조립')"
          @keydown="checkpacking(1, '부자재조립')"
        >
          <div>
            <p>부자재만 조립</p>
            <span>(부자재 옵션에서 선택한 부자재를 조립 후 포장하여 발송)</span>
            <hr />
            <img
              v-if="packingNum === 1"
              class="packing_img"
              src="@/assets/goods/packing_부자재_active.svg"
              alt=""
            />
            <img v-else class="packing_img" src="@/assets/goods/packing_부자재_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="packingBtn"
          :class="{ active: packingNum === 2 }"
          @click="checkpacking(2, '개별포장')"
          @keydown="checkpacking(2, '개별포장')"
        >
          <div>
            <p>개별포장</p>
            <span v-show="path === 'Basic'">(부자재옵션에서 선택한 부자재를 조립 후</span>
            <span v-show="path === 'Basic'"> 각각 포장하여 발송)</span>
            <span v-show="path === 'Special'"
              >(부자재옵션에서 선택한 부자재를 조립 후 각각 포장하여 발송)</span
            >
            <hr />
            <img
              v-if="packingNum === 2"
              class="packing_img"
              src="@/assets/goods/packing_개별_active.svg"
              alt=""
            />
            <img v-else class="packing_img" src="@/assets/goods/packing_개별_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <div v-if="type === '아크릴 블럭' || type === '입체 아크릴 블럭'" class="packingBox clearFix">
      <ul class="packingBtns block">
        <li
          class="packingBtn"
          :class="{ active: packingNum === 0 }"
          @click="checkpacking(0, '개별포장')"
          @keydown="checkpacking(0, '개별포장')"
        >
          <div>
            <p>개별포장</p>
            <span v-show="path === 'Basic'">(OPP봉투에 개별포장되어 발송)</span>
            <hr />
            <img
              v-if="packingNum === 0"
              class="packing_img"
              src="@/assets/goods/packing_개별_active.svg"
              alt=""
            />
            <img v-else class="packing_img" src="@/assets/goods/packing_개별_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    packingNum: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  methods: {
    checkpacking(i, packing) {
      this.$emit('checkPacking', 'packing', i, packing)
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  label {
    color: #707070;
    font-size: 12px;
  }

  > .cardPackingBox {
    font-weight: 400;
    position: relative;

    > .packingBtns {
      float: left;
      margin-top: 8px;

      > li {
        width: 280px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;
        text-align: center;

        &:last-child {
          margin-right: 0;

          > div {
            margin: 12px auto 0;

            hr {
              margin-top: 5px;

              &.lamp_hr {
                margin-top: 23px;
              }
              &.template_each_hr {
                margin-top: 23px;
              }
            }
          }
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 234px;
          height: 100px;
          margin: 12px auto 0;
          text-align: left;

          > p {
            font-size: 16px;
            color: #707070;
            text-align: left;
            margin-bottom: 10px;
          }

          > span {
            font-size: 12px;
            color: #707070;
            display: block;
            text-align: left;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 23px;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 100px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  > .packingBox {
    font-weight: 400;
    position: relative;

    > .packingBtns {
      float: left;
      margin-top: 8px;

      > li {
        width: 280px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        &:first-child {
          > div {
            > img {
              margin-top: 14px;
            }
          }
        }

        > div {
          width: 233px;
          margin: 12px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
            text-align: left;
            margin-bottom: 10px;
          }

          > span {
            font-size: 12px;
            color: #707070;
            display: block;
            text-align: left;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin-top: 25px;
          }
        }
      }

      &.block {
        > li {
          > div {
            > img {
              margin-top: 35px;
            }
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  label {
    color: #707070;
    font-size: 12px;
  }

  > .packingBox {
    font-weight: 400;

    > div {
      margin-bottom: 5px;
    }

    > .packingBtns {
      float: left;

      > li {
        width: 185px;
        height: 200px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        &:first-child {
          > div {
            margin: 12px auto 0;
          }
        }

        > div {
          width: 162px;
          margin: 12px auto 0;
          text-align: center;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
            text-align: left;
          }

          > span {
            display: block;
            font-size: 10px;
            color: #707070;
            text-align: left;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 10px;
          }

          > img {
            width: 60px;
            height: 100px;
            margin-top: 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

input[type='checkbox'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      content: ' ';
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 15px;
      margin: -2px 8px 0 0;
      text-align: center;
      vertical-align: middle;
      background: #fafafa;
      border: 1px solid #979797;
      border-radius: 5px;
    }
  }

  &:checked {
    + label {
      &::before {
        content: '\2713';
        color: #ff6699;
        text-shadow: 0.5px 0.5px #ff6699;
        background: #fedbe8;
        border-color: #979797;
      }
    }
  }
}
</style>
