<template>
  <div :class="{ step: path === 'Basic', SpecialStep: path === 'Special' }">
    <h2>군번줄 색상 선택</h2>
    <div class="colorWrap">
      <div class="colorBox">
        <div class="default">
          <div
            :class="{ active: tagColorNum === 0 }"
            @click="checkTagColor(0, '일반')"
            @keydown="checkTagColor(0, '일반')"
          />
        </div>
        <div class="line" />
        <div class="color">
          <div
            v-for="(tag, index) in dog_tag_color"
            :key="index"
            :style="{ backgroundColor: tag.color }"
            :class="{ active: tagColorNum === index + 1 }"
            @click="checkTagColor(index + 1, tag.name)"
            @keydown="checkTagColor(index + 1, tag.name)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
    },
    tagColorNum: {
      type: Number,
    },
  },
  data() {
    return {
      dog_tag_color: [
        {
          name: '빨강',
          color: '#f32f2f',
        },
        {
          name: '진분홍',
          color: '#f54385',
        },
        {
          name: '연분홍',
          color: '#fcc9d6',
        },
        {
          name: '피치',
          color: '#f9a993',
        },
        {
          name: '주황',
          color: '#ff7d44',
        },
        {
          name: '노랑',
          color: '#ffe167',
        },
        {
          name: '연두',
          color: '#76df68',
        },
        {
          name: '민트',
          color: '#96dfc9',
        },
        {
          name: '하늘',
          color: '#4bbef5',
        },
        {
          name: '남색',
          color: '#2d2d9c',
        },
        {
          name: '연보라',
          color: '#c89fdb',
        },
        {
          name: '흰색',
          color: '#ffffff',
        },
        {
          name: '회색',
          color: '#979797',
        },
        {
          name: '검정',
          color: '#000000',
        },
      ],
    }
  },
  methods: {
    checkTagColor(i, color) {
      this.$emit('checkTagColor', 'tagColor', i, color)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .colorWrap {
    width: 100%;
    border: 2px solid #bcbcbc;
    height: 178px;
    border-radius: 10px;

    > .colorBox {
      width: 95%;
      margin: 30px auto;

      > .default {
        width: 57px;
        float: left;

        > div {
          width: 57px;
          height: 57px;
          border-radius: 3px;
          background-color: #c7c7c7;
          box-sizing: border-box;
          cursor: pointer;

          &.active {
            border: 2px solid #f25ca2;
            background-image: url('../../../assets/goods/dog_tag_color_check.png');
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }

      > .line {
        float: left;
        width: 2px;
        height: 123px;
        background-color: #e6e6e6;
        margin: 0 10px;
      }

      > .color {
        width: 453px;
        height: 125px;
        float: left;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > div {
          width: 57px;
          height: 57px;
          border: 1px solid #bcbcbc;
          border-radius: 3px;
          box-sizing: border-box;
          cursor: pointer;

          &.active {
            border: 2px solid #f25ca2;
            background-image: url('../../../assets/goods/dog_tag_color_check.png');
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .colorWrap {
    width: 100%;
    border: 2px solid #bcbcbc;
    height: 118px;
    border-radius: 10px;

    > .colorBox {
      width: 95%;
      margin: 20px auto;

      > .default {
        width: 38px;
        float: left;

        > div {
          width: 38px;
          height: 38px;
          border-radius: 3px;
          background-color: #c7c7c7;
          box-sizing: border-box;
          cursor: pointer;

          &.active {
            border: 2px solid #f25ca2;
            background-image: url('../../../assets/goods/dog_tag_color_check.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 90%;
          }
        }
      }

      > .line {
        float: left;
        width: 2px;
        height: 81px;
        background-color: #e6e6e6;
        margin: 0 10px;
      }

      > .color {
        width: 300px;
        height: 85px;
        float: left;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > div {
          width: 38px;
          height: 38px;
          border: 1px solid #bcbcbc;
          border-radius: 3px;
          box-sizing: border-box;
          cursor: pointer;

          &.active {
            border: 2px solid #f25ca2;
            background-image: url('../../../assets/goods/dog_tag_color_check.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 90%;
          }
        }
      }
    }
  }
}
</style>
