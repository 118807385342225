<template>
  <div class="guideWrap">
    <div class="step first">
      <guide-caution :type="type" />
    </div>
    <div class="step">
      <guide-size :type="type" />
    </div>
    <div class="step">
      <guide-layer :type="type" />
    </div>
    <div v-show="type === '아크릴 바닥 부품'" class="step">
      <guide-floor-hole />
    </div>
    <div class="step">
      <guide-white :type="type" />
    </div>
    <div class="step">
      <guide-cutting :type="type" />
    </div>
    <div v-show="type === '아크릴 스탠드' || type === '아크릴 램프'" class="step">
      <guide-stand :type="type" />
    </div>
    <div v-show="type === '쉐이커 키링'" class="step">
      <frame-hole />
    </div>
    <div v-show="type === '아크릴 키링' || type === '쉐이커 키링'" class="step">
      <guide-hole :type="type" />
    </div>
    <div v-show="type === '자유형 책갈피'" class="step">
      <guide-folding />
    </div>
    <div v-show="type === '부착형 아크릴'" class="step">
      <location-layer />
    </div>
    <div v-show="type === '아크릴 블럭'" class="step">
      <cutting-lower />
    </div>
    <div class="step">
      <guide-finish :type="type" />
    </div>
    <div v-show="type !== '부착형 아크릴'" class="step">
      <side-caution :type="type" />
    </div>
    <div class="step last">
      <AI_Caution :type="type" />
    </div>
    <top-btn />
  </div>
</template>

<script>
import GuideCaution from '../../components/Guide/GuideCaution.vue'
import GuideSize from '../../components/Guide/GuideSize.vue'
import GuideLayer from '../../components/Guide/GuideLayer.vue'
import GuideWhite from '../../components/Guide/GuideWhite.vue'
import GuideCutting from '../../components/Guide/GuideCutting.vue'
import GuideHole from '../../components/Guide/GuideHole.vue'
import GuideStand from '../../components/Guide/GuideStand.vue'
import GuideFolding from '../../components/Guide/GuideFolding.vue'
import GuideFinish from '../../components/Guide/GuideFinish.vue'
import SideCaution from '../../components/Guide/SideCaution.vue'
import AI_Caution from '../../components/Guide/AI_Caution.vue'
import TopBtn from '../../components/Public/TopBtn.vue'
import GuideFloorHole from '../../components/Guide/FloorHole.vue'
import LocationLayer from '../../components/Guide/LocationLayer.vue'
import CuttingLower from '../../components/Guide/CuttingLower.vue'
import FrameHole from '../../components/Guide/FrameHole.vue'

export default {
  components: {
    GuideCaution,
    GuideSize,
    GuideLayer,
    GuideWhite,
    GuideCutting,
    GuideHole,
    GuideFolding,
    GuideFinish,
    SideCaution,
    AI_Caution,
    GuideStand,
    TopBtn,
    GuideFloorHole,
    LocationLayer,
    CuttingLower,
    FrameHole,
  },
  data() {
    return {
      params: '',
      type: '',
    }
  },
  watch: {
    $route(to, from) {
      this.paramsCheck()
    },
  },
  mounted() {
    this.paramsCheck()
  },
  methods: {
    paramsCheck() {
      this.params = this.$route.params.type
      if (this.params === 'Cham') {
        this.type = '아크릴 키링'
      } else if (this.params === 'Stand') {
        this.type = '아크릴 스탠드'
      } else if (this.params === 'Card') {
        this.type = '아크릴 카드텍'
      } else if (this.params === 'Bookmark') {
        this.type = '자유형 책갈피'
      } else if (this.params === 'FloorParts') {
        this.type = '아크릴 바닥 부품'
      } else if (this.params === 'LampStand') {
        this.type = '아크릴 램프'
      } else if (this.params === 'Attach') {
        this.type = '부착형 아크릴'
      } else if (this.params === 'Block') {
        this.type = '아크릴 블럭'
      } else if (this.params === 'ShakerKeyring') {
        this.type = '쉐이커 키링'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.guideWrap {
  width: 1160px;
  margin: 0 auto;

  > .step {
    border-bottom: 3px solid #ff6699;
    padding-bottom: 100px;
    margin-top: 100px;

    &.first {
      padding-bottom: 30px;
    }

    &.last {
      border-bottom: none;
      padding-bottom: 200px;
    }
  }
}
</style>
