<template>
  <div class="orderTotal clearFix">
    <h1>장바구니</h1>
    <div v-if="products.length > 0" class="totalBox">
      <product-table
        :path="path"
        :products="products"
        :select-check="selectCheck"
        :is-check-all="isCheckAll"
        @product-check="productCheck"
        @check-all="checkAll"
      />
    </div>
    <div v-else class="empty">
      <p>장바구니가 비었습니다.</p>
    </div>
    <div class="deleteBtn">
      <span @click="selectDelete()" @keydown="selectDelete()">선택상품 삭제</span>
    </div>
  </div>
</template>

<script>
import ProductTable from '../Public/ProductTable.vue'

export default {
  components: {
    ProductTable,
  },
  props: {
    products: {
      type: Array,
    },
    selectCheck: {
      type: Array,
    },
    isCheckAll: {
      type: Boolean,
    },
  },
  data() {
    return {
      path: '', // 현재 route name
    }
  },
  mounted() {
    this.path = this.$route.name
  },
  methods: {
    checkAll(check) {
      if (check === true) {
        this.$emit('allSelect')
      } else {
        this.$emit('allNone')
      }
    },
    productCheck(select) {
      this.$emit('productCheck', select)
    },
    selectDelete() {
      this.$emit('selectDelete')
    },
  },
}
</script>

<style scoped lang="scss">
.orderTotal {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;

  h1 {
    text-align: center;
    color: #454545;
    font-weight: 700;
    font-size: 26px;
    width: 160px;
    margin: 50px auto 40px;
  }

  > .totalBox {
    text-align: center;

    > .tableWrap {
      width: 100%;
      margin: 0 auto;
      border-collapse: collapse;
    }
  }

  .empty {
    height: 300px;

    p {
      line-height: 200px;
    }
  }

  .deleteBtn {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    > span {
      display: block;
      width: 106px;
      height: 25px;
      border: 1px solid #ffe1ec;
      border-radius: 5px;
      text-align: center;
      font-size: 12px;
      line-height: 25px;
      cursor: pointer;
      color: #656565;
    }
  }
}
</style>
