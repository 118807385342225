<template>
  <div class="cartWrap">
    <cart-list
      :products="products"
      :select-check="selectCheck"
      :is-check-all="isCheckAll"
      @product-check="productCheck"
      @select-delete="productDelete"
      @all-select="allSelect"
      @all-none="allNone"
    />
    <cart-price :sum-value="sumValue" :shipping-fee="shippingFee" :full-price="fullPrice" />
    <ul class="btnBox clearFix">
      <li class="shopping" @click="goHome()" @keydown="goHome()">홈으로 돌아가기</li>
      <li class="buyBtn" @click="goPayment()" @keydown="goPayment()">구매 하기</li>
    </ul>
  </div>
</template>

<script>
import CartList from '../../components/Cart/CartList.vue'
import CartPrice from '../../components/Cart/CartPrice.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    CartList,
    CartPrice,
  },
  data() {
    return {
      products: [],
      selectCheck: [], // 선택한 상품
      isCheckAll: true, // all 체크 여부
    }
  },
  computed: {
    // 배송비
    shippingFee() {
      let shippingFee = 3500
      if (this.sumValue >= 50000) {
        shippingFee = 0
      }
      return shippingFee
    },
    // 선택된 상품의 총 가격
    sumValue() {
      let sumValue = 0
      for (let i = 0; i < this.selectCheck.length; i++) {
        sumValue += this.selectCheck[i].price * 1
      }
      return sumValue
    },
    // 선택된 상품의 가격 + 배송비
    fullPrice() {
      let fullPrice = this.sumValue + this.shippingFee
      return fullPrice
    },
    checkId() {
      let checkId = []
      if (this.selectCheck.length > 0) {
        for (let i = 0; i < this.selectCheck.length; i++) {
          checkId.push(this.selectCheck[i].id)
        }
      }
      return checkId
    },
    ...mapGetters({
      userToken: 'UserInfo/userToken',
    }),
  },
  async mounted() {
    await this.indexedDB.initializeData()
    await this.getProduct()
  },
  methods: {
    // 장바구니에 담긴 상품 목록 호출
    async getProduct() {
      this.products = await this.indexedDB.getOrderFromDB()
      let ticketPageCheck = false
      let ticketPageId = []
      let ticketCheck = false
      let ticketId = []
      let lampCheck = false
      let lampId = []
      await this.products.forEach(async (data, index) => {
        if (data.type === '디페스타 입장권 패키지') {
          ticketPageCheck = true
          await ticketPageId.push(data.id)
        } else if (data.type === '디페스타 입장권') {
          ticketCheck = true
          await ticketId.push(data.id)
        }
        // else if (data.type === '아크릴 램프') {
        //   lampCheck = true
        //   await lampId.push(data.id)
        // }
      })
      if (ticketPageCheck === true) {
        await this.getTicketQuantity('디페스타 입장권 패키지', ticketPageId)
      }
      if (ticketCheck === true) {
        await this.getTicketQuantity('디페스타 입장권', ticketId)
      }
      // if (lampCheck === true) {
      //   await this.getTicketQuantity('아크릴 램프', lampId)
      // }
      this.selectCheck = this.products
    },
    // 한정수량 상품 조회
    async getTicketQuantity(type, idArray) {
      await this.axios.get(`${this.Path.GetTicketQuantity}?type=${type}`).then(async (res) => {
        if (res.data.success === true) {
          if (res.data.data <= 0) {
            await idArray.forEach(async (id) => {
              await this.indexedDB.deleteOrderFromDB(id)
            })
            this.Message(`${type} 상품이 품절되었습니다.`, 1)
          }
        }
      })
    },
    // 선택한 상품 삭제
    async productDelete() {
      await this.checkId.forEach(async (id) => {
        await this.indexedDB.deleteOrderFromDB(id)
      })
      this.products = await this.indexedDB.getOrderFromDB()
      this.checkId = []
      this.selectCheck = []
    },
    // 모든 상품이 체크되거나 상품 체크 해제했을때 전체 체크박스 변경
    productCheck(select) {
      this.selectCheck = select
      if (this.products.length === this.selectCheck.length) {
        this.isCheckAll = true
      } else {
        this.isCheckAll = false
      }
    },
    // 전체 선택
    allSelect() {
      this.selectCheck = this.products
    },
    // 전체 해제
    allNone() {
      this.selectCheck = []
    },
    // 메인페이지로 이동
    goHome() {
      this.$router.push({
        name: 'index',
      })
    },
    // 결제 페이지로 이동
    async goPayment() {
      if (this.selectCheck.length === 0) {
        this.Message('상품을 선택해주세요.', 0)
        return
      }
      if (!this.$store.state.UserInfo.userToken) {
        this.emitter.emit('loginView')
        return
      }
      let ticket_package_quantity = 0
      let ticket_quantity = 0
      let lamp_quantity = 0
      await this.indexedDB.selectOrderClear()
      await this.selectCheck.forEach(async (select) => {
        if (select.type === '디페스타 입장권 패키지') {
          ticket_package_quantity += select.order_quantity
        } else if (select.type === '디페스타 입장권') {
          ticket_quantity += select.order_quantity
        }
        //  else if (select.type === '아크릴 램프') {
        //   lamp_quantity += select.order_quantity
        // }
        await this.indexedDB.selectOrderToDB(select)
      })
      if (ticket_package_quantity > 0) {
        let check = await this.ticketPackageCount('디페스타 입장권 패키지', ticket_package_quantity)
        if (check === false) {
          this.Message('디페스타 입장권 패키지 구매 수량이 남은 수량보다 많습니다.', 0)
          return
        }
      }

      if (ticket_quantity > 0) {
        let check = await this.ticketPackageCount('디페스타 입장권', ticket_quantity)
        if (check === false) {
          this.Message('디페스타 입장권 구매 수량이 남은 수량보다 많습니다.', 0)
          return
        }
      }

      // if (lamp_quantity > 0) {
      //   let check = await this.ticketPackageCount('아크릴 램프', lamp_quantity)
      //   if (check === false) {
      //     this.Message('아크릴 램프 구매 수량이 남은 수량보다 많습니다.', 0)
      //     return
      //   }
      // }

      await this.$router.push({
        name: 'PaymentReady',
      })
    },
    async ticketPackageCount(type, quantity) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      let check
      await this.axios
        .patch(`${this.Path.TicketCount}?type=${type}&quantity=${quantity}`, {}, config)
        .then((res) => {
          check = res.data.data
        })
      return check
    },
  },
}
</script>

<style scoped lang="scss">
.cartWrap {
  width: 1160px;
  margin: 70px auto 80px;
  height: auto;
  font-family: 'Noto Sans KR';

  > .btnBox {
    width: 460px;
    margin: 40px auto 110px;

    > li {
      cursor: pointer;
      font-size: 12px;
      text-align: center;
      margin-right: 24px;
      width: 137px;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;

      &.shopping {
        float: left;
        background-color: #ffe1ec;
        color: #656565;
      }

      &.buyBtn {
        float: right;
        background-color: #ff6699;
        color: #fff;
      }
    }
  }
}
</style>
