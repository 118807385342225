<template>
  <div>
    <point-container />
  </div>
</template>

<script>
import PointContainer from '../../containers/MyPage/PointContainer.vue'

export default {
  components: {
    PointContainer,
  },
}
</script>

<style></style>
