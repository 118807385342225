<template>
  <div class="btn-cover">
    <div
      v-show="pagingData.curPage > 1"
      class="page-btn"
      @click="pageBtn('prev', 1)"
      @keydown="pageBtn('prev', 1)"
    >
      <img src="@/assets/icon/listIcon_left.svg" alt="" />
    </div>
    <div
      v-for="(page, index) in pageCount"
      :key="index"
      class="page-count"
      :class="{ active: page === pagingData.curPage }"
      @click="pageBtn('move', page)"
      @keydown="pageBtn('move', page)"
    >
      <span>{{ page }}</span>
    </div>
    <div
      v-if="nextCheck === true"
      class="page-btn"
      @click="pageBtn('next', 1)"
      @keydown="pageBtn('next', 1)"
    >
      <img src="@/assets/icon/listIcon_right.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pagingData: {
      type: Object,
    },
    params: {
      type: String,
    },
  },
  computed: {
    pageCount() {
      let listLeng = this.pagingData.endPage
      let listSize = this.pagingData.startPage
      let page = []
      if (this.pagingData.endPage > this.pagingData.totalPage) {
        listLeng = this.pagingData.totalPage
      }
      for (let i = listSize; i < listLeng + 1; i++) {
        page.push(i)
      }
      return page
    },
    nextCheck() {
      let check = true
      if (
        this.pagingData.endPage > this.pagingData.totalPage &&
        this.pagingData.curPage === this.pagingData.totalPage
      ) {
        check = false
      }
      return check
    },
  },
  methods: {
    nextPage() {
      // if (this.$route.query.page) {
      //   this.$router.push({
      //     name: this.$route.name,
      //     params: { type: this.params },
      //     query: {
      //       page: this.$route.query.page * 1 + 1,
      //       status: this.$route.query.status,
      //       search: this.$route.query.search,
      //       start: this.$route.query.start,
      //       end: this.$route.query.end,
      //     },
      //   })
      // } else {
      //   this.curPage += 1
      // }
      this.$emit('pageBtn', 'next', 1)
    },
    prevPage() {
      // if (this.$route.query.page) {
      //   this.$router.push({
      //     name: this.$route.name,
      //     params: { type: this.params },
      //     query: {
      //       page: this.$route.query.page * 1 - 1,
      //       status: this.$route.query.status,
      //       search: this.$route.query.search,
      //       start: this.$route.query.start,
      //       end: this.$route.query.end,
      //     },
      //   })
      // } else {
      //   this.curPage -= 1
      // }
      this.$emit('pageBtn', 'prev', 1)
    },
    pageBtn(type, page) {
      // if (this.$route.query.page) {
      //   this.$router.push({
      //     name: this.$route.name,
      //     params: { type: this.params },
      //     query: {
      //       page,
      //       status: this.$route.query.status,
      //       search: this.$route.query.search,
      //       start: this.$route.query.start,
      //       end: this.$route.query.end,
      //     },
      //   })
      // } else {
      //   this.curPage = page
      // }
      this.$emit('pageBtn', type, page)
    },
  },
}
</script>

<style scoped lang="scss">
.btn-cover {
  margin-top: 57px;
  text-align: center;
  margin-bottom: 100px;

  span {
    font-size: 13px;
    font-weight: 700;
  }

  .page-btn {
    display: inline-block;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .page-count {
    display: inline-block;
    width: 36px;
    height: 36px;
    border: 1px solid #c7c7c7;
    background-color: #fff;
    border-radius: 3px;
    text-align: center;
    line-height: 27px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0 4px;

    &.active {
      border: 1px solid #ff6699;
      > span {
        color: #ff6699;
      }
    }

    > span {
      display: inline-block;
      font-size: 19px;
      color: #c7c7c7;
      font-weight: 400;
    }
  }
}
</style>
