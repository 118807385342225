export const Drag = {
  namespaced: true,
  state: () => ({
    dragMessage: '구멍을 원하는 위치로 드래그 해주세요!',
    dragInfo: {
      width: '150px',
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 95,
    },
  }),
  mutations: {
    DRAG_MESSAGE(state, data) {
      state.dragMessage = data
    },
    DRAG_INFO(state, data) {
      state.dragInfo.top = data.top
      state.dragInfo.left = data.left
    },
  },
  actions: {
    dragMessage({ commit }, data) {
      commit('DRAG_MESSAGE', data)
    },
    dragInfo({ commit }, data) {
      commit('DRAG_INFO', data)
    },
  },
  getters: {
    dragMessage(state) {
      return state.dragMessage
    },
    dragInfo(state) {
      return state.dragInfo
    },
  },
}
