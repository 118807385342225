const TypeValue = {
  // 굿즈 타입
  Goods_Type: (params) => {
    let type
    if (params === 'Cham') {
      type = '아크릴 키링'
    } else if (params === 'Stand') {
      type = '아크릴 스탠드'
    } else if (params === 'Card') {
      type = '아크릴 카드텍'
    } else if (params === 'Canvas') {
      type = '일러스트 캔버스'
    } else if (params === 'Block') {
      type = '아크릴 블럭'
    } else if (params === 'Items') {
      type = '부자재 별도 구매'
    } else if (params === 'Quoin') {
      type = '아크릴 키링'
    } else if (params === 'LampStand') {
      type = '아크릴 램프'
    } else if (params === 'FloorParts') {
      type = '아크릴 바닥 부품'
    } else if (params === 'Bookmark') {
      type = '자유형 책갈피'
    } else if (params === 'Dwarf') {
      type = '오뚝이 아크릴'
    } else if (params === 'Fan') {
      type = '투명 부채'
    } else {
      type = ''
    }
    return type
  },
  // 결제 수단
  Payment_Method: (payment) => {
    let type
    if (payment === 'card') {
      type = '신용카드'
    } else if (payment === 'vbank') {
      type = '가상계좌'
    } else if (payment === 'cultureland') {
      type = '문화상품권'
    }
    return type
  },
  // 환불계좌 체크
  Check_Code: (code, text) => {
    let type
    if (code === 'BK04') {
      type = '004'
    } else if (code === 'BK23') {
      type = '023'
    } else if (code === 'BK39') {
      type = '039'
    } else if (code === 'BK34') {
      type = '034'
    } else if (code === 'BK03') {
      type = '003'
    } else if (code === 'BK11') {
      type = '011'
    } else if (code === 'BK31') {
      type = '031'
    } else if (code === 'BK32') {
      type = '032'
    } else if (code === 'BK02') {
      type = '002'
    } else if (code === 'BK07') {
      type = '007'
    } else if (code === 'BK88') {
      type = '088'
    } else if (code === 'BK48') {
      type = '048'
    } else if (code === 'BK81') {
      if (text === '외환은행') {
        type = '005'
      } else {
        type = '081'
      }
    } else if (code === 'BK20') {
      type = '020'
    } else if (code === 'BK71') {
      type = '071'
    } else if (code === 'BK37') {
      type = '037'
    } else if (code === 'BK12') {
      type = '012'
    } else if (code === 'BK90') {
      type = '090'
    } else if (code === 'BK89') {
      type = '089'
    } else if (code === 'BK53') {
      type = '027'
    }
    return type
  },
  // 유저 등급
  User_Ranking: (ranking) => {
    let type
    if (ranking === 0) {
      type = 'VIP'
    } else if (ranking === 1) {
      type = '신규회원'
    } else if (ranking === 2) {
      type = '일반회원'
    }
    return type
  },
  // 쿠폰 할인 타입
  Coupon_SaleType: (sale_type) => {
    let type
    if (sale_type === '퍼센트할인') {
      type = '%'
    } else {
      type = '원'
    }
    return type
  },
  // 결제 수단
  Payment_Status: (status) => {
    let type
    if (status === 'ready') {
      type = '결제대기'
    } else if (status === 'paid') {
      type = '결제완료'
    } else if (status === 'cancel') {
      type = '주문취소'
    } else if (status === 'request') {
      type = '환불신청중'
    } else if (status === 'refund_complete') {
      type = '환불완료'
    } else if (status === 'confirmation') {
      type = '구매확정'
    }
    return type
  },
  api_url: () => {
    let url
    if (window.location.host === 'web-test-1-dot-dn-web-339309.du.r.appspot.com') {
      url = 'https://dongne-api-dot-dn-web-339309.du.r.appspot.com/'
    } else if (
      window.location.host === 'dongne-production-dot-dn-web-339309.du.r.appspot.com' ||
      window.location.host === 'www.dongne.cc' ||
      window.location.host === 'dongne.cc'
    ) {
      url = 'https://dongne-api-production-dot-dn-web-339309.du.r.appspot.com'
    } else {
      url = 'http://192.168.0.35:3000'
    }

    return url
  },
}
export default TypeValue
