<template>
  <div class="caution">
    <h1>{{ type }} 제작 가이드</h1>
    <p>
      [ 원본 이미지가 <span>300dpi 미만</span>일 경우, <span>화질 저하</span>가 일어날 수 있습니다.
      ]
    </p>
    <p>[ 모든 작업은 <span>300dpi 이상</span>에서 작업을 권장합니다. ]</p>
    <p>[ 최대 해상도 지원은 <span>720dpi</span>입니다. ]</p>
    <p>[ 이미지 생상 모드는 <span>CMYK</span>로 작업해 주세요. ]</p>
    <p>
      [ 아래 가이드를 마치신 후 작업 파일을 <span>ZIP 파일</span>로 압축해 업로드 해주시기 바랍니다.
      ]
    </p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.caution {
  > h1 {
    width: 100%;
    text-align: center;
    border-bottom: 3px solid #ff6699;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  > p {
    font-size: 25px;
    color: #707070;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;

    > span {
      color: #ff6699;
    }
  }
}
</style>
