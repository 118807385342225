<template>
  <div class="stand">
    <h1>5. 스탠드바 작업</h1>
    <!--  -->
    <!--  -->
    <div v-show="type === '아크릴 스탠드'">
      <p>아크릴 스탠드를 세울 수 있도록 스탠드 바를 커팅 레이어에 작업합니다.</p>
      <p>
        <strong>아크릴 스탠드: <span>가로 10mm 세로 5mm 직사각형</span></strong>
      </p>
    </div>
    <div v-show="type === '아크릴 램프'">
      <p>아크릴을 램프에 꽂을 수 있도록 스탠드 바를 커팅 레이어에 작업합니다.</p>
      <p>
        <strong>아크릴 스탠드: <span>가로 70mm 세로 10mm 직사각형</span></strong>
      </p>
    </div>
    <!--  -->
    <div class="img_half clearFix" v-show="type === '아크릴 스탠드'">
      <div class="left">
        <img src="@/assets/guide/stand_stand_1.png" alt="" />
      </div>
      <div class="img_1 right">
        <p>1. '사각형 도구'를 사용하여 10X5(mm)의 사각형을 만듭니다.</p>
        <p>2. 커팅 레이어 아래쪽 끝에 스탠드 바 위치를 설정합니다.</p>
        <p>3. 커팅 레이어와 사각형을 ctrl+E를 눌러 병합합니다.</p>
      </div>
    </div>
    <div class="img_half clearFix" v-show="type === '아크릴 램프'">
      <div class="left">
        <img src="@/assets/guide/lamp_stand_1.png" alt="" />
      </div>
      <div class="img_1 right">
        <p>1. '사각형 도구'를 사용하여 70X10(mm)의 사각형을 만듭니다.</p>
        <p>2. 커팅 레이어 아래쪽 끝에 스탠드 바 위치를 설정합니다.</p>
        <p>3. 커팅 레이어와 사각형을 ctrl+E를 눌러 병합합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/stand_stand_2.png" alt="" />
      </div>
      <div class="img_2 right">
        <p>
          ※스탠드 바와 커팅 영역이 떨어져 있을 경우, <br />&nbsp;&nbsp;&nbsp;스탠드 바 와 커팅
          영역이 연결되도록 채워 넣습니다.
        </p>
      </div>
    </div>
    <!--  -->
    <div>
      <p>※커팅 영역은 스탠드 바 위쪽에 위치해야 합니다.</p>
    </div>
    <div class="ex clearFix">
      <p class="p1">[올바른 예시]</p>
      <div class="center">
        <img src="@/assets/guide/stand_stand_3.png" alt="" />
      </div>
    </div>
    <div class="ex clearFix">
      <p class="p1">[잘못된 예시]</p>
      <div class="cener">
        <img src="@/assets/guide/stand_stand_4.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.stand {
  text-align: center;
  h1 {
    width: 100%;
  }

  > div {
    margin-top: 40px;

    > img {
      margin-top: 20px;
    }

    > .img {
      margin-top: 30px;

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
    figure {
      margin-top: 10px;
    }
    p {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-top: 20px;

      span {
        color: #ff6699;
      }
    }

    &.img_half {
      > div {
        float: left;
        margin-left: 35px;
        text-align: left;

        &.img_1 {
          margin-top: 30px;
        }
        &.img_2 {
          margin-top: 150px;
        }
      }
    }

    &.ex {
      > div {
        text-align: center;
      }

      > p {
        text-align: left;
        margin-left: 200px;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
