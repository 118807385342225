<template>
  <div class="messageWrap">
    <div class="bg" />
    <div class="messageBox">
      <span>{{ message }}</span>
      <div v-if="type === 'confirm'" class="closeBtn" @click="closeMessage" @keydown="closeMessage">
        취소
      </div>
      <div class="okBtn" @click="urlBtn(code, data)" @keydown="urlBtn(code, data)">확인</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    async closeMessage() {
      this.$emit('closeMessage')
    },
    urlBtn(code, data) {
      if (code === 0) {
        this.closeMessage()
      } else if (code === 1) {
        window.location.reload()
      } else if (code === 2) {
        this.$router.push({
          name: 'Cart',
        })
        this.closeMessage()
      } else if (code === 3) {
        this.$router.push({
          name: 'PaymentComplet',
        })
        this.closeMessage()
      } else if (code === 4) {
        this.$router.push({
          name: 'index',
        })
        this.closeMessage()
      } else if (code === 5) {
        window.location.href = '/orderInfo/1'
      } else if (code === 6) {
        this.$router.push({
          name: 'MyPageInfo',
          params: { check: 'complet' },
        })
        this.closeMessage()
      } else if (code === 8) {
        this.$router.push({
          name: 'PaymentReady',
        })
        this.closeMessage()
      }
    },
  },
  computed: {
    ...mapGetters({
      message: 'Message/message',
      code: 'Message/code',
      type: 'Message/type',
      data: 'Message/data',
    }),
  },
}
</script>

<style scoped lang="scss">
.messageWrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  .bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9001;
  }

  .messageBox {
    width: 500px;
    height: 250px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    z-index: 9002;
    > span {
      width: 90%;
      text-align: center;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 18px;
      font-weight: bold;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }

    > .closeBtn {
      position: absolute;
      width: 80px;
      height: 38px;
      line-height: 38px;
      color: #ff6699;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #ff6699;
      text-align: center;
      bottom: 20px;
      left: 20px;
      font-weight: 400;
      cursor: pointer;
    }
    > .okBtn {
      position: absolute;
      width: 80px;
      height: 38px;
      line-height: 38px;
      color: #fff;
      background-color: #ff6699;
      border-radius: 5px;
      text-align: center;
      bottom: 20px;
      right: 20px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>
