import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt'
import moment from './plugins/Moment'
import Message from './plugins/Message'
import Formula from './plugins/Formula'
import AutoSize from './plugins/AutoSize'
import Cart from './plugins/Cart'
import dataURItoBlob from './plugins/ToBlob'
import Commas from './plugins/Commas'
import TypeValue from './plugins/TypeValue'
import SubStr from './plugins/SubStr'
import ErrorMessage from './plugins/ErrorMessage'
import PagingData from './plugins/PagingData'
import axios from './plugins/Axios'
import Path from './plugins/Path'
// import VCalendar from 'v-calendar'
import indexedDB from './plugins/IndexedDB'
import VueGtag from 'vue-gtag'

const emitter = mitt()

const app = createApp(App)
app.use(store)
app.use(router)
app.use(
  VueGtag,
  {
    config: {
      id: 'G-750589YRBG',
    },
  },
  router,
)
// app.use(VCalendar, {})
app.use({})
app.config.globalProperties.moment = moment
app.config.globalProperties.emitter = emitter
app.config.globalProperties.Message = Message
app.config.globalProperties.Formula = Formula
app.config.globalProperties.AutoSize = AutoSize
app.config.globalProperties.Cart = Cart
app.config.globalProperties.dataURItoBlob = dataURItoBlob
app.config.globalProperties.Commas = Commas
app.config.globalProperties.TypeValue = TypeValue
app.config.globalProperties.SubStr = SubStr
app.config.globalProperties.ErrorMessage = ErrorMessage
app.config.globalProperties.PagingData = PagingData
app.config.globalProperties.axios = axios
app.config.globalProperties.Path = Path
app.config.globalProperties.indexedDB = indexedDB
app.mount('#app')
