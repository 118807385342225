<template>
  <div class="wrap clearFix">
    <h1>주문서 작성</h1>
    <div class="orderTotal">
      <product-table :path="path" :products="products" />
    </div>
    <div class="orderUser">
      <h2>주문 고객</h2>
      <user-info :user-info="userInfo" @request-change="payment_request = $event" />
    </div>
    <div class="delivery clearFix">
      <h2>배송지 정보</h2>
      <delivery
        :delivery="delivery"
        :delivery-info="deliveryInfo"
        :site-pick="sitePick"
        :select_coupon="select_coupon"
        @delivery-change="deliveryChange"
        @input-change="inputChange"
        @address-btn="addressBtn"
        @requirements-sel="requirementsSel"
        @etc-requirements="etcRequirements"
      />
    </div>
    <div class="way clearFix">
      <h2>결제 수단</h2>
      <payment-method
        :method="payment_method"
        :verification="account_verification"
        :account_holder="account_holder"
        @method-change="methodChange"
        @refund-set="refundSet"
        @account-verification="accountVerification"
      />
    </div>
    <div class="payment clearFix">
      <!-- 할인 수단 -->
      <discount-method
        :sale-type="saleType"
        :user-info="userInfo"
        :select-coupon="select_coupon"
        :available_point="available_point"
        :full-price="fullPrice"
        :shipping-fee="shippingFee"
        :payment-method="payment_method"
        :products="products"
        @sale-type-sel="saleTypeSel"
        @change-point="changePoint"
        @coupon-lookup="couponLookup"
      />
      <!-- 결제 금액 -->
      <price-calculate
        :sum-value="sumValue"
        :total-sale="totalSale"
        :shipping-fee="shippingFee"
        :full-price="fullPrice"
        @terms-view="termsView"
        @check-change="checkChange"
      />
    </div>
    <div
      id="payBtn"
      class="payBtn"
      :class="{ disabled: payment_ing === true }"
      @click="requestPay"
      @keydown="requestPay"
    >
      결 제 하 기
    </div>
    <terms-modal v-if="terms_view === true" @close-tab="closeTab" />
    <coupon-modal v-if="view_coupon === true" @close-tab="closeTab" @use-coupon="useCoupon" />
    <loading v-if="payment_ing === true" />
  </div>
</template>

<script>
import { IMP_CODE } from '../../config/IMP_config'
import { mapGetters } from 'vuex'
import ProductTable from '../../components/Public/ProductTable.vue'
import UserInfo from '../../components/Payment/UserInfo.vue'
import Delivery from '../../components/Payment/PaymentDelivery.vue'
import PaymentMethod from '../../components/Payment/PaymentMethod.vue'
import DiscountMethod from '../../components/Payment/DiscountMethod.vue'
import PriceCalculate from '../../components/Payment/PriceCalculate.vue'
import TermsModal from '../../components/Modal/ModalTerms.vue'
import CouponModal from '../Modal/CouponList.vue'
import Loading from '../../components/Public/PublicLoading.vue'

export default {
  components: {
    ProductTable,
    UserInfo,
    Delivery,
    PaymentMethod,
    DiscountMethod,
    PriceCalculate,
    TermsModal,
    CouponModal,
    Loading,
  },
  data() {
    return {
      path: '', // 현재 route name
      products: [], // 선택한 상품 목록
      delivery: '기본배송지', // 배송지 선택
      // 배송지 정보
      deliveryInfo: {
        recipient: '',
        zip_code: '',
        address: '',
        detailed_address: '',
        phone: '',
        requirements: '',
      },
      // 환불계좌 정보
      refundInfo: {
        bank_code: 'BK04', // 은행 코드
        bank_name: 'KB국민은행', // 은행 이름
        account_holder: '', // 예금주
        account_number: '', // 계좌번호
      },
      payment_method: 'card', // 결제 방법
      payment_request: '', // 주문 요청사항
      saleType: '선택안함', // 할인 방법
      saleContent: '선택안함', // 할인 내용 -> Ryan 기본 값을 설정해야 합니다.
      point: 0, // 사용하려는 포인트
      salePrice: 0, // 등급 또는 쿠폰으로 할인된 금액
      terms_view: false, // 약관 보임 또는 숨김 여부
      payment_ing: false, // 결제 진행여부
      termsCheck: false,
      payment_obj: {}, // 결제시 api에 넘길 데이터
      account_verification: false, // 무통장 입금시 환불계좌 인증여부
      account_holder: '', // 환불계좌 인증시 받아오는 예금주 명
      view_coupon: false, // 쿠폰 모달창 보임 숨김 여부
      select_coupon: {}, // 선택한 쿠폰
      sitePick: false, // 현장수령 가능 여부(디페입장권 구매시 불가)
      mobile: false, // 모바일 접속여부
    }
  },
  beforeMount() {
    IMP.init(IMP_CODE)
  },
  async mounted() {
    const filter = 'win16|win32|win64|mac|macintel'
    if (navigator.platform) {
      if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
        this.mobile = true
      }
    }
    this.path = this.$route.name
    await this.indexedDB.initializeData()
    await this.getProduct()
    await this.getUser()
    await this.saleTypeSel(this.saleType)
    if (this.mobile === true) {
      await this.queryCheck()
    }
  },
  methods: {
    // 약관동의창 숨김
    closeTab() {
      this.terms_view = false
      this.view_coupon = false
    },
    async queryCheck() {
      if (this.$route.query.error) {
        this.Message(this.$route.query.error, 8)
      }
    },
    // 장바구니에서 선택한 상품 불러오기
    async getProduct() {
      let products = await this.indexedDB.getSelectOrderFromDB()
      if (products.length > 0) {
        products.forEach((data) => {
          if (data.type === '디페스타 입장권' || data.type === '디페스타 입장권 패키지') {
            this.sitePick = false
          }
        })
        this.products = products
      } else {
        this.Message('상품이 없습니다.', 2)
      }
    },
    // 유저 정보 불러오기
    getUser() {
      let deliveryInfo = {
        recipient: this.userInfo.name,
        zip_code: this.userInfo.zip_code,
        address: this.userInfo.address,
        detailed_address: this.userInfo.detailed_address,
        phone: this.userInfo.phone,
        requirements: '',
      }
      this.deliveryInfo = deliveryInfo
    },
    // delivery
    // 배송지 변경
    deliveryChange(delivery) {
      this.delivery = delivery
      let deliveryInfo = {}
      if (delivery === '기본배송지') {
        deliveryInfo.recipient = this.userInfo.name
        deliveryInfo.zip_code = this.userInfo.zip_code
        deliveryInfo.address = this.userInfo.address
        deliveryInfo.detailed_address = this.userInfo.detailed_address
        deliveryInfo.phone = this.userInfo.phone
      } else if (delivery === '방문수령') {
        deliveryInfo.recipient = ''
        deliveryInfo.zip_code = '06907'
        deliveryInfo.address = '서울 동작구 본동 485'
        deliveryInfo.detailed_address = '상가건물 2층'
        deliveryInfo.phone = ''
      } else if (delivery === '현장수령') {
        deliveryInfo.recipient = ''
        deliveryInfo.zip_code = ''
        if (this.select_coupon?.type === '행사') {
          deliveryInfo.address = `${this.select_coupon.name} 행사장 내에서 수령 가능합니다.`
        } else {
          deliveryInfo.address = '행사장 내에서 수령 가능합니다.'
        }

        deliveryInfo.detailed_address = ''
        deliveryInfo.phone = ''
      } else {
        deliveryInfo.recipient = ''
        deliveryInfo.zip_code = ''
        deliveryInfo.address = ''
        deliveryInfo.detailed_address = ''
        deliveryInfo.phone = ''
      }
      this.deliveryInfo = deliveryInfo
    },
    // 배송정보 변경
    inputChange(type, value) {
      this.deliveryInfo[type] = value
    },
    // 배송 요청사항 변경
    requirementsSel(value) {
      if (value === '직접입력') {
        this.deliveryInfo.requirements = ''
      } else {
        this.deliveryInfo.requirements = value
      }
    },
    // 배송 요청사항 직접 입력
    etcRequirements(value) {
      this.deliveryInfo.requirements = value
    },
    // 주소 변경
    addressBtn() {
      let vm = this
      new daum.Postcode({
        oncomplete(data) {
          let addr = '' // 주소 변수
          let extraAddr = '' // 참고항목 변수
          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.address
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress
          }
          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '' && data.apartment === 'Y') {
              extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName
            }
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          vm.deliveryInfo.zip_code = data.zonecode
          vm.deliveryInfo.address = addr
        },
      }).open()
    },

    // 결제 수단
    methodChange(method) {
      if (method === 'cultureland') {
        this.salePrice = 0
        this.select_coupon = {}
        this.saleType = '선택안함'
      } else if (method === 'card') {
        this.refundInfo.account_holder = ''
        this.refundInfo.account_number = ''
        this.account_verification = false
      }
      this.payment_method = method
    },
    // 환불계좌정보 저장
    refundSet(type, value) {
      this.refundInfo[type] = value
    },
    // 환불 계좌 인증
    async accountVerification() {
      let ver1 = /^[0-9]+$/
      // if (this.refundInfo.account_holder === '') {
      //   this.Message('예금주 명을 입력해주세요.', 0)
      //   return
      // }
      if (this.refundInfo.account_number === '') {
        this.Message('계좌번호를 입력해주세요.', 0)
        return
      }
      if (!ver1.exec(this.refundInfo.account_number)) {
        this.Message('계좌번호를 바르게 입력해주세요.', 0)
        return
      }
      let check_code = await this.TypeValue.Check_Code(this.refundInfo.bank_code)

      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }

      await this.axios
        .get(
          `${this.Path.AccountCertifications}?account_code=${check_code}&account_num=${this.refundInfo.account_number}`,
          config,
        )
        .then((res) => {
          if (res.data.success === true) {
            this.Message('계좌인증이 완료되었습니다.', 0)
            this.account_holder = res.data.data
            this.account_verification = true
            this.refundSet('account_holder', this.account_holder)
          }
        })
        .catch((err) => {
          console.log(err)
          this.Message(this.ErrorMessage.AccountCertifications(err.response.status), 0, 0)
        })
    },

    // 할인 방법
    saleTypeSel(type) {
      this.saleType = type
      let salePrice = 0
      let sumValue = 0
      if (type === '등급할인') {
        this.select_coupon = {}
        if (this.payment_method === 'cultureland') {
          salePrice = 0
        } else {
          this.products.forEach((data) => {
            if (data.type !== '디페스타 입장권 패키지' && data.type !== '디페스타 입장권') {
              sumValue += data.price
            }
          })
          if (this.userInfo.ranking === 1) {
            salePrice = 0
          } else if (this.userInfo.ranking === 2) {
            salePrice = Math.round(sumValue * 0.1)
          } else if (this.userInfo.ranking === 0) {
            salePrice = Math.round(sumValue * 0.3)
          }
          this.saleContent = this.TypeValue.User_Ranking(this.userInfo.ranking)
        }
      } else if (type === '쿠폰할인') {
        if (!this.select_coupon.type) {
          this.saleContent = '선택안함'
          salePrice = 0
        }
        this.couponLookup()
      } else if (type === '포인트사용') {
        this.select_coupon = {}
        this.saleContent = '포인트사용'
        salePrice = 0
      } else if (type === '선택안함') {
        this.select_coupon = {}
        this.saleContent = '선택안함'
        salePrice = 0
      }
      this.salePrice = salePrice
    },
    // 포인트 사용
    changePoint(point) {
      this.salePrice = point
    },

    // 약관보기
    termsView() {
      this.terms_view = true
    },
    checkChange(check) {
      this.termsCheck = check
    },
    // 내 쿠폰 목록 보기
    couponLookup() {
      this.view_coupon = true
    },
    // 쿠폰 사용
    useCoupon(coupon) {
      if (!coupon.type) {
        this.Message('쿠폰을 선택해주세요.', 0)
        return
      }
      let couponPrice = 0

      if (coupon.type === '전체' || coupon.type === '행사') {
        this.products.map((data) => {
          if (data.type !== '디페스타 입장권 패키지' && data.type !== '디페스타 입장권') {
            couponPrice += data.price
          }
          return
        })
      } else {
        this.products.map((data) => {
          if (data.type === coupon.type) {
            couponPrice += data.price
          }
          return
        })
      }
      if (couponPrice === 0) {
        this.Message('할인 가능한 상품이 없습니다.', 0)
        return
      }
      if (coupon.sale_type === '퍼센트할인') {
        const percentage = Number(coupon.price) * 0.01
        this.salePrice = Math.round(couponPrice * percentage)
      } else {
        if (Number(coupon.price) > this.sumValue) {
          coupon.price = this.sumValue
        }
        this.salePrice = Number(coupon.price)
      }

      this.select_coupon = coupon
      this.saleContent = coupon.name
      if (this.select_coupon.type === '행사') {
        this.products.map((data) => {
          if (data.type === '디페스타 입장권 패키지' || data.type === '디페스타 입장권') {
            this.Message(
              '디페스타 입장권 또는 디페스타 입장권 패키지가 포함된 경우 해당 쿠폰을 사용 할 수 없습니다.',
              0,
            )
            this.sitePick = false
            this.select_coupon = {}
            this.salePrice = 0
          }
          return
        })
        this.sitePick = true
        const site = document.getElementById('site')
        site.checked = true
        this.deliveryChange('현장수령')
      }

      this.view_coupon = false
    },

    // 결제
    async requestPay() {
      let ver1 = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/ // 휴대전화번호 정규표현식
      let { products } = this
      let file_url
      this.payment_ing = true
      if (this.deliveryInfo.recipient === '') {
        this.Message('수령자명을 입력해주세요.', 0)
        this.payment_ing = false
        return
      }
      if (this.delivery !== '현장수령') {
        if (this.deliveryInfo.zip_code === '') {
          this.Message('주소를 입력해주세요.', 0)
          this.payment_ing = false
          return
        }
        if (this.deliveryInfo.detailed_address === '') {
          this.Message('상세주소를 입력해주세요.', 0)
          this.payment_ing = false
          return
        }
      }
      if (!ver1.exec(this.deliveryInfo.phone)) {
        this.Message('휴대전화 번호를 제대로 입력해주세요.', 0)
        this.payment_ing = false
        return
      }
      if (this.payment_method === 'vbank') {
        if (this.account_verification === false) {
          this.Message('환불계좌 인증이 필요합니다.', 0)
          this.payment_ing = false
          return
        }
      }

      if (this.termsCheck === false) {
        this.Message('약관 동의해주세요.', 0)
        this.payment_ing = false
        return
      }
      const fileUpload = await this.mobileFileUpload(products)
      if (fileUpload) {
        products = fileUpload
      }
      let type_length = ''
      if (this.products.length > 1) {
        type_length = ` 외 ${this.products.length - 1} 건`
      }
      let production_period = 'quick'
      // eslint-disable-next-line no-restricted-syntax
      for (const product of products) {
        if (product.period === '일반제작') {
          production_period = 'common'
        }
      }

      await IMP.request_pay(
        {
          pg: 'kcp',
          pay_method: this.payment_method,
          merchant_uid: `${this.moment().unix()}_${this.userInfo.user_id}`,
          name: `${this.products[0].type}${type_length}`,
          amount: this.fullPrice,
          buyer_email: this.userInfo.email,
          buyer_name: this.userInfo.name,
          buyer_tel: this.deliveryInfo.phone,
          buyer_addr: this.deliveryInfo.address,
          buyer_postcode: this.deliveryInfo.zip_code,
          bypass: {
            shop_user_id: 'dongnecraft9722',
          },
          custom_data: {
            user: this.userInfo,
            products,
            delivery_method: this.delivery,
            delivery: this.deliveryInfo,
            payment_method: this.payment_method,
            payment_request: this.payment_request,
            discount_method: this.saleType,
            discount_content: this.saleContent,
            delivery_amount: this.shippingFee,
            total_discount_amount: this.totalSale,
            total_order_amount: this.sumValue,
            total_payment_amount: this.fullPrice,
            refundAccount: this.refundInfo,
            coupon_id: this.select_coupon.id,
            production_period,
          },
          m_redirect_url: `${this.TypeValue.api_url()}/api/payment/complete/mobile?origin_url=${
            window.location.origin
          }`,
        },
        async (rsp) => {
          if (rsp.success) {
            if (rsp.status !== 'ready') {
              this.refundInfo.bank_code = ''
              this.refundInfo.bank_name = ''
            }
            const config = {
              headers: {
                Authorization: `Bearer ${this.userToken}`,
              },
            }
            await this.axios
              .post(
                this.Path.Payment,
                {
                  imp_uid: rsp.imp_uid,
                  merchant_uid: rsp.merchant_uid,
                  user_info: this.userInfo,
                  products,
                  delivery_method: this.delivery,
                  delivery_info: this.deliveryInfo,
                  payment_method: this.payment_method,
                  payment_request: this.payment_request,
                  discount_method: this.saleType,
                  discount_content: this.saleContent,
                  delivery_amount: this.shippingFee,
                  total_discount_amount: this.totalSale,
                  total_order_amount: this.sumValue,
                  total_payment_amount: this.fullPrice,
                  refund_account: this.refundInfo,
                  coupon_id: this.select_coupon.id,
                  production_period,
                },
                config,
              )
              .then(async (res) => {
                if (res.data.success === true) {
                  await this.indexedDB.selectOrderClear()
                  await this.products.forEach(async (product) => {
                    await this.indexedDB.deleteOrderFromDB(product.id)
                  })
                  let completeOrder = {
                    payment_method: this.payment_method,
                    vbank_holder: rsp.vbank_holder,
                    vbank_name: rsp.vbank_name,
                    vbank_num: rsp.vbank_num,
                    vbank_date: this.moment(rsp.vbank_date)
                      .add(9, 'hour')
                      .format('YYYY-MM-DD HH:mm:ss'),
                    merchant_uid: rsp.merchant_uid,
                    total_order_amount: this.sumValue,
                    delivery_amount: this.shippingFee,
                    total_discount_amount: this.totalSale,
                    total_payment_amount: this.fullPrice,
                    payment_id: res.data.data,
                  }
                  await sessionStorage.setItem('completeOrder', JSON.stringify(completeOrder))
                  if (this.saleContent === '포인트사용') {
                    await this.$store.dispatch(
                      'Point/pointSet',
                      this.available_point - this.totalSale,
                    )
                  }

                  await this.Message('주문접수가 완료되었습니다.', 3)
                }
              })
              .catch((err) => {
                console.log('err:', err)
              })
          } else {
            this.Message(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`, 0)
            this.payment_ing = false
          }
        },
      )
    },
    async mobileFileUpload(products) {
      let formData = new FormData()
      await products.forEach(async (data, index) => {
        let index_num
        if (index < 10) {
          index_num = `0${index}`
        } else {
          index_num = index
        }
        if (data.file) {
          const dataURLtoFile = async (dataurl, filename) => {
            let arr = dataurl.split(',')
            let mime = arr[0].match(/:(.*?);/)[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)

            while (n--) {
              u8arr[n] = bstr.charCodeAt(n)
            }

            return new File([u8arr], filename, { type: mime })
          }
          const newFile = await dataURLtoFile(data.file, data.fileName)
          formData.append('files', newFile, `${index_num}:${data.fileName}`)
          data.fileData = newFile
          data.file = ''
        } else if (data.fileData) {
          formData.append('files', data.fileData, `${index_num}:${data.fileName}`)
        }
        if (data.svgData) {
          formData.append('files', data.svgData, `${index_num}:${data.svgData.name}`)
        }
      })
      formData.append('products', JSON.stringify(products))
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }

      const getFileUrl = await this.axios
        .post(this.Path.FileUpload, formData, config)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          console.log('err:', err)
          this.payment_ing = false
        })

      return getFileUrl
    },
  },
  computed: {
    // 상품들의 총 가격
    sumValue() {
      let sumValue = 0
      for (let i = 0; i < this.products.length; i++) {
        sumValue += this.products[i].price * 1
      }
      return sumValue
    },
    // 총 결제금액
    fullPrice() {
      let fullPrice = Math.round(this.sumValue - this.salePrice + this.shippingFee - this.point)
      // Math.round(this.sumValue - this.salePrice + this.shippingFee - this.point)

      return fullPrice
    },
    // 배송비
    shippingFee() {
      let shippingFee = 3500
      if (this.sumValue >= 50000 || this.delivery === '현장수령' || this.delivery === '방문수령') {
        shippingFee = 0
      }
      return shippingFee
    },
    // 총 할인금액
    totalSale() {
      let totalSale = this.salePrice * 1
      return totalSale
    },
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
      available_point: 'Point/available_point',
    }),
  },
}
</script>

<style scoped lang="scss">
.wrap {
  width: 1160px;
  margin: 50px auto;

  h2 {
    font-size: 16px;
    color: #707070;
    text-align: left;
    font-weight: 400;
  }

  > h1 {
    text-align: center;
    color: #707070;
    font-weight: 700;
    font-size: 26px;
    width: 160px;
    margin: 50px auto 40px;
  }

  > .orderTotal {
    width: 100%;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    margin: 0 auto;

    > .tableWrap {
      width: 100%;
      margin: 0 auto;
      border-collapse: collapse;
    }
  }

  > .orderUser {
    width: 100%;
    margin: 55px auto 0;
  }

  > .orderRequest {
    width: 100%;
    margin: 55px auto 0;

    > .requestBox {
      border-top: 2px solid #ff6699;

      > textarea {
        width: 500px;
        height: 100px;
        margin: 10px;
        padding: 10px;
        resize: none;
      }
    }
  }

  > .delivery {
    width: 100%;
    margin: 55px auto 0;
  }

  > .way {
    width: 100%;
    margin: 55px auto 0;
  }

  > .payment {
    width: 100%;
    margin: 55px auto 0;
  }

  .payBtn {
    width: 100%;
    background-color: #ff6699;
    border-radius: 10px;
    height: 60px;
    line-height: 60px;
    margin: 68px auto 200px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }
  }
}
</style>
