<template>
  <div class="news">
    <detail-container />
  </div>
</template>

<script>
import DetailContainer from '../../containers/News/DetailContainer.vue'

export default {
  name: 'NewsDetail',
  components: {
    DetailContainer,
  },
}
</script>

<style></style>
