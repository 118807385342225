<template>
  <div class="Wrap">
    <div class="Bg" />
    <Login
      v-if="login_view === true"
      :form-error="formError"
      @close-tab="closetab"
      @login="login"
      @message-reset="messageReset"
      @register-btn="registerView"
      @find-id="findView"
    />
    <Register
      v-if="register_view === true"
      :complete="register_complete"
      :overlap="register_id_overlap"
      :user-name="register_name"
      :user-phone="register_phone"
      :user-birth="register_birth"
      :user-zip-code="register_zipCode"
      :user-address="register_address"
      :pw-error-message="pwErrorMessage"
      :pw-check-error-message="pwCheckErrorMessage"
      @close-tab="closetab"
      @id-check="idCheck"
      @id-change="changeId"
      @phone-certification="phoneCertification"
      @address-search="addressSearch"
      @register-btn="registerBtn"
      @pw-length-check="pwLengthCheck"
      @pw-check-error="pwCheckError"
    />
    <find-id
      v-if="find_id_view === true"
      :complete="find_id_complete"
      :user-name="find_userName"
      :user-id="find_userId"
      @close-tab="closetab"
      @id-find="idFind"
      @find-pw-view="findPwView"
      @login-view="loginView"
    />
    <find-pw
      v-if="find_pw_view === true"
      :complete="find_pw_complete"
      :user-name="find_userName"
      :user-id="find_userId"
      @close-tab="closetab"
      @pw-find="pwFind"
      @find-id-view="findView"
      @login-view="loginView"
      @pw-change="pwChange"
    />
  </div>
</template>

<script>
import { IMP_CODE } from '../../config/IMP_config'
import Login from '../../components/Modal/ModalLogin.vue'
import Register from '../../components/Modal/ModalRegister.vue'
import FindId from '../../components/Modal/FindId.vue'
import FindPw from '../../components/Modal/FindPw.vue'
import ChannelService from '../../plugins/ChannelService'

export default {
  components: {
    Login,
    Register,
    FindId,
    FindPw,
  },
  props: {
    login_type: {
      type: String,
    },
  },
  data() {
    return {
      formError: '', // 로그인 아이디, 비밀번호 에러문구
      login_view: true, // true일 경우 로그인창이 보임
      register_view: false, // true일 경우 회원가입창이 보임
      register_complete: false, // 회원가입 완료 여부
      register_id_overlap: false, // 회원가입 아이디 중복 여부
      register_phone_certifications: false, // 회원가입 휴대폰 인증 여부
      register_name: '', // 회원가입 유저 이름
      register_phone: '', // 회원가입 유저의 휴대폰 번호
      register_birth: '', // 회원가입 유저의 생년월일
      register_zipCode: '', // 회원가입 우편번호
      register_address: '', // 회원가입 주소
      auth_key: '', // 휴대폰 인증후 받은 데이터 값
      find_id_view: false, // true일 경우 아이디 찾기 창이 보임
      find_id_complete: false, // 아이디 찾기 완료 여부
      find_pw_view: false, // true일 경우 비밀번호 찾기 창이 보임
      find_pw_complete: false, // 비밀번호 찾기 완료 여부
      find_userName: '', // 아이디찾기 유저 이름
      find_userId: '', // 아이디 찾기 유저 아이디
      pwErrorMessage: '', // 비밀번호 길이가 다를경우 나올 경고메세지
      pwCheckErrorMessage: '', // 비밀번호확인과 비밀번호가 다른경우 나올 경고 메세지
    }
  },
  beforeMount() {
    IMP.init(IMP_CODE)
  },
  mounted() {
    if (this.login_type === 'register') {
      this.registerView()
    } else if (this.login_type === 'find') {
      this.findView()
    } else {
      this.loginView()
    }
  },
  methods: {
    closetab() {
      this.$emit('closeTab')
    },
    // 로그인 모달 호출
    loginView() {
      this.register_view = false
      this.find_id_view = false
      this.find_id_complete = false
      this.find_pw_view = false
      this.find_pw_complete = false
      this.login_view = true
    },

    // login
    // 로그인
    async login(id, pw) {
      await this.axios
        .post(this.Path.SignIn, {
          user_id: id,
          password: pw,
        })
        .then(async (res) => {
          if (res.data.success === true) {
            const token = res.data.data
            await this.$store.dispatch('UserInfo/login', token)
            ChannelService.shutdown()
          }
        })
        .catch((err) => {
          this.Message(this.ErrorMessage.Login(err.response.status), 0, 0)
        })
    },
    // 에러메세지 숨김
    messageReset() {
      this.formError = ''
    },
    // 회원가입모달 보임
    registerView() {
      this.login_view = false
      this.register_view = true
    },
    findView() {
      this.login_view = false
      this.find_pw_view = false
      this.find_id_view = true
    },

    // register
    // 아이디 중복 체크
    idCheck(userId) {
      let ver2 = /\s/g
      let ver3 = /^[a-zA-Z0-9]+[a-zA-Z0-9]{4,15}$/g
      if (userId === '' || userId === undefined) {
        this.Message('아이디를 입력해주세요.', 0, 0)
        this.register_id_overlap = false
        return
      }
      if (!ver3.exec(userId) || ver2.exec(userId)) {
        this.Message('사용 불가능한 아이디 입니다.', 0, 0)
        this.register_id_overlap = false
        return
      }
      this.axios
        .get(`${this.Path.IdOverlap}?user_id=${userId}`)
        .then((res) => {
          this.register_id_overlap = true
          this.Message('사용 가능한 아이디 입니다.', 0)
        })
        .catch(() => {
          this.Message('중복된 아이디입니다.', 0, 0)
          this.register_id_overlap = false
        })
    },
    // 아이디 변경
    changeId() {
      this.register_id_overlap = false
    },
    // 비밀번호 길이확인
    pwLengthCheck(value) {
      if (value.length < 8 || value.length > 20) {
        this.pwErrorMessage = '비밀번호는 최소 8자 이상, 최대 20자 이하로 입력해야합니다.'
      } else {
        this.pwErrorMessage = ''
      }
    },
    // 비밀번호확인 체크
    pwCheckError(value, pw) {
      if (value !== pw) {
        this.pwCheckErrorMessage = '비밀번호가 일치하지 않습니다.'
      } else {
        this.pwCheckErrorMessage = ''
      }
    },
    // 핸드폰 인증
    phoneCertification() {
      IMP.certification(
        {
          merchant_uid: `register_${new Date().getTime()}`,
        },
        (rsp) => {
          if (rsp.success) {
            this.axios
              .post(this.Path.PhoneCertification, {
                imp_uid: rsp.imp_uid,
              })
              .then((res) => {
                if (res.data.success === true) {
                  this.auth_key = res.data.data.auth_key
                  this.register_name = res.data.data.name
                  this.register_phone = res.data.data.phone
                  this.register_birth = res.data.data.birthday
                  this.register_phone_certifications = true
                  this.Message('인증이 완료되었습니다.', 0)
                } else {
                  this.Message('인증에 실패하였습니다.', 0)
                  this.register_phone_certifications = false
                }
              })
          } else {
            this.Message('인증에 실패하였습니다.', 0)
          }
        },
      )
    },
    // 주소 찾기
    addressSearch() {
      let vm = this
      new daum.Postcode({
        oncomplete(data) {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let addr = '' // 주소 변수
          let extraAddr = '' // 참고항목 변수

          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.address
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '' && data.apartment === 'Y') {
              extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            // if(extraAddr !== ''){
            //     extraAddr = ' (' + extraAddr + ')';
            // }
            // 조합된 참고항목을 해당 필드에 넣는다.
            // document.getElementById("sample6_extraAddress").value = extraAddr;
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          vm.register_zipCode = data.zonecode
          // this.zipCode = data.zonecode
          vm.register_address = addr
        },
      }).open()
    },
    // 회원가입
    registerBtn(
      user_id,
      password,
      pwCheck,
      email,
      detailAddress,
      agreeCheck,
      snsCheck,
      recommender,
    ) {
      let ver1 = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/ // 휴대전화번호 정규표현식
      let ver2 = /\s/g // 공백 체크
      // eslint-disable-next-line no-useless-escape
      let ver3 = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/ // 이메일 정규표현식
      let ver4 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,20}$/ // 비밀번호 정규표현식

      if (this.register_id_overlap === false) {
        this.Message('아이디 중복 확인을 해주세요.', 0, 0)
        return
      }
      if (password === '' || password === undefined) {
        this.Message('비밀번호를 입력해주세요.', 0, 0)
        return
      }
      if (!ver4.exec(password) || ver2.exec(password)) {
        this.Message('올바른 비밀번호 양식에 맞게 작성하세요.', 0, 0)
        return
      }
      if (pwCheck !== password) {
        this.Message('비밀번호가 일치하지 않습니다.', 0, 0)
        return
      }
      if (!ver3.exec(email)) {
        this.Message('올바른 이메일 양식에 맞게 작성해주세요.', 0, 0)
        return
      }
      if (this.register_phone_certifications === false) {
        this.Message('본인인증 여부를 확인해주세요.', 0, 0)
        return
      }
      if (
        this.register_address === '' ||
        this.register_address === undefined ||
        detailAddress === '' ||
        detailAddress === undefined
      ) {
        this.Message('주소를 입력해주세요.', 0, 0)
        return
      }

      let privacy_consent = 0
      if (agreeCheck === false) {
        this.Message('약관에 동의해주세요.', 0, 0)
        return
      }
      privacy_consent = 1

      let sns_consent
      if (snsCheck === true) {
        sns_consent = 1
      } else {
        sns_consent = 0
      }

      this.axios
        .post(this.Path.SignUp, {
          user_id,
          password,
          name: this.register_name,
          phone: this.register_phone,
          email,
          birth: this.register_birth,
          address: this.register_address,
          detailed_address: detailAddress,
          zip_code: this.register_zipCode,
          auth_key: this.auth_key,
          privacy_consent,
          sns_consent,
          recommender,
        })

        .then((res) => {
          if (res.data.success === true) {
            this.Message('회원가입이 완료되었습니다.', 0)
            this.register_complete = true
          }
        })
        .catch((err) => {
          console.log('err:', err)
          this.Message(this.ErrorMessage.signUp(err.response.data.data), 0, 0)
        })
    },

    // find-id
    // 아이디 찾기 휴대폰 인증
    idFind(userName, userPhone_1, userPhone_2, userPhone_3) {
      if (userName === '' || userName === undefined) {
        this.Message('이름을 입력해주세요.', 0, 0)
        return
      }
      if (
        userPhone_2 === '' ||
        userPhone_2 === undefined ||
        userPhone_3 === '' ||
        userPhone_3 === undefined
      ) {
        this.Message('핸드폰 번호를 입력해주세요.', 0, 0)
        return
      }
      let userPhone = `${userPhone_1}${userPhone_2}${userPhone_3}`
      IMP.certification(
        {
          merchant_uid: `find_id_${new Date().getTime()}`,
        },
        (rsp) => {
          this.axios
            .post(this.Path.IdFind, {
              name: userName,
              phone: userPhone,
              imp_uid: rsp.imp_uid,
            })
            .then((res) => {
              if (res.data.success === true) {
                this.find_userId = res.data.data.user_id
                this.find_id_complete = true
              }
            })
            .catch((err) => {
              this.Message(this.ErrorMessage.findId(err.response.status), 0, 0)
            })
        },
      )
    },
    // 비밀번호 찾기 모달 호출
    findPwView() {
      this.find_id_view = false
      this.find_pw_view = true
    },

    // find-pw
    // 비밀번호 찾기 휴대폰 인증
    pwFind(userId, userName, userPhone_1, userPhone_2, userPhone_3) {
      if (userId === '' || userId === undefined) {
        this.Message('아이디를 입력해주세요.', 0, 0)
        return
      }
      if (userName === '' || userName === undefined) {
        this.Message('이름을 입력해주세요.', 0, 0)
        return
      }
      if (
        userPhone_2 === '' ||
        userPhone_2 === undefined ||
        userPhone_3 === '' ||
        userPhone_3 === undefined
      ) {
        this.Message('핸드폰 번호를 입력해주세요.', 0, 0)
        return
      }
      let userPhone = `${userPhone_1}${userPhone_2}${userPhone_3}`
      IMP.certification(
        {
          merchant_uid: `find_id_${new Date().getTime()}`,
        },
        (rsp) => {
          this.axios
            .post(this.Path.PwFind, {
              user_id: userId,
              name: userName,
              phone: userPhone,
              imp_uid: rsp.imp_uid,
            })
            .then((res) => {
              if (res.data.success === true) {
                this.find_pw_complete = true
              }
            })
            .catch((err) => {
              this.Message(this.ErrorMessage.findPassword(err.response.status), 0, 0)
            })
        },
      )
    },
    // 새 비밀번호 저장
    pwChange(userId, userName, userPhone_1, userPhone_2, userPhone_3, newPw, pwCheck) {
      let ver = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,20}$/ // 비밀번호 정규 표현식
      let ver2 = /\s/g // 공백체크
      let userPhone = `${userPhone_1}${userPhone_2}${userPhone_3}`
      if (newPw === '' || newPw === undefined) {
        this.Message('비밀번호를 입력해주세요.', 0, 0)
        return
      }
      if (!ver.exec(newPw) || ver2.exec(newPw)) {
        this.Message('잘못된 비밀번호 형식입니다.', 0, 0)
        return
      }
      if (newPw !== pwCheck) {
        this.Message('비밀번호가 일치하지 않습니다.', 0, 0)
        return
      }
      this.axios
        .patch(this.Path.FindPwChange, {
          user_id: userId,
          name: userName,
          phone: userPhone,
          password: newPw,
        })
        .then((res) => {
          if (res.data.success === true) {
            this.Message('비밀번호가 변경되었습니다.', 0, 0)
            this.loginView()
          }
        })
    },
  },
}
</script>

<style scoped lang="scss">
.Wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1010;
  display: block;

  > .Bg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }
}
</style>
