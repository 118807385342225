<template>
  <div class="Wrap">
    <div class="goodsWrap clearFix">
      <special-left
        :type="type"
        :svg="svg"
        :size-box="sizeBox"
        :size-message="sizeMessage"
        :image-style="imageStyle"
        :drop-box-style="dropBoxStyle"
        @cart-add="cartAdd"
        @drop-event="dropEvent"
        @cancel-canvas="cancelCanvas"
        @img-select="imgSelect"
        @canvas="canvas"
        @dpi-change="dpiChange"
        @over-event="overEvent"
      />
      <special-right
        :type="type"
        :thick-num="thickNum"
        :kind-num="kindNum"
        :hologram-num="hologramNum"
        :print-num="printNum"
        :item-num="itemNum"
        :tag-color-num="tagColorNum"
        :wire-color-num="wireColorNum"
        :packing-num="packingNum"
        :keyring-kind-num="keyringKindNum"
        :keyring-color-num="keyringColorNum"
        :period-num="periodNum"
        @sel-option="selOption"
        @quantity-change="quantityChange"
        @quantity-check="quantityCheck"
        @print-popup="printPopup"
        @wiring-popup="wiringPopup"
      />
    </div>
    <div class="margin" />
    <special-intro :params="params" :move-bar="intro_movebar" @scroll-event="scrollEvent" />
    <letter v-if="letter_view === true" :params="params" @close-tab="closeTab()" />
    <print-shell v-if="print_shell_popup === true" @close-tab="closeTab()" />
    <wiring-popup v-if="wiring_popup === true" @close-tab="closeTab()" />
    <period-modal v-if="period_popup === true" @close-tab="PeriodCloseTab()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpecialLeft from '../../components/Goods/Menu/SpecialLeft.vue'
import SpecialRight from '../../components/Goods/Menu/SpecialRight.vue'
import SpecialIntro from '../../components/Goods/Intro/IntroSpecial.vue'
import Letter from '../../components/Modal/ModalLetter.vue'
import PrintShell from '../../components/Modal/PrintShell.vue'
import WiringPopup from '../../components/Modal/ItemWiring.vue'
import PeriodModal from '../../components/Modal/PeriodModal.vue'

export default {
  components: {
    SpecialLeft,
    SpecialRight,
    SpecialIntro,
    Letter,
    PrintShell,
    WiringPopup,
    PeriodModal,
  },
  data() {
    return {
      params: '', // 현재 페이지의 params
      type: '',
      thickNum: 2, // 선택한 두께 번호
      kindNum: 0, // 선택한 아크릴 두께
      hologramNum: null, // 선택한 홀로그램 종류 번호
      printNum: 1, // 선택한 인쇄면 번호
      itemNum: 0, // 선택한 부자재 번호
      packingNum: 0, // 선택한 조립/포장 번호
      periodNum: 1, // 선택한 제작기간 번호
      tagColorNum: null, // 군번줄 색상 번호
      wireColorNum: null, // 와이어링 색상 번호
      keyringKindNum: null, // 선택한 키링 종류 번호
      keyringColorNum: null, // 선택한 키링 색상 번호
      intro_movebar: {
        position: 'absolute',
        width: '100px',
        height: '4px',
        backgroundColor: '#ff6699',
        borderRadius: '10px',
        top: '68px',
        left: '205px',
        transition: '0.5s',
      },
      headerTop: null, // intro 기준 높이
      introHeight: 0, // params에 따른 margin값
      scrolled: false,
      svg: false, // svg체크
      img_file: '', // 이미지 파일
      img_filename: '', // 이미지 파일 이름
      sizeMessage: '', // 캔버스 또는 블럭이미지 크기가 변경되었을때 나올메세지
      canvas_file: '',
      dropBoxStyle: {
        'background-color': 'transparent',
        opacity: '1',
      },
      letter_view: true,
      print_shell_popup: false, // 아크릴 종류 투명쉘 선택후 인쇄 양면선택시 안내문구 호출
      wiring_popup: false, // 부자재 와이어링 선택시 안내문구 호출
      period_popup: false, // 제작기간 안내문구 호출
    }
  },
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      sizeBox: 'SizeBox/sizeBox',
      imgSize: 'SizeBox/imgSize',
      imageStyle: 'SizeBox/imageStyle',
      quick_order: 'Goods/quick_order',
    }),
  },
  watch: {
    $route(to, from) {
      if (to.name === 'Special') {
        this.$store.dispatch('Goods/periodReset')
        this.goodsReset()
        this.numReset()
        this.paramsCheck()
        this.letter_view = true
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('Goods/periodReset')
    await this.indexedDB.initializeData()
    await this.goodsReset()
    this.numReset()
    await this.paramsCheck()
    window.addEventListener('scroll', this.detectWindowScrollY)
  },
  beforeDestory() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  unmounted() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  methods: {
    closeTab() {
      this.letter_view = false
      this.print_shell_popup = false
      this.wiring_popup = false
    },
    PeriodCloseTab() {
      this.period_popup = false
    },
    // Quoin
    numReset() {
      this.thickNum = 2
      this.itemNum = 0
      this.printNum = 1
      this.packingNum = 0
      this.periodNum = 1
    },
    // params에 따라 type값 배치
    async paramsCheck() {
      if (this.params === 'Quoin') {
        this.type = '아크릴 키링'
        if (this.quick_order === 'true' || this.quick_order === true) {
          this.selOption('period', 0, '빠른제작')
        }
        this.period_popup = true
      } else if (this.params === 'Canvas') {
        this.type = '일러스트 캔버스'
      }
      await this.$store.dispatch('Goods/typeSet', this.type)
    },
    // store에 담겨있는 goods값을 초기화
    goodsReset() {
      this.params = this.$route.params.type
      if (this.params === 'Quoin') {
        this.$store.dispatch('Goods/reset')
      } else if (this.params === 'Canvas') {
        this.$store.dispatch('Goods/canvasReset')
      }
      this.svg = false
      this.img_file = ''
      this.img_filename = ''
      let sizeBox = {
        boxWidth: 300,
        boxHeight: 300,
        dpi: 300,
        boxMargin: 100,
      }
      this.$store.dispatch('SizeBox/resetSize', sizeBox)
      this.sizeMessage = ''
    },

    // dpi클릭시 sizeBox.dpi를 변경시키고 외각선따는 함수를 다시 실행
    async dpiChange(i) {
      let dpi
      if (i === 0) {
        dpi = 100
      } else if (i === 1) {
        dpi = 300
      } else if (i === 2) {
        dpi = 720
      }
      this.$store.dispatch('SizeBox/dpiChange', dpi)
      if (this.img_file) {
        if (this.type === '아크릴 키링') {
          await this.AutoSize.quoinSize(this.sizeBox)
        } else {
          await this.cancelCanvas()
        }
      }
      this.sizeMessage = ''
    },

    // 이미지 드롭시 이미지 파일이 1개인지 검수, img_file에 이미지 파일등록
    dropEvent(e, type) {
      if (e.dataTransfer.files.length > 1) {
        let dropBox = document.getElementsByClassName('dropBox')
        dropBox[0].style.backgroundColor = 'transparent'
        dropBox[0].style.opacity = '1'
        this.Message('한개의 파일만 올려주세요.', 0, 0)
        return
      }

      ;[this.img_file] = e.dataTransfer.files
      this.img_filename = e.dataTransfer.files[0].name
      this.scrollY = window.scrollY
      this.svg = true
      this.dropBoxStyle = {
        'background-color': 'transparent',
        opacity: '1',
      }
      this.processFile(e.dataTransfer.files[0], type)
    },

    // 이미지 파일 크기 확인, 해당 이미지 미리보기
    processFile(file, type) {
      if (file) {
        if (file.type.match(/image.*/)) {
          if (file.size > 5242880) {
            this.Message('파일크기가 너무 큽니다.', 0, 0)
            this.cancelCanvas(type)
            return
          }
          let reader = new FileReader()
          reader.onload = () => {
            let { result } = reader
            document.getElementById(`${type}_img`).setAttribute('src', result)
            // 이미지 외각선 따기
            if (type === 'quoin') {
              this.AutoSize.quoinSize(this.sizeBox)
            } else {
              this.canvas(type, 675, 700)
            }
            this.$store.dispatch('Goods/file', this.img_file)
          }

          reader.readAsDataURL(file)
        } else {
          this.Message('이미지파일만 올려주세요.', 0, 0)
          this.cancelCanvas(type)
          return
        }
      }
    },

    // 이미지 초기화
    async cancelCanvas(type) {
      let inputFile = document.getElementById(`${type}_svg_file`)
      this.svg = false
      this.img_file = ''
      this.img_filename = ''
      this.image = ''
      inputFile.value = ''
      this.dropBoxStyle = {
        'background-color': 'transparent',
        opacity: '1',
      }
      if (type === 'quoin') {
        // this.$store.dispatch('Drag/dragMessage', '구멍을 원하는 위치로 드래그 해주세요!')
        this.$store.dispatch('Goods/pSize', {
          x: 4,
          y: 4,
        })
        const xbar = document.getElementById('quoin_xsize_bar')
        const ybar = document.getElementById('quoin_ysize_bar')
        xbar.style.width = '200px'
        ybar.style.height = '200px'
      } else if (type === 'block') {
        this.sizeMessage = ''
      }
    },
    // 클릭으로 이미지등록 이벤트
    imgSelect(type, file) {
      if (file === undefined) {
        this.img_file = ''
        this.img_filename = ''
      } else {
        this.img_file = file
        this.img_filename = file.name
      }
      this.svg = true
      this.processFile(file, type)
    },

    // 이미지 드롭오버시 배경색 변경
    overEvent(type) {
      if (type === 'dragenter') {
        this.dropBoxStyle = {
          'background-color': '#000000',
          opacity: '0.3',
        }
      } else {
        this.dropBoxStyle = {
          'background-color': 'transparent',
          opacity: '1',
        }
      }
    },

    async canvas(type, width, height) {
      let canvas = new fabric.Canvas(`${type}_b`)
      canvas.setDimensions({ width, height })
      $('.lower-canvas').css('z-index', 5)
      $('.upper-canvas').css('z-index', 6)
      let url = document.getElementById(`${type}_img`).src
      let scale = ((25.4 / this.sizeBox.dpi) * 2 * Math.SQRT2) / 1.5
      let message = ''
      fabric.Object.prototype.transparentCorners = false
      await fabric.Image.fromURL(url, (img) => {
        img.scale(scale * 1).set({
          left: 100,
          top: 100,
          angle: 0,
        })
        canvas.add(img).setActiveObject(img)
        this.canvas_file = canvas
        let moveImg = () => {
          this.canvas_file = canvas
        }
        let sizeingImg = () => {
          if (img.scaleX > scale || img.scaleY > scale) {
            this.sizeMessage = '크기변경시, 이미지가 깨질 수 있습니다.'
          } else {
            this.sizeMessage = ''
          }
          this.canvas_file = canvas
        }
        let scalingImg = () => {}
        canvas.on({
          'object:moving': moveImg,
          'object:scaling': sizeingImg,
        })
      })
    },
    // 스크롤 위치에따라 movebar위치 이동
    detectWindowScrollY() {
      const header = document.getElementById('Special_pageHeader')
      let fixed_Header = document.getElementById('special_fixed_Header')
      if (this.type === '아크릴 키링') {
        this.introHeight = 12100
      } else if (this.type === '일러스트 캔버스') {
        this.introHeight = 4700
      } else if (this.type === '아크릴 블럭') {
        this.introHeight = 4400
      }
      this.headerTop = header.offsetTop
      let margin = this.headerTop + this.introHeight
      // 감지 이벤트 메서드
      // this.scrolled = window.scrollY > 0
      this.scrolled = window.scrollY > this.headerTop
      if (this.scrolled === true) {
        fixed_Header.classList.add('scrolled')
        // topBtn.style.display = 'block'
      } else {
        fixed_Header.classList.remove('scrolled')
        // topBtn.style.display = 'none'
      }

      if (window.scrollY > 100 && window.scrollY < this.headerTop) {
        this.intro_movebar.left = '207px'
      }
      if (window.scrollY > this.headerTop && window.scrollY < margin + 400) {
        this.intro_movebar.left = '528px'
      }
      if (window.scrollY > margin + 500) {
        this.intro_movebar.left = '850px'
      }
    },
    // intro 메뉴 클릭시 클릭한메뉴 위치로 스크롤이동
    scrollEvent(i) {
      let margin = this.headerTop + this.introHeight
      if (i === 0) {
        window.scroll({
          behavior: 'auto',
          top: 0,
        })
        this.intro_movebar.left = '207px'
      } else if (i === 1) {
        window.scroll({
          behavior: 'auto',
          top: this.headerTop + 100,
        })
        this.intro_movebar.left = '528px'
      } else if (i === 3) {
        window.scroll({
          behavior: 'auto',
          top: margin + 600,
        })
        this.intro_movebar.left = '850px'
      }
    },
    // 옵션 선택
    selOption(type, i, value) {
      if (type === 'thick') {
        this.thickNum = i
        if (i !== 2) {
          this.selOption('kind', 0, '투명')
        }
      } else if (type === 'kind') {
        this.kindNum = i
        if (i === 1) {
          this.selOption('hologram_kind', 0, '쿨톤')
        } else {
          this.selOption('hologram_kind', null, null)
        }
      } else if (type === 'print') {
        this.printNum = i
      } else if (type === 'item') {
        this.itemNum = i
        if (i === 4) {
          this.selOption('keyring_kind', 0, '일반키링')
          this.selOption('keyring_color', 0, '실버')
        } else if (i === 2) {
          this.selOption('tagColor', 0, '일반')
        } else if (i === 5) {
          this.selOption('wiringColor', 0, '빨강')
        } else {
          this.selOption('keyring_kind', null, null)
          this.selOption('keyring_color', null, null)
        }
      } else if (type === 'keyring_kind') {
        if (i === 0) {
          this.selOption('keyring_color', 0, '실버')
        } else if (i === 7) {
          this.selOption('keyring_color', 2, '로즈골드')
        } else if (i === 8) {
          this.selOption('keyring_color', 0, '실버')
        }
        this.keyringKindNum = i
      } else if (type === 'keyring_color') {
        this.keyringColorNum = i
      } else if (type === 'packing') {
        this.packingNum = i
      } else if (type === 'tagColor') {
        this.tagColorNum = i
      } else if (type === 'wiringColor') {
        this.wireColorNum = i
      } else if (type === 'period') {
        this.periodNum = i
        this.packingNum = 0
        this.kindNum = 0
      } else if (type === 'hologram_kind') {
        this.hologramNum = i
      }
      this.$store.dispatch(`Goods/${type}`, value)
    },
    // quantity
    // 도안 또는 주문수량 input으로 입력시 이벤트
    quantityChange(i, path, goods_quantity) {
      this.$store.dispatch('Goods/quantity', {
        design_quantity: 1,
        order_quantity: goods_quantity,
        path,
      })
    },
    // 도안 또는 주문수량 버튼 클릭시 이벤트
    quantityCheck(i, type, path, goods_quantity) {
      if (type === 'minus') {
        if (goods_quantity > 1) {
          goods_quantity -= 1
        }
      } else {
        goods_quantity += 1
      }
      this.$store.dispatch('Goods/quantity', {
        design_quantity: 1,
        order_quantity: goods_quantity,
        path,
      })
    },
    async cartAdd() {
      let { goods } = this
      if (!goods.file) {
        this.Message('이미지를 선택해주세요.', 0)
        return
      }
      let buffer
      if (this.params === 'Quoin') {
        let svgData = document.getElementById('star')
        let dragged = document.getElementById('dragged')
        star.removeChild(dragged)
        let svgOuter = svgData.outerHTML
        buffer = svgOuter.replace(
          `width="${this.sizeBox.boxWidth}" height="${this.sizeBox.boxHeight}"`,
          `width="${await this.ptm(
            this.imgSize + this.sizeBox.boxMargin * 2,
          )}mm" height="${await this.ptm(this.imgSize + this.sizeBox.boxMargin * 2)}mm"`,
        )
      } else {
        buffer = this.canvas_file.toSVG()
      }
      let file_name = goods.file.name.substr(0, goods.file.name.length - 4)
      let svgFile = new File([buffer], `${file_name}.svg`, {
        type: 'text/plain',
      })
      // this.Cart.Add(goods, this.type, svgFile)
      await this.indexedDB.addOrder(goods, this.type, svgFile)
    },
    async ptm(px) {
      return (px * 25.4) / this.sizeBox.dpi
    },
    // 아크릴 종류 투명쉘 선택후 인쇄 양면선택시 안내문구 호출
    printPopup() {
      this.print_shell_popup = true
    },
    // 부자재 와이어링 선택시 안내문구 호출
    wiringPopup() {
      this.wiring_popup = true
    },
  },
}
</script>

<style scoped lang="scss">
.goodsWrap {
  width: 1160px;
  margin: 0 auto;
  font-weight: 400;

  h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
  }
}
</style>
