<template>
  <div class="wrap">
    <h1>주문 내역</h1>
    <order-search
      :start-date="startDate"
      :end-date="endDate"
      :search="search"
      @date-change="dateChange"
      @ago-select="agoSelect"
      @all-list="allList"
      @search-day="searchDay"
      @search-num="searchNum"
    />
    <div class="tableWrap">
      <h2>주문 상품</h2>
      <product-table
        v-if="get_product === true"
        :path="path"
        :products="tableData"
        @move-detail="moveDetail"
      />
    </div>
    <page-list :paging-data="pagingData" :params="params" @page-btn="pageBtn" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderSearch from '../../components/Order/OrderSearch.vue'
import ProductTable from '../../components/Public/ProductTable.vue'
import PageList from '../../components/Public/PageList.vue'

export default {
  components: {
    OrderSearch,
    ProductTable,
    PageList,
  },
  data() {
    return {
      startDate: '', // 기간별 조회 시작 날짜
      endDate: '', // 기간별 조회 끝 날짜
      path: '', // 현재 route name
      products: [], // 선택한 상품 목록
      tableData: [], // 받아온 상품목록을 페이징처리후 담을 데이터
      list_data: {}, // 페이지 이동시 기존에 있던 주문내역을 저장할 객체
      pagingData: {
        endPage: 5, // 끝 페이지
        startPage: 1, // 시작 페이지
        totalPage: 5, // 총 페이지 수
        totalItem: 0, // 받아온 주문내역의 수
        page_list_size: 5, // 한번에 몇개의 페이지가 보일건지
        page_size: 3, // 한 페이지에 몇개까지 보여줄건지
        curPage: 1, // 현재 페이지
        curSet: 0, // 몇번째 페이지리스트인가
        set_item: 0, // 페이지 리스트에 따른 아이템수
        start_range: 0, // 목록에서 데이터 불러올 시작값
        end_range: 0, // 목록에서 데터터 불러올 끝값
      },
      params: '', // 현재 페이지의 params값
      get_product: false, // 주문내역을 불러왔는지 여부
      search: '',
    }
  },
  watch: {
    async $route(to, from) {
      if (to.name === 'OrderList') {
        this.params = this.$route.params.type
        await this.setData()
        if (to.params.type !== from.params.type) {
          await this.getProduct(this.$route.query.page * 1)
        } else {
          if (to.query.start !== from.query.start || to.query.end !== from.query.end) {
            await this.getProduct(this.$route.query.page * 1)
          }
        }
        await this.setPagingData()
      }
    },
  },
  async mounted() {
    this.path = this.$route.name
    this.params = this.$route.params.type
    await this.setData()
    await this.getProduct(this.$route.query.page * 1)
    await this.setPagingData()
  },
  methods: {
    async setPagingData() {
      this.pagingData = await this.PagingData(
        this.pagingData,
        this.products,
        this.$route.query.page,
      )
      let data = []
      for (let i = this.pagingData.start_range; i < this.pagingData.end_range; i++) {
        data.push(this.products[i])
      }
      this.tableData = data
      this.get_product = true
    },
    // 처음 접속시 오늘 날짜로 세팅
    async setData() {
      if (this.$route.query.search) {
        this.search = this.$route.query.search
      } else {
        this.search = ''
      }
      if (this.$route.query.start) {
        this.startDate = this.moment(this.$route.query.start).tz('Asia/Seoul').format('YYYY-MM-DD')
        this.endDate = this.moment(this.$route.query.end).tz('Asia/Seoul').format('YYYY-MM-DD')
      } else {
        this.startDate = this.moment().tz('Asia/Seoul').format('YYYY-MM-DD')
        this.endDate = this.moment().tz('Asia/Seoul').format('YYYY-MM-DD')
      }
    },
    // 페이지 이동 이벤트 (type: prev, next, move, page: 이동할 페이지 번호 또는 +-1)
    async pageBtn(type, page) {
      let move_page
      let next_set = false
      // type이 prev일 경우 이전 페이지로 이동
      if (type === 'prev') {
        move_page = this.$route.query.page * 1 - page
        // 이동해야될 페이지 번호가 pagingData.page_list_size의 배수일 경우 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 0) {
          next_set = true
        }
      } else if (type === 'next') {
        move_page = this.$route.query.page * 1 + page
        // 이동해야될 페이지 번호가 pagingData.page_list_size로 나누었을때 값이 1이라면 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 1) {
          next_set = true
        }
      } else if (type === 'move') {
        move_page = page
      }
      // list_data의 속성값
      let cur_set = Math.ceil(move_page / this.pagingData.page_list_size)
      if (next_set === true) {
        // list_data에 cur_set의 값이 있을경우 list_data의 cur_set속성값을 products으로 설정
        if (this.list_data[cur_set]) {
          this.products = this.list_data[cur_set]
        } else {
          // 없을경우 api에서 주문내역값 조회
          await this.getProduct(move_page)
          if (this.products.length === 0) {
            // 주문내역 조회하였을떄 값이 없다면 메세지 호출
            this.Message('주문내역이 없습니다.', 0, 0)
            return
          }
        }
      }
      this.list_data[cur_set] = this.products
      await this.$router.push({
        name: this.$route.name,
        params: { type: this.params },
        query: {
          page: move_page,
          search: this.$route.query.search,
          start: this.$route.query.start,
          end: this.$route.query.end,
        },
      })
    },
    // 날짜 변경 이벤트
    dateChange(start_year, start_month, start_day, end_year, end_month, end_day) {
      this.startDate = this.moment(`${start_year}-${start_month}-${start_day}`)
        .tz('Asia/Seoul')
        .format('YYYY-MM-DD')
      this.endDate = this.moment(`${end_year}-${end_month}-${end_day}`)
        .tz('Asia/Seoul')
        .format('YYYY-MM-DD')
    },
    // 오늘 날짜 기준으로 1주, 1개월, 6개월 전 날짜조회
    async agoSelect(num, date) {
      let agoDate = this.moment().subtract(num, date).tz('Asia/Seoul').format('YYYY-MM-DD')
      this.startDate = this.moment(agoDate).tz('Asia/Seoul').format('YYYY-MM-DD')
      this.endDate = this.moment().tz('Asia/Seoul').format('YYYY-MM-DD')
    },
    // 주문내역 조회
    async getProduct(curPage) {
      // api에 넘길 skip값
      let skip = Math.ceil(curPage / this.pagingData.page_list_size)
      // 페이지 params값에 따라 다른 api호출
      let url
      // 기간별 조회
      if (this.$route.params.type === 'Date') {
        url = `${this.Path.GetOrderDateList}?start=${this.startDate} 00:00:00&end=${this.endDate} 23:59:59&skip=${skip}`
      }
      // 주문번호 검색
      else if (this.$route.params.type === 'Search') {
        url = `${this.Path.GetOrderSearchList}?merchant_uid=${this.search}`
      }
      // 모든 주문내역 조회
      else {
        url = `${this.Path.GetOrderAllList}?skip=${skip}`
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      }
      await this.axios
        .get(url, config)
        .then(async (res) => {
          if (res.data.success === true) {
            this.products = res.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 상세페이지로 이동
    moveDetail(product) {
      this.$router.push({
        name: 'OrderDetail',
        params: { type: this.params },
        query: {
          page: this.$route.query.page,
          payment_id: product.id,
        },
      })
    },
    // 전체 주문내역 조회
    allList() {
      this.$router.push({
        name: this.$route.name,
        params: { type: 'All' },
        query: {
          page: 1,
        },
      })
    },
    // 날짜로 검색
    searchDay() {
      this.$router.push({
        name: this.$route.name,
        params: { type: 'Date' },
        query: {
          page: 1,
          start: this.startDate,
          end: this.endDate,
        },
      })
    },
    // 주문번호로 검색
    searchNum(search) {
      this.$router.push({
        name: this.$route.name,
        params: { type: 'Search' },
        query: {
          page: 1,
          search,
        },
      })
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'UserInfo/userInfo',
      userToken: 'UserInfo/userToken',
    }),
  },
}
</script>

<style scoped lang="scss">
.wrap {
  font-family: 'Noto Sans KR';
  width: 1160px;
  color: #707070;
  margin: 0 auto;

  > h1 {
    color: #707070;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
  }

  > .tableWrap {
    height: 750px;
    width: 100%;
    margin: 100px auto 0;

    > h2 {
      font-size: 16px;
      color: #707070;
      text-align: left;
      font-weight: 400;
    }

    > table {
      width: 100%;
      border-collapse: collapse;
    }
  }
}
</style>
