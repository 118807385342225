<template>
  <div class="rightWrap clearFix sticky">
    <div class="detailWrap">
      <div class="explanation">
        <div class="imageBox">
          <img src="@/assets/goods/right_items.png" alt="" />
        </div>
        <h2>{{ type }}</h2>
        <p>
          ※ <span>타제품과 함께 주문</span>할 경우 <br />&nbsp;&nbsp;&nbsp;&nbsp;도안이 접수된
          날로부터 영업일 기준
          <span>최대 {{ production_period }}일 소요</span>
        </p>
      </div>
      <item-select
        v-if="params === 'Items'"
        :type="type"
        :Oring="Oring"
        :dog-tag="dogTag"
        :wiring="wiring"
        :smart-tok="smartTok"
        :badge="badge"
        :strap="strap"
        :keyring-type="keyringType"
        @plmin-btn="plminBtn"
        @num-change="numChange"
      />
      <ticket-package-select
        v-if="params === 'Ticket_Package'"
        :type="type"
        :ticket_package="ticket_package"
        :ticket_package_quantity="ticket_package_quantity"
        @Ticket_Package_plmin-btn="Ticket_Package_plminBtn"
        @Ticket_Package_num-change="Ticket_Package_numChange"
      />
      <ticket-select
        v-if="params === 'Ticket'"
        :type="type"
        :ticket="ticket"
        :ticket_quantity="ticket_quantity"
        @Ticket_plmin-btn="Ticket_plminBtn"
        @Ticket_num-change="Ticket_numChange"
      />
      <div class="priceBox">
        <div class="clearFix">
          <span class="left">총 금액</span>
          <span class="right"
            ><span v-if="params === 'Items'" class="strong">￦{{ itemPrice }}</span>
            <span v-if="params === 'Ticket_Package' || params === 'Ticket'" class="strong"
              >￦{{ ticketPrice }}</span
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSelect from '../List/EtcItem/Item_Select.vue'
import TicketPackageSelect from '../List/EtcItem/Ticket_Package_Select.vue'
import TicketSelect from '../List/EtcItem/Ticket_Select.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ItemSelect,
    TicketPackageSelect,
    TicketSelect,
  },
  props: {
    type: {
      type: String,
    },
    params: {
      type: String,
    },
    Oring: {
      type: Number,
    },
    wiring: {
      type: Array,
    },
    dogTag: {
      type: Array,
    },
    smartTok: {
      type: Object,
    },
    badge: {
      type: Object,
    },
    strap: {
      type: Number,
    },
    keyringType: {
      type: Array,
    },
    itemPrice: {
      type: Number,
    },
    ticket: {
      type: Number,
    },
    ticket_package: {
      type: Number,
    },
    ticketPrice: {
      type: Number,
    },
    ticket_package_quantity: {
      type: Number,
    },
    ticket_quantity: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      production_period: 'Goods/production_period',
      shell_period: 'Goods/shell_period',
    }),
  },
  methods: {
    plminBtn(fm, type, num, index, color) {
      this.$emit('plminBtn', fm, type, num, index, color)
    },
    numChange(type, value, index, color) {
      this.$emit('numChange', type, value, index, color)
    },
    Ticket_Package_plminBtn(fm, type, num) {
      this.$emit('Ticket_Package_plminBtn', fm, type, num)
    },
    Ticket_Package_numChange(type, value) {
      this.$emit('Ticket_Package_numChange', type, value)
    },
    Ticket_plminBtn(fm, type, num) {
      this.$emit('Ticket_plminBtn', fm, type, num)
    },
    Ticket_numChange(type, value) {
      this.$emit('Ticket_numChange', type, value)
    },
  },
}
</script>

<style scoped lang="scss">
.rightWrap {
  float: right;
  width: 410px;
  height: 100vh;
  background-color: #fff7fa;

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  > .detailWrap {
    > .explanation {
      width: 345px;
      margin: 0 auto;
      > .imageBox {
        border-bottom: 1px solid #ff6699;
        text-align: center;
        padding: 15px 0;
      }

      > h2 {
        color: #707070;
        margin: 31px auto 0;
      }

      > p {
        font-size: 12px;
        padding-bottom: 19px;
        border-bottom: 1px solid #ff6699;

        > span {
          color: #ff6699;
        }
      }
    }
    > .priceBox {
      width: 345px;
      margin: 0 auto;
      position: absolute;
      bottom: 10px;
      left: 50%;
      margin-left: -172px;

      > div {
        > .left {
          float: left;
          color: #656565;
          font-size: 12px;
          margin-top: 15px;
        }

        > .right {
          float: right;
          color: #707070;
          font-size: 12px;

          > .strong {
            font-size: 24px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
