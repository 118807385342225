<template>
  <div class="layer">
    <h1>2. 레이어 구성</h1>
    <!-- type === 아크릴 키링 -->
    <div v-show="type === '아크릴 키링'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/cham_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p>파일에는 다섯 가지(양면일 경우 여섯 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 내경 레이어</p>
            <p>2. 일러스트 레이어</p>
            <p>3. 후면 레이어(양면 인쇄일 경우)</p>
            <p>4. 화이트 레이어</p>
            <p>5. 커팅 레이어</p>
            <p>6. 외경 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 아크릴 카드텍 -->
    <div v-show="type === '아크릴 카드텍'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/card_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/card_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p>파일에는 세 가지(양면일 경우 네 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 일러스트 레이어</p>
            <p>2. 후면 레이어(양면 인쇄일 경우)</p>
            <p>3. 화이트 레이어</p>
            <p>4. 커팅 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 아크릴 스탠드 -->
    <div v-show="type === '아크릴 스탠드'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/stand_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p>파일에는 세 가지(양면일 경우 네 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 일러스트 레이어</p>
            <p>2. 후면 레이어(양면 인쇄일 경우)</p>
            <p>3. 화이트 레이어</p>
            <p>4. 커팅 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 자유형 책갈피 -->
    <div v-show="type === '자유형 책갈피'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/bookmark_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/bookmark_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top bookmark">
            <p>파일에는 네 가지(양면일 경우 다섯 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 일러스트 레이어</p>
            <p>2. 후면 레이어(양면 인쇄일 경우)</p>
            <p>3. 화이트 레이어</p>
            <p>4. 접는 선 레이어</p>
            <p>5. 커팅 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 아크릴 바닥 부품 -->
    <div v-show="type === '아크릴 바닥 부품'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/floor_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p>파일에는 네 가지(양면일 경우 다섯 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 구멍 레이어</p>
            <p>2. 일러스트 레이어</p>
            <p>3. 후면 레이어(양면 인쇄일 경우)</p>
            <p>4. 화이트 레이어</p>
            <p>5. 커팅 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 아크릴 램프 -->
    <div v-show="type === '아크릴 램프'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/lamp_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p>파일에는 세 가지(양면일 경우 네 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 일러스트 레이어</p>
            <p>2. 후면 레이어(양면 인쇄일 경우)</p>
            <p>3. 화이트 레이어</p>
            <p>4. 커팅 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 부착형 아크릴 -->
    <div v-show="type === '부착형 아크릴'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/attach_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p>파일에는 네 가지 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 위치 레이어</p>
            <p>2. 일러스트 레이어</p>
            <p>3. 화이트 레이어</p>
            <p>4. 커팅 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 아크릴 블럭 -->
    <div v-show="type === '아크릴 블럭'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/block_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/block_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p class="strong">[아크릴 블럭]</p>
            <p>파일에는 세 가지(양면일 경우 네 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 일러스트 레이어</p>
            <p>2. 후면 레이어(양면 인쇄일 경우)</p>
            <p>3. 화이트 레이어</p>
            <p>4. 커팅 레이어</p>
          </div>
        </div>
      </div>

      <div class="ex clearFix mt-4">
        <div class="left">
          <img src="@/assets/guide/solid_block_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top">
            <p class="strong">[입체 아크릴 블럭]</p>
            <p>파일에는 다섯 가지(양면일 경우 여섯 가지) 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <p>1. 앞면 일러스트 레이어</p>
            <p>2. 앞면 화이트 레이어</p>
            <p>3. 뒷면 일러스트 레이어</p>
            <p>4. 후면 레이어(양면 인쇄일 경우)</p>
            <p>5. 뒷면 화이트 레이어</p>
            <p>6. 커팅 레이어</p>
          </div>
        </div>
      </div>
    </div>
    <!-- type === 쉐이커 키링 -->
    <div v-show="type === '쉐이커 키링'" class="typeWrap">
      <div class="fullImg">
        <img src="@/assets/guide/shaker_keyring_layer_1.png" alt="" />
        <figure>완성된 도안 파일 스크린 샷</figure>
      </div>
      <div class="ex clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_layer_2.png" alt="" />
        </div>
        <div class="right">
          <div class="top shaker_top">
            <p>파일에는 세 가지 폴더와 필수 레이어만 존재해야 합니다.</p>
          </div>
          <div class="bottom">
            <ul>
              <li>1. 내경 레이어</li>
              <li>2. 외경 레이어</li>
              <li>3. 프레임 구멍(2개 이상~)</li>
              <li>
                4. 쉐이커 앞면
                <ul>
                  <li>- 일러스트 레이어(있을 경우)</li>
                  <li>- 후면 레이어(있을 경우)</li>
                  <li>- 화이트 레이어(있을 경우)</li>
                  <li>- 커팅 레이어</li>
                </ul>
              </li>
              <li>
                5. 쉐이커 파츠
                <ul>
                  <li>- 일러스트 레이어</li>
                  <li>- 후면 레이어(있을 경우)</li>
                  <li>- 화이트 레이어</li>
                  <li>- 커팅 레이어</li>
                  <li>- 판 커팅 레이어</li>
                </ul>
              </li>
              <li>
                6. 쉐이커 뒷면
                <ul>
                  <li>- 일러스트 레이어</li>
                  <li>- 후면 레이어(있을 경우)</li>
                  <li>- 화이트 레이어</li>
                  <li>- 커팅 레이어</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.layer {
  text-align: center;

  h1 {
    width: 100%;
    margin-bottom: 20px;
  }

  figure {
    margin-top: 10px;
  }
  > .typeWrap {
    > .fullImg {
      margin-bottom: 100px;
    }

    > .ex {
      > div {
        float: left;
        margin-left: 35px;

        > .top {
          margin-top: 200px;
          margin-bottom: 150px;
          text-align: left;

          &.shaker_top {
            margin-top: 450px;
          }

          &.bookmark {
            margin-top: 100px;
          }
          > p {
            font-size: 20px;
            color: #707070;
            font-weight: 500;
            margin-top: 20px;

            &.strong {
              font-weight: bold;
              color: #4a4545;
            }
          }
        }

        > .bottom {
          > p {
            font-size: 20px;
            color: #707070;
            font-weight: 500;
            margin-top: 20px;
            text-align: left;
          }

          > ul {
            > li {
              font-size: 20px;
              color: #707070;
              font-weight: 500;
              margin-top: 20px;
              text-align: left;

              > ul {
                margin-left: 20px;
              }
            }
          }
        }
      }

      &.mt-4 {
        margin-top: 4rem;
        > div {
          > .top {
            margin-top: 150px;
          }
        }
      }
    }
  }
}
</style>
