<template>
  <div
    class="announcementPopupBox"
    :style="{
      top: `${announcement.position_y}px`,
      left: `${announcement.position_x}px`,
      zIndex: `900${announcement.z_index}`,
    }"
  >
    <div class="closeTab" @click="closetab" @keydown="closetab">
      <img src="@/assets/icon/close_btn_black.png" alt="" />
    </div>
    <div class="announcementPopup">
      <img :src="announcement.img_url" alt="" />
      <div class="btnBox" />
      <div class="checkBox clearFix">
        <ul>
          <li @click="setCookie('오늘하루보지않기', 1)" @keydown="setCookie('오늘하루보지않기', 1)">
            오늘 하루 보지않기
          </li>
          <li @click="setCookie('7일동안 보지않기', 7)" @keydown="setCookie('7일동안 보지않기', 7)">
            7일동안 보지않기
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie'

export default {
  props: {
    announcement: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    async closetab() {
      this.$emit('closeTab', this.index)
    },
    setCookie(value, day) {
      Cookie.set(this.announcement.title, value, { expires: day })
      this.$emit('closeTab', this.index)
    },
  },
}
</script>
<style scoped lang="scss">
.announcementPopupBox {
  position: absolute;
  display: block;
  background-color: #ffffff;
  box-shadow: 2px 3px 6px #0000004d;
  border-radius: 5px;
  overflow: hidden;
  // transform: translate(-50%, -50%);
  // -webkit-transform: translate(-50%, -50%);
  padding-bottom: 10px;

  > .closeTab {
    position: absolute;
    /* border:1px solid #000; */
    top: 3%;
    right: 3%;
    cursor: pointer;
    z-index: 1002;
    width: 24px;
  }

  > .announcementPopup {
    > .btnBox {
      width: 100%;
      border-bottom: 2px solid #fea9aa;
      text-align: center;
    }

    > .checkBox {
      width: 90%;
      margin: 0 auto;

      > ul {
        float: right;
        margin-top: 7px;

        > li {
          float: left;
          font-size: 12px;
          color: #ab7979;
          cursor: pointer;
          margin-left: 17px;
        }
      }
    }
  }
}
</style>
