<template>
  <div class="wrap">
    <div class="contactBox centerBox">
      <h1>CONTACT</h1>
      <hr />
      <div>
        <p>
          <span>E - MAIL : </span>
          <span>craft.dongne@gmail.com</span>
        </p>
        <p>
          <span>TELL : </span>
          <span>070-8848-8489</span>
        </p>
      </div>
      <div>
        <p>
          <span>
            동네 공작소는 언제나 새로운 물품제작, 건의사항, 문의사항 등을
            <br />
            적극 수용하기 위해 노력하고 있습니다.
          </span>
        </p>
      </div>
    </div>
    <div class="officeBox centerBox">
      <h1>OFFICE</h1>
      <hr />
      <div>
        <p>
          <span>201 ho, 62, Noryangjin-ro 26-gil, Dongjak-gu, Seoul, Republic of Korea</span>
        </p>
        <p>
          <span>서울시 동작구 본동 485, 아파트 상가건물 2층(동네공작소)</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrap {
  width: 1160px;
  margin: 0 auto;

  > .centerBox {
    margin-top: 124px;

    > h1 {
      font-size: 25px;
      font-weight: 700;
      color: #ff6699;
      text-align: center;
    }

    > hr {
      width: 460px;
      height: 4px;
      border: none;
      background-color: #ff6699;
      margin: 16px auto 36px;
    }

    > div {
      text-align: center;
      font-size: 20px;
      color: #707070;
      font-weight: 400;
      margin-bottom: 36px;
      line-height: 35px;

      //   span {
      //     padding: 0px 8px;
      //     position: relative;

      //     &:first-child {
      //       &::after {
      //         display: none;
      //       }
      //     }

      //     &::after {
      //       content: '';
      //       position: absolute;
      //       left: 0;
      //       top: 4px;
      //       width: 1px;
      //       height: 20px;
      //       background-color: #cacaca;
      //     }
      //   }
    }
  }

  > .officeBox {
    margin-bottom: 350px;
  }
}
</style>
