<template>
  <div>
    <order-list />
  </div>
</template>

<script>
import OrderList from '../../containers/Order/ListContainer.vue'

export default {
  components: {
    OrderList,
  },
}
</script>

<style></style>
