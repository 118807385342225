<template>
  <div :class="{ step: path === 'Basic', SpecialStep: path === 'Special' }">
    <h2>아크릴 제작 기간</h2>
    <ul>
      <li
        :class="{
          active: periodNum === 0,
          disabled: quick_order === false || quick_order === 'false',
        }"
        @click="checkPeriod(0, '빠른제작')"
        @keydown="checkPeriod(0, '빠른제작')"
      >
        빠른 제작
      </li>
      <li
        :class="{ active: periodNum === 1 }"
        @click="checkPeriod(1, '일반제작')"
        @keydown="checkPeriod(1, '일반제작')"
      >
        일반 제작
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    periodNum: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
      quick_order: 'Goods/quick_order',
    }),
  },
  methods: {
    checkPeriod(i, period) {
      this.$emit('checkPeriod', 'period', i, period)
    },
  },
}
</script>
<style scoped lang="scss">
// basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > ul {
    display: flex;
    justify-content: space-around;
    > li {
      width: 45%;
      text-align: center;
      font-size: 20px;
      height: 65px;
      border: 2px solid #bcbcbc;
      border-radius: 10px;
      line-height: 65px;
      cursor: pointer;
      color: #707070;

      &.active {
        border: 2px solid #ff6699;
        color: #ff6699;
      }

      &.disabled {
        pointer-events: none;
        background-color: #bcbcbc;
        border: 2px solid #bcbcbc;
        color: #707070;
      }
    }
  }
}
// special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }
  > ul {
    display: flex;
    justify-content: space-around;
    > li {
      width: 45%;
      text-align: center;
      font-size: 20px;
      height: 65px;
      border: 2px solid #bcbcbc;
      border-radius: 10px;
      line-height: 65px;
      cursor: pointer;
      color: #707070;

      &.active {
        border: 2px solid #ff6699;
        color: #ff6699;
      }

      &.disabled {
        pointer-events: none;
        background-color: #bcbcbc;
        border: 2px solid #bcbcbc;
        color: #707070;
      }
    }
  }
}
</style>
