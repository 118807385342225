<template>
  <div :class="{ step: path === 'Basic' || path === 'Template', SpecialStep: path === 'Special' }">
    <h2>부자재 선택</h2>
    <!--  -->
    <div
      v-if="
        type === '아크릴 카드텍' ||
        type === '자유형 책갈피' ||
        type === '아크릴 램프' ||
        type === '아크릴 바닥 부품' ||
        path === 'Template'
      "
      class="cardItemBox clearFix"
    >
      <ul class="itemBtns">
        <li
          class="itemBtn"
          :class="{ active: itemNum === 0 }"
          @click="checkItem(0, '없음', 0)"
          @keydown="checkItem(0, '없음', 0)"
        >
          <div>
            <p>부자재없음</p>
            <hr />
            <img
              v-if="itemNum === 0"
              class="item_img"
              src="@/assets/goods/card_none_active.svg"
              alt=""
            />
            <img v-else class="item_img" src="@/assets/goods/card_none_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: itemNum === 1 }"
          @click="checkItem(1, 'OPP', 400)"
          @keydown="checkItem(1, 'OPP', 400)"
        >
          <div>
            <p>OPP</p>
            <hr />
            <img
              v-if="itemNum === 1"
              class="item_img"
              src="@/assets/goods/card_opp_active.svg"
              alt=""
            />
            <img v-else class="item_img" src="@/assets/goods/card_opp_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <!-- 일반 아크릴 상품 -->
    <div
      v-if="
        type !== '아크릴 카드텍' &&
        type !== '자유형 책갈피' &&
        type !== '아크릴 램프' &&
        type !== '아크릴 바닥 부품' &&
        path !== 'Template' &&
        type !== '부착형 아크릴'
      "
      class="itemBox clearFix"
    >
      <div>
        <input
          id="itemnone"
          type="checkbox"
          :checked="itemNum === 0"
          :disabled="itemNum === 0"
          @change="checkItem(0, '없음', 0)"
        /><label for="itemnone">부자재 없음</label>
      </div>
      <ul class="itemBtns">
        <li
          class="itemBtn"
          :class="{ active: itemNum === 1 }"
          @click="checkItem(1, 'O링', 400)"
          @keydown="checkItem(1, 'O링', 400)"
        >
          <div>
            <p>O링</p>
            <hr />
            <div />
            <img v-if="itemNum === 1" src="@/assets/goods/item_오링_active.svg" alt="" />
            <img v-else class="item_img" src="@/assets/goods/item_오링_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: itemNum === 2 }"
          @click="checkItem(2, '군번줄', 400)"
          @keydown="checkItem(2, '군번줄', 400)"
        >
          <div>
            <p>군번줄</p>
            <hr />
            <img v-if="itemNum === 2" src="@/assets/goods/item_군번줄_active.svg" alt="" />
            <img v-else class="item_img" src="@/assets/goods/item_군번줄_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: itemNum === 3 }"
          @click="checkItem(3, '휴대폰줄', 400)"
          @keydown="checkItem(3, '휴대폰줄', 400)"
        >
          <div>
            <p>휴대폰줄</p>
            <hr />
            <img v-if="itemNum === 3" src="@/assets/goods/item_휴대폰줄_active.svg" alt="" />
            <img v-else class="item_img" src="@/assets/goods/item_휴대폰줄_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: itemNum === 4 }"
          @click="checkItem(4, '고리', 1100)"
          @keydown="checkItem(4, '고리', 1100)"
        >
          <div>
            <p>고리</p>
            <hr />
            <img v-if="itemNum === 4" src="@/assets/goods/item_키링_active.svg" alt="" />
            <img v-else class="item_img" src="@/assets/goods/item_키링_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: itemNum === 5 }"
          @click="checkItem(5, '와이어링', 400)"
          @keydown="checkItem(5, '와이어링', 400)"
        >
          <div>
            <p>와이어링</p>
            <hr />
            <img v-if="itemNum === 5" src="@/assets/goods/item_와이어링_active.svg" alt="" />
            <img v-else class="item_img" src="@/assets/goods/item_와이어링_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <!-- 부착형 아크릴 -->
    <div v-if="type === '부착형 아크릴'" class="attachItemBox clearFix">
      <div>
        <input
          id="itemnone"
          type="checkbox"
          :checked="itemNum === 0"
          :disabled="itemNum === 0"
          @change="checkItem(0, '없음', 0)"
        /><label for="itemnone">부자재 없음</label>
      </div>
      <ul class="itemBtns">
        <li
          class="itemBtn"
          :class="{ active: itemNum === 1 }"
          @click="checkItem(1, '뱃지', 400)"
          @keydown="checkItem(1, '뱃지', 400)"
        >
          <div>
            <p>뱃지</p>
            <hr />
            <div />
            <img v-if="itemNum === 1" src="@/assets/goods/badge_active.svg" alt="" />
            <img v-else class="item_img" src="@/assets/goods/badge_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: itemNum === 2 }"
          @click="checkItem(2, '스마트톡', 400)"
          @keydown="checkItem(2, '스마트톡', 400)"
        >
          <div>
            <p>스마트톡</p>
            <hr />
            <img v-if="itemNum === 2" src="@/assets/goods/smart_tok_active.svg" alt="" />
            <img v-else class="item_img" src="@/assets/goods/smart_tok_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    itemNum: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  methods: {
    async checkItem(i, item, margin) {
      await this.$emit('checkItem', 'item', i, item)
      if (item === '와이어링') {
        await this.$emit('wiringPopup')
      }
    },
  },
}
</script>

<style scoped lang="scss">
// Basic
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .cardItemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;
      margin-top: 8px;
      margin-bottom: 8px;

      > li {
        width: 280px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 0px;
        cursor: pointer;

        &:first-child {
          margin-right: 14px;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 234px;
          height: 100px;
          margin: 26px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          span {
            font-size: 10px;
            color: #707070;
            display: block;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 100px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  > .itemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;
      margin-top: 8px;
      margin-bottom: 8px;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 17px;
        cursor: pointer;
        margin-bottom: 15px;

        &:nth-child(3),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        &:first-child {
          > div {
            > img {
              width: 32px;
              height: 32px;
              margin: 60px 55px;
            }
          }
        }

        > div {
          width: 144px;
          height: 185px;
          margin: 26px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          span {
            font-size: 10px;
            color: #707070;
            display: block;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 100px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  > .attachItemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;
      margin-top: 8px;
      margin-bottom: 8px;

      > li {
        width: 280px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 233px;
          height: 185px;
          margin: 26px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          span {
            font-size: 10px;
            color: #707070;
            display: block;
          }

          > img {
            margin-top: 25px;
            width: 100%;
            height: 120px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

// Special
.SpecialStep {
  width: 380px;
  margin: 0 auto 55px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 13px;
  }

  > .itemBox {
    font-weight: 400;

    > div {
      margin-bottom: 5px;
    }

    > .itemBtns {
      float: left;

      > li {
        width: 118px;
        height: 160px;
        margin-right: 9px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 10px;

        &:nth-child(3),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        &:first-child {
          > div {
            > img {
              width: 21px;
              height: 21px;
              margin: 35px 35px;
            }
          }
        }

        > div {
          width: 90px;
          height: auto;
          margin: 26px auto 0;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 1px;
          }

          > span {
            display: block;
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            width: 100%;
            height: 70px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

input[type='checkbox'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      content: ' ';
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 15px;
      margin: -2px 8px 0 0;
      text-align: center;
      vertical-align: middle;
      background: #fafafa;
      border: 1px solid #979797;
      border-radius: 5px;
    }
  }

  &:checked {
    + label {
      &::before {
        content: '\2713';
        /* font-weight: 700; */
        color: #ff6699;
        text-shadow: 0.5px 0.5px #ff6699;
        background: #fedbe8;
        border-color: #979797;
      }
    }
  }
}

label {
  color: #707070;
  font-size: 14px;
}
</style>
