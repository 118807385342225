<template>
  <div class="step">
    <h2>군번줄 색상 선택</h2>
    <div class="dogTagWrap">
      <div class="dogTagBox clearFix">
        <div class="imgBox">
          <img src="@/assets/goods/item_군번줄_active.svg" alt="" />
        </div>
        <div class="colorBox">
          <div class="default">
            <div
              :class="{ active: dogTag[0].amount > 0 }"
              @click.prevent="checkTagColor(dogTag[0].amount, 0)"
              @keydown.prevent="checkTagColor(dogTag[0].amount, 0)"
            />
          </div>
          <div class="line" />
          <div class="color">
            <div
              v-for="(tag, index) in dogTag"
              v-show="index > 0"
              :key="index"
              :style="{ backgroundColor: tag.color }"
              :class="{ active: tag.amount > 0 }"
              @click.prevent="checkTagColor(tag.amount, index)"
              @keydown.prevent="checkTagColor(tag.amount, index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dogTag: {
      type: Array,
    },
  },
  methods: {
    checkTagColor(num, index) {
      this.$emit('checkTagColor', num, index)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .dogTagWrap {
    width: 100%;
    height: 150px;
    border: 2px solid #bcbcbc;
    box-sizing: border-box;
    border-radius: 10px;

    > .dogTagBox {
      width: 95%;
      margin: 30px auto;

      > .imgBox {
        width: 78px;
        float: left;
      }

      > .colorBox {
        width: 440px;
        float: left;
        margin-left: 15px;

        > .default {
          float: left;

          > div {
            width: 35px;
            height: 35px;
            background-color: #c7c7c7;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid #bcbcbc;
            box-sizing: border-box;

            &.active {
              border: 2px solid #f25ca2;
              background-image: url('../../../../assets/goods/dog_tag_color_check.png');
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 80%;
            }
          }
        }

        > .line {
          float: left;
          width: 2px;
          height: 88px;
          background-color: #e6e6e6;
          margin: 0 0px 0 15px;
        }

        > .color {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          height: 105px;

          > div {
            width: 35px;
            height: 35px;
            cursor: pointer;
            border-radius: 3px;
            margin-left: 15px;
            border: 1px solid #bcbcbc;
            box-sizing: border-box;

            &.active {
              border: 2px solid #f25ca2;
              background-image: url('../../../../assets/goods/dog_tag_color_check.png');
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 80%;
            }
          }
        }
      }
    }
  }
}
</style>
