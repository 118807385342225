<template>
  <div class="priceWrap clearFix">
    <div class="priceBox clearFix">
      <ul class="clearFix">
        <li>
          <span>총 상품금액</span>
          <span class="medium">{{ Commas(sumValue) }} 원</span>
        </li>
        <li>
          <img src="@/assets/icon/plusIcon.svg" alt="" />
        </li>
        <li>
          <span>배송비</span>
          <span class="medium">{{ Commas(shippingFee) }} 원</span>
          <span class="reference">50,000원 이상 무료배송</span>
        </li>
        <li>
          <img src="@/assets/icon/isIcon.svg" alt="" />
        </li>
        <li>
          <span>총 주문금액</span>
          <span class="medium">{{ Commas(fullPrice) }} 원</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sumValue: {
      type: Number,
    },
    shippingFee: {
      type: Number,
    },
    fullPrice: {
      type: Number,
    },
  },
}
</script>

<style scoped lang="scss">
.priceWrap {
  width: 100%;
  height: 130px;
  background-color: #ffe1ec;
  border-radius: 10px;

  > .priceBox {
    width: 100%;
    margin: 0 auto;

    > ul {
      display: flex;
      justify-content: center;
      > li {
        float: left;
        font-size: 16px;
        color: #656565;
        margin-top: 32px;
        position: relative;

        > span {
          margin-right: 25px;

          &.medium {
            font-weight: 500;
          }

          &.reference {
            position: absolute;
            top: 25px;
            left: 0;
            font-size: 10px;
            color: #979797;
            margin-right: 0;

            > img {
              width: 9px;
              height: 9px;
            }
          }
        }
        > img {
          margin-right: 25px;
          text-align: center;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
