<template>
  <div class="loginWrap">
    <div class="logoBox">
      <img src="@/assets/icon/loginLogo.svg" alt="" />
    </div>
    <div class="formBox clearFix">
      <form method="post" @submit.prevent="login()">
        <div class="idBox">
          <p>ID</p>
          <input
            id="page_id"
            v-model="user_id"
            type="text"
            name="username"
            aria-label="user_id"
            @focus="messageReset()"
          />
        </div>
        <div class="pwBox">
          <p>PW</p>
          <input
            id="page_password"
            v-model="password"
            type="password"
            name="password"
            @focus="messageReset()"
          />
        </div>
        <div class="error">
          {{ form_error }}
        </div>
        <button type="submit" class="loginBtn">로그인</button>
      </form>
      <div class="moveBtn clearFix">
        <ul>
          <li @click="modalBtn('register')" @keydown="modalBtn('register')">회원가입</li>
          <li @click="modalBtn('find')" @keydown="modalBtn('find')">아이디/비밀번호찾기</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user_id: '',
      password: '',
      from_error: '',
    }
  },
  methods: {
    async login() {
      await this.axios
        .post(this.Path.SignIn, {
          user_id: this.user_id,
          password: this.password,
        })
        .then(async (res) => {
          if (res.data.success === true) {
            const token = res.data.data
            await this.$store.dispatch('UserInfo/login', token)
          }
        })
        .catch((err) => {
          this.Message(this.ErrorMessage.Login(err.response.status), 0, 0)
        })
    },
    // 에러메세지 숨김
    messageReset() {
      this.form_error = ''
    },
    modalBtn(type) {
      this.emitter.emit('loginView', type)
    },
  },
}
</script>

<style scoped lang="scss">
.loginWrap {
  width: 556px;
  margin: 80px auto;

  > .logoBox {
    width: 368px;
    margin: 0 auto 40px;
    text-align: center;

    > img {
      width: 180px;
    }
  }
  > .formBox {
    width: 368px;
    margin: 0px auto;

    > form {
      border-bottom: 1px solid #ff6699;

      > div {
        > p {
          text-indent: 5px;
        }

        > input {
          width: 100%;
          height: 48px;
          border: 1px solid #f25ca24d;
          border-radius: 10px;
          text-indent: 34px;
          font-size: 15px;
          color: #707070;
          box-sizing: border-box;
          background-color: #f2e3ea;
          text-align: left;

          &:focus {
            background-color: #fff;
            border: 2px solid #ff6699;
          }
        }

        &.pwBox {
          margin-top: 11px;
        }
      }

      > .loginBtn {
        width: 100%;
        border: none;
        height: 73px;
        background-color: #ff6699;
        color: white;
        font-size: 25px;
        text-align: center;
        line-height: 73px;
        border-radius: 10px;
        margin-top: 70px;
        margin-bottom: 38px;
        cursor: pointer;
      }
    }

    > .moveBtn {
      > ul {
        > li {
          float: left;
          width: 50%;
          text-align: center;
          color: #707070;
          margin-top: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
