<template>
  <table class="tableWrap">
    <thead>
      <!-- 장바구니, 결제 페이지 -->
      <tr v-show="path !== 'OrderList' && path !== 'OrderDetail'" class="tHead">
        <th v-if="path === 'Cart'" class="col allCheck">
          <input id="cart_allBox" v-model="checked" type="checkbox" @change="checkAll()" />
          <label for="cart_allBox" class="allBox" />
        </th>
        <th class="col headName">상품 정보</th>
        <th class="col cols">수량</th>
        <th class="col headPrice">가격</th>
        <th class="col cols">도안파일</th>
      </tr>
      <!-- 주문내역 페이지일떄 -->
      <tr v-show="path === 'OrderList'" class="tHead">
        <th class="col headName">상품 정보</th>
        <th class="col cols">주문금액</th>
        <th class="col cols">결제여부</th>
        <th class="col headPrice">제작진행상태</th>
        <th />
      </tr>
      <!-- 주문내역 상세 페이지일때 -->
      <tr v-show="path === 'OrderDetail'" class="tHead">
        <th class="col headName">상품 정보</th>
        <th class="col cols">주문수량</th>
        <th class="col cols">판매금액</th>
        <th class="col headPrice">도안파일</th>
      </tr>
    </thead>
    <!-- 장바구니, 결제 페이지 -->
    <tbody v-if="path !== 'OrderList' && path !== 'OrderDetail'">
      <tr v-for="(product, index) in products" :key="index" class="tBody">
        <!-- 장바구니 페이지 일경우에만 체크박스 보임 -->
        <td v-if="path === 'Cart'" class="selectCheck">
          <input
            v-model="select"
            type="checkbox"
            class="selectBox"
            :value="product"
            aria-label="select-product"
            checked
            @change="productCheck"
          />
        </td>
        <td class="bodyName">
          <div class="informationBox clearFix">
            <img :src="require(`@/assets/cart/cart_product_img_${product.type}.png`)" alt="" />
            <div class="informmation">
              <h2 class="productType">{{ product.type }}-({{ product.period }})</h2>
              <p
                v-show="
                  product.type !== '부자재 별도 구매' &&
                  product.type !== '디페스타 입장권' &&
                  product.type !== '디페스타 입장권 패키지' &&
                  product.type !== '커스텀 주문'
                "
              >
                <span v-show="product.type !== '쉐이커 키링'" class="border-right"
                  >아크릴두께 - {{ product.thick }}</span
                >
                <span v-show="product.type === '쉐이커 키링'" class="border-right"
                  >아크릴 종류 - {{ product.kind }}</span
                >
                <span>아크릴크기 - {{ product.x }}*{{ product.y }}cm</span>
              </p>
              <p
                v-show="
                  product.type !== '부자재 별도 구매' &&
                  product.type !== '디페스타 입장권' &&
                  product.type !== '디페스타 입장권 패키지' &&
                  product.type !== '커스텀 주문' &&
                  product.type !== '쉐이커 키링'
                "
              >
                <span v-show="product.type !== '아크릴 관람차'" class="border-right"
                  >아크릴인쇄면 - {{ product.print }}</span
                ><span>조립/포장선택 - {{ product.packing }}</span>
              </p>
              <p v-show="product.type === '쉐이커 키링'">
                <span
                  >인쇄면: 앞면 - {{ product.print }} / 뒷면 - {{ product.second_print }} / 파츠 -
                  {{ product.parts_print }}</span
                >
              </p>
              <p>
                <span
                  :class="{
                    'border-right':
                      product.type !== '부자재 별도 구매' &&
                      product.type !== '디페스타 입장권' &&
                      product.type !== '디페스타 입장권 패키지' &&
                      product.type !== '커스텀 주문' &&
                      product.type !== '아크릴 램프',
                  }"
                  ><span v-show="product.type !== '커스텀 주문' && product.type !== '아크릴 램프'"
                    >부자재 - {{ product.etc }}</span
                  ></span
                >
                <span
                  v-show="
                    product.type !== '부자재 별도 구매' &&
                    product.type !== '디페스타 입장권' &&
                    product.type !== '디페스타 입장권 패키지' &&
                    product.type !== '커스텀 주문' &&
                    product.type !== '쉐이커 키링'
                  "
                  >아크릴 종류 - {{ product.kind }}</span
                >
                <span v-show="product.type === '쉐이커 키링'"
                  >조립/포장선택 - {{ product.packing }}</span
                >
              </p>
              <p v-show="product.type === '아크릴 스탠드' || product.type === '아크릴 램프'">
                <span>바닥부품 - {{ product.stand }}</span>
              </p>
              <p v-show="product.type === '아크릴 카라비너'">
                <span>카라비너 모양 - {{ product.shape }}</span>
              </p>
              <p v-show="product.type === '아크릴 관람차'">
                <span>관람차 바퀴 크기 - {{ product.wheel }}</span>
              </p>
              <p v-show="product.type === '아크릴 관람차'">
                <span
                  >인쇄면: 바퀴/기둥 - {{ product.second_print }} / 키링 - {{ product.print }}</span
                >
              </p>
              <p v-show="product.type === '쉐이커 키링'">
                <span>쉐이커 프레임 구멍 개수 - {{ product.bolt_set }}개</span>
              </p>
              <p
                v-show="
                  product.type !== '부자재 별도 구매' &&
                  product.type !== '디페스타 입장권' &&
                  product.type !== '디페스타 입장권 패키지' &&
                  product.type !== '커스텀 주문'
                "
              >
                <span
                  >도안 수량 - {{ product.design_quantity }}
                  <span v-show="product.design_request">(제작 의뢰)</span></span
                >
              </p>
            </div>
          </div>
        </td>
        <td class="cols">
          {{ product.order_quantity }}
        </td>
        <td class="cols">{{ Commas(product.price) }} 원</td>
        <td class="cols">
          <span v-if="product.fileName">{{ product.fileName }}</span>
          <span v-else>없음</span>
        </td>
      </tr>
    </tbody>
    <!-- 주문내역 페이지일때 -->
    <tbody v-if="path === 'OrderList'">
      <tr v-for="(product, index) in products" :key="index" class="tBody">
        <td class="bodyName">
          <div class="informationBox clearFix">
            <img class="order_img" src="@/assets/main/dongne_logo.svg" alt="" />
            <div class="informmation orderInfo_info">
              <p>
                주문일 : <span>{{ moment(product.createdAt).format('YYYY-MM-DD') }}</span>
              </p>
              <p>
                주문번호 : <span class="orderNum">{{ SubStr.OrderNum(product.merchant_uid) }}</span>
              </p>
              <p>
                결제 방법 : <span>{{ TypeValue.Payment_Method(product.payment_method) }}</span>
              </p>
            </div>
          </div>
        </td>
        <td class="amount">{{ Commas(product.total_payment_amount) }}원</td>
        <td class="pay">
          <span>{{ TypeValue.Payment_Status(product.payment_status) }}</span>
        </td>
        <td class="progress">
          <span :class="[{ red: product.order_status === '파일에러' }]">
            {{ product.order_status }}
          </span>
        </td>
        <td class="detail">
          <div @click="moveDetail(product)" @keydown="moveDetail(product)">상세보기</div>
        </td>
      </tr>
      <tr v-if="products.length === 0" class="none_order">
        주문내역이 없습니다.
      </tr>
    </tbody>
    <!-- 주문내역 상세 페이지일때 -->
    <tbody v-if="path === 'OrderDetail'">
      <tr v-for="(product, index) in products" :key="index" class="tBody">
        <td class="bodyName">
          <div class="informationBox clearFix">
            <img :src="require(`@/assets/cart/cart_product_img_${product.type}.png`)" alt="" />
            <div class="informmation">
              <h2 class="productType">{{ product.type }}-({{ product.period }})</h2>
              <p
                v-show="
                  product.type !== '부자재 별도 구매' &&
                  product.type !== '디페스타 입장권' &&
                  product.type !== '디페스타 입장권 패키지' &&
                  product.type !== '커스텀 주문'
                "
              >
                <span v-show="product.type !== '쉐이커 키링'" class="border-right"
                  >아크릴두께 - {{ product.thick }}</span
                >
                <span v-show="product.type === '쉐이커 키링'" class="border-right"
                  >아크릴 종류 - {{ product.kind }}</span
                >
                <span>아크릴크기 - {{ product.x }}*{{ product.y }}cm</span>
              </p>
              <p
                v-show="
                  product.type !== '부자재 별도 구매' &&
                  product.type !== '디페스타 입장권' &&
                  product.type !== '디페스타 입장권 패키지' &&
                  product.type !== '커스텀 주문' &&
                  product.type !== '쉐이커 키링'
                "
              >
                <span v-show="product.type !== '아크릴 관람차'" class="border-right"
                  >아크릴인쇄면 - {{ product.print }}</span
                ><span>조립/포장선택 - {{ product.packing }}</span>
              </p>
              <p v-show="product.type === '쉐이커 키링'">
                <span
                  >인쇄면: 앞면 - {{ product.print }} / 뒷면 - {{ product.second_print }} / 파츠 -
                  {{ product.parts_print }}</span
                >
              </p>
              <p>
                <span
                  :class="{
                    'border-right':
                      product.type !== '부자재 별도 구매' &&
                      product.type !== '디페스타 입장권' &&
                      product.type !== '디페스타 입장권 패키지' &&
                      product.type !== '커스텀 주문' &&
                      product.type !== '아크릴 램프',
                  }"
                  ><span v-show="product.type !== '커스텀 주문' && product.type !== '아크릴 램프'"
                    >부자재 - {{ product.etc }}</span
                  ></span
                >
                <span
                  v-show="
                    product.type !== '부자재 별도 구매' &&
                    product.type !== '디페스타 입장권' &&
                    product.type !== '디페스타 입장권 패키지' &&
                    product.type !== '커스텀 주문' &&
                    product.type !== '쉐이커 키링'
                  "
                  >아크릴 종류 - {{ product.kind }}</span
                >
                <span v-show="product.type === '쉐이커 키링'"
                  >조립/포장선택 - {{ product.packing }}</span
                >
              </p>
              <p v-show="product.type === '아크릴 스탠드' || product.type === '아크릴 램프'">
                <span>바닥부품 - {{ product.stand }}</span>
              </p>
              <p v-show="product.type === '아크릴 카라비너'">
                <span>카라비너 모양 - {{ product.shape }}</span>
              </p>
              <p v-show="product.type === '아크릴 관람차'">
                <span>관람차 바퀴 크기 - {{ product.wheel }}</span>
              </p>
              <p v-show="product.type === '아크릴 관람차'">
                <span
                  >인쇄면: 바퀴/기둥 - {{ product.second_print }} / 키링 - {{ product.print }}</span
                >
              </p>
              <p v-show="product.type === '쉐이커 키링'">
                <span>쉐이커 프레임 구멍 개수 - {{ product.bolt_set }}개</span>
              </p>
              <p
                v-show="
                  product.type !== '부자재 별도 구매' &&
                  product.type !== '디페스타 입장권' &&
                  product.type !== '디페스타 입장권 패키지' &&
                  product.type !== '커스텀 주문'
                "
              >
                <span
                  >도안 수량 - {{ product.design_quantity }}
                  <span v-show="product.design_request">(제작 의뢰)</span></span
                >
              </p>
            </div>
          </div>
        </td>
        <td class="cols">
          {{ product.order_quantity }}
        </td>
        <td class="cols">{{ Commas(product.price) }} 원</td>
        <td
          v-if="
            product.type !== '부자재 별도 구매' &&
            product.type !== '디페스타 입장권' &&
            product.type !== '디페스타 입장권 패키지' &&
            product.type !== '커스텀 주문'
          "
          class="clos file"
        >
          <div
            v-if="
              product.design_file &&
              (orderInfo.payment_status !== 'cancel' || orderInfo.payment_status !== 'cancelled')
            "
            class="downFile clearFix uploadFile"
          >
            <div>
              <div class="area">
                <img src="@/assets/icon/file_downloadIcon.svg" alt="" />
                <span class="downLink">{{ SubStr.fileName(product.design_file) }}</span>
              </div>
              <button
                v-if="
                  (orderInfo.order_status === '결제대기' ||
                    orderInfo.order_status === '결제완료' ||
                    orderInfo.order_status === '도안미등록' ||
                    orderInfo.order_status === '파일에러' ||
                    orderInfo.order_status === '도안수정') &&
                  !product.svg_file
                "
                type="button"
                class="deleteBtn"
                @click.stop="designDelete(product, index)"
              >
                삭제
              </button>
            </div>
          </div>
          <div v-else class="uploadFile clearFix">
            <form
              v-if="
                orderInfo.payment_status !== 'cancel' || orderInfo.payment_status !== 'cancelled'
              "
              enctype="multipart/form-data"
              @submit.prevent="sendFile(product, index)"
            >
              <div class="area">
                <label :for="`order_file${index}`" class="labelImg"
                  ><img src="@/assets/icon/file_uploadIcon.svg" alt=""
                /></label>
                <label
                  v-if="!fileData[index]"
                  :id="`inputLabel_${index}`"
                  :for="`order_file${index}`"
                  class="inputLabel"
                  >파일을 선택해주세요.</label
                >
                <label
                  v-else
                  :id="`inputLabel_${index}`"
                  :for="`order_file${index}`"
                  class="inputLabel"
                  >{{ fileData[index].name }}</label
                >
              </div>
              <input
                :id="`order_file${index}`"
                ref="order_file"
                type="file"
                class="file-input"
                name="files"
                @change="selectFile($event, index)"
              />
              <button type="submit" class="uploadBtn">저장</button>
            </form>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    orderInfo: {
      type: Object,
    },
    products: {
      type: Array,
    },
    index: {
      type: Number,
    },
    path: {
      type: String,
    },
    selectCheck: {
      type: Array,
    },
    isCheckAll: {
      type: Boolean,
    },
    fileData: {
      type: Object,
    },
  },
  data() {
    return {
      select: this.selectCheck,
      checked: this.isCheckAll,
    }
  },
  watch: {
    selectCheck() {
      this.select = this.selectCheck
    },
    isCheckAll() {
      this.checked = this.isCheckAll
    },
  },
  methods: {
    productCheck() {
      this.$emit('productCheck', this.select)
    },
    moveDetail(product) {
      this.$emit('moveDetail', product)
    },
    selectFile(e, index) {
      this.$emit('selectFile', e, index)
    },
    checkAll() {
      this.$emit('checkAll', this.checked)
    },
    sendFile(product, index) {
      this.$emit('fileSave', product, index)
    },
    designDelete(product, index) {
      this.$emit('deleteFile', product, index)
    },
  },
}
</script>

<style scoped lang="scss">
.tableWrap {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  position: relative;

  .tHead {
    width: 100%;
    border-top: 2px solid #ff6699;
    border-bottom: 2px solid #ffe1ec;
    height: 39px;
    line-height: 39px;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #707070;
  }
  .tBody {
    height: 186px;
    border-bottom: 2px solid #ffe1ec;
    text-align: center;

    .cols {
      font-size: 16px;
      color: #656565;

      select {
        padding: 5px;
        border: 1px solid #ffe1ec;
        color: #656565;
        font-size: 14px;
      }
    }

    .bodyName {
      width: 50%;
      > .dn {
        margin-top: 17px;
        margin-left: 10px;
        font-size: 13px;
        color: #707070;
        text-align: left;

        > .bar {
          display: inline-block;
          padding-right: 8px;
          border-right: 1px solid #707070;
          margin-right: 4px;
        }

        > span {
          > .orderNum {
            font-weight: 500;
          }
        }
      }

      .informationBox {
        width: 100%;
        text-align: left;

        > .order_img {
          width: 100px;
        }

        > img {
          display: block;
          height: 150px;
          float: left;
        }

        > div {
          float: left;
          margin-left: 25px;

          &.orderInfo_info {
            margin-top: 40px;
          }

          > p {
            font-size: 12px;
            > span {
              font-size: 14px;
              color: #656565;

              &.border-right {
                padding-right: 5px;
                border-right: 1px solid #656565;
                margin-right: 5px;
              }
            }
          }
        }

        .productType {
          font-size: 16px;
          text-align: left;
          color: #656565;
          margin-bottom: 8px;
        }
      }
    }

    .detail {
      > div {
        width: 120px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background-color: #fedbe8;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .file {
      input {
        display: none;
      }

      > div {
        width: 200px;
        height: 93px;
        border-radius: 10px;
        margin: 0 auto;
        cursor: pointer;

        button {
          width: 100px;
          height: 25px;
          font-size: 12px;
          color: #707070;
          border: none;
          background-color: #fedbe8;
          border-radius: 5px;
          text-align: center;
          margin: 10px auto;
          cursor: pointer;
        }

        .area {
          width: 80%;
          margin: 0 auto;
          margin-top: 5px;

          img {
            width: 14px;
            height: 24px;
            cursor: pointer;
          }

          span {
            display: block;
            font-size: 12px;
            overflow: hidden;
            color: #ff6699;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 2px;
            cursor: pointer;
          }

          > .inputLabel {
            display: block;
            font-size: 12px;
            color: #ff6699;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 2px;
            cursor: pointer;
          }
        }
      }
      > .uploadFile {
        border: 1.5px solid #ff6699;
      }
    }
  }

  .retouchView {
    height: 40px;

    .text {
      text-align: left;
      text-indent: 10px;
      font-size: 12px;
      color: #ff0000;

      > span {
        display: inline-block;
        width: 88px;
        height: 21px;
        line-height: 21px;
        background-color: #fedbe8;
        color: #707070;
        border-radius: 5px;
        text-align: center;
        margin-left: 45px;
        text-indent: 0;
        cursor: pointer;
      }
    }
  }

  .none_order {
    position: absolute;
    top: 150px;
    left: 45%;
  }

  .red {
    color: #ff0000;
  }
}
</style>
