<template>
  <div class="wrap">
    <h3>[이용약관 시행 일자: 2017년 09월 04일]</h3>
    <h3>[ 제1장 총칙 ]</h3>
    <p class="agenda">제1조 (목적)</p>
    <p>
      이 약관은 동네공작소(이하 회사)가 운영하는 동네공작소 인터넷 사이트를 통하여 제공하는
      전자상거래 관련 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리, <br />
      의무 및 책임사항을 규정함을 목적으로 합니다.* PC통신, 무선 등을 이용하는 전자상거래에 대해서도
      그 성질이 반하지 않는 한 이 약관을 준용합니다.
    </p>
    <p class="agenda">제 2조 (정의)</p>
    <p>
      1. 회사란 동네공작소가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여
      컴퓨터 또는 모바일 등 <br />
      정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말합니다. <br />
      2. “이용자”란 회사에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을
      말합니다. <br />
      3. “회원”이란 회사에 개인정보를 제공하여 회원 등록을 한 자로서, 회사의 정보를 지속적으로 제공
      받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다. <br />
      4. “비회원”이란 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다. <br />
      5. 이외에 이 약관에서 사용하는 용어의 정의는 관계 법령 및 서비스 별 안내에서 정하는 바에
      의합니다.
    </p>
    <p class="agenda">제 3조 (약관 등의 명시와 설명 및 개정)</p>
    <p>
      1. 회사는 이 약관의 내용과 상호 및 대표자 성명, 사업소 소재지 주소(소비자의 불만을 처리할 수
      있는 곳의 주소를 포함), 전화번호, 팩스번호, 전자 우편주소, 사업자등록번호, <br />
      통신판매업 신고번호, 개인 정보관리책임자 등을 이용자가 쉽게 알 수 있도록 동네공작소 초기
      서비스 화면에 게시합니다. <br />
      다만, 약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 할 수 있습니다. <br />
      2. 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자 문서 및
      전자거래기본법, 전자금융거래법, 전자서명법, <br />
      정보통신망 이용 촉진 및 정보 보호 등에 관한 법률, 방문판매 등에 관한 법률, 소비자기본법 등
      관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. <br />
      3. 회사가 이 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 회사의
      홈페이지에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. <br />
      다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을
      두고 공지합니다. <br />
      이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록
      표시합니다. <br />
      또한 가입 시 명기된 이메일 주소를 통해 개정 내용을 공지할 수 있으며, 홈페이지 게재와 효력을
      같이합니다. <br />
      4. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에
      관한 법률, 약관의 규제 등에 관한 법률, <br />
      공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침 및 관계법령 또는 상관례에
      따릅니다.
    </p>
    <h3>[ 제 2장 회사의 서비스 ]</h3>
    <p class="agenda">제 4조 (“서비스”의 제공 및 변경)</p>
    <p>
      1. 회사는 다음과 같은 서비스를 제공합니다.<br />
      <span>1) 검색 서비스</span> <br />
      <span>2) 개방형 서비스 (리뷰 등)</span> <br />
      <span>3) 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결</span> <br />
      <span>4) 구매계약이 체결된 재화 또는 용역의 배송</span> <br />
      <span
        >5) 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 “회원”에게 제공하는 일체의
        서비스</span
      >
      <br />
      2. 회사는 재화 또는 용역의 품절 또는 기술적 사용의 변경 등의 경우에는 장차 체결되는 계약에
      의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. <br />
      이 경우에는 변경된 재화 또는 용역의 내용 및 제공 일자를 명시하여 현재의 재화 또는 용역의
      내용을 게시한 곳에 즉시 공지합니다. <br />
      다만 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다. <br />
      3. 회사가 제공하기로 이용자와 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사용의 변경
      등의 사유로 변경할 경우에는 <br />
      그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다. <br />
      4. 회사는 “서비스”의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스에
      공지한 바에 따릅니다. <br />
      5. 회사는 상당한 이유가 있는 경우 운영상 기술상의 필요에 따라 제공하고 있는 전부 또는 일부
      “서비스”를 변경할 수 있습니다. <br />
      6. “서비스”의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의
      내용 및 제공일자 등은 <br />
      그 변경 전에 해당 서비스의 공지사항 등을 통해 게시합니다.
    </p>
    <p class="agenda">제 5조 (서비스의 중단)</p>
    <p>
      1. 회사는 이용자의 결제 정보가 유출되어 남용되지 않도록 최선의 노력을 다하며, 회원 정보의
      유출로 인하여 이용자에게 불이익이 발생할 시 책임을 집니다. <br />
      2. 회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경 할
      수 있으며, <br />
      이에 대하여 관련법에 특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지 않습니다.
    </p>
    <p class="agenda">제 6조 (게시물의 저작권)</p>
    <p>
      1. “회원”이 “서비스” 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다. <br />
      2. “회원”이 “서비스” 내에 게시하는 게시물은 검색 결과 내지 “서비스” 및 관련 프로모션 등에
      노출될 수 있으며, <br />
      해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우,
      회사는 저작권법 규정을 준수하며, 회원은 언제든지 <br />
      고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색 결과 제외, 비공개 등의
      조치를 취할 수 있습니다. <br />
      3. 회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편
      등을 통해 사전에 회원의 동의를 얻어야 합니다.
    </p>
    <p class="agenda">제 7조 (게시물의 관리)</p>
    <p>
      1. “회원”의 게시물이 정보통신망법 및 저작권 법 등 관련 법에 위반되는 내용을 포함하는 경우
      관리자는 관련 법이 정한 절차에 따라 <br />
      해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법에 따라 조치를 취하여야
      합니다. <br />
      2. 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타
      회사 정책 및 관련 법에 위반되는 경우에는 <br />
      관련 법에 따라 해당 게시물에 대해 임시 조치 등을 취할 수 있습니다. <br />
      3. 본 조에 따른 세부절차는 정보통신망법 및 저작권법이 규정한 범위 내에서 회사가 정한 게시중단
      요청 서비스에 따릅니다.
    </p>
    <p class="agenda">제 8조 (타인 소유의 사진 주문에 관한 책임)</p>
    <p>
      1. 회사의 모든 공정은 전자동으로 이루어지므로 별도의 확인 과정이 없습니다. <br />
      2. “회원”이 주문한 상품 내용이 공공질서 및 미풍양속에 위반되거나 제 3자의 저작권 및 초상권,
      기타 권리를 침해하는 내용물일 경우, <br />
      이에 따라 발생하는 결과에 대한 모든 책임은 회원에게 있습니다.
    </p>
    <h3>[ 제 3장 회원가입 계약 ]</h3>
    <p class="agenda">제 9조 (회원가입)</p>
    <p>
      1. 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를
      함으로서 회원가입을 신청합니다. <br />
      2. 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한
      회원으로 등록합니다. <br />
      <span
        >1) 가입신청자가 이 약관 제7조 3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, <br />
        다만 제10조 3항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은
        경우에는 예외로 한다.</span
      >
      <br />
      <span>2) 등록 내용에 허위, 기재누락, 오기가 있는 경우</span><br />
      <span>3) 기타 회원으로 등록하는 것이 회원의 기술상 현저히 지장이 있다고 판단되는 경우</span>
      <br />
      3. 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다. <br />
      4. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회사에 대하여
      회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
    </p>
    <p class="agenda">제 10조 (회원 탈퇴 및 자격 상실 등)</p>
    <p>
      1. “회원”은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 7일이내 회원 탈퇴를 처리합니다.
      <br />
      2. “회원”이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
      <br />
      <span>1) 가입 신청 시에 허위 내용을 등록한 경우 </span><br />
      <span
        >2) 회사를 이용하여 구입한 재화 등의 대금, 기타 회사 이용에 관련하여 “회원”이 부담하는
        채무를 기일에 지급하지 않는 경우</span
      >
      <br />
      <span
        >3) 다른 사람의 회사 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는
        경우</span
      >
      <br />
      <span
        >4) 회사를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</span
      >
      <br />
      3. 회사가 회원 자격을 제한 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그
      사유가 시정되지 아니하는 경우 회사는 회원 자격을 상실시킬 수 있습니다. <br />
      4. 회사가 회원 자격을 상실시키는 경우에는 회원 등록을 말소합니다. <br />
      이 경우 “회원”에게 이를 통지하고, 회원 등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할
      기회를 부여합니다.
    </p>
    <p class="agenda">제 11조 (회원에 대한 통지)</p>
    <p>
      1. 회사가 “회원”에 대한 통지를 하는 경우, “회원”이 회사와 미리 약정하여 지정한 전자우편 주소로
      할 수 있습니다. <br />
      2. 회사는 불특정 다수 회원에 대한 통지의 경우 1주일이상 회사 게시판에 게시함으로써 개별 통지에
      갈음할 수 있습니다. <br />
      다만, “회원” 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.
    </p>
    <h3>[ 제 4장 구매계약 및 대금 결제 ]</h3>
    <p class="agenda">제 12조 (구매 신청 및 개인 정보 제공 동의 등)</p>
    <p>
      1. 회사이용자는 회사에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 이용자가
      구매 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. <br />
      <span>1) 재화 등의 검색 및 선택</span> <br />
      <span>2) 받는 사람의 성명, 주소, 전화번호, 이메일 주소(또는 휴대전화번호)등의 입력</span>
      <br />
      <span
        >3) 약관 내용, 청약철회권이 제한되는 서비스, 배송료 설치비 등의 비용 부담과 관련한 내용에
        대한 확인</span
      >
      <br />
      <span>4) 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시</span> <br />
      <span>5) 재화 등의 구매 신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의</span> <br />
      <span>6) 결제방법의 선택</span> <br />
      2. 회사가 제3자에게 구매자의 개인 정보를 취급할 수 있도록 업무를 위탁하는 경우에는 <br />
      <span>1) 개인정보 취급 위탁을 받는 자,</span> <br />
      <span
        >2) 개인 정보 취급 위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를
        받은 사항이 변경되는 경우에도 같습니다.) <br />
        다만, 서비스 제공에 관한 계약 이행을 위해 필요하고 구매자의 편의 증진과 관련된 경우에는
        정보통신망 이용 촉진 및 정보 보호 등에 관한 법률에서 <br />
        정하고 있는 방법으로 개인 정보 취급방침을 통해 알림으로써 고지절차와 동의 절차를 거치지
        않아도 됩니다.</span
      >
    </p>
    <p class="agenda">제 13조 (계약의 성립)</p>
    <p>
      1. 회사는 제12조와 같은 구매 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
      <br />
      다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
      법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다. <br />
      <span>1) 신청 내용에 허위, 기재누락, 오기가 있는 경우</span> <br />
      <span>2) 기타 구매 신청에 승낙하는 것이 회사 기술상 현저히 지장이 있다고 판단하는 경우</span>
      <br />
      2. 회사의 승낙이 제15조 제1항의 수신 확인 통지 형태로 이용자에게 도달한 시점에 계약이 성립한
      것으로 봅니다. <br />
      3. 회사의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매 가능 여부, 구매 신청의
      정정 취소 등에 관한 정보 등을 포함하여야 합니다.
    </p>
    <p class="agenda">제 14조 (지급 방법)</p>
    <p>
      회사에서 구매한 재화 또는 용역에 대한 대금 지급 방법은 다음 호의 방법 중 가용한 방법으로 할 수
      있습니다. <br />
      1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체 <br />
      2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제 <br />
      3. 온라인 무통장 입금 <br />
      4. 전자화폐에 의한 결제 <br />
      5. 수령 시 대금 지급 <br />
      6. 마일리지 등 회사가 지급한 포인트에 의한 결제 <br />
      7. 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제 <br />
      8. 기타 전자적 지급 방법에 의한 대금 지급 등
    </p>
    <p class="agenda">제 15조 (수신확인 통지 구매 신청 변경 및 취소)</p>
    <p>
      1. 회사는 이용자의 구매 신청이 있는 경우 이용자에게 수신확인 통지를 합니다. <br />
      2. 수신확인 통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후
      1시간 이내에 구매 신청 변경 및 취소를 요청할 수 있고, <br />
      회사는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제 18조의
      청약철회 등에 관한 규정에 따릅니다.
    </p>
    <p class="agenda">제 16조 (재화 등의 공급)</p>
    <p>
      1. 회사는 이용자와 재화 등의 공급시기에 관하여 홈페이지 내 고지하는 출고일자에 따라 재화 등을
      배송할 수 있도록 주문 제작, 포장 등 기타의 필요한 조치를 취합니다. <br />
      이때 회사는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
      <br />
      2. 공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적 사유가 발생하는 경우 그 해당기한은
      배송소요기간에서 제외합니다.
    </p>
    <p class="agenda">제 17조 (환급)</p>
    <p>
      회사는 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체
      없이 그 사유를 이용자에게 통지하고 <br />
      사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3 영업일 이내에 환급하거나 환급에
      필요한 조치를 취합니다.
    </p>
    <p class="agenda">제 18조 (청약철회 등)</p>
    <p>
      1. 회사가 재화 등의 구매에 관한 계약을 체결한 이용자는 전자상거래 등에서의 소비자보호에 관한
      법률 제13조 제2항에 따른 계약 내용에 관한 서면을 받은 날 <br />
      (그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의
      공급이 시작된 날을 말합니다)<br />
      부터 7일 이내에는 청약의 철회를 할 수 있습니다. <br />
      다만 청약철회에 관하여 전자상거래 등에서의 소비자 보호에 관한 법률에 달리 정함이 있는 경우에는
      동 법 규정에 따릅니다. <br />
      2. 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수
      없습니다. <br />
      <span
        >1) 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우 <br />
        (다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수
        있습니다)</span
      >
      <br />
      <span>2) 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우</span>
      <br />
      <span
        >3) 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우</span
      >
      <br />
      <span
        >4) 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한
        경우</span
      >
      <br />
      <span>5) 개인화되어 주문 제작되는 제품으로 단순 변심에 따라 반품 및 교환하는 경우</span>
      <br />
      3. 이용자가 APP 또는 WEB을 통해 90일을 초과하여 로그인한 기록이 없을 경우 장바구니에 저장된
      상품은 삭제되며, <br />
      이에 따른 손해에 대해 회사는 어떠한 책임도 지지 않습니다.
    </p>
    <h3>[ 제 5장 계약당사자의 의무 ]</h3>
    <p class="agenda">제 19조 (개인정보보호 및 이용)</p>
    <p>
      1. 회사는 이용자의 정보 수집 시 서비스의 제공에 필요한 최소한의 정보를 수집합니다. 다음 사항을
      필수사항으로 하며 그 외 사항은 선택사항으로 합니다. <br />
      <span>1) 성명</span> <br />
      <span>2) 주소</span> <br />
      <span>3) 전화번호 및 이동전화번호</span> <br />
      <span>4) 비밀번호</span> <br />
      <span>5) 이메일 (전자우편) 주소</span> <br />
      <span>6) 기타 회사가 필요하다고 인정하는 사항</span> <br />
      2. 회사는 이용자의 개인 식별이 가능한 개인 정보를 수집하는 때에는 반드시 당해 이용자의 동의를
      받습니다. <br />
      3. 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며,
      이에 대한 모든 책임은 회사가 집니다. <br />
      다만 다음의 경우는 예외로 합니다. <br />
      <span
        >1) 배송 업무상 배송 업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를
        알려주는 경우</span
      >
      <br />
      <span
        >2) 정보 통신 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인 정보로서 경제적,
        기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우</span
      >
      <br />
      <span>3) 재화 등의 거래에 따른 대금 정산을 위하여 필요한 경우</span> <br />
      <span>4) 도용 방지를 위하여 본인 확인에 필요한 경우</span> <br />
      <span>5) 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</span> <br />
      <span
        >6) 신속한 이용문의 상담 및 이용자의 불만 처리 업무를 대행하는 회사에 상담업무에 필요한
        이용자의 정보를 제공하는 경우</span
      >
      <br />
      4. 회사가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인 정보관리 책임자의
      신원 (소속, 성명 및 전화번호, 기타 연락처), <br />
      정보의 수집 목적 및 이용 목적, 제 3자에 대한 정보 제공 관련 사항 (제공받은 자, 제공 목적 및
      제공할 정보의 내용) 등 정보 통신 이용 촉진 등에 관한 <br />
      법률 제21조 등에서 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를
      철회할 수 있습니다. <br />
      5. 이용자는 언제든지 회사가 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수
      있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. <br />
      이용자가 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인 정보를
      이용하지 않습니다. <br />
      6. 회사는 개인 정보 위하여 이용자의 개인 정보 취급하는 자를 최소한으로 제한하여야 하며 <br />
      신용카드, 은행 계좌 등을 포함한 이용자의 개인 정보의 분실, 도난 유출, 동의 없는 제3자 제공,
      변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다. <br />
      7. 회사 또는 그로부터 개인 정보를 제공받은 제3자는 개인 정보의 수집목적 또는 제공받은 목적을
      달성할 때에는 당해 개인 정보를 지체 없이 파기합니다. <br />
      8. 회사가 회원의 개인 정보를 수집, 이용, 제공 등을 할 경우에는 정보통신망 이용 촉진 및 정보
      보호 등에 관한 법률에 따라 회원의 동의를 받습니다. <br />
      9. 회원은 원하는 경우 언제든 회사에 제공한 개인 정보의 수집과 이용에 대한 동의를 철회할 수
      있으며 동의의 철회는 회원 탈퇴를 하는 것으로 이루어집니다.
    </p>
    <p class="agenda">제 20조 (회사의 의무)</p>
    <p>
      1. 회사는 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 상품 또는 용역을 제공하는데 최선을
      다합니다. <br />
      2. 회사는 개인 정보 보호정책에 의거하여, 항상 회원의 정보 보호를 위해 각별히 주의하여
      노력하며, 상업적으로 활용하지 않습니다. <br />
      다만 관계법령에 따라 수사상의 목적으로 관계기관의 요청을 받은 경우나 정보통신윤리위원회의
      요청이 있는 경우에는 그러하지 않습니다. <br />
      3. 회사는 회원이 게시한 정보가 사이트 운영상 부적절 하거나 제 3자의 저작권 및 초상권 등 기타
      권리를 침해 한 경우, <br />
      게시자의 동의 없이 관련 정보를 삭제, 수정, 링크를 차단 할 수 있습니다. 단, 회사는 이러한
      정보의 삭제, 수정, 링크 차단을 지속적으로 관리하는 의무를 지지 않습니다. <br />
      4. 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인 정보(신용 정보 포함)
      보호를 위한 보완 시스템을 갖추어야 합니다. <br />
      5. 회사는 수신 거절의 의사를 명백히 표시한 이용자에 대해서는 영리목적의 광고성 이메일을
      발송하지 않습니다.
    </p>
    <p class="agenda">제 21조 (회원 ID 및 비밀번호에 대한 의무)</p>
    <p>
      1. 제19조의 경우를 제외한 이메일 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다. <br />
      2. 회원은 자신의 이메일 ID 및 비밀번호를 제 3자에게 이용하게 해서는 안됩니다. <br />
      3. 회원이 자신의 이메일 ID 및 비밀번호를 도난 당하거나 제 3자가 사용하고 있음을 인지한
      경우에는 <br />
      바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.<br />
      4. 회사는 회원이 상기 제1항, 제2항, 제3항을 위반하여 회원에게 발생한 손해에 대하여 아무런
      책임을 부담하지 않습니다.
    </p>
    <p class="agenda">제 22조 (회원의 의무)</p>
    <p>
      이용자는 다음 행위를 하여서는 안됩니다. <br />
      <span>1) 개인 정보의 등록(변경의 경우를 포함)시 허위 내용을 등록</span> <br />
      <span>2) 타인의 정보 도용</span> <br />
      <span>3) 회사에 게시된 정보를 임의로 변경</span> <br />
      <span>4) 회사가 허락하지 않은 정보(컴퓨터 프로그램 등)의 송신 또는 게시</span> <br />
      <span>5) 회사 기타 제3자의 저작권 등 지적 재산권에 대한 침해</span> <br />
      <span>6) 회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</span> <br />
      <span
        >7) 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 화면에 공개 또는
        게시하는 행위</span
      >
    </p>
    <h3>[ 제 6장 부가서비스의 이용 ]</h3>
    <p class="agenda">제 23조 (동네공작소 포인트 및 쿠폰)</p>
    <p>
      1. 회사에서 제공하는 서비스를 이용하거나 기타 행사 등을 통하여 회원에게 지급될 수 있으며,
      <br />
      회원이 스스로 서비스에서 정해진 금액을 제14조의 납부 방법을 통해 지불함으로써 구입할 수
      있습니다. <br />
      2. 동네공작소의 포인트를 이용하여 적립 금액에 해당하는 만큼의 회사 서비스를 이용할 수 있으며,
      요금 납부 시 결제 금액의 일부를 포인트로 대체할 수 있습니다.
    </p>
    <p class="agenda">제 24조 (할인쿠폰)</p>
    <p>
      1. 회사는 구매 서비스를 이용하는 회원에게 지정된 상품 구매 시 일정액 또는 일정 비율을 할인
      받을 수 있는 할인쿠폰을 발급할 수 있습니다. <br />
      2. 회원은 할인쿠폰을 회원 본인의 구매에 한해서만 사용할 수 있으며, 어떠한 경우에도 이를
      타인에게 실질적으로 매매 또는 양도할 수 없습니다. <br />
      3. 할인쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 유효기간이 지난 후에는 사용할
      수 없습니다. <br />
      4. 회원을 탈퇴한 경우 할인쿠폰은 소멸됩니다.
    </p>
    <h3>[ 제 7장 기타 ]</h3>
    <p class="agenda">제 25조 (저작권의 귀속 및 이용 제한)</p>
    <p>
      1. 회사가 작성한 저작물에 대한 저작권 기타 지적 재산권은 회사에 귀속합니다. <br />
      2. 회원은 서비스를 이용하면서 얻은 정보를 사이트의 사전 승인 없이 복제, 송신, 출판, 배포 등
      기타 영리적인 목적으로 이용하거나 제 3자에게 이용해서는 안됩니다. <br />
      3. 회사는 게시물에 대한 이용권과 서비스 내 게시권을 갖습니다. <br />
      4. 회사는 회원이 거제 또는 등록한 게시물에 대해 회사가 서비스를 운영, 전시, 전송 배포 또는
      홍보하기 위한 목적으로 활용할 수 있습니다. <br />
      5. 회사는 사용자의 이미지, 주문 데이터 등을 주문, 제작, 전시 등의 목적으로 저장 및 사용할 수
      있습니다.
    </p>
    <p class="agenda">제 26조 (면책)</p>
    <p>
      1. 회사는 천재지변, 불가항력 기타 회사의 합리적인 통제 범위를 벗어난 사유로 인하여 서비스를
      제공할 수 없는 경우에는 그에 대한 책임을 부담하지 않습니다. <br />
      2. 회사는 회원의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지
      않습니다. <br />
      3. 회사는 이용자의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을
      부담하지 않습니다. <br />
      4. 이용자가 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당
      이용자가 책임을 부담하며, <br />
      회사는 내용의 부정확 또는 허위로 인해 이용자 또는 제3자에게 발생한 손해에 대하여는 아무런
      책임을 부담하지 않습니다. <br />
      5. 회사는 서비스 이용과 관련하여 이용자의 고의 또는 과실로 인하여 이용자 또는 제 3자에게
      발생한 손해에 대하여는 아무런 책임을 부담하지 않습니다.
    </p>
    <p class="agenda">제 27조 (정보의 제공 및 광고의 게재)</p>
    <p>
      1. 회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 이메일 및 서신 우편 등의
      방법으로 회원에게 제공할 수 있습니다. <br />
      2. 회사는 서비스의 운영과 관련하여 홈페이지, 서비스 화면, SMS, 이메일 등에 광고 등을 게재할 수
      있습니다.
    </p>
    <p class="agenda">제 28조 (손해배상의 범위)</p>
    <p>
      1. 회사는 무료 서비스 이용에 관하여 이용자에게 어떠한 손해가 생기더라도 책임지지 않습니다.
      <br />
      2. 회사의 귀책사유로 회원이 서비스를 이용하지 못하는 경우 회사는 이에 대한 손해를 배상합니다.
      <br />
      다만 회원이 서비스 이용 불가 사실을 회사에 접수한 이후 12시간 이내에 서비스가 정상화된 경우는
      제외합니다. <br />
      3. 손해 배상에는 회사 서비스 이용 계약이 체결된 서비스에 한합니다.
    </p>
    <p class="agenda">제 29조 (손해배상의 청구)</p>
    <p>
      1. 회원은 손해 배상 청구 사유가 발생한 날로부터 3개월 이내에 손해 배상 청구 사유, 금액, 산출
      근거를 기재하여 회사에 서면으로 청구하여야하며, <br />
      회사는 회원에게 7영업일 이내에 그 결과를 회원에게 회신합니다.
    </p>
    <p class="agenda">제 30조 (분쟁해결)</p>
    <p>
      1. 회사는 이용자로부터 제출되는 불만사항 및 의견을 우선적으로 처리합니다. 다만, 신속한 처리가
      곤란한 경우에는 이용자에게 그 사유와 처리 일정을 통보합니다. <br />
      2. 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해 구제신청이 있는 경우에는
      <br />
      공정거래위원회 또는 시, 도지사가 의뢰하는 분쟁 조정 기관의 조정에 따를 수 있습니다.
    </p>
    <p class="agenda">제 31조 (재판권 및 준거법)</p>
    <p>
      1. 회사와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자 주소에 의하고,
      주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. <br />
      다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
      민사소송법상의 관할법원에 제기합니다. <br />
      2. 회사와 이용자 간에 제기된 전자상거래 소송에는 대한민국 법을 적용합니다.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.wrap {
  margin-bottom: 200px;

  > h3 {
    margin-top: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #ff6699;
    text-align: left;
  }

  > p {
    font-size: 16px;
    line-height: 24px;

    > span {
      display: inline-block;
      text-indent: 50px;
    }

    &.agenda {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #707070;
      text-align: left;
    }
  }
}
</style>
