<template>
  <div class="step step2">
    <h2>고리 선택</h2>
    <div class="keyringBox clearFix">
      <ul class="keyringBtns">
        <li
          v-for="(type, index) in keyringType"
          :key="index"
          class="keyringBtn"
          :class="{ active: type.metal > 0 || type.gold > 0 || type.rose > 0 }"
        >
          <div>
            <p>{{ type.type }}</p>
            <hr />
            <ul class="colorOption clearFix">
              <li
                v-show="type.type !== '벚꽃모양'"
                class="metal"
                :class="{ color: type.metal > 0 }"
                @click.prevent="checkColor('metal', type.metal, index)"
                @keydown.prevent="checkColor('metal', type.metal, index)"
              />
              <li
                v-show="
                  type.type !== '일반형' && type.type !== '벚꽃모양' && type.type !== '체인모양'
                "
                class="gold"
                :class="{ color: type.gold > 0 }"
                @click.prevent="checkColor('gold', type.gold, index)"
                @keydown.prevent="checkColor('gold', type.gold, index)"
              />
              <li
                v-show="type.type !== '일반형' && type.type !== '체인모양'"
                class="rose"
                :class="{ color: type.rose > 0 }"
                @click.prevent="checkColor('rose', type.rose, index)"
                @keydown.prevent="checkColor('rose', type.rose, index)"
              />
            </ul>
            <img
              v-if="type.metal > 0 || type.gold > 0 || type.rose > 0"
              class="keyring_img"
              :src="require(`@/assets/goods/keyring_${type.type}_active.svg`)"
              alt=""
            />
            <img
              v-else
              class="keyring_img"
              :src="require(`@/assets/goods/keyring_${type.type}_nomal.svg`)"
              alt=""
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    keyringType: {
      type: Array,
    },
  },
  methods: {
    checkColor(type, num, index) {
      this.$emit('checkColor', type, num, index)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .keyringBox {
    font-weight: 400;

    > .keyringBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        margin-top: 15px;

        &:nth-child(3),
        &:nth-child(6),
        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;

          > div {
            > .colorOption {
              > .color {
                border: 2px solid #ff6699;
                background-image: url('../../../../assets/icon/checkIcon.png');
                background-position: center center;
              }
            }
          }
        }

        > div {
          width: 144px;
          margin: 26px auto 0;

          > .colorOption {
            margin-top: 4px;
            float: left;
            z-index: 5;

            > li {
              width: 30px;
              height: 30px;
              border-radius: 3px;
              margin-right: 3px;
              margin-top: 15px;
              cursor: pointer;
              box-sizing: border-box;

              &.metal {
                background-color: #c7c7c7;
              }

              &.gold {
                background-color: #ece476;
              }

              &.rose {
                background-color: #e8c1bc;
              }
            }
          }

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
            text-align: left;
          }

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin-top: 25px;
            width: 100px;
            height: 100px;
            vertical-align: middle;
            float: right;
          }
        }
      }
    }
  }
}
</style>
