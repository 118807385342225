<template>
  <div class="couponBox">
    <div class="couponEnrollment clearFix">
      <input
        v-model="couponNumber"
        type="text"
        placeholder="쿠폰 번호를 입력해주세요."
        aria-label="couponNumber"
      />
      <span class="clearFix" @click="couponRegistration" @keydown="couponRegistration"
        >쿠폰 등록</span
      >
    </div>
    <div v-if="couponList.length > 0" class="couponList">
      <div
        v-for="(coupon, index) in couponList"
        :key="index"
        class="coupon clearFix"
        :class="{ select: selectIndex === index }"
        @click="selectCoupon(coupon, index)"
        @keydown="selectCoupon(coupon, index)"
      >
        <div class="left">
          <h2 class="couponTitle">
            {{ coupon.name }}
          </h2>
          <p class="couponType">사용 가능 상품: {{ coupon.type }}</p>
          <p class="couponDate">{{ moment(coupon.end_day).format('YYYY-MM-DD') }} 까지 사용 가능</p>
        </div>
        <div class="right">
          <p class="couponPrice">
            {{ Commas(coupon.price) }}{{ TypeValue.Coupon_SaleType(coupon.sale_type) }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="notCoupon">
      <p>보유중인 쿠폰이 없습니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    couponList: {
      type: Array,
    },
    selectIndex: {
      type: Number,
    },
  },
  data() {
    return {
      couponNumber: '',
    }
  },
  methods: {
    couponRegistration() {
      this.$emit('couponRegistration', this.couponNumber)
    },
    selectCoupon(coupon, index) {
      this.$emit('selectCoupon', coupon, index)
    },
  },
}
</script>

<style scoped lang="scss">
.couponBox {
  width: 90%;
  margin: 50px auto;
  > .couponEnrollment {
    width: 100%;
    border-bottom: 2px solid #ff6699;
    padding-bottom: 5px;
    margin: 0 auto;

    > input {
      width: 70%;
      border: none;
      font-size: 20px;
      background-color: transparent;
      padding: 5px 10px;

      &:focus {
        outline: none;
      }
    }

    > span {
      float: right;
      background-color: #ff6699;
      color: #fff;
      font-size: 18px;
      border-radius: 5px;
      text-align: center;
      padding: 5px 10px;
      cursor: pointer;
    }
  }

  > .couponList {
    width: 100%;
    margin: 20px auto;
    cursor: pointer;

    > .coupon {
      padding: 20px;
      border: 2px solid #ff6699;
      border-radius: 10px;
      background-color: #fff;
      margin-bottom: 20px;

      &.select {
        background-color: #ffebf2;
      }

      > .left {
        float: left;

        > .couponTitle {
          color: #707070;
          font-size: 23px;
        }

        > .couponType {
          margin: 10px 0;
        }
      }

      > .right {
        float: right;

        > p {
          font-size: 25px;
          margin-top: 25px;
          color: #ff6699;
          font-weight: bold;
        }
      }
    }
  }

  > .notCoupon {
    height: 200px;

    > p {
      margin-top: 100px;
      font-size: 20px;
      text-align: center;
    }
  }
}
</style>
