<template>
  <div class="step">
    <h2>와이어링 색상 선택</h2>
    <div class="wiringWrap">
      <div class="wiringBox clearFix">
        <div class="imgBox">
          <img src="@/assets/goods/item_와이어링_active.svg" alt="" />
        </div>
        <div class="colorBox">
          <div class="line" />
          <div class="color">
            <div
              v-for="(wire, index) in wiring"
              :key="index"
              :style="{ backgroundColor: wire.color }"
              :class="{ active: wire.amount > 0 }"
              @click.prevent="checkWiringColor(wire.amount, index)"
              @keydown.prevent="checkWiringColor(wire.amount, index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wiring: {
      type: Array,
    },
  },
  methods: {
    checkWiringColor(num, index) {
      this.$emit('checkWiringColor', num, index)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;

  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .wiringWrap {
    width: 100%;
    height: 150px;
    border: 2px solid #bcbcbc;
    box-sizing: border-box;
    border-radius: 10px;

    > .wiringBox {
      width: 95%;
      margin: 30px auto;

      > .imgBox {
        width: 78px;
        float: left;
        margin-top: 15px;
        margin-left: 15px;
        > img {
          width: 100%;
        }
      }

      > .colorBox {
        width: 420px;
        float: left;
        margin-left: 15px;

        > .line {
          float: left;
          width: 2px;
          height: 88px;
          background-color: #e6e6e6;
          margin: 0 25px 0 10px;
        }

        > .color {
          height: 105px;
          margin: 0 auto;

          > div {
            display: inline-block;
            width: 35px;
            height: 35px;
            cursor: pointer;
            border-radius: 3px;
            margin-left: 15px;
            border: 1px solid #bcbcbc;
            box-sizing: border-box;
            margin-bottom: 10px;
            margin-right: 25px;

            &.active {
              border: 2px solid #f25ca2;
              background-image: url('../../../../assets/goods/dog_tag_color_check.png');
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 80%;
            }
          }
        }
      }
    }
  }
}
</style>
