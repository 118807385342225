<template>
  <div class="loadingWrap">
    <div class="bg"></div>
    <div class="loadingBox">
      <ul class="loading">
        <li
          class="circle"
          v-for="index in circle"
          :key="index"
          :class="{ active: c_num === index }"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      circle: 3,
      c_num: 1,
      interval: '',
    }
  },
  mounted() {
    this.circleMove()
  },
  unmounted() {
    this.clearCircle()
  },
  methods: {
    circleMove() {
      this.interval = setInterval(() => {
        this.c_num++
        if (this.c_num > 3) {
          this.c_num = 1
        }
      }, 500)
    },
    clearCircle() {
      clearInterval(this.interval)
    },
  },
}
</script>

<style scoped lang="scss">
.loadingWrap {
  > .bg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }
  > .loadingBox {
    position: fixed;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 50%;
    display: block;
    z-index: 1001;
    background-color: #ffffff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    padding-bottom: 10px;

    > .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      -webkit-transform: translate(-50%, -50%);
      > .circle {
        position: relative;
        display: inline-block;
        width: 15px;
        bottom: -5px;
        height: 15px;
        background-color: #ff6699;
        border-radius: 50%;
        z-index: 1002;
        margin-left: 15px;
        transform: 1s;
        transition-property: bottom;
        transition-duration: 0.5s;

        &.active {
          bottom: 10px;
        }
      }
    }
  }
}
</style>
