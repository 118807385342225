<template>
  <div class="step step1">
    <h2>디페스타 입장권</h2>
    <div class="ticketBox">
      <p class="border">디페스타 입장권</p>
      <img src="@/assets/goods/ticket_card.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket_package: {
      type: Number,
    },
  },
  methods: {
    checkPackage(type, num) {
      this.$emit('checkPackage', type, num)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 150px auto 300px;

  > h2 {
    margin-bottom: 27px;
  }

  > .ticketBox {
    width: 100%;
    border: 2px solid #ff6699;
    border-radius: 10px;
    height: auto;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 30px;

    > .border {
      width: 490px;
      text-align: left;
      margin: 30px auto;
      border-bottom: 2px solid #e6e6e6;
      padding-bottom: 10px;
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: 700;
    }

    > img {
      width: 230px;
      height: 146px;
    }
  }
}
</style>
