<template>
  <div class="letterWrap">
    <div class="popupBg" @click="closeTab()" @keydown="closeTab()" />
    <div class="letterBox">
      <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
        <img src="@/assets/icon/close_btn.svg" alt="" />
      </div>
      <div class="letter">
        <h2>와이어 링 유의사항</h2>
        <hr />
        <div class="textBox">
          <p class="title">[와이어 링 선택 시 유의사항]</p>
          <p class="content">
            <span
              >와이어 링의 특성 상 구멍 크기가 타 부자재와 사이즈가 다릅니다.<br />
              구멍 크기가 작을 시, 와이어 링이 끼워지지 않을 수 있습니다.<br /><span class="red"
                >구멍 크기가 작아 들어가지 않는 것에 대한 환불 및 재제작은<br />
                어려운 점 양해 부탁드립니다.</span
              ></span
            >
          </p>
        </div>
        <div class="textBox">
          <p class="title">[와이어 링용 구멍크기]</p>
          <p class="content">
            <span><span class="red">외경 : 7.5mm 이상 / 내경 : 3.5mm 이상</span></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async closeTab() {
      this.$emit('close-tab')
    },
  },
}
</script>

<style scoped lang="scss">
.letterWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: block;

  > .popupBg {
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
  }

  > .letterBox {
    width: 608px;
    height: 340px;
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 1001;

    > .closeTab {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    > .letter {
      width: 501px;
      margin: 47px auto 0;

      > h2 {
        font-size: 20px;
        font-weight: bold;
        color: #ff6699;
      }

      > hr {
        width: 100%;
        height: 2px;
        background-color: #ff6699;
        border: none;
        margin-top: 14px;
        margin-bottom: 20px;
      }

      > .textBox {
        margin-bottom: 17px;
        text-align: left;

        > .title {
          font-size: 16px;
          color: #ff6699;
        }

        > .content {
          font-size: 14px;
          color: #707070;

          .red {
            color: #ff6699;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
