<template>
  <div class="leftWrap">
    <SpecialHeader />
    <div class="contentBox">
      <dpi-box
        :dpi="sizeBox.dpi"
        @dpi-view="dpiView"
        @dpi-hide="dpiHide"
        @cancel-quoin="cancelCanvas"
        @dpi-change="dpiChange"
      />
      <!-- 
        :drag-message="dragMessage"
        :drag-info="dragInfo" -->
      <quoin
        v-if="type === '아크릴 키링'"
        :goods="goods"
        :svg="svg"
        :image-style="imageStyle"
        :drop-box-style="dropBoxStyle"
        @over-event="overEvent"
        @drop-event="dropEvent"
        @img-select="imgSelect"
      />
      <block
        v-if="type === '아크릴 블럭'"
        :svg="svg"
        :message="sizeMessage"
        :drop-box-style="dropBoxStyle"
        @over-event="overEvent"
        @drop-event="dropEvent"
        @img-select="imgSelect"
      />
      <GoodsCanvas
        v-if="type === '일러스트 캔버스'"
        :svg="svg"
        :message="sizeMessage"
        :drop-box-style="dropBoxStyle"
        @over-event="overEvent"
        @drop-event="dropEvent"
        @img-select="imgSelect"
      />
    </div>
    <div class="cartBtnBox">
      <div class="message">오른쪽 필수옵션을 모두 선택해주세요!</div>
      <div class="cartBtn" @click="cartAdd()" @keydown="cartAdd()">장바구니에 담기</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpecialHeader from '../List/Special/SpecialHeader.vue'
import DpiBox from '../List/Special/DpiBox.vue'
import Quoin from '../List/Special/GoodsQuoin.vue'
import Block from '../List/Special/GoodsBlock.vue'
import GoodsCanvas from '../List/Special/GoodsCanvas.vue'

export default {
  components: {
    SpecialHeader,
    DpiBox,
    Quoin,
    Block,
    GoodsCanvas,
  },
  props: {
    type: {
      type: String,
    },
    svg: {
      type: Boolean,
    },
    sizeBox: {
      type: Object,
    },
    sizeMessage: {
      type: String,
    },
    imageStyle: {
      type: Object,
    },
    dropBoxStyle: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      // dragMessage: 'Drag/dragMessage',
      // dragInfo: 'Drag/dragInfo',
    }),
  },
  methods: {
    // dpiBox
    // 마우스 오버시 dpi설명 보임
    dpiView() {
      const completeBox = document.getElementById('dpiArea')
      completeBox.style.opacity = 1
    },
    // 마우스 아웃시 dpi설명 숨김
    dpiHide() {
      const completeBox = document.getElementById('dpiArea')
      completeBox.style.opacity = 0
    },
    dpiChange(i) {
      this.$emit('dpiChange', i)
    },

    // quoin
    // 이미지 드롭오버시 배경색 변경
    overEvent(e) {
      this.$emit('overEvent', e.type)
    },
    // 이미지 드롭시 이미지 파일이 1개인지 검수, img_file에 이미지 파일등록
    dropEvent(e, type) {
      this.$emit('dropEvent', e, type)
    },
    // 이미지 초기화
    async cancelCanvas(type) {
      this.$emit('cancelCanvas', type)
    },
    // 클릭으로 이미지등록 이벤트
    imgSelect(type, file) {
      this.$emit('imgSelect', type, file)
    },

    async canvas(type, width, height) {
      this.$emit('canvas', type, width, height)
    },
    cartAdd() {
      this.$emit('cartAdd')
    },
  },
}
</script>

<style scoped lang="scss">
.leftWrap {
  float: left;
  width: 750px;

  .contentBox {
    width: 100%;
    height: 852px;
    background-color: #ffe1ec;
  }

  .cartBtnBox {
    width: 100%;
    background-color: #ffe1ec;
    padding-bottom: 23px;
    margin-bottom: 200px;

    > .message {
      background-color: #fff;
      width: 418px;
      height: 25px;
      line-height: 25px;
      border-radius: 2px;
      font-size: 12px;
      color: #4b4b4b;
      text-align: center;
      box-shadow: 3px 3px 2px #00000029;
      margin: 0 auto 10px;
    }

    > .cartBtn {
      width: 574px;
      height: 45px;
      line-height: 45px;
      border-radius: 10px;
      background-color: #ff6699;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
