<template>
  <div class="location">
    <h1>5. 위치 레이어 작업</h1>
    <!--  -->
    <!--  -->
    <div>
      <p>아크릴 뒷면에 붙는 부자재 위치를 작업합니다.</p>
      <p>※ 위치 레이어를 작업해주시지 않으시면, 중앙에 붙여서 배송됩니다.</p>

      <p><strong>타원 : 핀 뱃지(14mm), 스마트톡(40mm)</strong></p>
      <p><strong>사각형 : 집게핀(13*22mm)</strong></p>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/attach_location_1.png" alt="" />
      </div>
      <div class="img_1 right">
        <p>1. 타원 도구를 선택 합니다.(집게핀일 경우, 사각형 도구)</p>
        <p>2. 타원 도구가 선택 되어 있는 상태에서 캔버스를 클릭합니다.</p>
        <p>3. 캔버스를 클릭하면 '타원 만들기'라는 창이 뜹니다.</p>
        <p>
          4. 폭과 높이를 40mm로 설정해 주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;(핀 뱃지 : 14mm 타원,
          집게핀 : 폭 13*높이 22mm 사각형)
        </p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/attach_location_2.png" alt="" />
      </div>
      <div class="img_2 right">
        <p>5. 칠을 분홍색 (<span>#ff00ff</span>), 불투명도 <span>80%</span>로 설정합니다.</p>
        <p>6. 획은 없음으로 설정합니다.</p>
        <p>7. 위치 레이어는 레이어의 가장 위쪽에 오도록 설정합니다.</p>
        <p>8. 원하시는 위치로 위치 레이어를 옮깁니다.</p>
      </div>
    </div>
    <div class="text clearFix">
      <p>※ 위치 레이어가 일러스트의 빈 공간에 위치하게 될 경우,</p>
      <p>해당 부분은 정면에서 부자재가 보이게 됩니다.</p>
    </div>
    <div class="img clearFix">
      <div class="left">
        <img src="@/assets/guide/attach_location_3.png" alt="" />
      </div>
      <div class="img_3 right">
        <figure>일러스트의 빈 공간으로 부자재가 보이는 부분</figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.location {
  text-align: center;
  h1 {
    width: 100%;
  }

  > div {
    margin-top: 40px;

    > img {
      margin-top: 20px;
    }
    > p {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-top: 5px;
    }

    &.text {
      margin-top: 100px;
    }

    > .img {
      margin-top: 30px;

      > div {
        display: flex;
        justify-content: space-between;
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 20px;

        span {
          color: #ff6699;
        }
      }
    }
    figure {
      margin-top: 10px;
    }

    &.img_half {
      > div {
        float: left;
        margin-left: 35px;
        text-align: left;

        &.img_1 {
          margin-top: 30px;
        }
        &.img_2 {
          margin-top: 120px;
        }
        &.img_3 {
          margin-top: 150px;
        }
        &.img_4 {
          margin-top: 200px;
        }
        &.img_5 {
          margin-top: 250px;
        }
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 20px;

        span {
          color: #ff6699;
        }
      }
    }

    &.ex {
      > div {
        text-align: center;
      }

      > p {
        text-align: left;
        margin-left: 200px;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
