<template>
  <div class="retouchWrap">
    <div class="bgWrap" />
    <div class="retouchBox">
      <div class="closeTab" @click="closeTab()" @keydown="closeTab()">
        <img src="@/assets/icon/photoClose.svg" alt="" />
      </div>
      <div class="retouch">
        <h2>도안 수정 사항</h2>
        <div class="retouchContent">
          <div v-html="retouch.contents" />
        </div>
        <div class="closeBtn" @click="closeTab()" @keydown="closeTab()">닫기</div>
        <div class="answerBtn" @click="answerView()" @keydown="answerView()">답변하기</div>
      </div>
      <answer
        v-if="answer_view === true"
        :retouch="retouch"
        @close-tab="closeModal"
        @retouch-answer="retouchAnswer"
      />
    </div>
  </div>
</template>

<script>
import answer from '../../containers/Modal/RetouchAnswer.vue'

export default {
  components: {
    answer,
  },
  props: {
    retouch: {
      type: Object,
    },
  },
  data() {
    return {
      answer_view: false,
    }
  },
  methods: {
    closeTab() {
      this.$emit('closeTab')
    },
    closeModal() {
      this.answer_view = false
      this.closeTab()
    },
    answerView() {
      this.answer_view = true
    },
    retouchAnswer(status) {
      this.$emit('retouchAnswer', status)
    },
  },
}
</script>

<style scoped lang="scss">
.retouchWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 200;
  color: #707070;
  font-family: 'Noto Sans KR';
  text-align: left;

  > .bgWrap {
    width: 100%;
    height: 100vh;
    z-index: 201;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    -o-backdrop-filter: blur(3px);
    -ms-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }

  > .retouchBox {
    position: fixed;
    width: 996px;
    height: 750px;
    left: 50%;
    top: 50%;
    z-index: 205;
    border-radius: 10px;
    box-shadow: 0px 3px 9px #0000005e;
    background-color: #fff;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    > .closeTab {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 15px;
      right: 25px;
      cursor: pointer;
    }

    > .retouch {
      width: 889px;
      margin: 47px auto 0;

      > h2 {
        font-size: 20px;
        font-weight: 700;
        color: #ff6699;
        border-bottom: 2px solid #ff6699;
        padding-bottom: 13px;
        margin-bottom: 20px;
      }

      > .retouchContent {
        height: 550px;
        overflow-y: auto;
        margin-bottom: 20px;
      }

      > .closeBtn {
        width: 40%;
        height: 50px;
        line-height: 50px;
        color: #000;
        border: 1px solid #111;
        font-size: 20px;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        float: left;
      }
      > .answerBtn {
        width: 40%;
        height: 50px;
        line-height: 50px;
        color: #fff;
        background-color: #ff6699;
        font-size: 20px;
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        float: right;
      }
    }
  }
}
</style>
