<template>
  <div>
    <tos-container />
  </div>
</template>

<script>
import TosContainer from '../../containers/ToS/ToSContainer.vue'

export default {
  components: {
    TosContainer,
  },
}
</script>

<style></style>
