<template>
  <div class="tableWrap">
    <table v-if="listArray.length > 0">
      <tr v-for="(list, index) in listArray" :key="index">
        <td class="info">
          <div class="dn">
            <span>{{ moment(list.createdAt).format('YYYY-MM-DD') }}</span>
            <!-- <span
              >주문번호 : <span class="orderNum">{{ list.orderNum }}</span></span
            > -->
          </div>
          <div class="orderInfo clearFix">
            <img :src="require(`@/assets/cart/cart_product_img_${list.type}.png`)" alt="" />
            <div class="informmation">
              <h2>{{ list.type }}</h2>
              <p
                v-show="
                  list.type !== '부자재 별도 구매' &&
                  list.type !== '디페스타 입장권' &&
                  list.type !== '디페스타 입장권 패키지' &&
                  list.type !== '커스텀 주문'
                "
              >
                <span class="border-right">아크릴두께 - {{ list.thick }}</span
                ><span>아크릴크기 - {{ list.x }}*{{ list.y }}cm</span>
              </p>
              <p
                v-show="
                  list.type !== '부자재 별도 구매' &&
                  list.type !== '디페스타 입장권' &&
                  list.type !== '디페스타 입장권 패키지' &&
                  list.type !== '커스텀 주문'
                "
              >
                <span v-show="list.type !== '아크릴 관람차'" class="border-right"
                  >아크릴인쇄면 - {{ list.print }}</span
                ><span>조립/포장선택 - {{ list.packing }}</span>
              </p>
              <p>
                <span
                  :class="{
                    'border-right':
                      list.type !== '부자재 별도 구매' &&
                      list.type !== '디페스타 입장권' &&
                      list.type !== '디페스타 입장권 패키지' &&
                      list.type !== '커스텀 주문' &&
                      list.type !== '아크릴 램프',
                  }"
                  ><span v-show="list.type !== '커스텀 주문' && list.type !== '아크릴 램프'"
                    >부자재 - </span
                  >{{ list.etc }}</span
                >
                <span
                  v-show="
                    list.type !== '부자재 별도 구매' &&
                    list.type !== '디페스타 입장권' &&
                    list.type !== '디페스타 입장권 패키지' &&
                    list.type !== '커스텀 주문'
                  "
                  :class="{
                    'border-right': list.type === '아크릴 스탠드' || list.type === '아크릴 램프',
                  }"
                  >아크릴 종류 - {{ list.kind }}</span
                ><span v-show="list.type === '아크릴 스탠드' || list.type === '아크릴 램프'"
                  >바닥부품 - {{ list.stand }}</span
                >
              </p>
              <p v-show="list.type === '아크릴 카라비너'">
                <span>카라비너 모양 - {{ list.shape }}</span>
              </p>
              <p v-show="list.type === '아크릴 관람차'">
                <span>관람차 바퀴 크기 - {{ list.wheel }}</span>
              </p>
              <p v-show="list.type === '아크릴 관람차'">
                <span>인쇄면: 바퀴/기둥 - {{ list.second_print }} / 키링 - {{ list.print }}</span>
              </p>
              <p>
                <span>총 주문 수량 - {{ list.order_quantity }}</span>
              </p>
            </div>
          </div>
        </td>
        <td class="detail">
          <div class="ballon">
            <p>글 +500P</p>
            <p>사진 +1,000P</p>
          </div>
          <div class="write" @click="reviewAdd(list)" @keydown="reviewAdd(list)">리뷰작성</div>
        </td>
      </tr>
    </table>
    <div v-else class="none_review">
      <div>작성 가능한 리뷰가 없습니다.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listArray: {
      type: Array,
    },
  },
  methods: {
    reviewAdd(list) {
      this.$emit('reviewAdd', list)
    },
  },
}
</script>

<style scoped lang="scss">
table {
  width: 1100px;
  margin: 0 auto;
  border-collapse: collapse;

  th {
    font-size: 14px;
    font-weight: 400;
    color: #707070;
    height: 39px;
  }

  tr {
    height: 202px;
    text-align: center;
    border-bottom: 2px solid #ffe1ec;
    font-size: 14px;

    > .info {
      width: 80%;
      color: #707070;
      text-align: left;

      > .dn {
        margin-top: 17px;
        margin-left: 10px;
        font-size: 13px;
        color: #707070;

        span {
          > .orderNum {
            font-weight: 500;
          }
        }

        > .bar {
          display: inline-block;
          padding-right: 8px;
          border-right: 1px solid #707070;
          margin-right: 4px;
        }
      }

      > .orderInfo {
        margin-top: 7px;
        margin-left: 10px;

        > img {
          float: left;
          height: 148px;
        }

        > div {
          float: left;
          margin-left: 27px;

          > h2 {
            font-size: 16px;
            font-weight: 400;
            color: #656565;
            margin-bottom: 8px;
          }

          > p {
            font-size: 14px;
            color: #656565;
            font-weight: 300;

            > .border-right {
              border-right: 1px solid #656565;
              padding-right: 3px;
              margin-right: 3px;
            }
          }
        }
      }
    }

    > .detail {
      position: relative;
      > .ballon {
        // display: none;
        position: absolute;
        font-size: 10px;
        width: 70px;
        left: 68px;
        top: 25px;
        background: #ff6699;
        color: white;
        border-radius: 10px;
        padding: 5px 5px;

        &:after {
          border-top: 10px solid #ff6699;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 0px solid transparent;
          content: '';
          position: absolute;
          top: 40px;
          left: 32px;
        }
      }
      > .write {
        width: 120px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background-color: #fedbe8;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

.none_review {
  text-align: center;
  width: 100%;
  height: 150px;

  > div {
    margin-top: 150px;
    font-size: 20px;
  }
}
</style>
