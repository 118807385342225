<template>
  <div>
    <!-- 가상계좌 -->
    <div v-if="orderInfo.payment_status === 'ready'" class="commonInfo">
      <h2>가상계좌</h2>
      <div>
        <span class="leftT">예금주</span>
        <span class="rightT">{{ vbankInfo.account_holder }}</span>
      </div>
      <div>
        <span class="leftT">은행명</span>
        <span class="rightT">{{ vbankInfo.bank_name }}</span>
      </div>
      <div>
        <span class="leftT">계좌번호</span>
        <span class="rightT">{{ vbankInfo.account_number }}</span>
      </div>
      <div>
        <span class="leftT">입금기간</span>
        <span class="rightT"
          ><span class="red">{{ moment(vbankInfo.date).format('YYYY-MM-DD HH:mm') }}</span></span
        >
      </div>
    </div>
    <!-- 환불정보 -->
    <div
      v-if="
        cancelInfo &&
        (orderInfo.payment_status === 'request' || orderInfo.payment_status === 'refund_complete')
      "
      class="commonInfo"
    >
      <h2>환불정보</h2>
      <div v-if="orderInfo.payment_method === 'vbank'">
        <span class="leftT">예금주</span>
        <span class="rightT">{{ cancelInfo.account_holder }}</span>
      </div>
      <div v-if="orderInfo.payment_method === 'vbank'">
        <span class="leftT">은행명</span>
        <span class="rightT">{{ cancelInfo.bank_name }}</span>
      </div>
      <div v-if="orderInfo.payment_method === 'vbank'">
        <span class="leftT">계좌번호</span>
        <span class="rightT">{{ cancelInfo.account_number }}</span>
      </div>
      <div>
        <span class="leftT">환불사유</span>
        <span class="rightT">{{ cancelInfo.reason }}</span>
      </div>
      <div>
        <span class="leftT">환불신청일</span>
        <span class="rightT">{{ moment(cancelInfo.createdAt).format('YYYY-MM-DD') }}</span>
      </div>
    </div>
    <!-- 배송정보 -->
    <div v-if="orderInfo.order_status === '배송중'" class="commonInfo">
      <h2>배송</h2>
      <div>
        <span class="leftT">배송일자</span>
        <span class="rightT">{{
          moment(orderInfo.delivery_date).tz('Asia/Seoul').format('YYYY-MM-DD')
        }}</span>
      </div>
      <div>
        <span class="leftT">배송번호</span>
        <span class="rightT">우체국택배 - {{ orderInfo.delivery_number }}</span>
      </div>
      <div>
        <span class="leftT">배송 요청사항</span>
        <span class="rightT">{{ orderInfo.delivery.requirements }}</span>
      </div>
    </div>
    <!-- 주문 일자 -->
    <div class="commonInfo">
      <h2>주문일자</h2>
      <div>
        <span class="leftT">주문일자</span>
        <span class="rightT">{{ moment(orderInfo.createdAt).format('YYYY-MM-DD') }}</span>
      </div>
    </div>
    <!-- 주문번호 -->
    <div class="commonInfo">
      <h2>주문번호</h2>
      <div>
        <span class="leftT">주문번호</span>
        <span class="rightT">{{ SubStr.OrderNum(orderInfo.merchant_uid) }}</span>
      </div>
    </div>
    <!-- 주문자 정보 -->
    <div class="userInfo commonInfo">
      <h2>주문자 정보</h2>
      <div>
        <span class="leftT">이름</span>
        <span class="rightT">{{ orderInfo.delivery.orderer }}</span>
      </div>
      <div>
        <span class="leftT">받으시는 분</span>
        <span class="rightT">{{ orderInfo.delivery.recipient }}</span>
      </div>
      <div>
        <span class="leftT">번호</span>
        <span class="rightT">{{ orderInfo.delivery.phone }}</span>
      </div>
      <div>
        <span class="leftT">e-mail</span>
        <span class="rightT">{{ orderInfo.delivery.email }}</span>
      </div>
      <div>
        <span
          v-show="
            orderInfo.delivery.delivery_method === '기본배송지' ||
            orderInfo.delivery.delivery_method === '직접입력'
          "
          class="leftT"
          >주소</span
        >
        <span v-show="orderInfo.delivery.delivery_method === '방문수령'" class="leftT"
          >방문수령주소</span
        >
        <span v-show="orderInfo.delivery.delivery_method === '현장수령'" class="leftT"
          >현장수령</span
        >
        <span class="rightT"
          >{{ orderInfo.delivery.address
          }}<span v-show="orderInfo.delivery.detailed_address"
            >, {{ orderInfo.delivery.detailed_address }}</span
          ></span
        >
      </div>
      <div class="request">
        <span class="leftT">주문 요청사항</span>
        <span class="rightT">{{ orderInfo.payment_request }}</span>
      </div>
    </div>
    <!-- 결제 금액 -->
    <div class="paymentInfo">
      <h2>결제금액</h2>
      <div>
        <span class="leftT">총 상품 금액</span>
        <span class="rightT">{{ Commas(orderInfo.total_order_amount) }} 원</span>
      </div>
      <div>
        <p class="marginTop">
          <span class="leftT">할인금액</span>
          <span class="rightT"
            ><span class="red">- {{ Commas(orderInfo.total_discount_amount) }} 원</span></span
          >
        </p>
        <p>
          <span class="leftT">배송비</span>
          <span class="rightT">{{ Commas(orderInfo.delivery_amount) }} 원</span>
        </p>
      </div>
      <div>
        <span class="leftT">총 주문금액</span>
        <span class="rightT">{{ Commas(orderInfo.total_payment_amount) }} 원</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
    orderInfo: {
      type: Object,
    },
    vbankInfo: {
      type: Object,
    },
    cancelInfo: {
      type: Object,
    },
  },
}
</script>

<style scoped lang="scss">
.commonInfo {
  width: 100%;
  margin-bottom: 65px;

  > h2 {
    font-size: 16px;
    color: #707070;
    text-align: left;
    border-bottom: 2px solid #ff6699;
    font-weight: 400;
  }

  > div {
    text-align: left;
    height: 44px;
    border-bottom: 2px solid #ffe1ec;
    line-height: 44px;

    > .leftT {
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      width: 150px;
      margin-left: 30px;
    }

    > .rightT {
      font-size: 12px;
      font-weight: 400;
    }

    &.request {
      height: auto;
      line-height: normal;
      padding: 10px 0;
      position: relative;

      > .leftT {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        width: 150px;
        margin-left: 30px;
        vertical-align: top;
      }

      > .rightT {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        white-space: pre;
      }
    }
  }
}

.paymentInfo {
  > h2 {
    font-size: 16px;
    color: #707070;
    text-align: left;
    border-bottom: 2px solid #ff6699;
    font-weight: 400;
  }
  > div {
    text-align: left;
    height: 44px;
    border-bottom: 2px solid #ffe1ec;
    line-height: 44px;

    .rightT {
      display: inline-block;
      width: 150px;
      text-align: right;
      font-size: 12px;
      font-weight: 400;
    }

    .leftT {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      width: 150px;
      margin-left: 30px;
    }

    &:nth-child(3) {
      height: 60px;
      line-height: 25px;

      > .marginTop {
        margin-top: 12px;
      }
    }
  }
}
.red {
  color: #ff0000;
}
</style>
