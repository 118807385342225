const ErrorMessage = {
  // 로그인
  Login: (status) => {
    let message
    if (status === 404) {
      message = '아이디 또는 비밀번호가 잘못되었습니다.'
    } else if (status === 401) {
      message = '아이디 또는 비밀번호가 잘못되었습니다.'
    } else if (status === 406) {
      message = '사용이 정지된 유저입니다.'
    }
    return message
  },
  signUp: (status) => {
    let message
    if (status === 401) {
      message = '휴대폰 인증 해주세요.'
    } else if (status === 404) {
      message = '추천인을 찾을 수 없습니다.'
    } else if (status === 412) {
      message = '이미 가입된 고객입니다.'
    } else if (status === 415) {
      message = '이용정책 위반으로 가입이 불가합니다.'
    }
    return message
  },
  findId: (status) => {
    let message
    if (status === 400) {
      message = '가입되지않은 회원입니다.'
    } else if (status === 500) {
      message = '휴대폰 인증 요청 실패'
    }
    return message
  },
  findPassword: (status) => {
    let message
    if (status === 400) {
      message = '가입되지않은 회원입니다.'
    } else if (status === 404) {
      message = '가입되지않은 회원입니다.'
    } else if (status === 500) {
      message = '휴대폰 인증 요청 실패'
    }
    return message
  },
  // 로그인 후 유저 정보 불러오가
  UserProfile: (status) => {
    let message
    if (status === 404) {
      message = '유저가 존재하지 않습니다.'
    }
    return message
  },
  // 회원정보 수정 - 비밀번호 확인
  UserProfilePwCheck: (status) => {
    let message
    if (status === 400) {
      message = '비밀번호가 잘못되었습니다.'
    } else if (status === 404) {
      message = '비밀번호가 잘못되었습니다.'
    }
    return message
  },
  // 회원정보 수정 - 비밀번호 변경
  UserProfilePwChange: (status) => {
    let message
    if (status === 400) {
      message = '기존 비밀번호가 맞지 않습니다.'
    }
    return message
  },
  // 회원정보 수정 - 핸드폰 번호 변경
  UserProfilePhoneChange: (status) => {
    let message
    // if (status === 400) {
    message = status
    // }
    return message
  },
  // 회원정보 수정 - 회원정보 수정
  UserProfileUpdate: (status) => {
    let message
    // if (status === 400) {
    message = status
    // }
    return message
  },
  // 환불계좌 조회
  AccountCertifications: (status) => {
    let message
    if (status === 400) {
      message = '예금주 명이 일치하지 않습니다.'
    } else if (status === 500) {
      message = '예금주 조회 실패'
    }

    return message
  },
  // 쿠폰 발급
  CouponRegistration: (status) => {
    let message
    if (status === 404) {
      message = '쿠폰이 존재하지 않습니다.'
    } else if (status === 409) {
      message = '발급 받은 쿠폰이 존재합니다.'
    } else if (status === 410) {
      message = '기간이 만료된 쿠폰입니다.'
    }
    return message
  },
}
export default ErrorMessage
