<template>
  <div>
    <order-detail />
  </div>
</template>

<script>
import OrderDetail from '../../containers/Order/DetailContainer.vue'

export default {
  components: {
    OrderDetail,
  },
}
</script>

<style></style>
