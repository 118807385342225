<template>
  <div class="tableWrap">
    <h2>기본정보</h2>
    <table>
      <tr>
        <td class="left">이름</td>
        <td class="right">
          {{ userInfo.name }}
        </td>
      </tr>
      <tr>
        <td class="left">아이디</td>
        <td class="right">
          {{ userInfo.user_id }}
        </td>
      </tr>
      <tr>
        <td class="left">회원 등급</td>
        <td class="right">
          {{ TypeValue.User_Ranking(userInfo.ranking)
          }}<span v-if="userInfo.ranking === 0 && userInfo.vip_end_day" class="vip_date"
            >[VIP 유지 기간 : {{ moment(userInfo.vip_end_day).format('YY년 MM월 DD일') }} 까지]
            <router-link class="vip_btn" :to="{ name: 'MyPageRanking' }"
              >등급별 혜택 보기 ></router-link
            ></span
          >
        </td>
      </tr>
      <tr>
        <td class="left">누적 주문 금액</td>
        <td class="right">{{ Commas(userInfo.accumulate_price) }} 원</td>
      </tr>
      <tr :class="{ pwChange: pwChangeStatus === true }">
        <td class="left">비밀번호</td>
        <td v-if="pwChangeStatus === false" class="right">
          <button type="button" @click="pwChangeBtn()">비밀번호 변경</button>
        </td>
        <td v-else class="right">
          <p>
            <span>현재 비밀번호</span><input id="myPage_nowPw" v-model="nowPw" type="password" />
          </p>
          <p>
            <span>새 비밀번호</span
            ><input
              id="myPage_newPw"
              v-model="newPw"
              type="password"
              placeholder="영,숫자 포함 8~20글자"
            />
          </p>
          <p>
            <span>새 비밀번호 확인</span
            ><input id="myPage_pwCheck" v-model="pwCheck" type="password" />
          </p>
          <p class="btn">
            <span class="ok" @click="pwChange()" @keydown="pwChange()">확인</span>
            <span @click="pwChangeCancel()" @keydown="pwChangeCancel()">취소</span>
          </p>
        </td>
      </tr>
      <tr class="email">
        <td class="left">이메일</td>
        <td class="right">
          <input
            id="myPage_email"
            type="text"
            name="email"
            :value="userInfo.email"
            @input="userInfoChange"
          />
        </td>
      </tr>
      <tr class="phone">
        <td class="left">전화 번호</td>
        <td class="right">
          <div>
            <select
              id="myPage_info_phone_1"
              v-model="phone_1"
              :disabled="phoneStatus === 0 || phoneStatus === 2"
              name="myPage_info_phone"
            >
              <option value="010">010</option>
              <option value="011">011</option>
              <option value="018">018</option>
              <option value="016">016</option>
            </select>
            <input
              id="myPage_info_phone_2"
              v-model="phone_2"
              :disabled="phoneStatus === 0 || phoneStatus === 2"
              type="text"
              name="phoneNumber"
              maxlength="4"
            />
            <input
              id="myPage_info_phone_3"
              v-model="phone_3"
              :disabled="phoneStatus === 0 || phoneStatus === 2"
              type="text"
              name="phoneNumber"
              maxlength="4"
            />
            <span v-if="phoneStatus === 0" @click="phoneStatusBtn(1)" @keydown="phoneStatusBtn(1)"
              >수정</span
            >
            <span v-if="phoneStatus === 1" @click="phoneAuth()" @keydown="phoneAuth()"
              >본인인증</span
            >
            <span v-if="phoneStatus === 2" @click="phoneStatusBtn(3)" @keydown="phoneStatusBtn(3)"
              >변경하기</span
            >
            <span
              v-if="phoneStatus !== 0"
              class="cancel"
              @click="phoneStatusBtn(0)"
              @keydown="phoneStatusBtn(0)"
              >취소</span
            >
          </div>
          <input
            id="myPage_checkbox1"
            type="checkbox"
            name="sns_consent"
            :checked="userInfo.sns_consent === true"
            @input="userInfoChange"
          />
          <label for="myPage_checkbox1">상품, 이벤트 정보 SNS, 이메일 수신에 동의[선택]</label>
        </td>
      </tr>
      <tr class="address">
        <td class="left">
          <p>주소</p>
          <p>(기본배송지)</p>
        </td>
        <td class="right">
          <p>
            <input
              id="myPage_zipCode"
              type="text"
              name="zipCode"
              size="15"
              :value="userInfo.zip_code"
              disabled
            />
            <button type="button" class="addressBtn" @click="addressBtn()">주소변경</button>
          </p>
          <p>
            <input
              id="myPage_address"
              type="text"
              name="address"
              :value="userInfo.address"
              size="30"
              disabled
            />
          </p>
          <p>
            <input
              id="myPage_detailAddress"
              type="text"
              name="detailed_address"
              :value="userInfo.detailed_address"
              size="30"
              placeholder="상세주소를 입력해주세요"
              @input="userInfoChange"
            />
          </p>
        </td>
      </tr>
    </table>
    <div class="memberSec" @click="deleteUser()" @keydown="deleteUser()">회원탈퇴</div>
    <div class="btnBox">
      <span class="saveBtn" @click="saveBtn()" @keydown="saveBtn()">저장</span>
      <span class="cancelBtn" @click="cancelBtn()" @keydown="cancelBtn()">취소</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
    },
    userPhone: {
      type: String,
    },
    pwChangeStatus: {
      type: Boolean,
    },
    phoneStatus: {
      type: Boolean,
    },
  },
  data() {
    return {
      phone_1: '',
      phone_2: '',
      phone_3: '',
      nowPw: '',
      newPw: '',
      pwCheck: '',
    }
  },
  watch: {
    userPhone() {
      this.userPhoneDetect()
    },
  },
  mounted() {
    this.userPhoneDetect()
  },
  methods: {
    userPhoneDetect() {
      if (this.userPhone) {
        this.phone_1 = this.userPhone.substr(0, 3)
        this.phone_2 = this.userPhone.substr(3, 4)
        this.phone_3 = this.userPhone.substr(7, 4)
      } else {
        this.phone_1 = ''
        this.phone_2 = ''
        this.phone_3 = ''
      }
    },
    pwChange() {
      this.$emit('pwChange', this.nowPw, this.newPw, this.pwCheck)
    },
    pwChangeBtn() {
      this.$emit('pwChangeBtn')
    },
    pwChangeCancel() {
      this.$emit('pwChangeCancel')
    },
    phoneStatusBtn(i) {
      this.$emit('phoneStatusBtn', i)
    },
    addressBtn() {
      this.$emit('addressBtn')
    },
    phoneAuth() {
      this.$emit('phoneCertification', this.phone_1, this.phone_2, this.phone_3)
    },
    userInfoChange(e) {
      let type = e.target.name
      let send
      if (type === 'sns_consent') {
        send = e.target.checked
      } else {
        send = e.target.value
      }
      this.$emit('userInfoChange', type, send)
    },
    saveBtn() {
      this.$emit('updateUser')
    },
    cancelBtn() {
      this.$emit('cancelBtn')
    },
    deleteUser() {
      this.$emit('deleteUserView')
    },
  },
}
</script>

<style scoped lang="scss">
.tableWrap {
  width: 842px;
  margin: 30px auto 50px;

  input {
    border: 1px solid #ffe1ec;
    height: 25px;
    text-indent: 8px;

    &:focus {
      outline: 1px solid #ff6699;
    }
  }

  > h2 {
    font-size: 20px;
    font-weight: 500;
    color: #707070;
    border-bottom: 2px solid #ff6699;
    padding-bottom: 13px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;

    > tr {
      border-bottom: 2px solid #ffe1ec;
      height: 44px;
      font-size: 12px;
      color: #707070;
      text-indent: 20px;

      > .left {
        font-weight: 500;
        width: 150px;
      }

      > .right {
        text-align: left;
        text-indent: 0;

        > .vip_date {
          margin-left: 5px;
          color: #ff6699;

          > .vip_btn {
            padding: 5px;
            background: #ffe1ec;
            margin-left: 5px;
          }
        }

        > button {
          background-color: #fedbe8;
          width: 123px;
          height: 25px;
          text-align: center;
          cursor: pointer;
          border: none;
        }

        > .nameInput {
          width: 177px;
        }

        > input {
          width: 300px;
        }

        > div {
          display: inline-block;
          text-indent: 0px;
          margin-left: 4px;

          > input {
            display: inline-block;
            width: 113px;
          }
        }
      }

      &.phone {
        height: 90px;

        > .right {
          > div {
            margin-bottom: 15px;

            > select {
              width: 80px;
              height: 25px;
              border: 1px solid #ffe1ec;
              text-indent: 8px;
              font-size: 12px;
              color: #656565;
              margin-right: 5px;
            }
            > input {
              width: 80px;
              margin-right: 5px;
            }

            > span {
              margin-left: 10px;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              background-color: #ff6699;
              color: #fff;
              display: inline-block;
              border-radius: 5px;
              cursor: pointer;
            }
          }

          > label {
            display: block;
            width: 300px;
          }

          input {
            &[type='checkbox'] {
              position: absolute;
              width: 1px;
              height: 1px;
              padding: 0;
              margin: -1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0);
              border: 0;

              &:checked + label::before {
                content: '\2713';
                /* font-weight: 700; */
                color: #ff6699;
                text-shadow: 0.5px 0.5px #ff6699;
                background: #fedbe8;
                border-color: #979797;
                text-indent: 0;
              }
            }

            &[type='checkbox'] + label:before {
              content: ' ';
              display: inline-block;
              width: 16px;
              height: 16px;
              line-height: 15px;
              margin: -2px 8px 0 0;
              text-align: center;
              vertical-align: middle;
              background: #fafafa;
              border: 1px solid #979797;
              border-radius: 5px;
            }
          }
        }
      }

      &.address {
        height: 133px;

        > .right {
          > p {
            margin-bottom: 10px;

            &:first-child {
              margin-top: 10px;
            }

            > button {
              width: 70px;
              height: 25px;
              background-color: #fedbe8;
              border: none;
              text-align: center;
              font-size: 12px;
              cursor: pointer;
              margin-left: 8px;
            }
          }
        }
      }

      &.pwChange {
        height: 133px;

        > .right {
          > p {
            margin-bottom: 10px;

            > input {
              width: 250px;
            }

            > span {
              width: 100px;
              display: inline-block;
            }

            &:first-child {
              margin-top: 10px;
            }

            &.btn {
              margin-left: 230px;
              > span {
                width: 50px;
                height: 30px;
                border: 1px solid #ff6699;
                border-radius: 5px;
                line-height: 30px;
                text-align: center;
                cursor: pointer;
                margin-right: 20px;
                box-sizing: border-box;

                &.ok {
                  border: none;
                  background-color: #ff6699;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  > .memberSec {
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }

  > .btnBox {
    margin-top: 60px;
    text-align: center;

    > span {
      display: inline-block;
      width: 107px;
      height: 33px;
      line-height: 33px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 12px;
      border: 1px solid #ff6699;
      margin: 0;
    }

    > .saveBtn {
      background-color: #ff6699;
      color: #fff;
      margin-right: 12px;
    }
  }
}
</style>
