<template>
  <div class="wayBox">
    <ul class="clearFix">
      <li
        id="card"
        :class="[{ active: method === 'card' }]"
        @click="paymentChange('card')"
        @keydown="paymentChange('card')"
      >
        신용카드
      </li>
      <!-- <li @click="paymentChange(1)" :class="[{'active':payment == '실시간계좌이체'}, {'disabled': payment_zero == true}]">실시간계좌이체</li> -->
      <li
        :class="[{ active: method === 'vbank' }]"
        @click="paymentChange('vbank')"
        @keydown="paymentChange('vbank')"
      >
        가상계좌
      </li>
      <li
        :class="[{ active: method === 'cultureland' }]"
        @click="paymentChange('cultureland')"
        @keydown="paymentChange('cultureland')"
      >
        문화상품권
      </li>
    </ul>
    <div class="wayEx">
      <div v-show="method === 'card'" class="exBox">
        <h3>신용카드</h3>
        <p>- 신용카드입금 확인 후 도안 검수가 진행 됩니다.</p>
        <p>- 동네 공작소의 주문제작 상품들은 , 도안 검수 완료이후에는 환불 진행이 불가능합니다.</p>
      </div>
      <div v-show="method === 'trans'" class="exBox">
        <h3>실시간계좌이체</h3>
        <p>- 입금 후 24시간 이내에 확인되며, 입금 확인 후 도안 검수가 진행 됩니다.</p>
        <p>- 동네 공작소의 주문제작 상품들은 , 도안 검수 완료이후에는 환불 진행이 불가능합니다.</p>
      </div>
      <div v-show="method === 'vbank'" class="exBox">
        <h3>가상계좌</h3>
        <p>- 계좌입금 시 입금자명은 회원명과 동일하게 입력하여 주시기 바랍니다.</p>
        <p>
          - 무통장주문 후 영업일 기준 5일이 지날 때까지 입금이 안되면 주문은 자동 취소되니, 입금일을
          꼭 확인해주세요.
        </p>
        <p>- 동네 공작소의 주문제작 상품들은 , 도안 검수 완료이후에는 환불 진행이 불가능합니다.</p>
      </div>
      <div v-show="method === 'cultureland'" class="exBox">
        <h3>문화상품권</h3>
        <p>- 문화상품권입금 확인후 도안 검수가 진행 됩니다.</p>
        <p>- 문화상품권 결제시 할인혜택을 받으실 수 없습니다.</p>
        <p>- 동네 공작소의 주문제작 상품들은 , 도안 검수 완료이후에는 환불 진행이 불가능합니다.</p>
      </div>
    </div>
    <div v-if="method !== 'card' && method !== 'cultureland'" class="return_accountBox">
      <ul class="clearFix">
        <li class="returnTitle">환불계좌</li>
        <li class="return_account">
          <select id="bank" v-model="bank_code" class="bank" name="bank" @change="refundChange">
            <option key="004" value="BK04">KB국민은행</option>
            <option value="BK23">SC제일은행</option>
            <option value="BK39">경남은행</option>
            <option value="BK34">광주은행</option>
            <option value="BK03">기업은행</option>
            <option value="BK11">농협</option>
            <option value="BK31">대구은행</option>
            <option value="BK32">부산은행</option>
            <option value="BK02">산업은행</option>
            <option value="BK07">수협</option>
            <option value="BK88">신한은행</option>
            <option value="BK48">신협</option>
            <option value="BK81">외환은행</option>
            <option value="BK20">우리은행</option>
            <option value="BK71">우체국</option>
            <option value="BK37">전북은행</option>
            <option value="BK12">축협</option>
            <option value="BK90">카카오뱅크</option>
            <option value="BK89">케이뱅크</option>
            <option value="BK81">하나은행(서울은행)</option>
            <option value="BK53">한국씨티은행(한미은행)</option>
          </select>
          <input
            v-model="account_number"
            type="text"
            placeholder="계좌 번호 (숫자만입력해주세요)"
            :disabled="verification === true"
            aria-label="account_number"
            @input="refundSet('account_number')"
          />
          <input
            type="text"
            :value="account_holder"
            aria-label="account_holder"
            placeholder="예금주 성함"
            disabled
          />

          <div
            v-if="verification === false"
            @click="accountVerification()"
            @keydown="accountVerification()"
          >
            계좌 인증
          </div>
          <div v-if="verification === true" class="complete">인증 완료</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    method: {
      type: String,
    },
    verification: {
      type: Boolean,
    },
    account_holder: {
      type: String,
    },
  },
  data() {
    return {
      bank_code: 'BK04',
      bank_name: 'KB국민은행',
      account_number: '',
    }
  },
  watch: {
    verification() {
      if (this.verification === false) {
        this.account_number = ''
      }
    },
  },
  methods: {
    paymentChange(method) {
      this.$emit('methodChange', method)
    },
    refundChange(e) {
      this.bank_code = e.target.value
      this.bank_name = e.target.options[e.target.selectedIndex].text
      this.refundSet('bank_code')
      this.refundSet('bank_name')
    },
    refundSet(type) {
      this.$emit('refundSet', type, this[type])
    },
    accountVerification() {
      this.$emit('accountVerification')
    },
  },
}
</script>

<style scoped lang="scss">
.wayBox {
  width: 100%;

  > ul {
    width: 100%;
    border-top: 2px solid #ff6699;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    > li {
      width: 150px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      cursor: pointer;
      border: 2px solid #f8bdd8;
      border-radius: 5px;
      font-size: 12px;
      color: #656565;
      text-align: center;
      margin: 16px 40px 16px 0;

      &.active {
        background-color: #ffebf2;
      }

      &.disabled {
        background-color: #eee;
        border: 2px solid #ddd;
        pointer-events: none;
      }
    }
  }

  > .wayEx {
    width: 100%;
    height: 135px;
    padding-top: 17px;
    border-radius: 10px;
    background-color: #ffebf2;

    > .exBox {
      margin-left: 100px;

      > h3 {
        font-size: 16px;
        color: #656565;
        margin-bottom: 14px;
      }

      > p {
        font-size: 12px;
        color: #656565;
      }
    }
  }

  > .return_accountBox {
    margin-left: 100px;

    > ul {
      > li {
        float: left;
        height: 110px;

        &.returnTitle {
          line-height: 110px;
          font-size: 12px;
          color: #707070;
          margin-right: 50px;
        }

        &.return_account {
          > select {
            margin-top: 16px;
            display: block;
            margin-bottom: 5px;
            height: 25px;
            line-height: 25px;
            text-indent: 8px;
            font-size: 12px;
            color: #707070;
            width: 133px;
            border: 1px solid #ffe1ec;
            text-align: left;
          }

          > input {
            display: block;
            margin-bottom: 5px;
            height: 25px;
            line-height: 25px;
            text-indent: 8px;
            font-size: 12px;
            color: #707070;
            width: 278px;
            border: 1px solid #ffe1ec;
            text-align: left;
            &:last-of-type {
              width: 100px;
            }
          }
          > div {
            width: 100px;
            height: 30px;
            line-height: 30px;
            background-color: #ff6699;
            color: #fff;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;

            &.complete {
              background-color: #707070;
              cursor: default;
            }
          }
        }
      }
    }
  }
}
</style>
