<template>
  <div class="step">
    <h2 v-show="type !== '자유형 책갈피' && path === 'Basic'">아크릴 크기</h2>
    <h2 v-show="type === '자유형 책갈피' && path === 'Basic'">책갈피 크기</h2>
    <div v-show="path === 'Template'" class="title">
      <h2>{{ type }} 크기</h2>
      <p v-show="type === '오뚝이 아크릴'">최대 주문 가능한 제작 사이즈: 9 x 6cm</p>
      <p v-show="type === '투명 부채'">투명 부채 크기는 19 x 19cm 고정입니다.</p>
    </div>
    <div class="sizeBox clearFix">
      <div class="size">
        <!-- <label for="xsize">가로길이 :</label> -->
        <input
          id="goods_xsize"
          v-model="setXsize"
          type="number"
          name="xsize"
          min="4"
          max="59"
          :readonly="type === '투명 부채'"
          @change="locationChange()"
        />
        <span>cm</span>
        <span class="margin">X</span>
        <input
          id="goods_ysize"
          v-model="setYsize"
          type="number"
          name="ysize"
          min="4"
          max="29"
          :readonly="type === '투명 부채'"
          @change="locationChange()"
        />
        <span>cm</span>
      </div>
      <div v-show="type !== '오뚝이 아크릴' && type !== '투명 부채'" class="sizeTable clearFix">
        <table class="t1">
          <tbody>
            <tr v-for="(yblock, index) in yLength" :key="index" class="yBox1">
              <td
                v-for="(xblock, i) in xLength"
                :key="i"
                class="xBox1"
                @click="location(xblock, yblock)"
                @keydown="location(xblock, yblock)"
                @mouseover.stop="locationOver(xblock, yblock)"
                @focus.stop="locationOver(xblock, yblock)"
              >
                <span class="previewSize">{{ previewXsize }} x {{ previewYsize }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="t2">
          <tbody>
            <tr v-for="(yblock, index) in yLength" :key="index" class="yBox">
              <td v-for="(xblock, index) in xLength" :key="index" class="xBox" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    path: {
      type: String,
    },
    xsize: {
      type: Number,
    },
    ysize: {
      type: Number,
    },
    xLength: {
      type: Number,
    },
    yLength: {
      type: Number,
    },
    previewXsize: {
      type: Number,
    },
    previewYsize: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      type: 'Goods/type',
    }),
  },
  watch: {
    xsize() {
      this.setXsize = this.xsize
    },
    ysize() {
      this.setYsize = this.ysize
    },
  },
  data() {
    return {
      setXsize: this.xsize,
      setYsize: this.ysize,
    }
  },
  methods: {
    locationChange() {
      this.$emit('sizeChange', this.setXsize, this.setYsize)
    },
    location(xblock, yblock) {
      this.$emit('location', xblock, yblock)
    },
    locationOver(xblock, yblock) {
      this.$emit('locationOver', xblock, yblock)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .title {
    > h2 {
      font-weight: 700;
      font-size: 26px;
      color: #848484;
    }

    > p {
      font-size: 14px;
      color: #ff6699;
      margin-bottom: 27px;
    }
  }

  > .sizeBox {
    font-weight: 400;

    input {
      width: 30px;
      padding-right: 6px;
      height: 21px;
      border: 1px solid #bcbcbc;
      border-radius: 5px;
      font-size: 13px;
      color: #707070;
      text-align: right;
    }

    > .size {
      > .margin {
        font-weight: 900;
        font-size: 16px;
        margin: 0 10px;
      }
    }

    > .sizeTable {
      height: 250px;
      margin: 20px auto;
      position: relative;
      z-index: 5;

      > table {
        border: 1px solid #333;
        border-collapse: collapse;
        margin: 0 auto;
        width: 100%;
        position: absolute;

        tr {
          td {
            border: 1px solid #fff;
            box-sizing: border-box;
            width: 9px;
            height: 8px;
            background-color: #fedbe8;
            border-radius: 2px;
          }
        }

        &.t1 {
          z-index: 5;
          opacity: 0;

          > tbody {
            > tr {
              position: relative;

              > td {
                position: relative;

                .previewSize {
                  visibility: hidden;
                  width: 100px;
                  background-color: Indigo;
                  color: Yellow;
                  text-align: center;
                  border-radius: 10px;
                  padding: 10px 5px;
                  position: absolute;
                  z-index: 1;
                  top: 200%;
                  left: 50%;
                  margin-left: -55px;
                }

                &:hover {
                  > .previewSize {
                    visibility: visible;
                  }
                }
              }
            }
          }
        }

        &.t2 {
          z-index: 1;
        }
      }
    }
  }
}
</style>
