<template>
  <div class="step">
    <h2>기타 부자재 선택</h2>
    <div class="itemBox clearFix">
      <ul class="itemBtns">
        <li
          class="itemBtn"
          :class="{ active: Oring > 0 }"
          @click="checkItem('Oring', Oring)"
          @keydown="checkItem('Oring', Oring)"
        >
          <div>
            <p>O링</p>
            <hr />
            <img
              v-if="Oring > 0"
              class="item_img"
              src="@/assets/goods/item_오링_active.svg"
              alt=""
            />
            <img v-else class="item_img" src="@/assets/goods/item_오링_nomal.svg" alt="" />
          </div>
        </li>
        <li
          class="itemBtn"
          :class="{ active: strap > 0 }"
          @click="checkItem('strap', strap)"
          @keydown="checkItem('strap', strap)"
        >
          <div>
            <p>휴대폰줄</p>
            <hr />
            <img
              v-if="strap > 0"
              class="item_img"
              src="@/assets/goods/item_휴대폰줄_active.svg"
              alt=""
            />
            <img v-else class="item_img" src="@/assets/goods/item_휴대폰줄_nomal.svg" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Oring: {
      type: Number,
    },
    strap: {
      type: Number,
    },
  },
  methods: {
    checkItem(type, num) {
      this.$emit('checkItem', type, num)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .itemBox {
    font-weight: 400;

    > .itemBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        > div {
          width: 121px;
          margin: 26px auto 0;
          text-align: center;
          vertical-align: middle;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
            text-align: left;
          }

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 14px;
          }

          > img {
            margin: 25px 10px;
            width: 100px;
            height: 100px;
            vertical-align: middle;
            float: right;
          }
        }
        &:first-child {
          > div {
            > img {
              width: 32px;
              height: 32px;
              margin: 53px 45px;
            }
          }
        }
      }
    }
  }
}
</style>
