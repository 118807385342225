import axios from 'axios'

let url
if (window.location.host === 'web-test-1-dot-dn-web-339309.du.r.appspot.com') {
  url = 'https://dongne-api-dot-dn-web-339309.du.r.appspot.com/'
} else if (
  window.location.host === 'dongne-production-dot-dn-web-339309.du.r.appspot.com' ||
  window.location.host === 'www.dongne.cc' ||
  window.location.host === 'dongne.cc'
) {
  url = 'https://dongne-api-production-dot-dn-web-339309.du.r.appspot.com'
} else {
  url = 'http://localhost:3000'
}
export default axios.create({
  baseURL: url,
})
