<template>
  <div class="Wrap">
    <div class="goodsWrap clearFix">
      <div class="leftWrap">
        <div class="content">
          <span>커스텀 주문 전용 페이지</span>
        </div>
        <div class="cartBtn" @click="cartAdd()" @keydown="cartAdd()">장바구니에 담기</div>
      </div>
      <div class="rightWrap">
        <div class="detailWrap">
          <div class="detailBox">
            <h2>커스텀 주문</h2>
            <p>
              ※ 제작기간 : 도안이 접수된 날로부터 영업일 기준
              <span>최대 {{ production_period }}일 소요</span>
            </p>
            <div>
              <span class="left">{{ custom_order.name }}</span>
              <div class="right">{{ custom_order.quantity }}개</div>
            </div>
          </div>
          <div class="priceBox">
            <div class="clearFix">
              <span class="left">총 금액</span>
              <span class="right"
                ><span class="strong">￦{{ Commas(custom_order.price) }}원</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      custom_order: {},
    }
  },
  computed: {
    ...mapGetters({
      production_period: 'Goods/production_period',
      shell_period: 'Goods/shell_period',
    }),
  },
  async mounted() {
    await this.$store.dispatch('Goods/periodReset')
    await this.indexedDB.initializeData()
    await this.getCustomOrder()
  },
  methods: {
    //   커스텀 주문 조회
    async getCustomOrder() {
      await this.axios
        .get(`${this.Path.GetCustomOrder}?custom_order_id=${this.$route.params.id}`)
        .then((res) => {
          if (res.data.data) {
            this.custom_order = res.data.data
          } else {
            this.$router.push({
              name: 'NotFound',
            })
          }
        })
    },
    async cartAdd() {
      const goods = [
        {
          etc: this.custom_order.name,
          order_quantity: this.custom_order.quantity,
          price: this.custom_order.price,
        },
      ]
      await this.indexedDB.addItems(goods, '커스텀 주문')
    },
  },
}
</script>

<style scoped lang="scss">
.goodsWrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  font-weight: 400;
  position: relative;

  > .leftWrap {
    float: left;
    width: 750px;

    > .content {
      height: 600px;
      text-align: center;
      position: relative;

      > span {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 25px;
        font-weight: bold;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
      }
    }

    > .cartBtn {
      width: 574px;
      height: 45px;
      line-height: 45px;
      border-radius: 10px;
      background-color: #ff6699;
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin: 0 auto 50px;
      cursor: pointer;
    }
  }

  > .rightWrap {
    float: right;
    width: 410px;
    height: 600px;
    background-color: #fff7fa;
    position: relative;

    > .detailWrap {
      > .detailBox {
        width: 345px;
        margin: 0 auto;

        > h2 {
          color: #707070;
          margin: 100px auto 0;
        }

        > p {
          font-size: 12px;
          padding-bottom: 19px;
          border-bottom: 1px solid #ff6699;

          > span {
            color: #ff6699;
          }
        }

        > div {
          height: 35px;
          line-height: 35px;
          border-bottom: 1px solid #ff6699;

          > .left {
            float: left;
            color: #979797;
            font-size: 12px;
            text-indent: 2px;
          }

          > .right {
            float: right;
            color: #656565;
            font-size: 12px;
          }
        }
      }
      > .priceBox {
        width: 345px;
        position: absolute;
        margin: 0 auto;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);

        > div {
          > .left {
            float: left;
            color: #656565;
            font-size: 12px;
            margin-top: 15px;
          }

          > .right {
            float: right;
            color: #707070;
            font-size: 12px;

            > .strong {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
</style>
