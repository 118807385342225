<template>
  <div class="goods">
    <template-container />
  </div>
</template>

<script>
import TemplateContainer from '../../containers/Goods/TemplateContainer.vue'

export default {
  name: 'Acrylic',
  components: {
    TemplateContainer,
  },
}
</script>

<style></style>
