<template>
  <div
    class="topBtn"
    :class="{ view: scroll_view === true }"
    @click="topMove()"
    @keydown="topMove()"
  >
    <img src="@/assets/icon/top.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      scroll_view: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.detectWindowScrollY)
  },
  beforeDestory() {
    window.removeEventListener('scroll', this.detectWindowScrollY)
  },
  methods: {
    detectWindowScrollY() {
      if (window.scrollY > 1000) {
        this.scroll_view = true
      } else {
        this.scroll_view = false
      }
    },
    topMove() {
      window.scroll({
        behavior: 'smooth',
        top: 0,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.topBtn {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 20;
  cursor: pointer;
  transition: 0.5s;
  opacity: 0;

  &.view {
    opacity: 1;
  }
}
</style>
