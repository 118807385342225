<template>
  <div class="tableBox">
    <table v-if="listArray.length > 0">
      <tr v-for="(list, index) in listArray" :key="index">
        <td class="info">
          <div class="orderInfo clearFix">
            <div
              v-if="list.file_url.length !== 0"
              class="imageBox"
              @click="reviewDetail(list, 0)"
              @keydown="reviewDetail(list, 0)"
            >
              <div class="imageBg">
                <div class="center">
                  <img
                    class="cursorPoint"
                    :src="list.file_url[0]"
                    :class="{ height: imgView(list.file_url[0]) }"
                    alt=""
                  />
                </div>
              </div>
              <img
                v-show="list.file_url.length > 1"
                class="multiImg"
                src="@/assets/icon/multi_img.svg"
                alt=""
              />
            </div>
            <div v-else class="imageBox">
              <div class="imageBg">
                <div class="center">
                  <img src="@/assets/cart/cart_product_img_cham.png" alt="" />
                </div>
              </div>
            </div>
            <div class="informmation">
              <p v-if="list.orderCounter >= 2" class="type">
                {{ `${list.orderType} 외 ${list.orderCounter - 1}건` }}
              </p>
              <p v-else class="type">
                {{ list.orderType }}
              </p>
              <p class="title">
                {{ list.title }}
              </p>
              <div class="starDateId">
                <div class="starBox">
                  <img
                    v-for="(star, index) in starNum"
                    v-show="list.scope > index"
                    :key="index"
                    src="@/assets/icon/star_black_fill.svg"
                    class="star"
                    alt=""
                  />
                  <img
                    v-for="(star, index) in starNum"
                    v-show="list.scope <= index"
                    :key="index"
                    src="@/assets/icon/star_black_empty.svg"
                    class="star"
                    alt=""
                  />
                </div>
                <span>{{ moment(list.date).format('YYYY-MM-DD') }}</span>
                <span>{{ list.user_id }}</span>
              </div>
              <p class="content">
                {{ list.content }}
              </p>
            </div>
          </div>
        </td>
        <!-- <td class="detail">
          <div class="reviewDelete" @click="reviewDelete(list)">삭제</div>
        </td> -->
      </tr>
    </table>
    <div v-else class="none_review">
      <div>작성한 리뷰가 없습니다.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listArray: {
      type: Array,
    },
  },
  data() {
    return {
      starNum: 5,
    }
  },
  methods: {
    reviewDelete(list) {
      this.$emit('reviewDelete', list)
    },
    reviewDetail(list, index) {
      this.$emit('reviewDetail', list, index)
    },
    // 이미지 사이즈 체크
    async imgView(img) {
      let image = new Image()
      let imageSize
      image.onload = async () => {
        // console.log(image.width, image.height)
        if (image.width > image.height) {
          imageSize = 'width'
        } else {
          imageSize = 'height'
        }
        return imageSize
      }
      image.src = img
    },
  },
}
</script>

<style scoped lang="scss">
.tableBox {
  > table {
    width: 1100px;
    margin: 0 auto;
    border-collapse: collapse;

    th {
      font-size: 14px;
      font-weight: 400;
      color: #707070;
      height: 39px;
    }

    > tr {
      height: 202px;
      text-align: center;
      border-bottom: 2px solid #ffe1ec;
      font-size: 14px;

      > .info {
        width: 80%;
        color: #707070;

        > .orderInfo {
          margin-top: 7px;
          margin-left: 10px;

          > .imageBox {
            width: 237px;
            height: 237px;
            float: left;
            margin: 0px 32px 0px 5px;
            position: relative;
            cursor: pointer;

            > .imageBg {
              position: relative;
              padding-top: 100%;
              overflow: hidden;

              > .center {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

                > img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  max-width: 237px;
                  height: auto;
                  -webkit-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);

                  &.height {
                    width: auto;
                    max-height: 237px;
                  }
                }
              }
            }
          }

          > div {
            float: left;
            width: 140px;
            height: 140px;
            position: relative;

            > img {
              width: 100%;
              height: 100%;
            }

            > .multiImg {
              position: absolute;
              bottom: 15px;
              right: 15px;
              width: 32px;
              height: 32px;
            }

            > .starDateId {
              width: 300px;

              > .starBox {
                display: inline-block;
                font-size: 0;
                margin-right: 10px;

                > img {
                  vertical-align: -3px;
                }
              }

              > span {
                margin-right: 10px;
                font-size: 14px;
              }
            }

            > .content {
              width: 300px;
              font-size: 14px;
              margin-top: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          > .informmation {
            float: left;
            width: auto;
            text-align: left;
            margin-top: 10px;
            margin-left: 27px;

            > .type {
              font-size: 14px;
              margin-bottom: 7px;
            }

            > .title {
              font-size: 20px;
              font-weight: 500;
              margin-bottom: 3px;
              width: 200px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      > .detail {
        float: right;
        margin-top: 63px;
        > .reviewRetouch {
          width: 129px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background-color: #ff6699;
          margin-bottom: 13px;
          color: #fff;
          cursor: pointer;
        }

        > .reviewDelete {
          width: 85px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          cursor: pointer;
          background-color: #fedbe8;
          float: right;
        }
      }
    }
  }
  .none_review {
    text-align: center;
    width: 100%;
    height: 150px;

    > div {
      margin-top: 150px;
      font-size: 20px;
    }
  }
}
</style>
