<template>
  <div :class="{ main: $route.path === '/' }" class="header clearFix">
    <div class="topHeader clearFix">
      <left-nav
        :s-num="sNum"
        :menu="menu"
        :category_view="category_view"
        @page-nav="pageNav"
        @nav-bar="navBar"
        @sub-hide="subHide"
        @sub-nav="subNav"
        @category-view="categoryView"
        @category-hide="categoryHide"
        @route-move="routeMove"
      />
      <right-nav
        :u-num="uNum"
        :available_point="available_point"
        @login-btn="loginBtn"
        @order-list-btn="orderListBtn"
        @user-menu="userMenu"
        @user-menu-hide="userMenuHide"
        @user-menu-bar="userMenuBar"
        @logout="logout"
      />
      <div class="moveBar" :style="moveBar" />
    </div>
    <login-modal v-if="login_view === true" :login_type="login_type" @close-tab="closeTab" />
  </div>
</template>

<script>
import LeftNav from '../components/Nav/LeftNav.vue'
import RightNav from '../components/Nav/RightNav.vue'
import LoginModal from './Modal/ModalLogin.vue'
import { mapGetters } from 'vuex'
import ChannelService from '../plugins/ChannelService'

export default {
  name: 'NavContainer',
  components: {
    LeftNav,
    RightNav,
    LoginModal,
  },
  data() {
    return {
      moveBar: {
        position: 'absolute',
        width: '141px',
        height: '4px',
        backgroundColor: '#ff6699',
        borderRadius: '10px',
        zIndex: '102',
        top: '86px',
        left: '0',
        transition: '0.5s',
      },
      sNum: 50,
      uNum: 0,
      login_view: false,
      menu: [
        {
          title: '소식',
          sub_menu: [
            {
              sub_title: '공지사항',
              sub_img: require('../assets/main/subMenu_img_0.jpg'),
              route_name: 'NewsList',
              route_params: {
                type: 'Notice',
              },
              route_query: {
                page: 1,
              },
            },
            {
              sub_title: '이벤트',
              sub_img: require('../assets/main/subMenu_img_1.jpg'),
              route_name: 'NewsList',
              route_params: {
                type: 'Event',
              },
              route_query: {
                page: 1,
                status: 'now',
              },
            },
          ],
        },
        {
          title: '굿즈제작',
          sub_menu: [
            {
              sub_title: '아크릴 키링',
              sub_img: require('../assets/main/subMenu_img_10.jpg'),
              category: [
                {
                  name: '간편 제작',
                  route_name: 'Special',
                  route_params: {
                    type: 'Quoin',
                  },
                },
                {
                  name: '주문 제작',
                  route_name: 'Acrylic',
                  route_params: {
                    type: 'Cham',
                  },
                },
              ],
            },

            {
              sub_title: '아크릴 블럭',
              sub_img: require('../assets/main/subMenu_img_14.png'),
              // route_name: 'Acrylic',
              // route_params: {
              //   type: 'Block',
              // },
              category: [
                {
                  name: '아크릴 블럭',
                  route_name: 'Acrylic',
                  route_params: {
                    type: 'Block',
                  },
                },
                {
                  name: '입체 아크릴 블럭',
                  route_name: 'Acrylic',
                  route_params: {
                    type: 'SolidBlock',
                  },
                },
              ],
            },
            {
              sub_title: '쉐이커 키링',
              sub_img: require('../assets/main/subMenu_img_25.png'),
              route_name: 'Acrylic',
              route_params: {
                type: 'ShakerKeyring',
              },
            },
            {
              sub_title: '아크릴 스탠드',
              sub_img: require('../assets/main/subMenu_img_11.jpg'),
              route_name: 'Acrylic',
              route_params: {
                type: 'Stand',
              },
            },
            {
              sub_title: '아크릴 램프',
              sub_img: require('../assets/main/subMenu_img_18.png'),
              route_name: 'Acrylic',
              route_params: {
                type: 'LampStand',
              },
            },
            {
              sub_title: '부착형 아크릴',
              sub_img: require('../assets/main/subMenu_img_13.png'),
              route_name: 'Acrylic',
              route_params: {
                type: 'Attach',
              },
            },
            {
              sub_title: '아크릴 바닥 부품',
              sub_img: require('../assets/main/subMenu_img_19.png'),
              route_name: 'Acrylic',
              route_params: {
                type: 'FloorParts',
              },
            },

            {
              sub_title: '아크릴 카드텍',
              sub_img: require('../assets/main/subMenu_img_12.jpg'),
              route_name: 'Acrylic',
              route_params: {
                type: 'Card',
              },
            },
            {
              sub_title: '자유형 책갈피',
              sub_img: require('../assets/main/subMenu_img_17.png'),
              route_name: 'Acrylic',
              route_params: {
                type: 'Bookmark',
              },
            },
            // {
            //   sub_title: '아크릴 블럭',
            //   sub_img: require('../assets/main/subMenu_img_14.jpg'),
            //   route_name: 'Special',
            //   route_params: {
            //     type: 'Block',
            //   },
            // },
            {
              sub_title: '캔버스',
              sub_img: require('../assets/main/subMenu_img_15.jpg'),
              route_name: 'Special',
              route_params: {
                type: 'Canvas',
              },
            },
          ],
        },
        {
          title: '템플릿주문',
          sub_menu: [
            {
              sub_title: '아크릴 카라비너',
              sub_img: require('../assets/main/subMenu_img_21.png'),
              route_name: 'Template',
              route_params: {
                type: 'Carabiner',
              },
            },
            {
              sub_title: '아크릴 관람차',
              sub_img: require('../assets/main/subMenu_img_22.png'),
              route_name: 'Template',
              route_params: {
                type: 'Wheel',
              },
            },
            {
              sub_title: '오뚝이 아크릴',
              sub_img: require('../assets/main/subMenu_img_23.png'),
              route_name: 'Template',
              route_params: {
                type: 'Dwarf',
              },
            },
            {
              sub_title: '투명 부채',
              sub_img: require('../assets/main/subMenu_img_24.png'),
              route_name: 'Template',
              route_params: {
                type: 'Fan',
              },
            },
          ],
        },
        {
          title: '일반상품',
          sub_menu: [
            {
              sub_title: '부자재',
              sub_img: require('../assets/main/subMenu_img_16.jpg'),
              route_name: 'EtcItem',
              route_params: {
                type: 'Items',
              },
            },
            // {
            //   sub_title: '디페스타 입장권',
            //   sub_img: require('../assets/main/subMenu_img_21.png'),
            //   route_name: 'EtcItem',
            //   route_params: {
            //     type: 'Ticket',
            //   },
            // },
            // {
            //   sub_title: '디페스타 입장권 패키지',
            //   sub_img: require('../assets/main/subMenu_img_22.png'),
            //   route_name: 'EtcItem',
            //   route_params: {
            //     type: 'Ticket_Package',
            //   },
            // },
          ],
        },
        {
          title: '가이드',
          sub_menu: [
            {
              sub_title: '아크릴 키링',
              sub_img: require('../assets/main/subMenu_img_30.png'),
              route_name: 'Guide',
              route_params: {
                type: 'Cham',
              },
            },
            {
              sub_title: '쉐이커 키링',
              sub_img: require('../assets/main/subMenu_img_38.png'),
              route_name: 'Guide',
              route_params: {
                type: 'ShakerKeyring',
              },
            },
            {
              sub_title: '아크릴 스탠드',
              sub_img: require('../assets/main/subMenu_img_31.png'),
              route_name: 'Guide',
              route_params: {
                type: 'Stand',
              },
            },
            {
              sub_title: '아크릴 블럭',
              sub_img: require('../assets/main/subMenu_img_37.png'),
              route_name: 'Guide',
              route_params: {
                type: 'Block',
              },
            },
            {
              sub_title: '부착형 아크릴',
              sub_img: require('../assets/main/subMenu_img_36.png'),
              route_name: 'Guide',
              route_params: {
                type: 'Attach',
              },
            },
            {
              sub_title: '아크릴 램프',
              sub_img: require('../assets/main/subMenu_img_35.png'),
              route_name: 'Guide',
              route_params: {
                type: 'LampStand',
              },
            },
            {
              sub_title: '아크릴 바닥 부품',
              sub_img: require('../assets/main/subMenu_img_34.png'),
              route_name: 'Guide',
              route_params: {
                type: 'FloorParts',
              },
            },
            {
              sub_title: '아크릴 카드텍',
              sub_img: require('../assets/main/subMenu_img_32.png'),
              route_name: 'Guide',
              route_params: {
                type: 'Card',
              },
            },
            {
              sub_title: '자유형 책갈피',
              sub_img: require('../assets/main/subMenu_img_33.png'),
              route_name: 'Guide',
              route_params: {
                type: 'Bookmark',
              },
            },
          ],
        },
      ],
      category_view: false,
      login_type: 'login',
    }
  },
  watch: {
    $route() {
      this.pageNav()
    },
  },
  created() {
    this.emitter.on('loginView', (type) => {
      this.loginBtn(type)
    })
  },
  mounted() {
    this.pageNav()
    this.getPoint()
  },
  methods: {
    // leftNav
    // movebar의 위치 선정
    pageNav() {
      let path = this.$route.name
      if (path === 'NewsList' || path === 'NewsDetail') {
        this.moveBar.width = '50px'
        this.moveBar.left = '195px'
      } else if (path === 'Acrylic' || path === 'Special') {
        this.moveBar.width = '90px'
        this.moveBar.left = '290px'
      } else if (path === 'Template') {
        this.moveBar.width = '110px'
        this.moveBar.left = '422px'
      } else if (path === 'EtcItem') {
        this.moveBar.width = '90px'
        this.moveBar.left = '578px'
      } else if (path === 'Guide') {
        this.moveBar.width = '70px'
        this.moveBar.left = '711px'
      } else {
        this.moveBar.width = '141px'
        this.moveBar.left = '0px'
      }
    },
    // nav에 마우스 오버했을때 해당 li의 subMeun가 보이도록
    navBar(i) {
      let subWrap = document.getElementsByClassName('subWrap')
      if (i === 0) {
        this.moveBar.width = '141px'
        this.moveBar.left = '0px'
      } else if (i === 1) {
        this.moveBar.width = '50px'
        this.moveBar.left = '195px'
        subWrap[i - 1].style.height = '480px'
      } else if (i === 2) {
        this.moveBar.width = '90px'
        this.moveBar.left = '290px'
        subWrap[i - 1].style.height = '480px'
      } else if (i === 3) {
        this.moveBar.width = '110px'
        this.moveBar.left = '422px'
        subWrap[i - 1].style.height = '480px'
      } else if (i === 4) {
        this.moveBar.width = '90px'
        this.moveBar.left = '578px'
        subWrap[i - 1].style.height = '480px'
      } else if (i === 5) {
        this.moveBar.width = '70px'
        this.moveBar.left = '711px'
        subWrap[i - 1].style.height = '480px'
      }
    },
    // nav에서 마우스 아웃되었을때 해당 li의 subMenu 숨김
    subHide(i) {
      let subWrap = document.getElementsByClassName('subWrap')
      subWrap[i].style.height = '0px'
      this.sNum = 50
    },
    // subMenu에 마우스가 오버되었을때 sNum변경
    subNav(i) {
      this.sNum = i
    },

    // rightNav
    // 로그인클릭시 로그인 모달 호출
    loginBtn(type) {
      if (type) {
        this.login_type = type
      } else {
        this.login_type = 'login'
      }
      this.login_view = true
    },
    // 주문내역 버튼 클릭시 로그인이 안되어있는 상태이면 로그인 모달 호출
    orderListBtn() {
      if (!this.$store.state.UserInfo.userToken) {
        this.loginBtn()
      } else {
        this.$router.push({
          name: 'OrderList',
          params: {
            type: 'All',
          },
          query: {
            page: 1,
          },
        })
      }
    },
    // 유저 포인트 조회
    async getPoint() {
      if (this.userInfo.user_id) {
        const config = {
          headers: {
            Authorization: `Bearer ${this.userToken}`,
          },
        }
        await this.axios.get(this.Path.UserPoint, config).then(async (res) => {
          if (res.data.success === true) {
            let point = 0
            if (res.data.data.available_point) {
              point = res.data.data.available_point
            }
            await this.$store.dispatch('Point/pointSet', point)
          }
        })
      }
    },
    // 로그인후 유저 이름에 마우스 오버시 유저 메뉴가 보임
    userMenu() {
      let userMenu = document.getElementsByClassName('userMenu')
      userMenu[0].style.height = '180px'
    },
    // 유저 이름부분에서 마우스 아웃이 유저메뉴 숨김
    userMenuHide() {
      let userMenu = document.getElementsByClassName('userMenu')
      userMenu[0].style.height = '0px'
      this.uNum = 0
    },
    // 유저메뉴에 마우스 오버시 이벤트
    userMenuBar(i) {
      this.uNum = i
    },
    // 로그아웃
    logout() {
      this.$store.dispatch('UserInfo/logout')
      ChannelService.shutdown()
    },
    // 서브메뉴 카테고리 보임
    categoryView() {
      this.category_view = true
    },
    // 서브메뉴 카테고리 숨김
    categoryHide() {
      this.category_view = false
    },
    // 클릭한 메뉴 페이지로 이동
    routeMove(i, route_name, route_params, route_query) {
      if (route_query) {
        this.$router.push({
          name: route_name,
          params: route_params,
          query: route_query,
        })
      } else if (route_params) {
        this.$router.push({
          name: route_name,
          params: route_params,
        })
      } else if (route_name) {
        this.$router.push({
          name: route_name,
        })
      }
      this.subHide(i)
    },
    // loginModal
    closeTab() {
      this.login_view = false
    },
  },
  computed: {
    ...mapGetters({
      userToken: 'UserInfo/userToken',
      available_point: 'Point/available_point',
      userInfo: 'UserInfo/userInfo',
    }),
  },
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  min-width: 1160px;
  background-color: #fafafa;
  margin: 0 auto;

  .topHeader {
    width: 1160px;
    min-width: 1080px;
    position: relative;
    z-index: 100;
    height: 88px;
    margin: 0 auto;
  }
}
</style>
