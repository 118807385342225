<template>
  <div class="wrap">
    <h1>{{ type }}</h1>
    <news-list
      :list-array="tableData"
      :params="params"
      @detail-move="detailMove"
      @event-status="eventStatus"
    />
    <page-list :paging-data="pagingData" :params="params" @page-btn="pageBtn" />
  </div>
</template>

<script>
import NewsList from '../../components/News/NewsList.vue'
import PageList from '../../components/Public/PageList.vue'

export default {
  components: {
    NewsList,
    PageList,
  },
  data() {
    return {
      listArray: [], // 받아온 공지사항, 이벤트 리스트
      tableData: [], // 보여줄 공지사항, 이벤트 리스트
      list_data: {}, // 페이지 이동시 기존에 있던 주문내역을 저장할 객체
      params: '',
      type: '',
      // 페이징 더미데이터 api에서 페이징 처리후 받아온 값을 넣을 예정
      pagingData: {
        endPage: 5, // 끝 페이지
        startPage: 1, // 시작 페이지
        totalPage: 5, // 총 페이지 수
        totalItem: 0, // 받아온 주문내역의 수
        page_list_size: 5, // 한번에 몇개의 페이지가 보일건지
        page_size: 6, // 한 페이지에 몇개까지 보여줄건지
        curPage: 1, // 현재 페이지
        curSet: 0, // 몇번째 페이지리스트인가
        set_item: 0, // 페이지 리스트에 따른 아이템수
        start_range: 0, // 목록에서 데이터 불러올 시작값
        end_range: 0, // 목록에서 데터터 불러올 끝값
      },
      get_list: false,
    }
  },
  watch: {
    async $route(to, from) {
      this.paramsCheck()
      // route 페이지가 다른지 체크
      if (to.params.type !== from.params.type) {
        // 다르다면 api호출
        await this.getList(this.$route.query.page * 1)
      } else {
        // to query값에 status 값이 있다면 status값이 다른지 체크
        if (to.query.status) {
          // 다르다면 api호출
          if (to.query.status !== from.query.status) {
            await this.getList(this.$route.query.page * 1)
          }
        }
      }

      await this.setPagingData()
    },
  },
  async mounted() {
    await this.paramsCheck()
    await this.getList(this.$route.query.page * 1)
    await this.setPagingData()
  },
  methods: {
    async setPagingData() {
      this.pagingData = await this.PagingData(
        this.pagingData,
        this.listArray,
        this.$route.query.page,
      )
      let data = []
      for (let i = this.pagingData.start_range; i < this.pagingData.end_range; i++) {
        data.push(this.listArray[i])
      }
      this.tableData = data
      this.get_list = true
    },
    // 현재 페이지의 params 체크
    async paramsCheck() {
      this.params = this.$route.params.type
      if (this.params === 'Notice') {
        this.type = '공지사항'
      } else if (this.params === 'Event') {
        this.type = '이벤트'
      }
    },
    // 공지사항, 이벤트 조회
    async getList(curPage) {
      let path
      if (this.params === 'Notice') {
        path = 'GetNotice'
      } else {
        if (this.$route.query.status === 'now') {
          path = 'GetEvent'
        } else {
          path = 'GetEndEvent'
        }
      }
      let skip = Math.ceil(curPage / this.pagingData.page_list_size)
      let today = this.moment().format('YYYY-MM-DD')
      await this.axios.get(`${this.Path[path]}?skip=${skip}&today=${today}`).then((res) => {
        if (res.data.success === true) {
          this.listArray = res.data.data
        }
      })
    },
    async pageBtn(type, page) {
      let move_page
      let next_set = false
      // type이 prev일 경우 이전 페이지로 이동
      if (type === 'prev') {
        move_page = this.$route.query.page * 1 - page
        // 이동해야될 페이지 번호가 pagingData.page_list_size의 배수일 경우 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 0) {
          next_set = true
        }
      } else if (type === 'next') {
        move_page = this.$route.query.page * 1 + page
        // 이동해야될 페이지 번호가 pagingData.page_list_size로 나누었을때 값이 1이라면 next_set의 값 변경
        if (move_page % this.pagingData.page_list_size === 1) {
          next_set = true
        }
      } else if (type === 'move') {
        move_page = page
      }
      // list_data의 속성값
      let cur_set = Math.ceil(move_page / this.pagingData.page_list_size)
      if (next_set === true) {
        // list_data에 cur_set의 값이 있을경우 list_data의 cur_set속성값을 products으로 설정
        if (this.list_data[cur_set]) {
          this.listArray = this.list_data[cur_set]
        } else {
          // 없을경우 api에서 주문내역값 조회
          await this.getList(move_page)
          if (this.listArray.length === 0) {
            // 주문내역 조회하였을떄 값이 없다면 메세지 호출
            this.Message('페이지가 없습니다.', 0, 0)
            return
          }
        }
      }
      this.list_data[cur_set] = this.listArray
      await this.$router.push({
        name: this.$route.name,
        params: { type: this.params },
        query: {
          page: move_page,
          status: this.$route.query.status,
        },
      })
    },
    // 상세 페이지로 이동
    detailMove(list) {
      sessionStorage.setItem('newsDetail', JSON.stringify(list))
      this.$router.push({
        name: 'NewsDetail',
        params: { type: this.params },
        query: {
          page: this.$route.query.page,
          status: this.$route.query.status,
          id: list.id,
        },
      })
    },
    eventStatus(status) {
      this.$router.push({
        name: 'NewsList',
        params: { type: this.params },
        query: {
          page: this.$route.query.page,
          status,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.wrap {
  width: 1160px;
  margin: 0 auto;
  font-family: 'Noto Sans KR';
  font-weight: 400;

  h1 {
    font-size: 26px;
    font-weight: 700;
    color: #707070;
    text-align: center;
    margin-top: 40px;
  }
}
</style>
