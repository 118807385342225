<template>
  <div class="wrap">
    <div class="closeTab" @click="closetab" @keydown="closetab">
      <img src="@/assets/icon/close_btn.svg" alt="" />
    </div>
    <div v-if="complete === false">
      <h1>회원가입</h1>
      <div class="registerWrap">
        <form method="post" class="tableWrap">
          <!-- 아이디  -->
          <div class="idBox">
            <p>아이디 *</p>
            <input
              id="register_userId"
              v-model="formId"
              type="text"
              name="userId"
              placeholder="영어 또는 숫자가 포함된 5~15글자"
              maxlength="15"
              :disabled="overlap === true"
            />
            <!-- 
              :disabled="overlap === true" -->
            <span v-show="overlap === false" @click="overlapCheck()" @keydown="overlapCheck()"
              >중복확인</span
            >
            <span v-show="overlap === true" @click="changeId()" @keydown="changeId()"
              >변경하기</span
            >
          </div>
          <!-- 비밀번호 -->
          <div class="pwBox">
            <p>비밀번호 *</p>
            <input
              id="register_userPw"
              v-model="formPw"
              type="password"
              name="userPw"
              placeholder="영,숫자 포함 8~20글자"
              @blur="pwLengthCheck"
            />
          </div>
          <p v-show="pwErrorMessage" class="error">
            {{ pwErrorMessage }}
          </p>
          <div class="pwCheck">
            <p>비밀번호 확인</p>
            <input
              id="register_passwordCheck"
              v-model="formPwCheck"
              type="password"
              name="passwordCheck"
              @blur="pwCheckError($event, formPw)"
            />
          </div>
          <p v-show="pwCheckErrorMessage" class="error">
            {{ pwCheckErrorMessage }}
          </p>
          <!-- 휴대전화 -->
          <div class="phoneBox">
            <p>휴대전화 *</p>
            <input
              id="register_phone_2"
              type="text"
              name="phoneNumber"
              :value="phone_1"
              maxlength="4"
              disabled
            />
            <input
              id="register_phone_2"
              type="text"
              name="phoneNumber"
              :value="phone_2"
              maxlength="4"
              disabled
            />
            <input
              id="register_phone_3"
              type="text"
              name="phoneNumber"
              :value="phone_3"
              maxlength="4"
              disabled
            />
            <span @click="certificationBtn()" @keydown="certificationBtn()">인증하기</span>
          </div>
          <!-- 이름 -->
          <div class="nameBox">
            <p>이름 *</p>
            <input id="register_userName" type="text" :value="userName" name="userName" disabled />
          </div>

          <!-- 생년월일 -->
          <div class="birthBox">
            <p>생년월일 *</p>
            <input id="register_birth" type="text" :value="userBirth" disabled />
            <!-- <v-date-picker v-if="calendar_view === true" v-model="date" :max-date="new Date()" /> -->
          </div>
          <!-- 이메일 -->
          <div class="emailBox">
            <p>이메일 *</p>
            <input id="register_email" v-model="formEmail" type="text" name="email" />
          </div>
          <!-- 주소 -->
          <div class="addressBox">
            <p>주소 *</p>
            <input
              id="register_zipCode"
              type="text"
              :value="userZipCode"
              name="zipCode"
              class="block"
              disabled
            />
            <span @click="addressBtn()" @keydown="addressBtn()">주소찾기</span>
            <input id="register_address" type="text" :value="userAddress" name="address" disabled />
            <input
              id="register_detailedAddress"
              v-model="formDetailAddr"
              type="text"
              placeholder="상세주소"
              name="detailedAddress"
            />
          </div>
          <div class="recommenderBox">
            <p>추천인</p>
            <input
              id="register_recommender"
              v-model="recommender"
              type="text"
              name="recommender"
              placeholder="추천인 아이디를 입력해주세요."
              maxlength="15"
            />
            <span>※ 첫 가입시 추천인 등록하면 1,000 포인트가 지급됩니다.</span>
          </div>
          <div class="agreement">
            <label for="register_check1"
              ><input id="register_check1" v-model="formCheck_1" type="checkbox" /><span
                >동네 공작소
                <router-link target="_blank" :to="{ name: 'ToS', params: { type: 'terms' } }"
                  >이용 약관</router-link
                >
                및<router-link target="_blank" :to="{ name: 'ToS', params: { type: 'privacy' } }"
                  >개인정보 취급방침</router-link
                >에 대한 내용을 확인하고 동의 합니다.(필수)</span
              ></label
            >
            <label for="register_check2"
              ><input id="register_check2" v-model="formCheck_2" type="checkbox" /><span
                >문자메세지를 통한 상품 및 이벤트 정보 수신에 동의 합니다.(선택)</span
              ></label
            >
          </div>
          <div class="registerBtn" @click="register()" @keydown="register()">
            <span>회원가입</span>
          </div>
        </form>
      </div>
    </div>
    <!-- 회원가입 완료시 나올 화면 -->
    <div v-if="complete === true">
      <div class="completeWrap">
        <div class="logoBox">
          <img src="@/assets/icon/loginLogo.svg" alt="" />
        </div>
        <div class="textBox">
          <p>동네 공작소 회원이 되신 걸 진심으로 감사드립니다!</p>
          <span>다양한 이벤트 및 혜택을 경험하세요.</span>
        </div>
        <div class="tableBox">
          <table>
            <tr class="topBorder">
              <td class="left">아이디</td>
              <td class="right">
                {{ formId }}
              </td>
            </tr>
            <tr>
              <td class="left">이메일</td>
              <td class="right">
                {{ formEmail }}
              </td>
            </tr>
            <tr>
              <td class="left">이름</td>
              <td class="right">
                {{ userName }}
              </td>
            </tr>
          </table>
        </div>
        <div class="buttonBox" @click="closetab" @keydown="closetab">동네 공작소 시작하기</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    complete: {
      type: Boolean,
    },
    overlap: {
      type: Boolean,
    },
    userName: {
      type: String,
    },
    userPhone: {
      type: String,
    },
    userBirth: {
      type: String,
    },
    userZipCode: {
      type: String,
    },
    userAddress: {
      type: String,
    },
    pwErrorMessage: {
      type: String,
    },
    pwCheckErrorMessage: {
      type: String,
    },
  },
  data() {
    return {
      date: '',
      calendar_view: false,
      selDate: '',
      formId: '', // 아이디
      formPw: '', // 비밀번호
      formPwCheck: '', // 비밀번호 확인
      formBirth: '', // 생년월일
      phone_1: '', // 휴대전화_1
      phone_2: '', // 휴대전화_2
      phone_3: '', // 휴대전화_3
      formEmail: '', // 이메일
      formDetailAddr: '', // 상세주소
      formCheck_1: false,
      formCheck_2: false,
      recommender: '',
    }
  },
  watch: {
    userPhone() {
      this.phone_1 = this.userPhone.substr(0, 3)
      this.phone_2 = this.userPhone.substr(3, 4)
      this.phone_3 = this.userPhone.substr(7, 4)
    },
    overlap() {
      if (this.overlap === false) {
        this.formId = ''
      }
    },
  },
  methods: {
    closetab() {
      this.$emit('close-tab')
    },
    overlapCheck() {
      this.$emit('idCheck', this.formId)
    },
    changeId() {
      this.$emit('idChange')
    },
    certificationBtn() {
      this.$emit('phoneCertification')
    },
    addressBtn() {
      this.$emit('addressSearch')
    },
    register() {
      this.$emit(
        'registerBtn',
        this.formId,
        this.formPw,
        this.formPwCheck,
        this.formEmail,
        this.formDetailAddr,
        this.formCheck_1,
        this.formCheck_2,
        this.recommender,
      )
    },
    pwLengthCheck(e) {
      this.$emit('pwLengthCheck', e.target.value)
    },
    pwCheckError(e, pw) {
      this.$emit('pwCheckError', e.target.value, pw)
    },
    calendarView() {
      this.calendar_view = true
    },
  },
}
</script>

<style scoped lang="scss">
.wrap {
  position: fixed;
  width: 556px;
  height: 650px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  padding: 30px;
  box-sizing: border-box;
  display: block;
  z-index: 1001;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);

  h1 {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: #707070;
    font-weight: bold;
    font-size: 31px;
    /* border-bottom: 4px solid #bcbcbc; */
    width: 368px;
    margin: 0px auto;
    height: 124px;
    line-height: 124px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  > .closeTab {
    position: absolute;
    /* border:1px solid #000; */
    top: 3%;
    right: 3%;
    cursor: pointer;
    z-index: 1002;
    width: 24px;
  }

  > div {
    > .registerWrap {
      width: 368px;
      margin: 0 auto;

      div {
        > p {
          font-size: 15px;
          color: #707070;
          margin-bottom: 2px;
          text-indent: 5px;
        }

        > input {
          width: 100%;
          height: 36px;
          border-radius: 10px;
          border: 2px solid #f8bdd8;
          text-indent: 10px;
          font-size: 15px;
          color: #707070;
          margin-bottom: 7px;
          box-sizing: border-box;

          &:focus {
            border: 2px solid #ff6699;
          }
        }

        > span {
          display: inline-block;
          width: 102px;
          height: 36px;
          border-radius: 10px;
          background-color: #ff6699;
          color: #fff;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }

        &.idBox {
          > input {
            width: 255px;
            margin-right: 5px;
          }
        }

        &.birthBox {
          position: relative;
          .vc-container {
            width: 100%;
          }
        }

        &.phoneBox {
          > select {
            width: 80px;
            height: 36px;
            border-radius: 10px;
            border: 2px solid #f8bdd8;
            text-indent: 15px;
            font-size: 15px;
            color: #707070;
            margin-bottom: 7px;
            box-sizing: border-box;
            margin-right: 8px;
          }
          > input {
            width: 80px;
            margin-right: 8px;
          }
        }

        &.addressBox {
          :nth-child(2) {
            width: 255px;
            margin-right: 5px;
          }
        }

        &.agreement {
          > label {
            display: block;
            white-space: nowrap;
            margin-top: 5px;

            > input {
              width: 15px;
              height: 15px;
              vertical-align: middle;
            }

            > span {
              display: inline-block;
              white-space: normal;
              vertical-align: top;
              position: relative;
              top: 2px;
              font-size: 12px;
              color: #707070;
              margin-left: 5px;
              letter-spacing: 0px;
            }

            a {
              text-decoration: underline;
            }
          }
        }

        &.recommenderBox {
          margin-top: 20px;

          > input {
            margin-bottom: 0px;
          }

          > span {
            display: block;
            width: 100%;
            background-color: transparent;
            color: #7c7c7c;
            height: auto;
            line-height: normal;
            margin-left: 0px;
            text-align: left;
            font-size: 12px;
            cursor: default;
            margin-bottom: 10px;
          }
        }

        &.registerBtn {
          width: 130px;
          margin: 30px auto 0;
          cursor: pointer;

          > span {
            width: 100%;
          }
        }
      }
    }

    > .completeWrap {
      .logoBox {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 28px;
      }

      .textBox {
        width: 259px;
        margin: 0 auto;

        > p {
          font-size: 23px;
          color: #707070;
          line-height: 34px;
          letter-spacing: 0px;
          font-family: 'Noto Sans KR';
          text-align: center;
          font-weight: 400;
        }

        > span {
          display: block;
          font-size: 16px;
          color: #707070;
          text-align: center;
          margin-top: 38px;
        }
      }

      .tableBox {
        width: 415px;
        margin: 50px auto 0;

        > table {
          width: 100%;
          border-collapse: collapse;

          > .topBorder {
            border-top: 2px solid #ff6699;
          }

          > tr {
            height: 57px;
            border-top: 1px solid #f8bdd8;
            font-size: 13px;
            color: #707070;

            > .left {
              width: 78px;
              text-align: center;
            }

            > .right {
              text-indent: 32px;
            }
          }
        }
      }

      > .buttonBox {
        width: 368px;
        height: 73px;
        line-height: 73px;
        border-radius: 10px;
        background-color: #ff6699;
        color: #fff;
        font-size: 23px;
        text-align: center;
        margin: 45px auto 0;
        cursor: pointer;
      }
    }
  }
  .error {
    margin-bottom: 5px;
    color: red;
    font-size: 12px;
    text-indent: 5px;
    margin-top: -5px;
  }
}
</style>
