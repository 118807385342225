<template>
  <div class="floor_hole">
    <h1>3. 구멍 레이어 작업</h1>
    <!--  -->
    <div>
      <p>아크릴 스탠드가 들어 갈 수 있는 구멍을 제작합니다.</p>
      <p>
        <strong
          >3mm스탠드 구멍 사이즈: 가로 <span class="red">스탠드 바 가로 사이즈</span> 세로
          <span class="red"> 2.6mm 직사각형</span></strong
        >
      </p>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/floor_hole_1.png" alt="" />
      </div>
      <div class="img_1 right">
        <p>1. 사각형 도구를 선택 합니다.</p>
        <p>2. 선택 도구 모드를 픽셀로 선택합니다.</p>
        <p>3. 사각형 도구가 선택되어 있는 상태에서 캔버스를 클릭합니다.</p>
        <p>4. 캔버스를 클릭하면 '사각형 만들기'라는 창이 뜹니다.</p>
        <p>5. 폭은 스탠드 바 가로 사이즈, 높이는 2.6mm로 설정해 주세요.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/floor_hole_2.png" alt="" />
      </div>
      <div class="img_2 right">
        <p>6. 이동 도구 (V)를 선택합니다.</p>
        <p>7. 원하는 위치에 구멍 레이어를 배치합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/floor_hole_3.png" alt="" />
      </div>
      <div class="img_3 right">
        <p>8. 일러스트 레이어를 클릭합니다.</p>
        <p>9. 오른쪽 마우스를 눌러 '픽셀 선택'을 클릭합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_full clearFix">
      <div class="center">
        <img src="@/assets/guide/floor_hole_4.png" alt="" />
      </div>
      <p>픽셀 선택이 된 모습</p>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/floor_hole_5.png" alt="" />
      </div>
      <div class="img_4 right">
        <p>10. 메뉴에 '선택'을 클릭합니다.</p>
        <p>11. '수정'을 클릭합니다.</p>
        <p>12. '확대'를 클릭합니다.<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(선택→수정→확대)</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/floor_hole_6.png" alt="" />
      </div>
      <div class="img_5 right">
        <p>13. '확대'를 클릭하면 '선택 영역 확대'라는 창이 뜹니다.</p>
        <p>
          14. 확대량을 최소 2mm가 되게 설정해주세요.<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(300dpi기준
          24픽셀 권장)
        </p>
        <p>* 300dpi보다 높을 경우, 픽셀을 늘려주시기 바랍니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_half clearFix">
      <div class="left">
        <img src="@/assets/guide/floor_hole_7.png" alt="" />
      </div>
      <div class="img_6 right">
        <p>15. 일러스트 레이어를 선택합니다.</p>
        <p>16. delete를 눌러 일러스트를 삭제합니다.</p>
      </div>
    </div>
    <!--  -->
    <div class="img_full clearFix">
      <p class="fs20">
        ※ 멀티 스탠드로 제작을 원하실 경우, 위의 작업을 반복하여 구멍 레이어 작업 부탁드립니다.
      </p>
      <div class="center">
        <img src="@/assets/guide/floor_hole_8.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.floor_hole {
  text-align: center;
  h1 {
    width: 100%;
  }

  > div {
    margin-top: 40px;

    > img {
      margin-top: 20px;
    }

    > .img {
      margin-top: 30px;

      > div {
        display: flex;
        justify-content: space-between;
      }
    }
    figure {
      margin-top: 10px;
    }
    p {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-top: 20px;

      span {
        color: #ff6699;
      }
    }

    &.img_half {
      > div {
        float: left;
        margin-left: 35px;
        text-align: left;

        &.img_1 {
          margin-top: 120px;
        }
        &.img_2 {
          margin-top: 150px;
        }
        &.img_3 {
          margin-top: 150px;
        }
        &.img_4 {
          margin-top: 120px;
        }
        &.img_5 {
          margin-top: 120px;
        }
        &.img_6 {
          margin-top: 120px;
        }
      }
    }

    &.img_full {
      margin: 80px 0;
      > div {
        text-align: center;
      }

      > p {
        text-align: center;
        font-size: 14px;

        &.fs20 {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
