<template>
  <div class="size">
    <h1>포토샵(PSD)으로 작업 시 유의 사항</h1>
    <h2>1. 크기</h2>
    <div class="typeWrap" v-show="type !== '아크릴 램프' && type !== '아크릴 블럭'">
      <div class="imgBox">
        <div>
          <img src="@/assets/guide/size_min.png" alt="" />
          <figure>최소 크기</figure>
        </div>
        <div>
          <img src="@/assets/guide/size_max.png" alt="" />
          <figure>최대 크기</figure>
        </div>
      </div>
      <p>최소 주문 크기 : <span>4X4cm</span></p>
      <p>최대 주문 크기 : <span>29X59cm</span>(폭, 높이 반전 가능)</p>
      <p>
        주문 페이지의 아크릴 크기는 1cm 단위로 조정이 가능합니다.<br />최소 주문 크기 미만으로
        제작을 원하실 경우, 제작은 가능하나 금액은 최소 주문크기(4cm)로 측정됩니다.<br />(실제 제작
        가능한 최소 크기 : 2X2cm)
      </p>
      <p class="margin_70">
        ※도안 크기가 <span>소수점</span>으로 나올 경우, <span>다음 정수로 올림</span> 하여 주문을
        넣습니다.
      </p>
      <p class="ex1 clearFix">
        <span class="left">ex) 4.67 X 7.2(cm)</span>
        <span class="right">6.2 X 8.4(cm)</span>
      </p>
      <p class="ex2 clearFix"><span class="left">↓</span> <span class="right">↓</span></p>
      <p class="ex3 clearFix">
        <span class="left">5 X 8(cm)</span> <span class="right">7 X 9(cm)</span>
      </p>
      <p>
        ※크기에 따라 가격이 달라지며, 주문 페이지에서 크기와 수량을 입력하시면 가격을 확인하실 수
        있습니다.<br />※ 캔버스 크기가 아닌 <span>작업 된 도안 크기로 제작</span>이 진행되므로 주의
        부탁 드립니다.<br />※ 아크릴이 부자재보다 작게 작업되면 정면에서 부자재가 보일 수
        있습니다.<br />이 경우, <span>재제작 및 환불은 불가능</span>합니다.
      </p>
    </div>
    <div class="typeWrap" v-show="type === '아크릴 램프'">
      <div class="imgBox">
        <div>
          <img src="@/assets/guide/lamp_size_min.png" alt="" />
          <figure>최소 크기</figure>
        </div>
        <div>
          <img src="@/assets/guide/lamp_size_max.png" alt="" />
          <figure>최대 크기</figure>
        </div>
      </div>
      <p>최소 주문 크기 : <span>8X8cm</span></p>
      <p>최대 주문 크기 : <span>29X59cm</span>(폭, 높이 반전 가능)</p>
      <p>주문 페이지의 아크릴 크기는 1cm 단위로 조정이 가능합니다.</p>
      <p class="margin_70">
        ※도안 크기가 <span>소수점</span>으로 나올 경우, <span>다음 정수로 올림</span> 하여 주문을
        넣습니다.
      </p>
      <p class="ex1 clearFix">
        <span class="left">ex) 4.67 X 7.2(cm)</span>
        <span class="right">6.2 X 8.4(cm)</span>
      </p>
      <p class="ex2 clearFix"><span class="left">↓</span> <span class="right">↓</span></p>
      <p class="ex3 clearFix">
        <span class="left">5 X 8(cm)</span> <span class="right">7 X 9(cm)</span>
      </p>
      <p>
        ※크기에 따라 가격이 달라지며, 주문 페이지에서 크기와 수량을 입력하시면 가격을 확인하실 수
        있습니다.<br />※ 캔버스 크기가 아닌 <span>작업 된 도안 크기로 제작</span>이 진행되므로 주의
        부탁 드립니다.<br />※ 주문해주신 주문 사이즈보다 도안 사이즈가 작을 경우 별도의 안내를
        드리지 않는점 참고 부탁드립니다.
      </p>
    </div>
    <div class="typeWrap" v-show="type === '아크릴 블럭'">
      <div class="imgBox">
        <div>
          <img src="@/assets/guide/block_size_min.png" alt="" />
          <figure>최소 크기</figure>
        </div>
        <div>
          <img src="@/assets/guide/lamp_size_max.png" alt="" />
          <figure>최대 크기</figure>
        </div>
      </div>
      <p>최소 주문 크기 : <span>5X5cm</span></p>
      <p>최대 주문 크기 : <span>29X59cm</span>(폭, 높이 반전 가능)</p>
      <p>주문 페이지의 아크릴 크기는 1cm 단위로 조정이 가능합니다.</p>
      <p class="margin_70">
        ※도안 크기가 <span>소수점</span>으로 나올 경우, <span>다음 정수로 올림</span> 하여 주문을
        넣습니다.
      </p>
      <p class="ex1 clearFix">
        <span class="left">ex) 4.67 X 7.2(cm)</span>
        <span class="right">6.2 X 8.4(cm)</span>
      </p>
      <p class="ex2 clearFix"><span class="left">↓</span> <span class="right">↓</span></p>
      <p class="ex3 clearFix">
        <span class="left">5 X 8(cm)</span> <span class="right">7 X 9(cm)</span>
      </p>
      <p>
        ※사이즈에 따라 가격이 달라지며, 주문 페이지에서 사이즈와 수량을 입력하시면 가격을 확인하실
        수 있습니다.<br />※ 캔버스 크기가 아닌 <span>작업 된 도안 크기로 제작</span>이 진행되므로
        주의 부탁 드립니다.<br />※ 주문해주신 주문 사이즈보다 도안 사이즈가 작을 경우 별도의 안내를
        드리지 않는점 참고 부탁드립니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.size {
  text-align: center;
  color: #707070;
  h1 {
    width: 100%;
    margin-bottom: 120px;
    margin-top: 100px;
    font-size: 34px;
    color: #707070;
  }

  h2 {
    font-size: 34px;
    color: #707070;
    margin-bottom: 20px;
  }

  figure {
    margin-top: 10px;
    font-size: 18px;
    margin-bottom: 30px;
  }

  > .typeWrap {
    > p {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-top: 20px;

      &.margin_70 {
        margin-top: 70px;
      }

      > span {
        color: #ff6699;
      }

      &.ex1 {
        position: relative;
        height: 30px;
        > span {
          color: #707070;
          position: absolute;
          transform: translate(-50%, 0%);

          &.left {
            left: 40%;
          }
          &.right {
            right: 30%;
          }
        }
      }
      &.ex2 {
        position: relative;
        height: 30px;
        > span {
          color: #707070;
          position: absolute;
          transform: translate(-50%, 0%);

          &.left {
            left: 40%;
          }
          &.right {
            right: 40%;
          }
        }
      }
      &.ex3 {
        position: relative;
        height: 30px;
        > span {
          color: #707070;
          position: absolute;
          transform: translate(-50%, 0%);

          &.left {
            left: 41%;
          }
          &.right {
            right: 33%;
          }
        }
      }
    }
    > .imgBox {
      > div {
        display: inline-block;
        margin: 0 30px;
      }
    }
  }
}
</style>
