<template>
  <div class="detailBox">
    <!-- 키링 -->
    <div v-for="(type, index) in keyringType" :key="index">
      <div v-if="type.metal > 0">
        <span class="left">{{ type.type }}(실버)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'metal', type.metal, index)"
            @keydown="plminBtn('plus', 'metal', type.metal, index)"
          />
          <input
            id="items_nomal_metal"
            type="number"
            :value="type.metal"
            @change="numChange($event, 'metal', index)"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'metal', type.metal, index)"
            @keydown="plminBtn('minus', 'metal', type.metal, index)"
          />
        </div>
      </div>
      <div v-if="type.gold > 0">
        <span class="left">{{ type.type }}(골드)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'gold', type.gold, index)"
            @keydown="plminBtn('plus', 'gold', type.gold, index)"
          />
          <input
            id="items_nomal_gold"
            type="number"
            :value="type.gold"
            @change="numChange($event, 'gold', index)"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'gold', type.gold, index)"
            @keydown="plminBtn('minus', 'gold', type.gold, index)"
          />
        </div>
      </div>
      <div v-if="type.rose > 0">
        <span class="left">{{ type.type }}(로즈골드)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'rose', type.rose, index)"
            @keydown="plminBtn('plus', 'rose', type.rose, index)"
          />
          <input
            id="items_nomal_rose"
            type="number"
            :value="type.rose"
            @change="numChange($event, 'rose', index)"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'rose', type.rose, index)"
            @keydown="plminBtn('minus', 'rose', type.rose, index)"
          />
        </div>
      </div>
    </div>
    <!-- 군번줄 -->
    <div v-for="(tag, index) in dogTag" :key="index">
      <div v-if="tag.amount > 0">
        <span class="left">군번줄({{ tag.name }})</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'tagSel', tag.amount, index)"
            @keydown="plminBtn('plus', 'tagSel', tag.amount, index)"
          />
          <input
            id="items_nomal_metal"
            type="number"
            :value="tag.amount"
            @change="numChange($event, 'tagSel', index)"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'tagSel', tag.amount, index)"
            @keydown="plminBtn('minus', 'tagSel', tag.amount, index)"
          />
        </div>
      </div>
    </div>
    <!-- 와이어링 -->
    <div v-for="(wire, index) in wiring" :key="index">
      <div v-if="wire.amount > 0">
        <span class="left">와이어링({{ wire.name }})</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'wiringSel', wire.amount, index)"
            @keydown="plminBtn('plus', 'wiringSel', wire.amount, index)"
          />
          <input
            id="items_nomal_metal"
            type="number"
            :value="wire.amount"
            @change="numChange($event, 'wiringSel', index)"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'wiringSel', wire.amount, index)"
            @keydown="plminBtn('minus', 'wiringSel', wire.amount, index)"
          />
        </div>
      </div>
    </div>
    <!-- 스마트톡 -->
    <div>
      <div v-if="smartTok.empty > 0">
        <span class="left">스마트톡(투명색)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'smartTokSel', smartTok.empty, 0, 'empty')"
            @keydown="plminBtn('plus', 'smartTokSel', smartTok.empty, 0, 'empty')"
          />
          <input
            id="items_smartTok"
            type="number"
            :value="smartTok.empty"
            @change="numChange($event, 'smartTokSel', 0, 'empty')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'smartTokSel', smartTok.empty, 0, 'empty')"
            @keydown="plminBtn('minus', 'smartTokSel', smartTok.empty, 0, 'empty')"
          />
        </div>
      </div>
      <div v-if="smartTok.white > 0">
        <span class="left">스마트톡(흰색)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'smartTokSel', smartTok.white, 0, 'white')"
            @keydown="plminBtn('plus', 'smartTokSel', smartTok.white, 0, 'white')"
          />
          <input
            id="items_smartTok"
            type="number"
            :value="smartTok.white"
            @change="numChange($event, 'smartTokSel', 0, 'white')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'smartTokSel', smartTok.white, 0, 'white')"
            @keydown="plminBtn('minus', 'smartTokSel', smartTok.white, 0, 'white')"
          />
        </div>
      </div>
      <div v-if="smartTok.black > 0">
        <span class="left">스마트톡(검정색)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'smartTokSel', smartTok.black, 0, 'black')"
            @keydown="plminBtn('plus', 'smartTokSel', smartTok.black, 0, 'black')"
          />
          <input
            id="items_smartTok"
            type="number"
            :value="smartTok.black"
            @change="numChange($event, 'smartTokSel', 0, 'black')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'smartTokSel', smartTok.black, 0, 'black')"
            @keydown="plminBtn('minus', 'smartTokSel', smartTok.black, 0, 'black')"
          />
        </div>
      </div>
    </div>
    <!-- 뱃지 -->
    <div>
      <div v-if="badge.pin > 0">
        <span class="left">뱃지(핀뱃지)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'badgeSel', badge.pin, 0, 'pin')"
            @keydown="plminBtn('plus', 'badgeSel', badge.pin, 0, 'pin')"
          />
          <input
            id="items_smartTok"
            type="number"
            :value="badge.pin"
            @change="numChange($event, 'badgeSel', 0, 'pin')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'badgeSel', badge.pin, 0, 'pin')"
            @keydown="plminBtn('minus', 'badgeSel', badge.pin, 0, 'pin')"
          />
        </div>
      </div>
      <div v-if="badge.tongs > 0">
        <span class="left">뱃지(집게핀뱃지)</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'badgeSel', badge.tongs, 0, 'tongs')"
            @keydown="plminBtn('plus', 'badgeSel', badge.tongs, 0, 'tongs')"
          />
          <input
            id="items_smartTok"
            type="number"
            :value="badge.tongs"
            @change="numChange($event, 'badgeSel', 0, 'tongs')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'badgeSel', badge.tongs, 0, 'tongs')"
            @keydown="plminBtn('minus', 'badgeSel', badge.tongs, 0, 'tongs')"
          />
        </div>
      </div>
    </div>
    <!-- o링 -->
    <div>
      <div v-if="Oring > 0">
        <span class="left">O링</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'Oring', Oring)"
            @keydown="plminBtn('plus', 'Oring', Oring)"
          />
          <input
            id="items_Oring"
            type="number"
            :value="Oring"
            @change="numChange($event, 'Oring')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'Oring', Oring)"
            @keydown="plminBtn('minus', 'Oring', Oring)"
          />
        </div>
      </div>
      <!-- 휴대폰줄 -->
      <div v-if="strap > 0">
        <span class="left">휴대폰줄</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="plminBtn('plus', 'strap', strap)"
            @keydown="plminBtn('plus', 'strap', strap)"
          />
          <input
            id="items_strap"
            type="number"
            :value="strap"
            @change="numChange($event, 'strap')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="plminBtn('minus', 'strap', strap)"
            @keydown="plminBtn('minus', 'strap', strap)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
    Oring: {
      type: Number,
    },
    dogTag: {
      type: Array,
    },
    wiring: {
      type: Array,
    },
    smartTok: {
      type: Object,
    },
    badge: {
      type: Object,
    },
    strap: {
      type: Number,
    },
    keyringType: {
      type: Array,
    },
  },
  methods: {
    plminBtn(fm, type, num, index, color) {
      this.$emit('plminBtn', fm, type, num, index, color)
    },
    numChange(e, type, index, color) {
      let typeNum = e.target.value * 1
      this.$emit('numChange', type, typeNum, index, color)
    },
  },
}
</script>

<style scoped lang="scss">
.detailBox {
  width: 345px;
  margin: 0 auto;
  height: 43vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-bottom: 3px solid #ff6699;

  > div {
    > div {
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid #ff6699;

      > .left {
        float: left;
        color: #979797;
        font-size: 12px;
        text-indent: 2px;
      }

      > .right {
        float: right;
        color: #656565;
        font-size: 12px;

        > input {
          width: 50px;
          height: 20px;
          text-align: center;
          margin: 0 5px;
          border-radius: 2px;
          border: 0.5px solid #ff6699;

          &:focus {
            outline: none;
          }
        }

        > img {
          width: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
