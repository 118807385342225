export const Lamp = {
  namespaced: true,
  state: () => ({
    Lamp: {
      '3000k': 0,
      '4000k': 0,
      '6500k': 0,
    },
  }),
  mutations: {
    LAMP_SET(state, data) {
      state.Lamp[data.color] = data.quantity
    },
  },
  actions: {
    lamp_quantity_set({ commit }, data) {
      commit('LAMP_SET', data)
    },
  },
  getters: {
    lamp(state) {
      return state.Lamp
    },
  },
}
