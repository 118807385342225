import { createStore } from 'vuex'
import { UserInfo } from './modules/UserInfo'
import { Message } from './modules/Message'
import { Goods } from './modules/Goods'
import { IntroSet } from './modules/IntroSet'
import { Drag } from './modules/Drag'
import { Accessories } from './modules/Accessories'
import { SizeBox } from './modules/SizeBox'
import { Ticket } from './modules/Ticket'
import { Point } from './modules/Point'
import { Lamp } from './modules/Lamp'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: { UserInfo, Message, Goods, IntroSet, Drag, Accessories, SizeBox, Ticket, Point, Lamp },
})
