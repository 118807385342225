<template>
  <div class="step">
    <h2>우드 램프</h2>
    <div class="standBox clearFix">
      <ul class="standBtns">
        <li
          class="standBtn lamp"
          :class="[{ active: standNum === 1 }, { disabled: lamp['3000k'] <= 0 }]"
          @click="checkstand(1, '우드 램프(3000k)')"
          @keydown="checkstand(1, '우드 램프(3000k)')"
        >
          <div>
            <p>
              3000K <span>(재고 수량:{{ lamp['3000k'] }})</span>
            </p>
            <div class="colorBox k3000" :class="{ color: standNum === 1 }" />

            <!-- <span>(가로: 약 100mm, 높이 : 약 35mm)</span> -->
            <hr />
            <img
              v-if="standNum === 1"
              class="stand_img"
              src="@/assets/goods/stand_lamp_3000k_active.png"
              alt=""
            />
            <img v-else class="stand_img" src="@/assets/goods/stand_lamp_3000k_normal.png" alt="" />
          </div>
        </li>
        <li
          class="standBtn lamp"
          :class="{ active: standNum === 2 }"
          @click="checkstand(2, '우드 램프(4000k)')"
          @keydown="checkstand(2, '우드 램프(4000k)')"
        >
          <div>
            <p>
              4000K <span>(재고 수량:{{ lamp['4000k'] }})</span>
            </p>
            <div class="colorBox k4000" :class="{ color: standNum === 2 }" />

            <!-- <span>(가로: 약 100mm, 높이 : 약 35mm)</span> -->
            <hr />
            <img
              v-if="standNum === 2"
              class="stand_img"
              src="@/assets/goods/stand_lamp_4000k_active.png"
              alt=""
            />
            <img v-else class="stand_img" src="@/assets/goods/stand_lamp_4000k_normal.png" alt="" />
          </div>
        </li>
        <li
          class="standBtn lamp"
          :class="{ active: standNum === 3 }"
          @click="checkstand(3, '우드 램프(6500k)')"
          @keydown="checkstand(3, '우드 램프(6500k)')"
        >
          <div>
            <p>
              6500K <span>(재고 수량:{{ lamp['6500k'] }})</span>
            </p>
            <div class="colorBox k6500" :class="{ color: standNum === 3 }" />

            <!-- <span>(가로: 약 100mm, 높이 : 약 35mm)</span> -->
            <hr />
            <img
              v-if="standNum === 3"
              class="stand_img"
              src="@/assets/goods/stand_lamp_6500k_active.png"
              alt=""
            />
            <img v-else class="stand_img" src="@/assets/goods/stand_lamp_6500k_normal.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    standNum: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      lamp: 'Lamp/lamp',
      type: 'Goods/type',
    }),
  },
  methods: {
    checkstand(i, stand) {
      this.$emit('checkStand', 'stand', i, stand)
    },
  },
}
</script>

<style scoped lang="scss">
.step {
  width: 575px;
  margin: 0 auto 100px;
  > h2 {
    font-weight: 700;
    font-size: 26px;
    color: #848484;
    margin-bottom: 27px;
  }

  > .standBox {
    font-weight: 400;

    > .standBtns {
      float: left;

      > li {
        width: 180px;
        height: 240px;
        border: 2px solid #bcbcbc;
        border-radius: 10px;
        float: left;
        box-sizing: border-box;
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border: 2px solid #ff6699;
          background-color: #fff7fa87;
        }

        &.disabled {
          background: #ddd;
          pointer-events: none;
        }

        > div {
          width: 144px;
          height: 185px;
          margin: 26px auto 0;
          text-align: center;
          vertical-align: middle;

          > p {
            font-size: 16px;
            color: #707070;
            margin-bottom: 2px;
            text-align: left;

            > span {
              font-size: 12px;
            }
          }

          > .colorBox {
            width: 15px;
            height: 15px;
            border-radius: 3px;
            margin-right: 3px;
            margin-top: 5px;
            cursor: pointer;
            box-sizing: border-box;

            &.k3000 {
              background-color: #f8d89c;
            }
            &.k4000 {
              background-color: #fceccf;
            }
            &.k6500 {
              background-color: #e4eaef;
            }

            &.color {
              border: 2px solid #ff6699;
              background-image: url('../../../assets/icon/checkIcon.png');
              background-size: 90%;
              background-position: center center;
            }
          }

          > span {
            font-size: 10px;
            color: #707070;
          }

          > hr {
            width: 100%;
            border: 1px solid #e6e6e6;
            margin-top: 5px;
          }

          > img {
            margin-top: 48px;
          }
        }
      }
    }
  }
}
input[type='checkbox'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      content: ' ';
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 15px;
      margin: -2px 8px 0 0;
      text-align: center;
      vertical-align: middle;
      background: #fafafa;
      border: 1px solid #979797;
      border-radius: 5px;
    }
  }

  &:checked {
    + label {
      &::before {
        content: '\2713';
        /* font-weight: 700; */
        color: #ff6699;
        text-shadow: 0.5px 0.5px #ff6699;
        background: #fedbe8;
        border-color: #979797;
      }
    }
  }
}
label {
  color: #707070;
  font-size: 14px;
  margin-bottom: 5px;
}
</style>
