<template>
  <div class="detailBox">
    <div class="imageBox">
      <img src="@/assets/goods/ticket_card.png" alt="" />
    </div>
    <h2>{{ type }}</h2>
    <p v-if="ticket_package_quantity > 0">(남은 수량: {{ ticket_package_quantity }}개)</p>
    <p v-else>(품절)</p>
    <div class="ticketBox">
      <div v-if="ticket_package > 0">
        <span class="left clearFix">아크릴 우드 램프</span>
        <div class="right">
          <img
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
            @click="Ticket_Package_plminBtn('plus', 'Neri', ticket_package)"
            @keydown="Ticket_Package_plminBtn('plus', 'Neri', ticket_package)"
          />
          <input
            id="items_Neri"
            type="number"
            :value="ticket_package"
            @change="Ticket_Package_numChange($event, 'Neri')"
          />
          <img
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
            @click="Ticket_Package_plminBtn('minus', 'Neri', ticket_package)"
            @keydown="Ticket_Package_plminBtn('minus', 'Neri', ticket_package)"
          />
        </div>
      </div>
      <!-- <div v-if="ticket_package.Nathan > 0">
        <span class="left clearFix">네이선</span>
        <div class="right">
          <img
            @click="Ticket_Package_plminBtn('plus', 'Nathan', ticket_package.Nathan)"
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
          />
          <input
            type="number"
            id="items_Nathan"
            @change="Ticket_Package_numChange($event, 'Nathan')"
            :value="ticket_package.Nathan"
          />
          <img
            @click="Ticket_Package_plminBtn('minus', 'Nathan', ticket_package.Nathan)"
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
          />
        </div>
      </div>
      <div v-if="ticket_package.Synthesis > 0">
        <span class="left clearFix">네이선 + 네리</span>
        <div class="right">
          <img
            @click="Ticket_Package_plminBtn('plus', 'Synthesis', ticket_package.Synthesis)"
            src="@/assets/icon/goods_plus_ff6699.png"
            alt=""
          />
          <input
            type="number"
            id="items_Synthesis"
            @change="Ticket_Package_numChange($event, 'Synthesis')"
            :value="ticket_package.Synthesis"
          />
          <img
            @click="Ticket_Package_plminBtn('minus', 'Synthesis', ticket_package.Synthesis)"
            src="@/assets/icon/goods_minus_ff6699.png"
            alt=""
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
    ticket_package: {
      type: Object,
    },
    ticket_package_quantity: {
      type: Number,
    },
  },
  methods: {
    Ticket_Package_plminBtn(fm, type, num) {
      this.$emit('Ticket_Package_plminBtn', fm, type, num)
    },
    Ticket_Package_numChange(e, type) {
      let typeNum = e.target.value * 1
      this.$emit('Ticket_Package_numChange', type, typeNum)
    },
  },
}
</script>

<style scoped lang="scss">
.detailBox {
  width: 345px;
  margin: 0 auto;

  > .imageBox {
    border-bottom: 1px solid #ff6699;
    text-align: center;
    padding: 15px 0;

    > img {
      width: 266px;
    }
  }

  > h2 {
    color: #707070;
    margin: 31px auto 0;
  }

  > p {
    font-size: 12px;
    padding-bottom: 19px;
    border-bottom: 1px solid #ff6699;

    > span {
      color: #ff6699;
    }
  }

  > div {
    > div {
      height: 35px;
      line-height: 35px;
      border-bottom: 1px solid #ff6699;

      > .left {
        float: left;
        color: #979797;
        font-size: 12px;
        text-indent: 2px;
      }

      > .right {
        float: right;
        color: #656565;
        font-size: 12px;

        > input {
          width: 50px;
          height: 20px;
          text-align: center;
          margin: 0 5px;
          border-radius: 2px;
          border: 0.5px solid #ff6699;

          &:focus {
            outline: none;
          }
        }

        > img {
          width: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
