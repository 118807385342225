import router from '../router'

const Cart = {
  Add: async (goods, type, svg) => {
    let cartList
    if (!sessionStorage.getItem('cartList')) {
      cartList = []
    } else {
      cartList = await JSON.parse(sessionStorage.getItem('cartList'))
    }
    if (svg) {
      let reader = new FileReader()
      reader.onload = async (event) => {
        let file = {
          name: svg.name,
          size: svg.size,
          type: svg.type,
        }
        const blob = await String(event.target.result)
        goods.svgData = {
          blob,
          file,
        }
      }
      await reader.readAsDataURL(svg)
    }
    goods.id = cartList.length
    goods.type = type
    goods.price = goods.fullPrice
    if (goods.etc === '고리') {
      goods.etc = `${goods.keyring_option}(${goods.keyring_color})`
    }
    if (goods.file) {
      let reader = new FileReader()
      reader.onload = async (event) => {
        let file = {
          name: goods.file.name,
          size: goods.file.size,
          type: goods.file.type,
        }
        const blob = await String(event.target.result)
        goods.fileData = {
          blob,
          file,
        }
        cartList.push(goods)
        sessionStorage.setItem('cartList', JSON.stringify(cartList))
        await router.push({
          name: 'Cart',
        })
      }
      await reader.readAsDataURL(goods.file)
    } else {
      cartList.push(goods)
      sessionStorage.setItem('cartList', JSON.stringify(cartList))
      await router.push({
        name: 'Cart',
      })
    }
  },
  Items: async (goods, type) => {
    let cartList
    if (!sessionStorage.getItem('cartList')) {
      cartList = []
    } else {
      cartList = await JSON.parse(sessionStorage.getItem('cartList'))
    }
    let cartId = cartList.length
    let concat = []
    await goods.map(async (data, index) => {
      let check = false
      data.type = type
      await cartList.map(async (cart) => {
        if (cart.type === data.type) {
          if (cart.etc === data.etc) {
            cart.order_quantity += data.order_quantity
            cart.price += data.price
            check = true
            return check
          }
        }
        return check
      })
      if (check === false) {
        data.id = cartId
        concat.push(data)
        cartId++
      }
      return concat
    })
    cartList = cartList.concat(concat)
    await sessionStorage.setItem('cartList', JSON.stringify(cartList))
    await router.push({
      name: 'Cart',
    })
  },
  Remove: async (goods) => {
    let cartList = await JSON.parse(sessionStorage.getItem('cartList'))
    let idCheck = async (i) => {
      for (let j = 0; j < goods.length; j++) {
        if (cartList[i].id === goods[j].id) {
          cartList.splice(i, 1)
          return
        }
      }
    }
    for (let i = cartList.length - 1; i >= 0; i--) {
      idCheck(i)
    }
    await sessionStorage.setItem('cartList', JSON.stringify(cartList))
  },
}

export default Cart
