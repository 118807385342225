<template>
  <div id="dragTarget" class="quoinWrap clearFix">
    <div v-if="svg === true" class="compareBox clearFix">
      <canvas id="quoin_canvas2" />
      <canvas id="quoin_canvas3" />
      <div id="goods_quoin_div_1" />
      <div id="goods_quoin_div_2">
        <img id="quoin_img" src="" alt="" :style="imageStyle" />
      </div>
      <div id="quoin_mask">
        <img id="quoin_loading" alt="" />
      </div>
    </div>
    <div id="quoin_ysize_bar" class="ysizeBar">
      <span class="bar" />
      <span class="ysize">{{ goods.y * 10 }}mm</span>
      <span class="bar" />
    </div>
    <div id="quoin_xsize_bar" class="xsizeBar">
      <span class="bar" />
      <span class="xsize">{{ goods.x * 10 }}mm</span>
      <span class="bar" />
    </div>
    <!-- <div class="labelBox"> -->
    <label v-if="svg === false" id="quoinLabel" for="quoin_svg_file" class="inputLabel">
      <div
        @dragleave.prevent="overEvent"
        @dragenter.prevent="overEvent"
        @dragover.prevent
        @drop.prevent="dropEvent"
      >
        <div class="dropBox" :style="dropBoxStyle" />
        <img class="exIcon" src="@/assets/goods/quoin_img.svg" alt="" />
        <img class="plusIcon" src="@/assets/icon/plusIcon.svg" alt="" />
      </div>
    </label>
    <input
      id="quoin_svg_file"
      ref="img_file"
      type="file"
      accept="image/*"
      class="file-input"
      name="files"
      @change="imgSelectFile"
    />
    <div v-show="svg === true" id="dragInfo" class="dragMessage">
      구멍을 원하는 위치로 드래그 해주세요!
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goods: {
      type: Object,
    },
    svg: {
      type: Boolean,
    },
    // dragMessage: {
    //   type: String,
    // },
    // dragInfo: {
    //   type: Object,
    // },
    imageStyle: {
      type: Object,
    },
    dropBoxStyle: {
      type: Object,
    },
  },
  methods: {
    overEvent(e) {
      this.$emit('overEvent', e)
    },
    dropEvent(e) {
      this.$emit('dropEvent', e, 'quoin')
    },
    imgSelectFile(e) {
      this.$emit('imgSelect', 'quoin', e.target.files[0])
    },
  },
}
</script>

<style scoped lang="scss">
.quoinWrap {
  width: 300px;
  height: 300px;
  margin: 200px auto;
  position: relative;

  input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;

    > div {
      width: 300px;
      height: 300px;
      position: relative;

      > div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
      }

      .exIcon {
        position: absolute;
        width: 184px;
        height: 247px;
        top: 50%;
        margin-top: -123px;
        left: 50%;
        margin-left: -92px;
        z-index: 3;
      }

      .plusIcon {
        position: absolute;
        margin-left: -19px;
        top: 50%;
        margin-top: -19px;
        left: 50%;
        z-index: 3;
      }
    }
  }

  > .compareBox {
    position: relative;
    height: 100%;
    overflow: hidden;

    #goods_quoin_div_1 {
      z-index: 10;
    }

    #goods_quoin_div_2 {
      border: none;
      z-index: 5;
      display: flex;
      justify-content: center;
      text-align: center;

      img {
        display: none;
      }
    }

    canvas {
      opacity: 0;
      z-index: -100;
    }

    > div {
      position: absolute;
      width: 300px;
      height: 300px;
      top: 0;
      left: 0;
    }
  }

  > .ysizeBar {
    position: absolute;
    border-right: 1px solid #ff6699;
    height: 200px;
    width: 1px;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    right: 0;

    > span {
      position: absolute;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }
    }

    > .bar {
      width: 5px;
      border-bottom: 1px solid #ff6699;
      left: -1px;
    }

    > .ysize {
      top: 50%;
      left: 10px;
      font-size: 10px;
      color: #ff6699;
      margin-top: -7px;
    }
  }

  > .xsizeBar {
    position: absolute;
    border-bottom: 1px solid #ff6699;
    height: 1px;
    width: 200px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    > span {
      position: absolute;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    > .bar {
      height: 5px;
      border-right: 1px solid #ff6699;
      top: -1px;
    }

    > .xsize {
      left: 50%;
      font-size: 10px;
      color: #ff6699;
      top: 10px;
      margin-left: -15px;
    }
  }

  > .dragMessage {
    position: absolute;
    top: -60px;
    color: #ff6699;
    font-size: 26px;
    width: 500px;
    left: 50%;
    margin-left: -250px;
    text-align: center;
    font-weight: 700;
  }
}
</style>
