<template>
  <div>
    <guide-container />
  </div>
</template>

<script>
import GuideContainer from '../../containers/Guide/GuideContainer.vue'

export default {
  components: {
    GuideContainer,
  },
}
</script>

<style></style>
