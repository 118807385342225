const Path = {
  SignIn: 'api/user/sign_in', // 로그인
  UserProfile: 'api/user/profile', // 유저 정보
  UserIdHash: 'api/user/user_id_hash', // 유저 아이디 암호화
  UserPoint: 'api/user/point', // 유저 포인트 조회
  Logout: '/logout', // 로그아웃
  SignUp: 'api/user/sign_up', // 회원가입
  IdOverlap: 'api/user/check_id', // 아이디 중복 체크
  PhoneCertification: 'api/user/phone_certifications', // 휴대폰 인증
  IdFind: 'api/user/find_id', // 아이디 찾기
  PwFind: 'api/user/find_password', // 비밀번호 찾기
  FindPwChange: 'api/user/change_password', // 비밀번호 찾기후 비밀번호 변경
  GetNotice: 'api/manage/notice_list', // 공지사항 조회
  GetEvent: 'api/manage/progress_event_list', // 이벤트 조회
  GetEndEvent: 'api/manage/end_event_list', // 종료된 이벤트 조회
  CouponCheck: '/coupon_check', // 쿠폰 체크
  AccountCertifications: '/api/payment/account_certifications', // 환불 계좌 인증
  Payment: 'api/payment/complete', // 결제
  FileUpload: '/api/file/upload_file', // 단일 파일 업로드
  GetOrderAllList: '/api/payment/order_list', // 주문내역 전체조회
  GetOrderDateList: '/api/payment/search', // 주문내역 날짜조회
  GetOrderSearchList: '/api/payment/merchant_uid', // 주문내역 주문번호조회
  GetOrderDetail: '/api/payment/payment_id', // 주문내역 상세 조회
  GetRefundInfo: '/api/payment/response_refund', // 환불정보 조회
  OrderFileUpload: '/api/payment/change_order_file', // 주문내역 상세 파일 업로드
  OrderFileDelete: '/api/payment/delete_order_file', // 주문내역 상세 파일 삭제
  CancelPayment: '/api/payment/change_payment_status', // 주문취소
  RefundRequest: '/api/payment/request_refund', // 환불신청
  ConfirmationOrder: '/api/payment/confirmation_of_purchase', // 구매 확정
  OrderRetouchAnswer: '/api/payment/retouch_answer', // 도안수정요청 답변
  MyPageCheck: '/api/user/check_password', // 회원정보 수정 - 비밀번호확인
  MyPagePwChange: '/api/user/change_profile_password', // 회원정보 수정 - 비밀번호 변경
  MyPagePhoneChange: '/api/user/change_phone', // 회원정보 수정 - 휴대폰 번호변경
  MyPageUpdate: '/api/user/change_profile_info', // 회원정보 수정
  GetPossibleReview: '/api/payment/approved_review', // 작성 가능 리뷰 조회
  WriteReview: '/api/manage/add_review', // 리뷰 작성
  GetWrittenReview: '/api/manage/my_registered_review', // 내가 작성한 리뷰 조회
  reviewDelete: '/review_delete', // 리뷰 삭제
  getReview: '/api/manage/review_list', // 리뷰 조회
  MyCouponList: '/api/user/coupon_list', // 내 쿠폰 조회
  CouponRegistration: '/api/user/add_coupon', // 쿠폰 등록
  GetTicketQuantity: '/api/ticket', // 디페스타 입장권 티켓 남은 수량 조회
  TicketCount: '/api/ticket/update', // 디페스타 입장권, 패키지 구매 수량 카운트
  GetCustomOrder: '/api/payment/get_custom_order', // 커스텀주문 조회
  MyPointHistory: '/api/user/point_history', // 내 포인트 내역 조회

  MyAccumulatePrice: '/api/user/get_accumulate_price', // 내 3개월간 구매확정 누적금액 조회

  UserSuspension: '/api/user/user_suspension', // 유저 회원탈퇴 신청

  GetMaterial: '/api/manage/material', // 부자재 남은수량 조회
  GetAnnouncement: '/api/manage/announcement', // 메인팝업 조회
  GetQuickOrder: '/api/manage/quick_order_check', // 빠른제작 가능 여부 조회
}
export default Path
