<template>
  <div class="searchWrap">
    <h2>주문내역 조회</h2>
    <!-- <div class="orderSearch clearFix"> -->
    <div class="selectSearch">
      <span class="searchType">기간별 조회</span>
      <select id="start_year" v-model="s_year" name="start_year" @change="dateChange()">
        <option v-for="y in start_year" :key="y">
          {{ y }}
        </option>
      </select>
      <select id="start_month" v-model="s_month" name="start_month" @change="dateChange()">
        <option v-for="m in start_month" :key="m">
          {{ m }}
        </option>
      </select>
      <select id="start_day" v-model="s_day" name="start_day" @change="dateChange()">
        <option v-for="d in start_day" :key="d">
          {{ d }}
        </option>
      </select>
      ~
      <select id="end_year" v-model="e_year" name="end_year" @change="dateChange()">
        <option v-for="y in end_year" :key="y">
          {{ y }}
        </option>
      </select>
      <select id="end_month" v-model="e_month" name="end_month" @change="dateChange()">
        <option v-for="m in end_month" :key="m">
          {{ m }}
        </option>
      </select>
      <select id="end_day" v-model="e_day" name="end_day" @change="dateChange()">
        <option v-for="d in end_day" :key="d">
          {{ d }}
        </option>
      </select>
      <button type="button" class="search" @click="searchDayBtn()">찾기</button>
      <button type="button" @click="agoSelect(7, 'days')">1주</button>
      <button type="button" @click="agoSelect(1, 'month')">1개월</button>
      <button type="button" @click="agoSelect(6, 'month')">6개월</button>
      <button type="button" @click="allList()">전체</button>
    </div>
    <div class="nameSearch">
      <span class="searchType">주문번호검색</span>
      <form @submit.prevent="searchNumBtn">
        <input
          v-model="search_data"
          type="text"
          placeholder="주문번호로 검색"
          onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
          aria-label="search_date"
          @input="searchChange()"
        />
        <button type="submit">찾기</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    search: {
      type: String,
    },
  },
  data() {
    return {
      s_year: '',
      s_month: '',
      s_day: '',
      e_year: '',
      e_month: '',
      e_day: '',
      start_month: '',
      start_day: '',
      end_month: '',
      end_day: '',
      search_data: this.search,
    }
  },
  computed: {
    start_year() {
      let year = []
      let date = Number(this.moment().tz('Asia/Seoul').format('YYYY'))
      for (let i = date; i > date - 5; i--) {
        year.push(String(i))
      }
      return year
    },
    end_year() {
      let year = []
      let date = Number(this.moment().tz('Asia/Seoul').format('YYYY'))
      for (let i = date; i > date - 5; i--) {
        year.push(String(i))
      }
      return year
    },
  },
  watch: {
    startDate() {
      this.start_month = this.moment(this.startDate).tz('Asia/Seoul').endOf('year').format('MM') * 1
      this.start_day = this.moment(this.startDate).tz('Asia/Seoul').endOf('month').format('DD') * 1
      this.s_year = this.moment(this.startDate).tz('Asia/Seoul').format('YYYY')
      this.s_month = this.moment(this.startDate).tz('Asia/Seoul').format('M')
      this.s_day = this.moment(this.startDate).tz('Asia/Seoul').format('D')
    },
    endDate() {
      this.end_month = this.moment(this.endDate).tz('Asia/Seoul').endOf('year').format('MM') * 1
      this.end_day = this.moment(this.endDate).tz('Asia/Seoul').endOf('month').format('DD') * 1
      this.e_year = this.moment(this.endDate).tz('Asia/Seoul').format('YYYY')
      this.e_month = this.moment(this.endDate).tz('Asia/Seoul').format('M')
      this.e_day = this.moment(this.endDate).tz('Asia/Seoul').format('D')
    },
  },
  methods: {
    dateChange() {
      this.$emit(
        'dateChange',
        this.s_year,
        this.s_month,
        this.s_day,
        this.e_year,
        this.e_month,
        this.e_day,
      )
    },
    agoSelect(num, date) {
      this.$emit('agoSelect', num, date)
    },
    allList() {
      this.$emit('allList')
    },
    searchDayBtn() {
      this.$emit('searchDay')
    },
    searchChange() {
      this.$emit('searchChange', this.search_data)
    },
    searchNumBtn(e) {
      this.$emit('searchNum', e.target[0].value)
    },
  },
}
</script>

<style scoped lang="scss">
.searchWrap {
  button {
    padding: 3px;
    background-color: white;
    border: 1px solid #000;
  }
  > h2 {
    font-size: 16px;
    color: #707070;
    text-align: left;
    border-bottom: 2px solid #ff6699;
    font-weight: 400;
  }

  > div {
    width: 100%;
    border-bottom: 2px solid #ffe1ec;
    height: 44px;
    line-height: 44px;
  }

  > .selectSearch {
    > .searchType {
      display: inline-block;
      width: 150px;
      text-align: center;
      font-size: 12px;
    }

    select {
      height: 25px;
      padding: 0 3px;
      border: 1px solid #ffe1ec;
      font-size: 12px;
    }

    .search {
      margin-right: 30px;
    }

    button {
      background-color: #ffe1ec;
      font-size: 12px;
      border: none;
      height: 25px;
      width: 46px;
      margin-left: 11px;
    }
  }

  > .nameSearch {
    form {
      display: inline-block;
      > input {
        width: 156px;
        height: 25px;
        font-size: 12px;
        box-sizing: border-box;
        border: 1px solid #ffe1ec;
        text-indent: 10px;
      }

      > button {
        background-color: #ffe1ec;
        font-size: 12px;
        border: none;
        height: 25px;
        width: 46px;
        margin-left: 11px;
      }
    }

    > .searchType {
      display: inline-block;
      width: 150px;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
