import Cookies from 'js-cookie'
import axios from '../../plugins/Axios'
import Path from '../../plugins/Path'
import ErrorMessage from '../../plugins/ErrorMessage'

export const UserInfo = {
  namespaced: true,
  state: () => ({
    userInfo: {
      address: null,
      birth: null,
      detailed_address: null,
      email: null,
      phone: null,
      name: null,
      ranking: null,
      user_id: null,
      vip_end_day: null,
      zip_code: null,
      accumulate_price: 0,
    },
    userToken: null,
  }),
  mutations: {
    setUser(state, data) {
      state.userInfo = data.info
      state.userToken = data.token
    },
    logout(state) {
      state.userInfo = null
      state.userToken = null
    },
    CONFIRMATION(state, data) {
      state.userInfo.accumulate_price = data.accumulate_price
      state.userInfo.ranking = data.ranking
      state.userInfo.vip_end_day = data.vip_end_day
      this.dispatch('UserInfo/updateUserInfo')
    },
  },
  actions: {
    async login({ commit }, token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      await axios
        .get(Path.UserProfile, config)
        .then((res) => {
          if (res.data.success === true) {
            Cookies.set('user_token', token, { expires: 0.5 })
            Cookies.set('user_info', JSON.stringify(res.data.data))
            window.location.reload()
          }
        })
        .catch((err) => {
          this.dispatch('Message/message', {
            message: ErrorMessage.UserProfile(err.response.status),
            code: 0,
            type: 0,
          })
        })
    },
    getUserInfo({ commit, dispatch }) {
      let user_info = Cookies.get('user_info')
      let user_token = Cookies.get('user_token')
      if (user_token) {
        if (user_info) {
          user_info = JSON.parse(user_info)
          let user = {
            info: user_info,
            token: user_token,
          }
          commit('setUser', user)
        } else {
          dispatch('login', user_token)
        }
      } else {
        Cookies.remove('user_info')
      }
    },
    updateUserInfo({ state }) {
      Cookies.set('user_info', JSON.stringify(state.userInfo))
    },
    logout({ commit }) {
      Cookies.remove('user_info')
      Cookies.remove('user_token')
      window.location.reload()
    },
    confirmation({ commit }, data) {
      commit('CONFIRMATION', data)
    },
  },
  getters: {
    userInfo(state) {
      return state.userInfo
    },
    userToken(state) {
      return state.userToken
    },
  },
}
