import store from '../store'

const methods = {
  Message: (message, code, type, data) => {
    store.dispatch('Message/message', {
      message,
      code,
      type,
      data,
    })
  },
}
export default methods.Message
