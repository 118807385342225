<template>
  <div class="footerWrap">
    <div class="footer">
      <div class="footTop clearFix">
        <ul class="footNav">
          <li class="footLogo">
            <img src="@/assets/main/dongne_foot_logo.svg" alt="" />
          </li>
          <li>
            <span class="footTitle">동네공작소</span>
            <ul class="subNav">
              <li>동네공작소 소개</li>
              <li>
                <router-link :to="{ name: 'Review' }"> 리뷰 </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'NewsList',
                    params: { type: 'Event' },
                    query: { page: 1, status: 'now' },
                  }"
                >
                  이벤트
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'NewsList', params: { type: 'Notice' }, query: { page: 1 } }"
                >
                  공지사항
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <span class="footTitle">문의</span>
            <ul class="subNav">
              <li>
                <router-link :to="{ name: 'Service' }"> 제작/이용문의 </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Service' }"> 협력문의 </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Guide', params: { type: 'Cham' } }">
                  작업가이드
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <span class="footTitle">소셜미디어</span>
            <ul class="subNav sns">
              <li>
                <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                <a href="https://twitter.com/DONGNECRAFT" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/main/twitter.svg" alt="" />
                </a>
              </li>
              <li>
                <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                <a href="https://twitter.com/DONGNECRAFT" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/main/facebook.svg" alt="" />
                </a>
              </li>
              <li>
                <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                <a href="https://twitter.com/DONGNECRAFT" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/main/instagram.svg" alt="" />
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span class="footTitle">연락처</span>
            <ul class="subNav">
              <li class="call">070 - 8848 - 8489</li>
              <li>상담 가능시간 : 평일 10 : 00 ~ 18 : 00</li>
              <li class="right">(점심시간 : 13 : 00 ~ 14 : 00)</li>
              <li>craft.dongne@gmail.com</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="info">
        <p>© Copyright {{ moment().format('YYYY') }} | All Rights Reserved by 동네공작소</p>
        <p>사업자 등록 번호 875-29-00481 | 통신 판매업 신고번호 제2018-서울동작-0422호</p>
        <p>
          서울시 동작구 본동 485, 아파트 상가건물 2층(동네공작소) | 070-8848-8489 |
          craft.dongne@gmail.com
        </p>
        <p>
          <router-link target="_blank" :to="{ name: 'ToS', params: { type: 'terms' } }">
            이용약관
          </router-link>
          |
          <router-link target="_blank" :to="{ name: 'ToS', params: { type: 'privacy' } }">
            개인정보취급방침
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.footerWrap {
  background-color: #ff6699;
  min-width: 1080px;
  height: 390px;

  > .footer {
    width: 1160px;
    margin: 0 auto;

    .footNav {
      width: 100%;
      height: 230px;
      border-bottom: 1px solid #fff;
      float: left;

      > .footLogo {
        margin-right: 100px;
      }

      > li {
        float: left;
        margin-top: 37px;
        margin-right: 120px;
        text-align: left;

        &:last-child {
          margin-right: 0px;
        }

        > .footTitle {
          font-size: 20px;
          color: #fff;
          margin-bottom: 17px;
          display: block;
        }

        > .subNav {
          > li {
            margin-bottom: 5px;
            color: #fff;
            font-size: 14px;

            &.right {
              text-align: center;
            }

            > a {
              color: #fff;
            }

            > .call {
              font-size: 28px;
            }
          }
        }

        > .sns {
          li {
            float: left;
            margin-right: 13px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .info {
      text-align: left;
      font-size: 10px;
      color: #fff;
      font-weight: 300;
      margin-top: 39px;

      > p {
        margin-top: 0;
        margin-bottom: 0.5rem;

        > a {
          color: #fff;
        }
      }
    }
  }
}
</style>
