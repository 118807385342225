<template>
  <div class="leftWrap">
    <div class="stepWrap">
      <!-- 제작기간설정 -->
      <order-period
        v-if="
          type === '아크릴 키링' ||
          type === '아크릴 스탠드' ||
          type === '아크릴 카드텍' ||
          type === '부착형 아크릴'
        "
        :path="name"
        :period-num="periodNum"
        @check-period="selOption"
      />
      <!-- 두께 설정 -->
      <thick
        v-if="type !== '쉐이커 키링'"
        :path="name"
        :thick-num="thickNum"
        @check-thick="selOption"
      />
      <!-- 아크릴 종류 -->
      <kind
        v-if="thickNum === 2"
        :path="name"
        :type="type"
        :kind-num="kindNum"
        :period="goods.period"
        :print="goods.print"
        @check-kind="selOption"
        @print-popup="printPopup"
      />
      <!-- 홀로그램 종류 -->
      <hologram-kind
        v-if="thickNum === 2 && kindNum === 1 && type !== '부착형 아크릴'"
        :path="name"
        :hologram-num="hologramNum"
        @check-hologram="selOption"
      />
      <!-- 바닥부품 설정 -->
      <stand v-if="type === '아크릴 스탠드'" :stand-num="standNum" @check-stand="selOption" />
      <!-- 우드 램프 설정 -->
      <lamp v-if="type === '아크릴 램프'" :stand-num="standNum" @check-stand="selOption" />
      <!-- 사이즈 설정 -->
      <size
        :path="name"
        :xsize="goods.x"
        :ysize="goods.y"
        :x-length="xLength"
        :y-length="yLength"
        :preview-xsize="previewXsize"
        :preview-ysize="previewYsize"
        @size-change="sizeChange"
        @location="location"
        @location-over="locationOver"
      />
      <!-- 인쇄면 설정 -->
      <print
        :path="name"
        :print-num="printNum"
        :kind="goods.kind"
        @check-print="selOption"
        @print-popup="printPopup"
      />
      <!-- 두번째 인쇄면 설정 -->
      <secondPrint
        v-if="type === '쉐이커 키링'"
        :sec-print-num="secPrintNum"
        @check-print="selOption"
      />
      <!-- 파츠 인쇄면 설정 -->
      <partsPrint
        v-if="type === '쉐이커 키링'"
        :parts-print-num="partsPrintNum"
        @check-print="selOption"
      />
      <!-- 부자재 설정 -->
      <item
        v-if="type !== '아크릴 램프' && type !== '아크릴 블럭' && type !== '입체 아크릴 블럭'"
        :path="name"
        :item-num="itemNum"
        @check-item="selOption"
        @wiring-popup="wiringPopup"
      />
      <!-- 군번줄 색상 설정 -->
      <dog-tag-color
        v-if="itemNum === 2 && goods.etc === '군번줄'"
        :path="name"
        :tag-color-num="tagColorNum"
        @check-tag-color="selOption"
      />
      <!-- 와이어링 색상 설정 -->
      <wire-color
        v-if="itemNum === 5 && goods.etc === '와이어링'"
        :path="name"
        :wire-color-num="wireColorNum"
        @check-wire-color="selOption"
      />
      <!-- 뱃지 종류 설정 -->
      <badge-kind
        v-if="itemNum === 1 && goods.etc === '뱃지'"
        :path="name"
        :badge-kind-num="badgeKindNum"
        @check-badge-kind="selOption"
      />
      <!-- 스마트톡 색상 설정 -->
      <tok-color
        v-if="itemNum === 2 && goods.etc === '스마트톡'"
        :path="name"
        :tok-color-num="tokColorNum"
        @check-tok-color="selOption"
      />
      <!-- 키링 옵션 설정 -->
      <keyring
        v-if="itemNum === 4 && goods.etc === '고리'"
        :path="name"
        :keyring-kind-num="keyringKindNum"
        :keyring-color-num="keyringColorNum"
        @check-kind="selOption"
        @check-color="selOption"
      />
      <!-- 조립/포장 설장 -->
      <packing
        v-if="goods.period !== '빠른제작'"
        :path="name"
        :packing-num="packingNum"
        @check-packing="selOption"
      />
      <!-- 파일 업로드 -->
      <file-upload
        :path="name"
        :file="goods.file"
        :request="goods.design_request"
        @select-file="selectFile"
        @check-request="selOption"
      />
      <!-- 도안수량, 주문수량 -->
      <bolt
        v-if="type === '쉐이커 키링'"
        :bolt="goods.bolt_set"
        :shaker-size-hole="shakerSizeHole"
        @bolt-change="selOption"
        @bolt-check="selOption"
        @speech-view="speechView"
        @speech-hide="speechHide"
      />
      <!-- 도안수량, 주문수량 -->
      <quantity
        :path="name"
        :type="type"
        :design="goods.design_quantity"
        :quantity="goods.order_quantity"
        :speech-bubble="speechBubble"
        @quantity-change="quantityChange"
        @quantity-check="quantityCheck"
        @speech-view="speechView"
        @speech-hide="speechHide"
      />
    </div>
    <div class="cartBtn" @click="cartAdd()" @keydown="cartAdd()">장바구니에 담기</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import thick from '../List/GoodsThick.vue'
import kind from '../List/GoodsKind.vue'
import stand from '../List/GoodsStand.vue'
import lamp from '../List/GoodsLamp.vue'
import size from '../List/GoodsSize.vue'
import print from '../List/GoodsPrint.vue'
import item from '../List/GoodsItem.vue'
import wireColor from '../List/WiringColor.vue'
import dogTagColor from '../List/DogTagColor.vue'
import tokColor from '../List/TokColor.vue'
import badgeKind from '../List/BadgeKind.vue'
import keyring from '../List/GoodsKeyring.vue'
import packing from '../List/GoodsPacking.vue'
import FileUpload from '../List/FileUpload.vue'
import quantity from '../List/GoodsQuantity.vue'
import OrderPeriod from '../List/OrderPeriod.vue'
import secondPrint from '../List/SecondPrint.vue'
import partsPrint from '../List/PartsPrint.vue'
import bolt from '../List/GoodsBolt.vue'
// 10.30
import HologramKind from '../List/HologramKind.vue'

export default {
  components: {
    thick,
    kind,
    stand,
    lamp,
    size,
    print,
    item,
    dogTagColor,
    wireColor,
    tokColor,
    badgeKind,
    keyring,
    packing,
    FileUpload,
    quantity,
    OrderPeriod,
    secondPrint,
    partsPrint,
    bolt,
    HologramKind,
  },
  props: {
    name: {
      type: String,
    },
    thickNum: {
      type: Number,
    },
    kindNum: {
      type: Number,
    },
    hologramNum: {
      type: Number,
    },
    standNum: {
      type: Number,
    },
    printNum: {
      type: Number,
    },
    secPrintNum: {
      type: Number,
    },
    partsPrintNum: {
      type: Number,
    },
    itemNum: {
      type: Number,
    },
    periodNum: {
      type: Number,
    },
    wireColorNum: {
      type: Number,
    },
    tagColorNum: {
      type: Number,
    },
    tokColorNum: {
      type: Number,
    },
    badgeKindNum: {
      type: Number,
    },
    packingNum: {
      type: Number,
    },
    keyringKindNum: {
      type: Number,
    },
    keyringColorNum: {
      type: Number,
    },
    xLength: {
      type: Number,
    },
    yLength: {
      type: Number,
    },
    previewXsize: {
      type: Number,
    },
    previewYsize: {
      type: Number,
    },
    speechBubble: {
      type: Boolean,
    },
    shakerSizeHole: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      goods: 'Goods/goods',
      type: 'Goods/type',
    }),
  },
  methods: {
    selOption(type, i, value) {
      this.$emit('selOption', type, i, value)
    },
    sizeChange(xsize, ysize) {
      this.$emit('sizeChange', xsize, ysize, this.goods.etc)
    },
    location(xsize, ysize) {
      this.$emit('location', xsize, ysize, this.goods.etc)
    },
    locationOver(x, y) {
      this.$emit('locationOver', x, y)
    },
    quantityChange(i, path, setQuantity, setDesign) {
      this.$emit('quantityChange', i, path, setQuantity, setDesign)
    },
    quantityCheck(i, type, path, setQuantity, setDesign) {
      this.$emit('quantityCheck', i, type, path, setQuantity, setDesign)
    },
    selectFile() {
      this.$emit('selectFile')
    },
    cartAdd() {
      this.$emit('cartAdd')
    },
    speechView() {
      this.$emit('speechView')
    },
    speechHide() {
      this.$emit('speechHide')
    },
    // 아크릴 종류 투명쉘 선택후 인쇄 양면선택시 안내문구 호출
    printPopup() {
      this.$emit('printPopup')
    },
    // 부자재 와이어링 선택시 안내문구 호출
    wiringPopup() {
      this.$emit('wiringPopup')
    },
  },
}
</script>

<style scoped lang="scss">
.leftWrap {
  float: left;
  width: 750px;
  > .stepWrap {
    margin-top: 46px;
  }

  > .cartBtn {
    width: 574px;
    height: 45px;
    line-height: 45px;
    border-radius: 10px;
    background-color: #ff6699;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 0 auto 50px;
    cursor: pointer;
  }
  .disabled {
    background-color: #777;
    pointer-events: none;
  }
}
</style>
