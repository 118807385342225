<template>
  <div class="finish">
    <h1>마무리</h1>
    <!-- type === '아크릴 키링' -->
    <div v-show="type === '아크릴 키링'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/cham_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 세 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <!-- type === '아크릴 카드텍' -->
    <div v-show="type === '아크릴 카드텍'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/card_finish_1.png" alt="" />
        </div>
        <div class="card_img_1 right">
          <p>1. 세 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <!-- type === '아크릴 스탠드' -->
    <div v-show="type === '아크릴 스탠드'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/stand_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 세 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <div v-show="type === '자유형 책갈피'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/bookmark_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 네 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <!-- type === '아크릴 바닥 부품' -->
    <div v-show="type === '아크릴 바닥 부품'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/floor_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 네 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <!-- type === '아크릴 램프' -->
    <div v-show="type === '아크릴 램프'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/lamp_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 세 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <!-- type === '부착형 아크릴' -->
    <div v-show="type === '부착형 아크릴'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/attach_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 네 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <!-- type === '아크릴 블럭' -->
    <div v-show="type === '아크릴 블럭'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/block_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 세 가지 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
    <!-- type === '쉐이커 키링' -->
    <div v-show="type === '쉐이커 키링'" class="typeWrap">
      <div class="img_half clearFix">
        <div class="left">
          <img src="@/assets/guide/shaker_keyring_finish_1.png" alt="" />
        </div>
        <div class="img_1 right">
          <p>1. 레이어가 모두 구성되었는지 확인합니다.</p>
          <p>2. 작업한 파일은 PSD로 저장하여 zip로 압축해 주문 시 첨부합니다.</p>
        </div>
      </div>
      <!--  -->
      <div>
        <p>* 반드시 위 작업 과정을 따를 필요는 없으며, 구성만 맞춰주시면</p>
        <p>ai 작업 파일(레이어 분리 필수)도 접수가 가능합니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.finish {
  text-align: center;
  h1 {
    width: 100%;
    margin-bottom: 30px;
  }
  > .typeWrap {
    > div {
      margin-top: 50px;
      figure {
        margin-top: 10px;
      }
      p {
        font-size: 20px;
        color: #707070;
        font-weight: 500;
        margin-top: 20px;

        > span {
          color: #ff6699;
        }
      }

      &.img_half {
        > div {
          float: left;
          margin-left: 20px;
          text-align: left;

          &.img_1 {
            margin-top: 130px;
          }
          &.card_img_1 {
            margin-top: 80px;
          }
        }
      }
    }
  }
}
</style>
