import store from '../store'

// const { sizeBox } = store.state.SizeBox
const AutoSize = {
  quoinSize: async (sizeBox) => {
    let imgSrc = document.getElementById('quoin_img').src
    let ctx3 = document.getElementById('quoin_canvas3').getContext('2d')
    let ctx2 = document.getElementById('quoin_canvas2').getContext('2d')
    let img = new Image()
    let canvas = document.getElementById('quoin_canvas2')
    let ctx4 = document.getElementById('quoin_canvas3')
    let img_width = 0
    let img_height = 0
    let imgPoint = 5
    let svgScale = 5

    img.onload = async () => {
      let canvasWidth = img.width + sizeBox.boxMargin * 2
      let canvasHeight = img.height + sizeBox.boxMargin * 2
      ctx4.width = img.width + sizeBox.boxMargin * 2
      ctx4.height = img.height + sizeBox.boxMargin * 2
      canvas.width = img.width + sizeBox.boxMargin * 2
      canvas.height = img.height + sizeBox.boxMargin * 2
      img_width = img.width
      img_height = img.height
      await ctx2.drawImage(img, 0, 0)
      let imageData2 = await ctx2.getImageData(0, 0, canvasWidth, canvasHeight)
      let buf2 = new ArrayBuffer(imageData2.data.length)
      let buf82 = new Uint8ClampedArray(buf2)
      let data2 = new Uint32Array(buf2)
      for (let y = 0; y < canvasHeight; ++y) {
        for (let x = 0; x < canvasWidth; ++x) {
          let value = imageData2.data[(y * canvasWidth + x) * 4 + 3]

          if (value < 5) {
            data2[y * canvasWidth + x] = (0 << 24) | (255 << 16) | (255 << 8) | 255
          } else {
            data2[y * canvasWidth + x] = (255 << 24) | (255 << 16) | (255 << 8) | 255
          }
        }
      }
      imageData2.data.set(buf82)
      await ctx3.putImageData(imageData2, 0, 0)
      let imgstring = await ctx4.toDataURL('image/png')
      let url = await imgstring
      let imgObj = new Image()
      imgObj.src = `${url}`
      imgObj.onload = async () => {
        let tracedimg

        let imageStyle = {
          width: '0px',
          height: '0px',
        }
        await ImageTracer.imageToSVG(
          imgObj.src,
          async (svgstr) => {
            document.getElementById('goods_quoin_div_1').innerHTML = svgstr
            document.getElementById('goods_quoin_div_1').style.marginLeft = -img.width / 2
            let page = document.getElementById('goods_quoin_div_1').firstChild
            let image = document.getElementById('goods_quoin_div_2').firstChild
            page.id = 'star'
            let svgPoint
            // eslint-disable-next-line no-unused-expressions
            img.width > img.height ? (imgPoint = img.width) : (imgPoint = img.height)
            // eslint-disable-next-line no-unused-expressions
            img.width > img.height
              ? (svgPoint = img.width + sizeBox.boxMargin)
              : (svgPoint = img.height + sizeBox.boxMargin)

            // 실제로는 비율에 따라서 스케일링이 이루어지는 부분을 써야함. 예를 들어서 이미지가 가로사이즈에 의해 리사이징 되면, boxWidth, 세로사이즈에 대하 리사이징 되면 boxHeight. 지금은 boxWidth와 boxheight가 300으로 고정이라. 불필요함.
            svgScale = sizeBox.boxWidth / (sizeBox.boxMargin * 2 + imgPoint * 1)
            // svgScale = sizeBox.dpi / (sizeBox.boxMargin * 2 + imgPoint * 1)
            store.dispatch('SizeBox/imgSizeChange', imgPoint)
            await page.setAttribute(
              'viewBox',
              `-${sizeBox.boxMargin} -${sizeBox.boxMargin} ${img.width + sizeBox.boxMargin * 2} ${
                img.height + sizeBox.boxMargin * 2
              }`,
            )
            await page.setAttribute('width', sizeBox.boxWidth)
            await page.setAttribute('height', sizeBox.boxHeight)
            imageStyle.width = `${img.width * svgScale}px`
            imageStyle.height = `${img.height * svgScale}px`
            store.dispatch('SizeBox/styleChange', imageStyle)
            let path = page.childNodes
            for (let i = path.length - 1; i >= 0; i--) {
              if (path[i].getAttribute('fill') === 'rgb(255,255,255)') {
                path[i].id = 'path'
                // path[i].style.transform = 'scale(0.5)'
              } else {
                path[i].remove()
              }
            }

            path.id = 'path'

            await AutoSize.quoinBtn(sizeBox, img.width, img.height, svgScale)
          },
          { ltres: 1, qtres: 0, scale: 1, strokewidth: 0 },
        )
      }
    }
    img.src = imgSrc
  },

  quoinBtn: async (sizeBox, originalW, originalH, svgScale) => {
    let cont = document.getElementById('star')
    let i
    let ii
    let j // 변수들 선언
    let path = document.getElementById('path')
    let imgSrc = document.getElementById('quoin_img').src
    let d = path.getAttribute('d')
    let path1 = AutoSize.svgpath_to_clipper_polygons(d)
    let scale = 10
    let offset = AutoSize.mtp(2, sizeBox) * 2
    let dragOffset = AutoSize.mtp(2.5, sizeBox) * 2
    let xsize = 4
    let ysize = 4
    cont.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')
    await ClipperLib.JS.ScaleUpPaths(path1, scale)
    let joinTypes = [
      ClipperLib.JoinType.jtSquare,
      ClipperLib.JoinType.jtRound,
      ClipperLib.JoinType.jtMiter,
    ]
    let endType = ClipperLib.EndType.etClosedPolygon // 가장 가까운 패스로 닫음
    let co = new ClipperLib.ClipperOffset() // 컨스트럭터 co변수에 객체형 함수 저장
    let offsetted_paths = new ClipperLib.Paths() // 오프셋된 패스를 저장할 빈 솔루션
    await co.Clear() // 혹시라도 들어온게 있으면 비우기
    await co.AddPaths(path1, joinTypes[1], endType) // 패스를 넣고, 연결방법과 마감방법을 정함
    co.MiterLimit = 2 // 파악중
    co.ArcTolerance = 0.25 // 파악중
    await co.Execute(offsetted_paths, offset * scale) // 비어있던 솔루션에 오프셋된 함수 저장

    path.setAttribute('d', AutoSize.paths2string(offsetted_paths, scale))
    path.setAttribute('fill', 'rgb(255,255,255)')
    let dragPath = `<path id="dragged" fill="rgb(255,255,255)" opacity="0" d="M Z" ></path>`
    cont.innerHTML += dragPath
    let offsetted_paths2 = new ClipperLib.Paths() // 오프셋된 패스를 저장할 빈 솔루션
    let dragPath2 = document.getElementById('dragged')

    co.Clear() // 혹시라도 들어온게 있으면 비우기
    co.AddPaths(path1, joinTypes[1], endType) // 패스를 넣고, 연결방법과 마감방법을 정함
    co.MiterLimit = 2 // 파악중
    co.ArcTolerance = 0.25 // 파악중
    co.Execute(offsetted_paths2, dragOffset * scale)
    dragPath2.setAttribute('d', AutoSize.paths2string(offsetted_paths2, scale))
    let imgWidth = Math.ceil((($('#dragged').width() / svgScale) * 25.4) / sizeBox.dpi)
    xsize = imgWidth
    let imgHeight = Math.ceil((($('#dragged').height() / svgScale) * 25.4) / sizeBox.dpi)
    ysize = imgHeight

    let drag = `<g id="drag" strock="rgb(255,255,255)"><circle class="oval" fill="rgb(255,255,255)" cx="${
      AutoSize.mtp(7, sizeBox) / 2
    }" cy="${AutoSize.mtp(7, sizeBox) / 2}" r="${
      AutoSize.mtp(7, sizeBox) / 2
    }" /><circle fill="rgb(255,225,236)" class="arrow" cx="${AutoSize.mtp(7, sizeBox) / 2}" cy="${
      AutoSize.mtp(7, sizeBox) / 2
    }" r="${AutoSize.mtp(2.5, sizeBox) / 2}" /></g>`
    let img = `<image width="${originalW}" height="${originalH}" xlink:href="${imgSrc}"></image>`
    cont.innerHTML = cont.innerHTML + img + drag
    // let outline2 = spo(d, 24 , {joints:0,bezierAccuracy:1,tagName:'path'});
    let svgsmoother = paper.setup()
    let SVG_object = `${cont}`
    await svgsmoother.project.importSVG(SVG_object, (item) => {
      let path2 = item.children[1] // 반환된 item의 두번째 객체를 가져옴(첫번째 패스값이 반환됨)
      // 패스 총 개수를 반환함. 아래와 비교 후 얼마나 줄었는지 확인 가능.
      path2.simplify(50) // 패스 총 개수를 줄이면서 부드럽게 만듬
      // 패스 총 개수를 반환함. 위와 비교 후 얼마나 줄었는지 확인 가능.
      // path.fullySelected = true // canvas 이용시 시각적 확인 가능
      cont.innerHTML = item.exportSVG({ asString: true }) // svg 객체로 반환. path.exportSVG() 로 path 값만 반환 하여 기존 svg에 넣는것도 가능.
    })
    document.getElementById('quoin_mask').style.display = 'none'
    document.getElementById('quoin_loading').src = ''
    await store.dispatch('Goods/pSize', {
      x: Math.ceil(xsize / 10),
      y: Math.ceil(ysize / 10),
    })
    const xbar = document.getElementById('quoin_xsize_bar')
    const ybar = document.getElementById('quoin_ysize_bar')
    const xbarSize = document.getElementById('dragged').getBoundingClientRect().width
    const ybarSize = document.getElementById('dragged').getBoundingClientRect().height
    xbar.style.width = `${xbarSize}px`
    ybar.style.height = `${ybarSize}px`
    await AutoSize.dragEvent()
  },

  dragEvent: async () => {
    let DEG = 180 / Math.PI
    let drag = document.querySelector('#drag')
    let path = document.querySelector('#dragged')
    let page = document.querySelector('#star')
    let path2 = document.querySelector('#path')
    let pathLength = path.getTotalLength() || 0
    let startPoint = path.getPointAtLength(0)
    let startAngle = AutoSize.getRotation(startPoint, path.getPointAtLength(0.1))
    await path2.setAttribute('opacity', 1)
    await TweenLite.set(drag, {
      transformOrigin: 'center',
      rotation: `${startAngle}_rad`,
      xPercent: -50,
      yPercent: -50,
      x: startPoint.x,
      y: startPoint.y,
    })
    let draggable = new Draggable(drag, {
      liveSnap: {
        points: (point) => {
          let p = AutoSize.closestPoint(path, pathLength, point, DEG)
          store.dispatch('Drag/dragMessage', '')
          TweenLite.set(drag, {
            rotation: p.rotation,
          })
          return p.point
        },
      },
    })
    await TweenLite.set('#goods_quoin_div_1', {
      autoAlpha: 1,
    })

    function pointModifier(point) {
      let p = AutoSize.closestPoint(path, pathLength, point, DEG)
      TweenLite.set(drag, {
        rotation: p.rotation,
      })

      return p.point
    }

    await AutoSize.dragBox()
  },

  getRotation: (p1, p2) => {
    let dx = p2.x - p1.x
    let dy = p2.y - p1.y
    return Math.atan2(dy, dx)
  },

  closestPoint: (pathNode, pathLength, point, DEG) => {
    let precision = 8
    let best
    let bestLength
    let bestDistance = Infinity

    // linear scan for coarse approximation
    for (
      let scan, scanLength = 0, scanDistance;
      scanLength <= pathLength;
      scanLength += precision
    ) {
      if (
        // eslint-disable-next-line no-cond-assign
        (scanDistance = AutoSize.distance2((scan = pathNode.getPointAtLength(scanLength)), point)) <
        bestDistance
      ) {
        // eslint-disable-next-line no-unused-expressions
        ;(best = scan), (bestLength = scanLength), (bestDistance = scanDistance)
      }
    }

    // binary search for precise estimate
    precision /= 2
    while (precision > 0.5) {
      let before
      let after
      let beforeLength
      let afterLength
      let beforeDistance
      let afterDistance
      if (
        // eslint-disable-next-line no-cond-assign
        (beforeLength = bestLength - precision) >= 0 &&
        // eslint-disable-next-line no-cond-assign
        (beforeDistance = AutoSize.distance2(
          // eslint-disable-next-line no-cond-assign
          (before = pathNode.getPointAtLength(beforeLength)),
          point,
        )) < bestDistance
      ) {
        // eslint-disable-next-line no-unused-expressions
        ;(best = before), (bestLength = beforeLength), (bestDistance = beforeDistance)
      } else if (
        // eslint-disable-next-line no-cond-assign
        (afterLength = bestLength + precision) <= pathLength &&
        // eslint-disable-next-line no-cond-assign
        (afterDistance = AutoSize.distance2(
          // eslint-disable-next-line no-cond-assign
          (after = pathNode.getPointAtLength(afterLength)),
          point,
        )) < bestDistance
      ) {
        // eslint-disable-next-line no-unused-expressions
        ;(best = after), (bestLength = afterLength), (bestDistance = afterDistance)
      } else {
        precision /= 2
      }
    }

    let len2 = bestLength + (bestLength === pathLength ? -0.1 : 0.1)
    let rotation = AutoSize.getRotation(best, pathNode.getPointAtLength(len2))

    return {
      point: best,
      rotation: rotation * DEG,
      // distance: Math.sqrt(bestDistance),
    }
  },
  distance2: (p, point) => {
    let dx = p.x - point.x
    let dy = p.y - point.y
    return dx * dx + dy * dy
  },

  svgpath_to_clipper_polygons(d) {
    let arr
    d = d.trim()
    arr = Raphael.parsePathString(d) // str to array
    // eslint-disable-next-line no-underscore-dangle
    arr = Raphael._pathToAbsolute(arr) // mahvstcsqz -> uppercase
    let str = Array.prototype.concat.apply([], arr).join(' ')
    let paths = str.replace(/M/g, '|M').split('|')
    let k
    let polygons_arr = []
    let polygon_arr = []
    for (k = 0; k < paths.length; k++) {
      // eslint-disable-next-line no-continue
      if (paths[k].trim() === '') continue
      arr = Raphael.parsePathString(paths[k].trim())
      polygon_arr = []
      let i = 0
      let j
      let m = arr.length
      let letter = ''
      let x = 0
      let y = 0
      let pt = {}
      let subpath_start = {}
      subpath_start.x = ''
      subpath_start.y = ''
      for (; i < m; i++) {
        letter = arr[i][0].toUpperCase()
        // eslint-disable-next-line no-continue
        if (letter !== 'M' && letter !== 'L' && letter !== 'Z') continue
        if (letter !== 'Z') {
          for (j = 1; j < arr[i].length; j += 2) {
            if (letter === 'V') y = arr[i][j]
            else if (letter === 'H') x = arr[i][j]
            else {
              x = arr[i][j]
              y = arr[i][j + 1]
            }
            pt = {}
            pt.X = null
            pt.Y = null
            // eslint-disable-next-line no-restricted-globals
            if (typeof x !== 'undefined' && !isNaN(Number(x))) pt.X = Number(x)
            // eslint-disable-next-line no-restricted-globals
            if (typeof y !== 'undefined' && !isNaN(Number(y))) pt.Y = Number(y)
            if (pt.X !== null && pt.Y !== null) {
              polygon_arr.push(pt)
            } else {
              return false
            }
          }
        }
        if ((letter !== 'Z' && subpath_start.x === '') || letter === 'M') {
          subpath_start.x = x
          subpath_start.y = y
        }
        if (letter === 'Z') {
          // eslint-disable-next-line prefer-destructuring
          x = subpath_start.x
          // eslint-disable-next-line prefer-destructuring
          y = subpath_start.y
        }
      }
      polygons_arr.push(polygon_arr)
    }
    return polygons_arr
  },

  mtp(mm, sizeBox) {
    return (mm * sizeBox.dpi) / 25.4
  },
  ptm(px) {
    return (px * 25.4) / sizeBox.dpi
  },
  paths2string(paths, scale) {
    let svgpath = ''
    let i
    let j
    if (!scale) scale = 1
    for (i = 0; i < paths.length; i++) {
      for (j = 0; j < paths[i].length; j++) {
        if (!j) svgpath += 'M'
        else svgpath += 'L'
        svgpath += `${paths[i][j].X / scale}, ${paths[i][j].Y / scale}`
      }
      svgpath += 'Z'
    }
    if (svgpath === '') svgpath = 'M0,0'
    return svgpath
  },

  dragBox() {
    let target = document.getElementById('dragTarget').getBoundingClientRect()
    let target2 = document.querySelector('#drag').getBoundingClientRect()

    let x1 = target2.x - target.x
    let y1 = target2.y - target.y
    // store.dispatch('Drag/dragInfo', {
    //   top: `${y1}px`,
    //   left: `${x1 + 50}px`,
    // })
  },
}

export default AutoSize
