<template>
  <div class="contents clearFix">
    <div class="dpiBox">
      <ul class="clearFix">
        <li :class="{ active: dpi === 100 }" @click="dpiChange(0)" @keydown="dpiChange(0)">
          <span>100dpi</span>
        </li>
        <li :class="{ active: dpi === 300 }" @click="dpiChange(1)" @keydown="dpiChange(1)">
          <span>300dpi</span>
        </li>
        <li :class="{ active: dpi === 720 }" @click="dpiChange(2)" @keydown="dpiChange(2)">
          <span>720dpi</span>
        </li>
      </ul>
      <div class="dpiEx clearFix">
        <div
          class="dpiSpan"
          @mouseover="dpiView()"
          @focus="dpiView()"
          @mouseleave="dpiHide()"
          @focusout="dpiHide()"
        >
          <img src="@/assets/icon/questionIcon.svg" alt="" />
          <span>dpi란?</span>
        </div>
        <div id="dpiArea" class="dpiArea">
          <div class="text">
            <p>DPI는 Dots Per Inch의 약자로</p>
            <p>실제 크기 1인치 당 점이 몇개 들어가는가를 나타냅니다.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="executionBox">
      <div @click="cancelQuoin()" @keydown="cancelQuoin()">
        <img src="@/assets/icon/resetIcon.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dpi: {
      type: Number,
    },
  },
  methods: {
    dpiView() {
      this.$emit('dpiView')
    },
    dpiHide() {
      this.$emit('dpiHide')
    },
    cancelQuoin() {
      const type = this.$route.params.type.toLowerCase()
      this.$emit('cancelQuoin', type)
    },
    dpiChange(i) {
      this.$emit('dpiChange', i)
    },
  },
}
</script>

<style scoped lang="scss">
.contents {
  > .dpiBox {
    float: left;
    margin-left: 11px;
    margin-top: 17px;

    > ul {
      > li {
        float: left;
        width: 64px;
        height: 22px;
        border-radius: 5px;
        box-shadow: 0px 1px 1px #00000029;
        text-align: center;
        line-height: 20px;
        margin-right: 10px;
        background-color: #fff;
        cursor: pointer;

        &.active {
          border: 1px solid #ff6699;
          background-color: #ffe1ec;
        }

        span {
          font-size: 10px;
          color: #707070;
        }
      }
    }

    > .dpiEx {
      position: relative;
      > .dpiSpan {
        float: left;
        cursor: pointer;

        > span {
          color: #ff6699;
          font-size: 12px;
          margin-left: 3px;
        }
      }

      > .dpiArea {
        width: 310px;
        height: 80px;
        position: absolute;
        left: 53px;
        top: 2px;
        background: url('../../../../assets/icon/speech_bubble.png') center center no-repeat;
        transition: 0.5s;
        opacity: 0;
        pointer-events: none;

        > .text {
          position: absolute;
          top: 20px;
          left: 20px;

          > p {
            font-size: 12px;
            color: #707070;
          }
        }
      }
    }
  }

  > .executionBox {
    float: right;

    > div {
      width: 47px;
      height: 33px;
      margin-top: 11px;
      margin-right: 6px;
      cursor: pointer;
    }
  }
}
</style>
